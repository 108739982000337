import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { Image } from 'modules/core/components/common/image/image';
import { Config, VisualizationType } from 'config';
import clsx from 'clsx';
import { GrowthComponentsVisualization } from 'config/interfaces/growth_settings.interface';
import { useSelector } from 'react-redux';
import { currentSelectedRoundSelector } from 'modules/core/store/selectors';
import scoreModuleStoreInitialState from 'modules/user/store/reducer/score.reducer';

const makeClasses = makeStyles<DefaultTheme, { config: GrowthComponentsVisualization }>((theme) => ({
  root: {
    width: 'auto',
    height: props => props.config.HEIGHT ?? 'auto',
  },
}));

export interface GrowthComponentProps {
  growthIndex: number,
  visualizationType?: VisualizationType,
  className?: string,
  isWithered?: boolean;
}

export const GrowthComponent: React.FC<GrowthComponentProps> = (props: GrowthComponentProps) => {
  const currentRound = useSelector(currentSelectedRoundSelector);
  const isWithered = props.isWithered;
  const type = props.visualizationType ?? currentRound?.visualizationType?.name ?? VisualizationType.FruitTrees;
  const growthComponentConfig = Config.GROWTH_SETTINGS.VISUALIZATIONS[type].COMPONENTS.find(component => component.growthIndex ===
    (isWithered ? -props.growthIndex : props.growthIndex));
  const classes = makeClasses({ config: Config.GROWTH_SETTINGS.VISUALIZATIONS[type] });
  return (
    <Image
      className={clsx(classes.root, props.className)}
      src={growthComponentConfig?.assetPath}
    />
  );
};
