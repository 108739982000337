export enum NewsMainTextType {
  QUEST = 'Quest',
  QUIZ = 'Quiz',
  PHOTO = 'Photo',
  VIDEO = 'Video',
  TEXT = 'Text',
}

export enum NewsHeaderType {
  TEXT = 'Text',
  BONUS = 'Bonus',
}

export interface INews {
  id: string;
  date: string;
  textHeader: string;
  addedAt: string;
  headerType: NewsHeaderType.TEXT | NewsHeaderType.BONUS;
  externalId?: string | IExternalId;  // id to photo/video etc, use this id + url pattern to get appropriate media
  mainText: string;
  type: NewsMainTextType
}

export interface INewsManage {
  id: string;
  date: string;
  textHeader: string;
  headerType: NewsHeaderType.TEXT | NewsHeaderType.BONUS;
  externalId?: string | IExternalId;  // id to photo/video etc, use this id + url pattern to get appropriate media
  mainText: string;
  type: NewsMainTextType;
  addedById: string;
  addedAt: string;
  addedBy: string;
}

export interface INewsPlain {
  id?: string,
  date?: string,
  textHeader: string,
  headerType: NewsHeaderType.TEXT,
  externalId?: string,
  mainText: string,
  type: NewsMainTextType,
  workerGroupIds: string[]
}

export interface IExternalId {
  fileToken: string
  id: string
}

export interface IThumbnail {
  id: string | undefined;
  thumbnailUrlLink: string;
}


