import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grow, Chip, Box } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { MultiSelectChipsListType } from './interfaces';

const makeClasses = makeStyles(theme => ({
  headbarRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: theme.spacing(8),
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`
  },
  selectAllCheckboxRoot: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export const MultiSelectChipsList: MultiSelectChipsListType = props => {
  const classes = makeClasses();
  return (
    <div className={clsx(classes.headbarRoot, props.classNames?.toolbarRoot)}>
      {!!props.selected.length &&
        props.selected.map((value, index) => (
          <Grow key={index} in={true}>
            <Box m={.25} display='inline-block'>
              <Chip
                color="primary"
                onClick={() => props.onSelect(value)}
                onDelete={() => props.onSelect(value)}
                deleteIcon={<ClearIcon />}
                variant="outlined"
                size="small"
                label={value.value}
              />
            </Box>
          </Grow>
        ))}
    </div>
  );
};
