import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const QuestionMarkSVG: React.FC<SVGComponentProps> = ({ className }) => {
  return (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <g id="Group_14693" data-name="Group 14693" transform="translate(-835 -499)">
    <g id="Ellipse_144" data-name="Ellipse 144" transform="translate(835 499)" fill="none" stroke="#4c4cff" strokeWidth="1.3">
      <circle cx="9" cy="9" r="9" stroke="none"/>
      <circle cx="9" cy="9" r="8.35" fill="none"/>
    </g>
    <path id="question-round" d="M11.9,14.966a.609.609,0,0,0-1.217,0Zm-1.217.008a.609.609,0,1,0,1.217,0Zm0-2.435a.609.609,0,1,0,1.217,0ZM8.271,8.471a.609.609,0,1,0,1.175.317Zm2.414,6.5v.008H11.9v-.008ZM13.119,9.22c0,.485-.3.742-.952,1.185a2.618,2.618,0,0,0-1.483,2.135H11.9c0-.44.283-.674.952-1.129A2.654,2.654,0,0,0,14.337,9.22ZM11.293,7.467A1.777,1.777,0,0,1,13.119,9.22h1.217a2.994,2.994,0,0,0-3.043-2.97ZM9.446,8.788a1.9,1.9,0,0,1,1.847-1.32V6.25A3.112,3.112,0,0,0,8.271,8.471Z" transform="translate(832.707 497.083)" fill="#4c4cff"/>
  </g>
  </svg>
  );
};
