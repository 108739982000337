import { HttpClientService } from 'modules/core/functional-services';
import { AppService, getService } from 'modules/core/services';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { IQuestionAddAnswersCommand, IQuiz, IQuizSettings, INextQuizToComplete } from 'modules/user/interfaces/quiz.interface';

export class QuizzesService {

  constructor(
    private appService = getService(AppService),
    private httpClientService = getService(HttpClientService)
  ) {
  }

  async getQuizById(id: string) {
    try {
      const { value }: IAPIResponse<IQuiz> = await this.httpClientService.get<IQuiz>(`/quizes/${id}`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
  async getQuizSettings() {
    try {
      const { value }: IAPIResponse<IQuizSettings> = await this.httpClientService.get<IQuizSettings>(`/quizes/settings`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getNextQuizToComplete(roundId: string, currentQuizId: string | undefined) {
    try {
      const { value }: IAPIResponse<INextQuizToComplete> = await this.httpClientService.get<INextQuizToComplete>(`/quizes/nextquiztocomplete/${roundId}?currentQuizId=${currentQuizId}`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  private sendQuizAnswerFile = (file: FormData) => this.httpClientService.post<FormData, string>(`/quizes/files`, file).catch(this.appService.handleError);
  private sendQuizAnswers = (quizId: string, payload: IQuestionAddAnswersCommand[], forceRepetition: boolean) => this.httpClientService.post(`/quizes/${quizId}/user-answers`, { answers: payload, forceRepetition});

  public getQuizFile = async (fileID: string) => {
    try {
      return await this.httpClientService.get<Blob, Blob>(`/quizes/file/${fileID}`, {
        responseType: 'blob',
      });
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async answerToQuizQuestions(quizId: string, payload: IQuestionAddAnswersCommand[], forceRepetition = false) {
    try {
      // Answers without files
      const answers = [ ...payload.filter(answer => !answer.fileId) ];
      const answersWithFiles = payload.filter(answer => answer.fileId);
      for await (const answer of answersWithFiles) {
        if (answer.fileId instanceof File) {
          const fd = new FormData();
          fd.append('file', answer.fileId);
          const { value: fileId } = await this.sendQuizAnswerFile(fd);
          answers.push({
            ...answer,
            fileId,
          });
        } else throw new Error();
      }
      const reponse = await this.sendQuizAnswers(quizId, answers, forceRepetition);
      return reponse;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
}
