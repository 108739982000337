import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const PlainCheckRoundedSVG: React.FC<SVGComponentProps> = ({ className, innerElement, outerElement }: SVGComponentProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g transform="translate(-3.375 -3.375)">
      <path className={innerElement} d="M20.649,13.142l-.839-.878a.179.179,0,0,0-.134-.058h0a.172.172,0,0,0-.134.058l-5.818,5.96-2.117-2.153a.182.182,0,0,0-.267,0l-.849.863a.2.2,0,0,0,0,.276l2.67,2.716a.839.839,0,0,0,.558.276.879.879,0,0,0,.553-.267h0l6.376-6.518A.21.21,0,0,0,20.649,13.142Z" transform="translate(-2.19 -2.827)"/>
      <path className={outerElement} d="M13.375,4.721a8.65,8.65,0,1,1-6.12,2.534,8.6,8.6,0,0,1,6.12-2.534m0-1.346a10,10,0,1,0,10,10,10,10,0,0,0-10-10Z"/>
    </g>
  </svg>
);
