export const _mockBackground = {
  background: `url('/static/img/dashboard_image_top.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '102vw auto',
  backgroundPosition: '50% -6vw'
};

export const _resetButtonStyle = {
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  background: 'none',
  border: 'none',
  fontSize: 'inherit',
  color: 'inherit'
};

export const _refButton = {
  ..._resetButtonStyle,
  outline: 0,
  textDecoration: 'underline'
};
