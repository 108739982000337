import React, { Ref } from 'react';
import { useService } from 'modules/core/hooks';
import { AuthService } from 'modules/core/services';

export interface IClaimedRenderProps {
  claims: string[];
  invert?: boolean
}

export type ClaimedRenderType = React.FC<IClaimedRenderProps>;

export const useClaimGuard = (claims: string[]) => {
  const authService = useService(AuthService);
  return [
    authService.hasClaims(claims)
  ]
};

export const ClaimedRender: ClaimedRenderType = (props) => {
  const { claims, children } = props;
  const [canBeRendered]  = useClaimGuard(claims);
  if (canBeRendered) {
    return children as React.ReactElement
  }
  return null;
};

export interface IClaimedRenderForwardRefProps extends IClaimedRenderProps {
  children: (ref: Ref<HTMLElement>) => React.ReactElement,
}

export const ClaimedRenderForwardRef = React.forwardRef<HTMLElement, IClaimedRenderForwardRefProps>((props, ref) => {
  const { invert, claims, children } = props;
  const [canBeRendered]  = useClaimGuard(claims);
  if ((canBeRendered && !invert) || (!canBeRendered && invert)) {
    return children(ref) as React.ReactElement;
  }
  return null;
});

