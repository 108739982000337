import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInput, InputLabel, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const makeClasses = makeStyles(theme => ({
  rootInput: {
    width: '100%'
  },
  visibility: {
    opacity: '0.5'
  }
}));

export interface PasswordFormFieldProps {
  handleChange: (stateValue: any) => (event: React.ChangeEvent<HTMLInputElement>) => any;
  handleClickShowPassword: (event: React.MouseEvent<HTMLElement>) => any;
  password: string;
  showPassword: boolean;
  name?: string;
  label?: string;
  error?: boolean;
  required?: boolean;
}

export const PasswordFormField = ({
  error,
  handleChange,
  handleClickShowPassword,
  password,
  showPassword,
  name,
  label,
  required
}: PasswordFormFieldProps) => {
  const classes = makeClasses();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div>
      <InputLabel required>{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        error={error}
        className={classes.rootInput}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={handleChange(name)}
        required={required}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <Visibility className={classes.visibility} />
              ) : (
                <VisibilityOff className={classes.visibility} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
};

PasswordFormField.defaultProps = {
  label: 'Password',
  name: 'password'
};
