import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInput, InputLabel } from '@material-ui/core';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

interface InputFormFieldProps {
  name: string;
  type?: string;
  required?: boolean;
  value: string | number;
  handleChange: (name: any) => any;
  label: string;
  error?: any;
}

type Props = React.PropsWithChildren<InputFormFieldProps>;

const makeClasses = makeStyles<DefaultTheme>((theme: DefaultTheme) => ({
  rootInput: {
    width: '100%',
    height: 40,
  },
  inputLabel: {
    ...theme.mixins.fonts.regular.xs,
    marginTop: theme.spacing(1),
  }
}));

export const InputFormField: React.FC<Props> = (props: Props) => {
  const classes = makeClasses();
  return (
    <>
      <InputLabel classes={{ root: classes.inputLabel }} required={props.required}>
        {props.label}
      </InputLabel>
      <OutlinedInput
        error={props.error}
        value={props.value}
        onChange={props.handleChange(props.name)}
        type={props.type || 'text'}
        classes={{ root: classes.rootInput }}
      />
    </>
  );
};
