import React from 'react';
import { useTranslation } from 'react-i18next';
import { RankingFeedElement } from './ranking-feed-element';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { BACKGROUND } from 'themes/utils/background-utils';
import { FeedCard } from 'modules/user/components/layout';
import { IPagedResponse } from 'interfaces/paged-data.interface';
import { IRankingPerGroup } from 'interfaces/ranking.interface';
import { showApiErrors } from 'utils/show-errors';
import { debugError } from 'utils/debug';
import { useService, useIcons, IconsNames } from 'modules/core/hooks';
import { RankingService } from 'modules/user/services/ranking.service';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { userScoreSelector, userSelectedDashboardGroupSelector } from 'modules/user/store/selectors';
import { currentSelectedRoundSelector, userProfileSelector, feVisualizationType } from 'modules/core/store/selectors';
import { UsersService } from 'modules/core/services/users.service';
import { IUserGroup } from 'interfaces/user.interface';
import { SupportedCompanies } from 'config/config.const';
import { DotsRoundIcon } from 'modules/core/components/common/tag/components/dots-round-icon/dots-round-icon';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    paddingTop: 0
  },
  icon: {
    width: theme.icons.sizes.medium
  },
  noResults: {
    color: theme.palette.common.black
  }
}));

export interface IRankingList {
  id: string;
  rank?: number;
  avatar?: string;
  name: string;
  points: number;
  index?: number;
  isGroup?: boolean;
}

export const RankingFeed = () => {
  const { t } = useTranslation();
  const rankingService = useService(RankingService);
  const usersService = useService(UsersService);
  const [rankingList, setRankingList] = React.useState<IRankingList[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const selectedGroupId = useSelector(userSelectedDashboardGroupSelector);
  const userScore = useSelector(userScoreSelector);
  const [groups, setGroups] = React.useState<IUserGroup[]>([]);
  const [currentUserScore, setCurrentUserScore] = React.useState<IRankingList>();
  const currentUser = useSelector(userProfileSelector);
  const currentRound = useSelector(currentSelectedRoundSelector);
  const visualizationType = useSelector(feVisualizationType);
  const isEXCompany = visualizationType === SupportedCompanies.EX;

  const classes = makeClasses();
  const trophyIcon = useIcons(
    IconsNames.CUP,
    classes.icon
  );

  const getGroups = async () => {
    try {
      const value = await usersService.getUsersInGroup('')
      setGroups(value)
    }
    catch (e) {
      showApiErrors(e, enqueueSnackbar);
    }
  }

  React.useEffect(
    () => {
      if (rankingList && rankingList.length && currentUser) {
        if (!rankingList.some(item => item.id === currentUser.id)) {
          getCurrentUserScore()
        } else {
          setCurrentUserScore(undefined)
        }
      }
    }, [rankingList, currentRound]
  )
  const getCurrentUserScore = async () => {
    try {
      const { records } = await rankingService.getRankingSummary(
        currentRound.id,
        undefined, {
          page: 1,
          size: 1,
        }
      );

      if (currentUser && records?.length) {
        const { position , points } = records[0];
        const { id, name, surname, avatar } = currentUser
        setCurrentUserScore({
          id,
          rank: position,
          name: `${name} ${surname}`,
          avatar: avatar ?? '',
          points
        });
      } else {
        setCurrentUserScore(undefined)
      }

    } catch (e) {
      showApiErrors(e, enqueueSnackbar);
    }
  }
  React.useEffect(() => {
    if (groups.length) {
      const groupIds = groups.map(group => group.id);
      rankingService.getRankingForGroups(groupIds, currentRound?.id).then(rankGroups => {
        const parsedRank = rankGroups.sort((a, b) => (a.average < b.average) ? 1 : -1).map((rankGroup, rank) => {
          const currentGroup = groups.find(group => group.id === rankGroup.groupId);
          const { groupId: id, average: points } = rankGroup;
          return {
            id,
            name: currentGroup?.name,
            points,
            rank: rank + 1,
            isGroup: true
          }
        });
        setRankingList(parsedRank as IRankingList[])
      }).catch(error => {
        showApiErrors(error, enqueueSnackbar);
      })
    }
  },
    [groups],
  )

  const rankingUser = () => {
    if (currentRound?.id && selectedGroupId) {
      rankingService
        .getRankingsPerGroupAndRound([], currentRound.id, 1, isEXCompany ? 5 : 14)
        .then(resultRanking => {
          const { records }: IPagedResponse<IRankingPerGroup> = resultRanking?.[0];
          if (records?.length) {
            const parsedRank: IRankingList[] = records.map((rank: IRankingPerGroup) => ({
              id: rank?.userId,
              rank: rank?.position,
              name: `${rank?.user?.name} ${rank?.user?.surname}`,
              avatar: rank?.user?.avatar ?? '',
              points: rank?.points
            }));
            setRankingList(parsedRank);
          } else {
            setRankingList([])
          }
        })
        .catch(error => {
          showApiErrors(error, enqueueSnackbar);
          debugError(error);
        });
    }
  };

  React.useEffect(() => {
    visualizationType === SupportedCompanies.GU ? getGroups() : rankingUser()
  }, [selectedGroupId, currentRound, currentUser?.round]);


  return (
    <FeedCard
      headingColor={isEXCompany ? BACKGROUND.NONE : BACKGROUND.TERTIARY}
      title={`${t('userPanel.dashboard.rankingFeed')} - ${currentRound.name}`}
      titleIcon={() => trophyIcon}
    >
      <div className={classes.root}>
        {rankingList.length ? rankingList.map((mock, index) => (
          <RankingFeedElement data={mock} key={index} hasIcon={false} />
        )) : <Typography className={classes.noResults}>{t('userPanel.gameResults.noListGameResults')}</Typography>}
        {isEXCompany && currentUserScore &&
          <>
            <DotsRoundIcon />
            <RankingFeedElement data={currentUserScore} key={currentUserScore?.id} hasIcon={false} />
          </>
        }
      </div>
    </FeedCard>
  );
};
