import { Actions as IQuizzesModuleActions, quizzesActions } from './quizzes.actions';
import { Actions as IQuestsModuleActions, questsActions } from './quests.actions';
import { Actions as IFaqModuleActions, faqActions } from './faq.actions';
import { Actions as ITrainingsModuleActions, trainingsActions } from './trainings.actions';
import { Actions as IScoreModuleActions, scoreActions } from './score.actions';
import { Actions as IRoundsModuleActions, roundsActions } from './rounds.actions';
import { Actions as IRankingModuleActions, rankingActions } from './ranking.actions';
import { Actions as IGalleryModuleActions, galleryActions } from './gallery.actions';
import { Actions as ITaskModuleActions, tasksActions } from './tasks.actions';
import { Actions as IGroupsActions, groupsActions } from './groups.actions';
import { Actions as INewsActions, newsActions } from './news.actions';

export type Actions = IQuizzesModuleActions &
  IQuestsModuleActions &
  IFaqModuleActions &
  ITrainingsModuleActions &
  IScoreModuleActions &
  IRoundsModuleActions &
  IRankingModuleActions &
  IGalleryModuleActions &
  ITaskModuleActions &
  IGroupsActions &
  INewsActions &
  ISetResetUserStore

export enum UserStoreActions {
  SET_RESET_USER_STORE = 'SET_RESET_USER_STORE'
}

export interface ISetResetUserStore {
  type: UserStoreActions.SET_RESET_USER_STORE,
}

export const setResetUserStore = (): ISetResetUserStore => ({
  type: UserStoreActions.SET_RESET_USER_STORE,
})

export const userActions = {
  quizzes: quizzesActions,
  quests: questsActions,
  faq: faqActions,
  trainings: trainingsActions,
  score: scoreActions,
  rounds: roundsActions,
  ranking: rankingActions,
  gallery: galleryActions,
  tasks: tasksActions,
  groups: groupsActions,
  news: newsActions
};
