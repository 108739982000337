import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { BackgroundComponents } from 'modules/user/components/layout/background-components/background-components';
import BackgroundEx from 'assets/img/bg_dashboard_banner.png';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { feVisualizationType } from 'modules/core/store/selectors'
import { SupportedCompanies } from 'config/config.const';
import BackgroundPP from 'assets/img/dashboard_city_pepco.svg';
import BG_pepco_shop from 'assets/img/BG_dashboard_banner_shop.jpg';
import { useTranslation } from 'react-i18next';

const makeClasses = makeStyles<DefaultTheme, BackgroundProps>(theme => ({
  background: {
    filter: props => (props.blurred ? 'blur(3px)' : 'none')
  },
  backgroundEx: {
    height: '423px',
    marginBottom: theme.spacing(-45),
    maxWidth: '100%'
  },
  backgroundPP: {
    marginBottom: theme.spacing(-20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative'
  },
  bgPicture: {
    objectFit: 'cover',
    height: '620px'
  },
  hero: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '600px',
  },

}));

export interface BackgroundProps {
  className?: string;
  blurred?: boolean;
  noComponents?: boolean;
}

export const Background: React.FC<BackgroundProps> = props => {
  const classes = makeClasses(props);
  const { t } = useTranslation();

  const visualizationType = useSelector(feVisualizationType)
  if (visualizationType === SupportedCompanies.GU || visualizationType === SupportedCompanies.EDU) {
    return (
      <BackgroundComponents
        className={clsx(classes.background, props.className)}
        noComponents={props.noComponents}
      />
    )
  }
  if (visualizationType === SupportedCompanies.EX) {
    return (
      <img src={BackgroundEx} className={classes.backgroundEx} alt="" />
    )
  }
  if (visualizationType === SupportedCompanies.PP) {
    return (
      <div className={classes.backgroundPP}>
        <img src={BG_pepco_shop} className={classes.bgPicture} alt="" />
        <img
          className={classes.hero}
          alt=''
          src='/static/img/pepco/pepco_girl2.png'
        />
      </div>
    )
  }
  return null;
};
