import { IStoreState } from 'modules/core/store/store';
import { createSelector } from 'reselect';
import { IRankingPerGroup, IRankingsFilters } from 'interfaces/ranking.interface';
import { IPagedData } from 'interfaces/paged-data.interface';

export const userQuizzesSelector = (state: IStoreState) => state.userModule.quizzes;
export const userFaqSelector = (state: IStoreState) => state.userModule.faq;
export const userTrainingsSelector = (state: IStoreState) => state.userModule.trainings;
export const userQuestListSelector = (state: IStoreState) => state.userModule.quests;
export const userGallerySelector = createSelector(
  (state: IStoreState) => state.userModule.gallery,
  gallery => gallery.list
);

export const userRoundsSelector = createSelector(
  (state: IStoreState) => state.userModule.rounds,
  rounds => rounds.list
);

export const userScoreSelector = createSelector(
  (state: IStoreState) => state.userModule.score,
  scores => scores.userScore
);

export const userAllScoresSelector = createSelector(
  (state: IStoreState) => state.userModule.score,
  scores => scores.list
);

export const userGroupsSelector = createSelector(
  (state: IStoreState) => state.userModule.groups,
  groups => groups.list
);

export const userSelectedDashboardGroupSelector = createSelector(
  (state: IStoreState) => state.userModule.groups,
  groups => groups.selectedDashboardGroupId
);
export const userSelectedResultsGroupSelector = createSelector(
  (state: IStoreState) => state.userModule.groups,
  groups => groups.selectedResultsGroupId
);

export const userTasksListSelector = createSelector(
  (state: IStoreState) => state.userModule.tasks,
  tasks => tasks.list
);

export const userNewsFeedSelector = createSelector(
  (state: IStoreState) => state.userModule.news,
  news => news.list
);

export const userThumbnailsSelector = createSelector(
  (state: IStoreState) => state.userModule.news,
  news => news.thumbnails
);


export const dashboardRankingsSelector = (state: IStoreState): IPagedData<IRankingPerGroup, IRankingsFilters> => state.userModule.ranking.dashboardRanking;
export const blockRankingSelector = (state: IStoreState): IPagedData<IRankingPerGroup> => state.userModule.ranking.blockRanking;
