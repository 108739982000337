import { Reducer } from 'redux';
import { Actions, QuizzesModuleActions } from '../actions/quizzes.actions';
import { IQuiz, QuizzesTypes } from 'modules/user/interfaces/quiz.interface';

export const quizzesModuleStoreInitialState: IQuiz[] = [];

export const quizzesModuleStoreReducer: Reducer<IQuiz[], Actions> = (
  state: IQuiz[] = quizzesModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case QuizzesModuleActions.SET_QUIZZES:
      return {
        ...state,
        quizzes: payload.quizzes
      };
    default:
      return state;
  }
};

export default quizzesModuleStoreInitialState;
