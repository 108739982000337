import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from 'modules/core/store';
import { getService, RoundsService } from 'modules/core/services';
import { currentSelectedRoundSelector } from 'modules/core/store/selectors';
import { Dropdown } from 'modules/core/components/common';
import { IDropDownElement } from 'modules/core/components/common/dropdown/dropdown';
import { setCurrentSelectedRound } from 'modules/core/store/actions';
import { userRoundsSelector } from 'modules/user/store/selectors';
import { IRound } from 'interfaces/round.interface';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { makeStyles } from '@material-ui/core';

const makeClasses = makeStyles<DefaultTheme>((theme: DefaultTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '81px',
    padding: theme.spacing(0, 1, 2),
    margin: theme.spacing(0, 2.5)
  },
  noRounds: {
    minWidth: '80px',
    paddingBottom: '12px',
    lineHeight: '20px',
    marginLeft: '20px'
  },
}));
export interface INavListProps {
}

const flattenFirstLevelRoundsList = (rounds: IRound[]) => {
  const newFlattenedRounds: any[] = [];
  rounds.forEach(round => {
    newFlattenedRounds.push(round);
    if (round.hasOwnProperty('children')) {
      round.children.forEach(childrenRound => {
        newFlattenedRounds.push({...childrenRound, nestingLevel: 1});
      });
    }
  });
  return newFlattenedRounds;
}

export const NavRoundDropdown: React.FC<INavListProps> = () => {
  const currentRound = useSelector(currentSelectedRoundSelector);
  const rounds = useSelector(userRoundsSelector);
  const roundsService = getService(RoundsService);
  const { t } = useTranslation();
  const classes = makeClasses();
  const flattenedRounds: any[] = flattenFirstLevelRoundsList(rounds);

  const onTaskSelectClick = (round: IDropDownElement) => {
    if (flattenedRounds) {
      const actualRound = flattenedRounds.find((el: IRound) => round.id === el.id);
      if (actualRound) {
        roundsService.setCurrentRound(actualRound.id)
        store.dispatch(setCurrentSelectedRound(actualRound));
      } else throw new Error('Round not found')
    }
  };

  return (
    <nav className={classes.wrapper}>
      {rounds && rounds.length ? (
        <Dropdown
          onElementSelect={onTaskSelectClick}
          firstElementSelected={currentRound?.id.length ?
            {
              id: currentRound.id,
              name: currentRound.name
            } : {
              id: flattenedRounds[0].id,
              name: flattenedRounds[0].name
            }}
            elements={flattenedRounds.map(round => ({
              id: round.id,
              name: round.name,
              nestingLevel: round.nestingLevel ?? 0
            }))}
        />
      ) : (
        <strong className={classes.noRounds}>{t('common.noRounds')}</strong>
      )}
    </nav>
  );
};
