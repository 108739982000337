import React from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, Link } from '@material-ui/core';
import { UsersService } from 'modules/core/services';
import { useService } from 'modules/core/hooks';
import { IRegulation } from 'interfaces/models/regulation.interface';
import { showApiErrors } from 'utils/show-errors';

export interface RegulationLinkProps {
  model: IRegulation;
  checked: boolean;
  onChange: (id: string, checked: boolean) => void;
}

const makeClasses = makeStyles(theme => ({
  regulationsLinkItem: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export const RegulationLink: React.FC<RegulationLinkProps> = (props: RegulationLinkProps) => {
  const { model: { id, name, file }, checked, onChange } = props;
  const classes = makeClasses();

  const { enqueueSnackbar } = useSnackbar();
  const usersService = useService(UsersService);

  const [isLoaded, setLoaded] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState('');

  React.useEffect(() => {
    try {
      if (!isLoaded) {
        const generatedFileUrl = usersService.createLinkToRegulationsFile(file);
        if (generatedFileUrl) {
          setFileUrl(generatedFileUrl);
          setLoaded(true);
        }
      }
    } catch (e) {
      showApiErrors(e, enqueueSnackbar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={classes.regulationsLinkItem}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(element, checkedChange) => onChange(id, checkedChange)}
          />
        }
        label={
          <>
            {'I agree to '}
            <Link href={fileUrl} target="_blank" rel="noopener noreferrer">
              {name}
            </Link>
          </>
        }
      />
    </div>
  );
};
