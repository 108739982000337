import store from 'modules/core/store';
import { scoreModuleStoreInitialState } from 'modules/user/store/reducer/score.reducer';
import { getService, AppService } from 'modules/core/services';
import { HttpClientService } from 'modules/core/functional-services';
import { scoreActions } from '../store/actions/score.actions';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { ITotalScore, IUserScore } from '../interfaces/score.interface';

export class ScoreService {
  constructor(private appService = getService(AppService),
              private httpClientService = getService(HttpClientService)) {
  }

  async getScore(userId: string) {
    try {
      const { value }: IAPIResponse<IUserScore[]> = await this.httpClientService.get(`/scores/${userId}`);
      this.applyScores(value);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  applyScores(scores: IUserScore[]) {
    const filteredScore = scores.filter(score => score.roundId !== '00000000-0000-0000-0000-000000000000');
    store.dispatch(scoreActions.setScore(filteredScore));
  }

  applyUserScore(score: IUserScore) {
    store.dispatch(scoreActions.setUserScore(score));
  }

  async getUserScorePerActualRound() {
    const { userModule: { score: { list: scores } }, core: { currentSelectedRound } } = store.getState();
    const scoreInSelectedRound = scores.filter((score: IUserScore) => score.roundId === currentSelectedRound?.id && !score.workerGroupId);
    return scoreInSelectedRound[0] ?? scoreModuleStoreInitialState.userScore;
  }
  async getAllScores() {
    try {
      const { value }: IAPIResponse<ITotalScore> = await this.httpClientService.get('/scores/all');
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
}



