import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Hidden } from '@material-ui/core';
import { RankingFeed, ScoreFeed, TaskFeed, MyRankingFeed } from 'modules/user/components/feed-cards';
import { useSelector } from 'react-redux';
import { feVisualizationType, layoutWrapperElementsSelector } from 'modules/core/store/selectors'
import { DuelFeed } from '../../feed-cards/ranking-feed/ranking-feed-duel';
import { RankingFeedPepco } from '../../feed-cards/ranking-feed/ranking-feed-pepco';
import { SupportedCompanies } from 'config';

const makeClasses = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    marginBottom: theme.spacing(5),
    minHeight: '75vh',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0',
      paddingRight: '0',
      marginBottom: '0',
      minHeight: '0',
      flexDirection: 'column'
    }
  },
  content: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '80%'
    }
  },
  cards: {
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    width: '30%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      width: '100%'
    }
  }
}));

export interface LayoutWrapperProps {
  showDefaultFeeds?: boolean,
  className?: string;
  replaceTaskViewByRanking?: boolean,
  showPepcoRanking?: boolean
}

export const LayoutWrapper: React.FC<React.PropsWithChildren<LayoutWrapperProps>> = props => {
  const { children, className, showDefaultFeeds = true, showPepcoRanking = false } = props;
  const layoutWrapperElements = useSelector(layoutWrapperElementsSelector)
  const classes = makeClasses();
  const visualizationType = useSelector(feVisualizationType)

  return (
    <div className={className}>
      <Container className={classes.container}>
        <div className={classes.content}>{children}</div>
        <Hidden mdDown={visualizationType !== SupportedCompanies.PP}>
          <div className={classes.cards}>
            {!showDefaultFeeds && <MyRankingFeed />}
            {showDefaultFeeds && layoutWrapperElements && layoutWrapperElements.scoreFeed && <ScoreFeed />}
            {showDefaultFeeds && layoutWrapperElements && layoutWrapperElements.rankingFeed && <RankingFeed />}
            {showDefaultFeeds && layoutWrapperElements && layoutWrapperElements.taskFeed && <TaskFeed />}
            {showDefaultFeeds && layoutWrapperElements && layoutWrapperElements.duelFeed && <DuelFeed />}
            {showPepcoRanking && <RankingFeedPepco />}
          </div>
        </Hidden>
      </Container>
    </div>
  );
};
