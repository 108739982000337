import { getService, AppService } from 'modules/core/services';
import { HttpClientService } from 'modules/core/functional-services';
import { IKnowledgePage, IKnowledgePageDetailed, IKnowledgePageStatus } from 'modules/user/interfaces/knowledge.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { IPagedResponse } from 'interfaces/paged-data.interface';
import { APIPaths } from 'config/paths';

export class KnowledgeService {
  constructor(private appService = getService(AppService), private httpClientService = getService(HttpClientService)) {
  }

  async getKnowledgeList(roundId: string, pagination?: IPagination): Promise<IPagedResponse<IKnowledgePage>> {
    try {
      const { value } = await this.httpClientService.get<IPagedResponse<IKnowledgePage>>(`${APIPaths.ROUNDS}/${roundId}/knowledge-pages`, {
        params: pagination
      });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getKnowledgePageById(id: string, roundId: string) {
    try {
      const { value } = await this.httpClientService.get<IKnowledgePageDetailed>(`/knowledge-pages/${id}`, {
        params: {
          round: roundId
        }
      });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async setUserStatus(pageId: string, processed: boolean) {
    try {
      await this.httpClientService.post(`/knowledge-pages/user-status`, {
        knowledgePageId: pageId,
        processed
      })
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
}
