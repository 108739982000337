import { Config, SupportedCompanies } from 'config';

// set language suffix by config type
export const getLangByConfig = (lang: string): string => {
  let suffix: string = '';
  switch (Config.COMPANY) {
    case SupportedCompanies.GU:
      suffix = 'GU';
      break;
    case SupportedCompanies.TH:
      suffix = 'TH';
      break;
    case SupportedCompanies.EX:
      suffix = 'TH';
      break;
    case SupportedCompanies.EDU:
      suffix = 'EDU';
      break;
  }
  return `${lang}_${suffix}`;
};
