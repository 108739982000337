import React from 'react';
import {useLocation} from 'react-router-dom';
import { MyTablePaginationType } from './interfaces';
import TablePagination from '@material-ui/core/TablePagination';
import { useTranslation } from 'react-i18next';


export const MyTablePagination: MyTablePaginationType = (props) => {
  const { pagination, events } = props;
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <TablePagination
      component={'div'}
      count={pagination.total ?? 0}
      rowsPerPageOptions={[
        10,
        20,
        50,
        100,
        200,
      ]}
      rowsPerPage={pagination.size ?? 0}
      labelRowsPerPage={location.pathname.includes('admin/') ? t('adminPanel.common.table.rowsPerPage') : t('common.table.rowsPerPage')}
      labelDisplayedRows={({from, to, count}) =>  (`${from} - ${to} ${location.pathname.includes('admin/') ? t('adminPanel.common.table.of') : t('common.table.of')} ${count}`)}
      page={(pagination.page ?? 1) - 1}
      onChangePage={(e, page) => {
        if (events?.onPageChange) {
          events.onPageChange(page + 1);
        }
      }}
      onChangeRowsPerPage={(e) => {
        const value = Number(e.target.value);
        if (events?.onRowsPerPageChange) {
          events.onRowsPerPageChange(value);
        }
      }}/>
  );
};