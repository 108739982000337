import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const LevelSVG: React.FC<SVGComponentProps> = ({className, innerElement, outerElement }: SVGComponentProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="29.999" viewBox="0 0 30 29.999">
    <g transform="translate(-54.867 0.095)">
      <g transform="translate(54.867 -0.095)">
        <path className={outerElement} d="M14.886,29.9a15,15,0,1,1,10.666-4.472l-.092.092A14.853,14.853,0,0,1,14.886,29.9Z" transform="translate(0.133 0.095)"/>
        <path className={innerElement} d="M13.1,29.614a1,1,0,0,1-.821-1.561l3.8-5.538a1,1,0,0,1,1.549-.117l3.564,3.8,3.545-5.585a1,1,0,0,1,1.49-.223l3.419,2.928a1,1,0,1,1-1.3,1.514L25.8,22.657l-3.619,5.7a1,1,0,0,1-1.569.147l-3.6-3.838-3.1,4.515a1,1,0,0,1-.823.433Z" transform="translate(-6.002 -10.062)"/>
      </g>
    </g>
  </svg>
);
