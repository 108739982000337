import React from 'react';
import Fade from '@material-ui/core/Fade/Fade';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

export interface ILoaderViewStylingProps {
  position?: 'absolute' | 'fixed',
  text?: string,
  classRoot?: string,
  background?: string,
}

export interface ILoaderView extends ILoaderViewStylingProps {
  visible: boolean;
}

const makeClasses = makeStyles<DefaultTheme, ILoaderViewStylingProps>(theme => ({
  root: {
    position: props => props.position ? props.position : 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: props => props.background ? props.background : 'rgba(0, 0, 0, .2)'
  },
  textRoot: {
    margin: 0,
  }
}));

export const LoaderView: React.FC<ILoaderView> = (props) => {
  const classes = makeClasses(props);
  return (
    <Fade in={props.visible} unmountOnExit mountOnEnter>
      <div className={clsx(classes.root, props.classRoot)}>

        <Box textAlign='center'>
          <CircularProgress />
          {
            props.text && <p className={classes.textRoot}>{props.text}</p>
          }
        </Box>

      </div>
    </Fade>
  );
};
