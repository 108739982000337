import { IConfig } from 'config/interfaces/config.interface';
import { GrowthSettings } from 'config/interfaces/growth_settings.interface';
import { SupportedCompanies } from './config.const';
import { growthSettingsTH } from 'config/growth_settings_th';
import { growthSettingsGU } from 'config/growth_settings_gu';

const DEVELOPMENT = process.env.NODE_ENV === 'development';

const pickGrowthSettings = (company: SupportedCompanies): GrowthSettings => {
  switch (company) {
    case SupportedCompanies.TH:
      return growthSettingsTH;
    case SupportedCompanies.EX:
      return growthSettingsTH;
    case SupportedCompanies.GU:
      return growthSettingsGU;
    case SupportedCompanies.EDU:
      return growthSettingsGU;
    case SupportedCompanies.PP:
      return growthSettingsTH;
  }
};

const appType: SupportedCompanies = ((type) => {
  switch (type) {
    case 'EDU':
      return SupportedCompanies.EDU;
    case 'TH':
      return SupportedCompanies.TH;
    case 'PP':
      return SupportedCompanies.PP;
    default:
      return SupportedCompanies.GU;
  }
})(process.env.REACT_APP_TYPE);

const API_URL_DEVELOPMENT = 'https://gro-d-gup-app.azurewebsites.net/';
const API_URL_BUILD = '/';
const GTM_ID_DEV = 'GTM-TZK5JBX';
const GTM_ID_PROD = 'GTM-WN8WKD2'
const API_URL = DEVELOPMENT ? (process.env.REACT_APP_API_URL ?? API_URL_DEVELOPMENT) : API_URL_BUILD;
const FUNCTIONS_URL = DEVELOPMENT ? ('https://gro-d-gup-fap.azurewebsites.net/api') : 'https://gro-p-gup-fap.azurewebsites.net/api';
const GTM_ID = DEVELOPMENT ? (process.env.REACT_APP_GTM_ID_DEV ?? GTM_ID_DEV) : (process.env.REACT_APP_GTM_ID_PROD ?? GTM_ID_PROD);

export const Config: IConfig = {
  API_URL,
  FUNCTIONS_URL,
  GTM_ID,
  APP_TITLE: 'Grow Uperion',
  TRANSLATION_ENABLED: false,
  TRANSLATION_NAMESPACE: 'translation',
  STORED_TOKEN_KEY: 'GP_AUTH_JWT',
  STORED_LANGUAGE_KEY: 'GP_LANG',
  DEFAULT_LANGUAGE: 'pl',
  STORED_CLAIMS: 'GP_CLAIMS',
  STORED_USER_PROFILE: 'GP_USER_PROFILE',
  COMPANY: appType,
  GROWTH_SETTINGS: pickGrowthSettings(appType)
};
