import { Reducer } from 'redux';
import { Actions, RoundsModuleActions } from '../actions/rounds.actions';
import { IRound } from 'interfaces/round.interface';

export const roundsModuleStoreInitialState: IListedRounds = { list: [] };

export interface IListedRounds {
  list: IRound[]
}

export const roundsModuleStoreReducer: Reducer<IListedRounds, Actions> = (
  state: IListedRounds = roundsModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case RoundsModuleActions.SET_ROUNDS:
      return {
        ...state,
        list: [...payload.list]
      };
    default:
      return state;
  }
};

export default roundsModuleStoreInitialState;
