import { GrowthComponent, GrowthSettings } from './interfaces/growth_settings.interface';
import { VisualizationType } from './config.const';

function range(start: number, end: number): number[] {
  return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
}

export const buildComponentsPathsForType = (type: VisualizationType, assetsFolderPath: string): GrowthComponent[] => {
  if (type === VisualizationType.FruitWormTrees) {
    const newGrowthIndex = (grIndex: number) => {
      if (grIndex === 0 || grIndex === 1) {
        return 10;
      } else if (grIndex > 1) {
        return 11 - grIndex;
      } else if (grIndex < 0) {
        return -11 - grIndex;
      }
    }
    return range(-10, 10).map(growthIndex => ({
      growthIndex,
      assetPath: `${assetsFolderPath}${newGrowthIndex(growthIndex)}.png`,
    }));
  }
  return range(-10, 10).map(growthIndex => ({
    growthIndex,
    assetPath: `${assetsFolderPath}${growthIndex}.png`,
  }));
};

export const growthSettingsTH: GrowthSettings = {
  GROWTH_ICON: {
    assetPath: '/static/img/growth_icon_building.svg',
  },
  STATISTICS_FLYING_IMAGE: {
    assetPath: '/static/img/airship.svg',
  },
  VISUALIZATIONS: {
    [VisualizationType.Trees]: {
      SPACING: -120,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Trees, '/static/img/growth-components/trees/'),
      SHOW_AVATAR_LINE: false,
      AVATAR_IN_COMPONENT: true,
    },
    [VisualizationType.FruitTrees]: {
      SPACING: -250,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.FruitTrees, '/static/img/growth-components/fruit-trees/'),
      SHOW_AVATAR_LINE: false,
      AVATAR_IN_COMPONENT: true,
    },
    [VisualizationType.FruitWormTrees]: {
      SPACING: -250,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.FruitWormTrees, '/static/img/growth-components/fruit-worm-trees/'),
      SHOW_AVATAR_LINE: false,
      AVATAR_IN_COMPONENT: true,
    },
    [VisualizationType.SeedBags]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.SeedBags, '/static/img/growth-components/seed-bags/'),
      SHOW_AVATAR_LINE: false,
      AVATAR_IN_COMPONENT: false,
      HEIGHT: 100,
    },
    [VisualizationType.Seedlings]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Seedlings, '/static/img/growth-components/seedlings/'),
      SHOW_AVATAR_LINE: false,
      AVATAR_IN_COMPONENT: false,
    },
    [VisualizationType.Buildings]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Buildings, '/static/img/growth-components/buildings/'),
      SHOW_AVATAR_LINE: true,
      AVATAR_IN_COMPONENT: false,
    },
    [VisualizationType.m1]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Buildings, '/static/img/growth-components/m1/'),
      SHOW_AVATAR_LINE: true,
      AVATAR_IN_COMPONENT: false,
    },
    [VisualizationType.m2]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Buildings, '/static/img/growth-components/m2/'),
      SHOW_AVATAR_LINE: true,
      AVATAR_IN_COMPONENT: false,
    },
    [VisualizationType.m3]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Buildings, '/static/img/growth-components/m3/'),
      SHOW_AVATAR_LINE: true,
      AVATAR_IN_COMPONENT: false,
    },
    [VisualizationType.m4]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Buildings, '/static/img/growth-components/m4/'),
      SHOW_AVATAR_LINE: true,
      AVATAR_IN_COMPONENT: false,
    },
    [VisualizationType.m5]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Buildings, '/static/img/growth-components/m5/'),
      SHOW_AVATAR_LINE: true,
      AVATAR_IN_COMPONENT: false,
    },
    [VisualizationType.m6]: {
      SPACING: 20,
      COMPONENTS: buildComponentsPathsForType(VisualizationType.Buildings, '/static/img/growth-components/m6/'),
      SHOW_AVATAR_LINE: true,
      AVATAR_IN_COMPONENT: false,
    },

  },
};
