import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery
} from '@material-ui/core';
import { AuthService, UsersService } from 'modules/core/services';
import { RegulationLink } from 'modules/core/components/base/global-modals/components/regulations-modal/components/regulation-link';
import { authTokenSelector, feVisualizationType } from 'modules/core/store/selectors';
import { getErrorText } from 'utils/show-errors';
import { defaultTheme } from 'themes/default-theme';
import { IRegulation } from 'interfaces/models/regulation.interface';
import { useService } from 'modules/core/hooks';
import { SupportedCompanies } from 'config/config.const';

const makeClasses = makeStyles(theme => ({
  checkAllButton: {
    marginTop: theme.spacing(3)
  }
}));

export const RegulationsModal: React.FC = () => {
  const classes = makeClasses();
  const visualizationType = useSelector(feVisualizationType)

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [regulations, setRegulations] = React.useState<IRegulation[]>([]);
  const [acceptedRegulations, setAcceptedRegulations] = React.useState<string[]>([]);

  const fullScreen = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  const history = useHistory();

  const usersService = useService(UsersService);
  const authService = useService(AuthService);

  const authToken = useSelector(authTokenSelector);
  const isLoggedIn = authService.isTokenValid(authToken);

  const cancel = useCallback(async () => {
    setIsLoaded(false);
    await authService.logout();
    history.push('/auth');
  }, [authService, history]);


  const getInitalData = React.useCallback(
    async () => {
      try {
        const response = await usersService.getNotAcceptedRegulations();
        setRegulations(response.value as IRegulation[]);
        setIsLoaded(true);
      } catch (e) {
        if (e.messages && e.messages.length > 0) {
          enqueueSnackbar(getErrorText(e), {
            variant: 'error'
          });
        } else {
          enqueueSnackbar(`Couldn't load regulations. Please try to log in again.`, {
            variant: 'error'
          });
        }
        if (visualizationType === SupportedCompanies.TH) {
          cancel();
        }
      }
    },
    [cancel, enqueueSnackbar, usersService]
  );

  React.useEffect(() => {
    // if (isLoggedIn && !isLoaded) {
    //   getInitalData();
    // }
    // setIsLoaded(true);
  }, [isLoaded, isLoggedIn, getInitalData]);

  const onSubmit = async () => {
    if (!isSending && acceptedRegulations.length === regulations.length) {
      try {
        setIsSending(true);
        await Promise.all(acceptedRegulations.map(id => usersService.acceptRegulation(id)));
        // clear regulations to accept to empty array, as there are no regulations left to accept when user clicked submit.
        setRegulations([]);
      } catch (e) {
        enqueueSnackbar(getErrorText(e), {
          variant: 'error'
        });
      } finally {
        setIsSending(false);
      }
    } else {
      enqueueSnackbar(t('adminPanel.regulations.agreeToTerms'), {
        variant: 'error'
      });
    }
  };

  return (
    <Dialog open={isLoaded && regulations.length > 0} fullScreen={fullScreen}>
      <DialogTitle>{t('adminPanel.regulations.welcome')}</DialogTitle>

      <DialogContent>
        <DialogContentText paragraph>
        {t('adminPanel.regulations.terms')}
        </DialogContentText>
        <DialogContentText>
          {regulations.map((data: IRegulation) => (
            <RegulationLink
              key={data.id}
              model={data}
              checked={acceptedRegulations.some(id => data.id === id)}
              onChange={(id, checked) => {
                if (checked) {
                  setAcceptedRegulations([...acceptedRegulations, id]);
                } else {
                  setAcceptedRegulations(acceptedRegulations.filter(regulationId => regulationId !== id));
                }
              }}
            />
          ))}
        </DialogContentText>
        {regulations.length > 1 && (
          <Button
            className={classes.checkAllButton}
            color="primary"
            variant="outlined"
            onClick={() => {
              setAcceptedRegulations(regulations.map(regulation => regulation.id));
            }}
          >
            {t('adminPanel.regulations.checkAll')}
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={isSending} onClick={cancel}>
          {t('common.cancel')}
        </Button>
        <Button
          color="primary"
          autoFocus
          disabled={isSending || acceptedRegulations.length !== regulations.length}
          onClick={onSubmit}
        >
          {t('common.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
