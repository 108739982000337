import { AuthService, getService, LangService } from 'modules/core/services';
import store from './modules/core/store';
import { setCoreServicesReady, setShowLoader } from './modules/core/store/actions';

export function bootstrapCoreServices() {
  const authService = getService(AuthService);
  const langService = getService(LangService);
  Promise.all([authService.bootstrap(), langService.bootstrap()]).then(() => {
    store.dispatch(setCoreServicesReady(true));
    store.dispatch(setShowLoader(false));
  });
}
