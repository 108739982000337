import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';


const makeClasses = makeStyles<DefaultTheme>(theme => ({
  rootInput: {
    width: '100%'
  },
  select: {
    height: '40px'
  },
  selectLabel: {
    ...theme.mixins.fonts.regular.xs,
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0
  }
}));

export const SelectFormField = ({ name, label, handleChange, value, options, required }: any) => {
  const classes = makeClasses();

  return (
    <div>
      <FormControl variant="outlined" className={classes.rootInput}>
        <label className={classes.selectLabel} htmlFor={'select-' + { name }}>
          {label} {required && '*'}
        </label>
        <Select
          className={classes.select}
          labelId={'select-' + { name } + '-label'}
          id={'select-' + { name }}
          value={value}
          onChange={handleChange(name)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((option: any, i: any) => {
            return (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
