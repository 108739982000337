import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useSelector } from 'react-redux';
import { feNavSettingsSelector } from 'modules/core/store/selectors';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Link as ExternalLink } from '@material-ui/core';
import { defaultTheme } from 'themes/default-theme';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  footer: {
    position: 'relative',
    display: 'flex',
    marginTop: 'auto',
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid rgba(255, 255, 255, 0.5)',
    background: theme.backgrounds.footer ?? 'transparent',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '20px',
      paddingTop: '20px',
      border: 'none',
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100vh'
    }
  },
  link: {
    fontSize: theme.mixins.fonts.bold.xs.fontSize,
    color: theme.palette.common.black,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(5)
    }
  }
}));

export const Footer = () => {
  const classes = makeClasses();
  const navSettings = useSelector(feNavSettingsSelector);
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <>
      {!isMobile && navSettings.footerNavigation && navSettings.footerNavigation.length > 0 ? (
        <footer className={classes.footer}>
          {navSettings.footerNavigation.map((itemNav, index) => {
            if (itemNav.url.indexOf('http') === 0) {
              return (
                <ExternalLink href={itemNav.url} key={index} className={classes.link} target="_blank">
                  {t(`userPanel.nav.${itemNav.name}`)}
                </ExternalLink>
              );
            } else {
              return (
                <Link to={itemNav.url} key={index} className={classes.link}>
                  {t(`userPanel.nav.${itemNav.name}`)}
                </Link>
              );
            }
          })}
        </footer>
      ) : (
        <></>
      )}
      {isMobile && navSettings.mobileFooterNavigation && navSettings.mobileFooterNavigation.length && (
        <footer className={classes.footer}>
          {navSettings.mobileFooterNavigation.map((itemNav, index) => {
            if (itemNav.url.indexOf('http') === 0) {
              return (
                <ExternalLink href={itemNav.url} key={index} className={classes.link} target="_blank">
                  {t(`userPanel.nav.${itemNav.name}`)}
                </ExternalLink>
              );
            } else {
              return (
                <Link to={itemNav.url} key={index} className={classes.link}>
                  {t(`userPanel.nav.${itemNav.name}`)}
                </Link>
              );
            }
          })}
        </footer>
      )}
    </>
  );
};
