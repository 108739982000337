import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const QuizSVG: React.FC<SVGComponentProps> = ({ className, innerElement, outerElement }: SVGComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21.301" height="21.301" viewBox="0 0 21.301 21.301" className={className}>
    <g transform="translate(0.65 0.65)">
      <path className={innerElement} d="M12.871,17.278a.77.77,0,0,0-1.54,0Zm-1.54.01a.77.77,0,1,0,1.54,0Zm0-3.081a.77.77,0,1,0,1.54,0ZM8.277,9.06a.77.77,0,1,0,1.487.4Zm3.054,8.218v.01h1.54v-.01Zm3.081-7.271c0,.614-.382.939-1.2,1.5a3.313,3.313,0,0,0-1.877,2.7h1.54c0-.556.358-.852,1.2-1.429a3.357,3.357,0,0,0,1.877-2.772ZM12.1,7.79a2.248,2.248,0,0,1,2.31,2.217h1.54A3.788,3.788,0,0,0,12.1,6.25ZM9.764,9.461A2.4,2.4,0,0,1,12.1,7.79V6.25A3.938,3.938,0,0,0,8.277,9.06Z" transform="translate(-1.75 -3.979)"/>
      <path className={outerElement} d="M-16828.574,15160.409v-16.164h20v16.164h-8.094l-1.844,3.836-1.971-3.836Z" transform="translate(16828.574 -15144.245)"/>
    </g>
  </svg>
);