import { Reducer } from 'redux';
import { VisualizationType } from 'config';
import { Actions, CoreStoreActions } from '../actions';
import { IRound } from 'interfaces/round.interface';
import { IUserProfile } from 'interfaces/user.interface';
import { IFESettingsType, IResultTreeTypes, ITenantLogo, IOtherSettings } from 'interfaces/settings.interface';
import { IStaticPages } from 'interfaces/static-pages.interface';
import { IRole } from 'interfaces/role.interface';
import { Errors } from 'const';
import { ILanguage } from 'interfaces/language-user.interface';

export interface ICoreStoreState {
  showLoader: boolean;
  currentModule: string;
  currentSelectedRound: IRound;
  showedMessage: boolean;
  showedNewsMessage: boolean;
  staticPages: IStaticPages[];
  showedMessages: {
    results: number
  }
  claims: string[],
  coreServicesReady: boolean,
  languages: ILanguage[],
  error: {
    type: Errors
    message?: string,
  } | null;
  user: {
    token: string | null,
    roles: IRole[],
    profile: IUserProfile | null,
    avatarURL: string, // TODO
  },
  settings: IFESettingsType,
  tenantLogo: ITenantLogo,
}

export const coreStoreInitialState: ICoreStoreState = {
  showLoader: true,
  currentModule: '',
  error: null,
  showedMessage: false,
  showedNewsMessage: false,
  showedMessages: {
    results: 0,
  },
  currentSelectedRound: {
    id: '',
    children: [],
    name: '',
    parentId: '',
    start: new Date(),
    end: new Date(),
    treeFactory: 0,
    isArchived: false,
    visualizationType: {
      id: '',
      name: '' as VisualizationType
    },
    visualizationTypeId: ''
  },
  claims: [],
  coreServicesReady: false,
  user: {
    token: null,
    roles: [],
    profile: null,
    avatarURL: '',
  },
  staticPages: [],
  languages: [],
  settings: {
    tenant: {
      tenantId: ''
    },
    name: 'null',
    url: 'null',
    title: 'null',
    googleTagManagerId: 'null',
    STORED_TOKEN_KEY: 'null',
    STORED_LANGUAGE_KEY: 'null',
    DEFAULT_LANGUAGE: 'null',
    STORED_CLAIMS: 'null',
    fileTypeSettings: [],
    visualization_type: 'GU',
    user_menu: {
      navigation: [],
      footerNavigation: [],
      mobileFooterNavigation: []
    },
    admin_redirect_route: 'null',
    displayed_elements: {
      dashboard_elements: [
        'taskFeed',
        'newsFeed',
        'rankingFeed'
      ],
      score_feed_elements: {
        foundationToken: true,
        bonusStar: true,
        points: true,
        growthIndex: true,
      },
      game_results_elements: [],
      layout_wrapper_elements: {
        scoreFeed: true,
        taskFeed: true,
        rankingFeed: true,
        duelFeed: false,
        duelRankingFeed: false
      },
      user_panel_elements: {
        sex: true,
        changePassword: true,
        changeLanguage: true,
        changeAvatar: true,
        hidePersonalData: true,
        avatarAd: true
      },
      score_list_elements: {
        hidePointsOnUserScoresList: false
      },
      knowledge_elements: {
        display_date: true
      },
      points_elements: {
        level_points: false
      }
    },
    group_settings: {
      resultsTreeType: IResultTreeTypes.ORGANIZATION,
      dashboardTreeLevel: 0,
    },
    theme: {
      default: []
    },
    enableKnowledgeBaseGroupFiltering: false,
    other_settings: {
      tasks: {
        displayMaxBeforePoints: false,
      },
      quizzes: {
        goFurtherAfterQuiz: false,
        afterQuizMessage: false,
        hideZeroPoints: false,
      },
      quests: {
        hideStatus: false,
      },
      gameResultsList: {
        showPositionsNotIndex: false,
      },
      staticPages: {
        relatedTaskUrl: false,
      },
      popupModals: {
        welcomeModalId: '',
      },
      selectPoints: {
        pointsValues: [],
        defaultValue: 0,
      },
      alternativeDialog: {
        showDialog: false,
      },
      company: {
        name: ''
      },
      surveys: {
        linkToKnowledgePage: false,
        afterSurveyMessage: false,
      }
    }
  },
  tenantLogo: {url: '/static/img/gu_logo.svg'},
};

export const coreStoreReducer: Reducer<ICoreStoreState, Actions> = (
  state: ICoreStoreState = coreStoreInitialState,
  action: Actions
) => {
  switch (action.type) {

    case CoreStoreActions.SET_CORE_SERVICES_READY: {
      return {
        ...state,
        coreServicesReady: action.payload.statement,
      }
    }

    case CoreStoreActions.SET_USER_AVATAR_URL: {
      return {
        ...state,
        user: {
          ...state.user,
          avatarURL: action.payload.avatarURL,
        }
      }
    }
    case CoreStoreActions.SET_FE_SETTINGS: {
      return {
        ...state,
        settings: action.payload.settings,
      }
    }

    case CoreStoreActions.SET_TENANT_LOGO: {
      return {
        ...state,
        tenantLogo: action.payload.tenantLogo,
      }
    }

    case CoreStoreActions.SET_CURRENT_MODULE:
      return {
        ...state,
        currentModule: action.payload.currentModule
      };

    case CoreStoreActions.SET_SHOWED_MESSAGE:
      return {
        ...state,
        showedMessage: action.payload.statement
      };
    case CoreStoreActions.SET_SHOWED_NEWS_MESSAGE:
      return {
        ...state,
        showedNewsMessage: action.payload.statement
      };

    case CoreStoreActions.SET_SHOWED_MESSAGE_RESULTS:
      return {
        ...state,
        showedMessages: {
          ...state.showedMessages,
          results: action.payload.time
        }
      };

    case CoreStoreActions.SET_SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload.statement
      };

    case CoreStoreActions.SET_APP_CLAIMS:
      return {
        ...state,
        claims: action.payload.claims
      };

    case CoreStoreActions.SET_CURRENT_SELECTED_ROUND:
      return {
        ...state,
        currentSelectedRound: action.payload.currentSelectedRound
      };

    case CoreStoreActions.SET_AUTH_TOKEN: {
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.token
        }
      };
    };

    case CoreStoreActions.SET_LANGUAGES: {
      return {
        ...state,
        languages: action.payload.languages
      };
    }

    case CoreStoreActions.SET_USER_ROLES: {
      return {
        ...state,
        user: {
          ...state.user,
          roles: action.payload.roles,
        }
      }
    }

    case CoreStoreActions.SET_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          profile: { ...action.payload.profile }
        }
      };

    case CoreStoreActions.SET_STATIC_PAGES:
      return {
        ...state,
        staticPages: action.payload.staticPages
      };

    case CoreStoreActions.SET_ERROR_TYPE:
      return {
        ...state,
        error: action.payload.error ? { ...action.payload.error } : null,
      };

    case CoreStoreActions.SET_RESET_CORE_STORE:
      return {
        ...coreStoreInitialState
      }

    default:
      return state;
  }
};
