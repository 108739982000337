import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { defaultTheme } from 'themes/default-theme';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  expansionPanel: {
    borderBottom: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    },
    '&$disabled': {
      backgroundColor: theme.palette.common.white
    }
  },
  heading: {
    ...defaultTheme.mixins.fonts.bold.xs
  },
  details: {
    padding: theme.spacing(2, 2, 2, 0)
  },
  summary: {
    padding: 0,
    backgroundColor: theme.palette.common.white,
    borderBottom: '2px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  }
}));

export interface AccordionPanelPropsClassNames {
  panel?: string;
  summary?: string;
  header?: string;
  details?: string;
  typography?: string;
}

export interface AccordionPanelProps {
  title: string;
  disabled?: boolean;
  className?: AccordionPanelPropsClassNames;
  children?: React.ReactNode;
}

export type AccordionPanelPropsType = React.FC<AccordionPanelProps>;

export const AccordionPanel: AccordionPanelPropsType = (props: AccordionPanelProps) => {
  const { className, disabled, title, children } = props;
  const classes = makeClasses();
  return (
    <ExpansionPanel className={clsx(classes.expansionPanel, className?.panel)} disabled={disabled}>
      <ExpansionPanelSummary className={clsx(classes.summary, className?.summary)} expandIcon={<ExpandMoreIcon />}>
        <div className={clsx(classes.heading, className?.header)}>{title}</div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={clsx(classes.details, className?.details)}>
        <Typography className={clsx(classes.details, className?.typography)} component="span">
          {children}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
