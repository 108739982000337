import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const TrophySVG: React.FC<SVGComponentProps> = ({ fill, className }: SVGComponentProps) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M18 2c-.9 0-2 1-2 2H8c0-1-1.1-2-2-2H2v9c0 1 1 2 2 2h2.2c.4 2 1.7 3.7 4.8 4v2.08C8 19.54 8 22 8 22h8s0-2.46-3-2.92V17c3.1-.3 4.4-2 4.8-4H20c1 0 2-1 2-2V2h-4M6 11H4V4h2v7m14 0h-2V4h2v7z"
      />
    </svg>
  );
};
