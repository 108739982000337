import { DomElement } from 'domhandler';
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Bold } from 'modules/core/components/common/rich-editor/components/bold';
import { Paragraph } from 'modules/core/components/common/rich-editor/components/paragraph';
import { Video } from 'modules/core/components/common/video/video';
import { Image } from 'modules/core/components/common/image/image';
import { List } from 'modules/core/components/common/rich-editor/components/list';
import { ListItem } from 'modules/core/components/common/rich-editor/components/list-item';
import { Code } from 'modules/core/components/common/rich-editor/components/code';
import { Hearts } from 'modules/core/components/common/rich-editor/components/hearts';
import { VideoUploaded } from 'modules/core/components/common/rich-editor/components/video-uploaded';
import { PdfViewer } from 'modules/core/components/common/rich-editor/components/pdfviewer';
import { Document, Page, pdfjs } from 'react-pdf';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// https://stackoverflow.com/questions/9518956/javascript-convert-css-style-string-into-js-object
const css2obj = (cssStyle: string) => {
  const r = /(?=^|;)\s*([^:]+)\s*:\s*([^;]+)\s*/g;
  const o: { [key: string]: string } = {};
  cssStyle.replace(r, (m: string, p: string, v: string) => {
    return (o[p.replace(/;/g, '')] = v);
  });
  return o;
};

const getAdditionalProps = (domNode: DomElement) => {
  const { attribs } = domNode;
  const style = (attribs && attribs['style'] && css2obj(attribs['style'])) || undefined;
  const className = (attribs && attribs['class']) || undefined;
  const src = (attribs && attribs['src']) || undefined;
  const href = (attribs && attribs['href']) || undefined;
  const rel = (attribs && attribs['rel']) || undefined;
  const target = (attribs && attribs['target']) || undefined;
  const id = (attribs && attribs['id']) || undefined;

  return {
    style,
    className,
    src,
    href,
    rel,
    target,
    id
  };
};

export const parseToComponents = (
  domNode: DomElement
): React.ReactElement | object | void | undefined | null | false => {
  if (domNode.type === 'text') {
    return <>{domNode.data}</>;
  }

  const props = getAdditionalProps(domNode);

  if (domNode.name === 'br') {
    return <br {...props} />;
  }

  if (domNode.name === 'iframe') {
    if (domNode?.attribs?.src) {
      return <Video {...props} />;
    }
    return null;
  }

  if (domNode.name === 'img') {
    if (domNode?.attribs?.src) {
      return <Image {...props} />;
    }
    return null;
  }


  if (domNode.name === 'a' && domNode.attribs?.class === 'doc-link' && domNode.attribs?.['data-uri']) {
    const file = domNode.attribs?.['data-uri'];
    const ext = domNode.attribs?.['data-ext'];

    const result = [
      {
        uri:
          file, fileType: ext
      }
    ];

    return (
      <DocViewer pluginRenderers={DocViewerRenderers} documents={result}  style={{height: 600}} config={{
 header: {
  disableHeader: true,
  disableFileName: true,
  retainURLParams: false
 }
}}  /> 
    );
  }

  if (domNode.name === 'div' && domNode.attribs?.class === 'pdfViewer' && domNode.attribs?.['data-file-id']) {
    return (
      <PdfViewer
        fileId={domNode.attribs?.['data-file-id']}
        width={domNode.attribs?.['data-pdf-width'] ?? 'auto'}
        multiPageDisplay={domNode.attribs?.['data-multi-page-display'] ? true : false}
      />
    );
  }

  if (domNode.name === 'div' && domNode.attribs?.class === 'heartsIcons') {
    return <Hearts />;
  }

  if (domNode.name === 'div' && domNode.attribs?.class === 'videoUploaded') {
    return <VideoUploaded mediaURL={domNode.attribs?.datasrc} />;
  }

  if (domNode.children?.length) {
    if (domNode.name === 'p') {
      return <Paragraph {...props}>{domNode.children.map(child => parseToComponents(child))}</Paragraph>;
    }
    if (domNode.name === 'a') {
      if (domNode?.attribs?.href && domNode?.attribs?.href.indexOf('#') > -1) {
        const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
        };
        return (
          <Link onClick={onClick} smooth to={domNode?.attribs?.href}>
            {domNode.children.map(child => parseToComponents(child))}
          </Link>
        );
      }
    }
    if (domNode.name === 'a') {
      return <a {...props}>{domNode.children.map(child => parseToComponents(child))}</a>;
    }
    if (domNode.name === 'span') {
      return <span {...props}>{domNode.children.map(child => parseToComponents(child))}</span>;
    }
    if (domNode.name === 'dl') {
      return <dl {...props}>{domNode.children.map(child => parseToComponents(child))}</dl>;
    }
    if (domNode.name === 'dd') {
      return <dd {...props}>{domNode.children.map(child => parseToComponents(child))}</dd>;
    }
    if (domNode.name === 'dt') {
      return <dt {...props}>{domNode.children.map(child => parseToComponents(child))}</dt>;
    }
    if (domNode.name === 'strong') {
      return <Bold {...props}>{domNode.children.map(child => parseToComponents(child))}</Bold>;
    }
    if (domNode.name === 'ul') {
      if (domNode.attribs?.class === 'no-bullets') {
        return (
          <List {...props} type="unordered" hideBullets={true}>
            {domNode.children.map(child => parseToComponents(child))}
          </List>
        );
      } else {
        return (
          <List {...props} type="unordered">
            {domNode.children.map(child => parseToComponents(child))}
          </List>
        );
      }
    }
    if (domNode.name === 'ol') {
      return (
        <List {...props} type="ordered">
          {domNode.children.map(child => parseToComponents(child))}
        </List>
      );
    }
    if (domNode.name === 'li') {
      return <ListItem {...props}>{domNode.children.map(child => parseToComponents(child))}</ListItem>;
    }
    if (domNode.name === 'h4') {
      return <h4 {...props}>{domNode.children.map(child => parseToComponents(child))}</h4>;
    }
    if (domNode.name === 'em') {
      return <em {...props}>{domNode.children.map(child => parseToComponents(child))}</em>;
    }
    if (domNode.name === 'u') {
      return <u {...props}>{domNode.children.map(child => parseToComponents(child))}</u>;
    }
    if (domNode.name === 'b') {
      return <b {...props}>{domNode.children.map(child => parseToComponents(child))}</b>;
    }
    if (domNode.name === 'i') {
      return <i {...props}>{domNode.children.map(child => parseToComponents(child))}</i>;
    }
    if (domNode.name === 'pre' || domNode.name === 'code') {
      return <Code {...props}>{domNode.children.map(child => parseToComponents(child))}</Code>;
    }
  }
};
