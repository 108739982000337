import React from 'react';
import { Drawer, Hidden, IconButton } from '@material-ui/core';
import { Appbar } from 'modules/core/components/common';
import { NavUser } from './nav-user';
import { NavBrand } from './nav-brand';
import { NavList } from './nav-list';
import { NavRoundDropdown } from './nav-roundDropdown';
import { IModuleView } from 'interfaces/module-view.interface';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';
import { dashboardElementSelector } from 'modules/core/store/selectors';
import { IDashboardElements } from 'interfaces/settings.interface';

export interface INavProps {
  list: IModuleView[];
}

export const Nav: React.FC<INavProps> = ({ list }: INavProps) => {

  const dashboardElements = useSelector(dashboardElementSelector);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const accessibleViews = React.useMemo(() => {
    return list.filter(view => view.routeProps.accessible === true || view.routeProps.accessible === undefined);
  }, [list]);

  const showDesktopFromMD = accessibleViews.length < 4;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Appbar>

      <Hidden
        mdUp={!showDesktopFromMD}
        smUp={showDesktopFromMD}>
        <IconButton
          aria-label="open menu"
          onClick={toggleDrawer(true)}
          edge="start"
        >
          <MenuIcon/>
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <NavUser type="user" />
          {!dashboardElements.includes(IDashboardElements.HIDE_ROUND_DROPDOWN) && <NavRoundDropdown/>}
          <NavList
            list={accessibleViews}
            variant="mobile"
          />
        </Drawer>
      </Hidden>

      <NavBrand linkTo={'/user/'}/>

      <Hidden
        smDown={!showDesktopFromMD}
        xsDown={showDesktopFromMD}>
        {!dashboardElements.includes(IDashboardElements.HIDE_ROUND_DROPDOWN) && <NavRoundDropdown/>}
        <NavList
          list={accessibleViews}
          variant="desktop"
        />

        <NavUser type="user" />
      </Hidden>

    </Appbar>
  );
};
