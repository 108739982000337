import { IRole } from './role.interface';
import { IWorkerGroup } from './worker-group.interface';
import { IMappedProperty } from 'interfaces/core/mapped-property';
import { ILanguage } from './language-user.interface';

export interface IUserPlain {
  email: string,
  name: string,
  surname: string,
}

export interface IUser extends IUserPlain {
  id: string,
  password?: string,
}

export interface IAPUserPlain extends IUserPlain {
  isBlocked: boolean,
}
export interface IManagerUser {
  id: string;
  displayName: string;
}

export interface IAPUserRaw extends IAPUserPlain {
  languageId: string,
  hidePersonalData: boolean,
  roles: string[],
  gender: IUserProfileSex,
  workerGroups: string[],
}

export interface IAPUser extends IAPUserPlain {
  id: string,
  hidePersonalData: boolean,
  gender: IUserProfileSex,
  languageId: string,
  roles: IRole[],
  workerGroups: IWorkerGroup[],
}

export enum IUserProfileSex {
  MALE = 'Male',
  FEMALE = 'Female',
  NONE = 'NotSet'
}

export interface IUserProfile {
  id: string,
  email: string,
  name: string,
  surname: string,
  avatar: string,
  gender: IUserProfileSex,
  round: IMappedProperty,
  claims: string[],
  language: string,
  hidePersonalData: boolean,
}

export interface IUserProfileToSend {
  id: string,
  content: IUserProfile
}

export interface IUserGroup {
  id: string,
  name: string,
  children?: IUserGroup[],
}

export interface IUserPersonalData {
  name: string,
  surname: string,
  hidePersonalData: boolean,
}
export interface IRecentUserForPicker {
  id: string,
  value: string,
}
