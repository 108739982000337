import { IRound } from 'interfaces/round.interface';
import { IWorkerGroup } from 'interfaces/worker-group.interface';
import { IQuiz, IQuizAnswer } from 'interfaces/quiz.interface';
import { IRole } from 'interfaces/role.interface';
import { IQuest, IQuestAnswer } from 'interfaces/quest.interface';
import { IFlowListItem, IFlowItemTypesManage } from 'interfaces/flows.interface';
import { IKnowledgePage } from 'interfaces/knowledge-page.interface';
import { IAPUser, IRecentUserForPicker } from 'interfaces/user.interface';
import { IPagedData } from 'interfaces/paged-data.interface';
import { IFAQElement } from 'interfaces/faq.interface';
import { IMappedProperty } from 'interfaces/core/mapped-property';
import { IRegulation } from 'modules/admin/interfaces/regulation.interface';
import { IPagedListData } from 'interfaces/paged-list-data';
import { IAuditEvent, IAuditEventsFilters } from 'interfaces/admin/audit-event';
import { IClaim } from 'interfaces/models/claim.interface';
import { IStaticPage } from 'interfaces/admin/static-page';
import { IPagination } from 'interfaces/pagination.interface';
import { IEmailTemplate } from 'interfaces/models/email-templates';
import { INewsManage } from 'interfaces/news.interface';
import { IFileManage } from 'interfaces/files.interface';
import { IUserActivity, IUserActivityHistory } from 'interfaces/activity.interface';
import { IKPITask, IKPITaskDto } from 'interfaces/kpi.interface';
import { ISurveyPlain } from 'interfaces/survey.interface';
import { ITrainingBudgetListItem, ITrainingRequests } from 'interfaces/trainings.interface';
import { ISuggestionsElement } from 'interfaces/suggestions.interface';

export enum AdminModuleActions {
  // Common
  SET_COMMON_CLAIMS = 'SET_COMMON_CLAIMS',
  SET_COMMON_CLAIMS_LOADED = 'SET_COMMON_CLAIMS_LOADED',
  SET_COMMON_GROUPS = 'SET_COMMON_GROUPS',
  SET_COMMON_ROUNDS = 'SET_COMMON_ROUNDS',
  SET_COMMON_USERS = 'SET_COMMON_USERS',
  SET_VISUALIZATION_TYPES = 'SET_VISUALIZATION_TYPES',
  SET_COMMON_REGULATIONS = 'SET_COMMON_REGULATIONS',
  SET_FLOW_ITEM_TYPES = 'SET_FLOW_ITEM_TYPES',
  SET_RECENT_SELECTED_USERS = 'SET_RECENT_SELECTED_USERS',
  // Tables
  SET_USERS_TABLE_DATA = 'SET_USERS_TABLE_DATA',
  SET_NEWS_TABLE_DATA = 'SET_NEWS_TABLE_DATA',
  SET_QUIZ_TABLE_DATA = 'SET_QUIZ_TABLE_DATA',
  SET_FILES_TABLE_DATA = 'SET_FILES_TABLE_DATA',
  SET_QUIZ_ANSWERS_TABLE_DATA = 'SET_QUIZ_ANSWERS_TABLE_DATA',
  SET_ACTIVITIES_IMPORT_HISTORY = 'SET_ACTIVITIES_IMPORT_HISTORY',
  SET_QUIZ_ANSWERS_TO_REVIEW_TABLE_DATA = 'SET_QUIZ_ANSWERS_TO_REVIEW_TABLE_DATA',
  SET_QUEST_TABLE_DATA = 'SET_QUEST_TABLE_DATA',
  SET_WORKER_GROUPS_TABLE_DATA = 'SET_WORKER_GROUPS_TABLE_DATA',
  SET_QUEST_ANSWERS_TABLE_DATA = 'SET_QUEST_ANSWERS_TABLE_DATA',
  SET_KNOWLEDGE_PAGES_TABLE_DATA = 'SET_KNOWLEDGE_PAGES_TABLE_DATA',
  SET_ACTIVITIES_TABLE_DATA = 'SET_ACTIVITIES_TABLE_DATA',
  SET_ROLES_TABLE_DATA = 'SET_ROLES_TABLE_DATA',
  SET_FLOW_TABLE_DATA = 'SET_FLOW_TABLE_DATA',
  SET_KPI_TABLE_DATA = 'SET_KPI_TABLE_DATA',
  SET_KPI_ASSIGNMENT_DATA = 'SET_KPI_ASSIGNMENT_DATA',
  SET_SURVEYS_TABLE_DATA = 'SET_SURVEYS_TABLE_DATA',
  SET_TRAINING_REQUESTS_TABLE_DATA = 'SET_TRAINING_REQUESTS_TABLE_DATA',
  SET_TRAINING_BUDGET_TABLE_DATA = 'SET_TRAINING_BUDGET_TABLE_DATA',
  // MISC
  SET_AUDIT_EVENTS_DATA = 'SET_AUDIT_EVENTS_DATA',
  SET_MORE_AUDIT_EVENTS_DATA = 'SET_MORE_AUDIT_EVENTS_DATA',
  SET_EMAILTEMPLATES_DATA = 'SET_EMAILTEMPLATES_DATA',
  SET_FAQ_DATA = 'SET_FAQ_DATA',
  SET_STATIC_PAGES_DATA = 'SET_STATIC_PAGES_DATA',
  SET_RESET_ADMIN_STORE = 'SET_RESET_ADMIN_STORE',
  SET_SUGGESTIONS_DATA = 'SET_SUGGESTIONS_DATA',
}

export interface ISetCommonClaims {
  type: AdminModuleActions.SET_COMMON_CLAIMS,
  payload: {
    claims: IClaim[],
  }
}
export const setCommonClaims = (claims: IClaim[]) => ({
  type: AdminModuleActions.SET_COMMON_CLAIMS,
  payload: {
    claims,
  }
})
export interface ISetClaimsLoaded {
  type: AdminModuleActions.SET_COMMON_CLAIMS_LOADED,
  payload: {
    claimsLoaded: boolean
  }
}


export const setClaimsLoaded = (claimsLoaded: boolean) => ({
  type: AdminModuleActions.SET_COMMON_CLAIMS_LOADED,
  payload: {
    claimsLoaded,
  }
})
export interface ISetCommonRegulations {
  type: AdminModuleActions.SET_COMMON_REGULATIONS,
  payload: {
    regulations: IRegulation[],
  }
}

export const setCommonRegulations = (regulations: IRegulation[]) => ({
  type: AdminModuleActions.SET_COMMON_REGULATIONS,
  payload: {
    regulations
  }
});

export interface ISetVisualizationTypes {
  type: AdminModuleActions.SET_VISUALIZATION_TYPES,
  payload: {
    types: IMappedProperty[],
  }
}

export const setVisualizationTypes = (types: IMappedProperty[]) => ({
  type: AdminModuleActions.SET_VISUALIZATION_TYPES,
  payload: {
    types,
  }
})

export interface ISetFAQData {
  type: AdminModuleActions.SET_FAQ_DATA,
  payload: Partial<IPagedData<IFAQElement>>;
}

export const setFAQData: (data?: IFAQElement[], pagination?: IPagination) => ISetFAQData = (
  data?: IFAQElement[],
  pagination?: IPagination
) => ({
  type: AdminModuleActions.SET_FAQ_DATA,
  payload: {
    data,
    pagination
  }
 });

export interface ISetSuggestionsData {
  type: AdminModuleActions.SET_SUGGESTIONS_DATA,
  payload: Partial<IPagedData<ISuggestionsElement>>;
}

export const setSuggestionsData: (data?: ISuggestionsElement[], pagination?: IPagination) => ISetSuggestionsData = (
  data?: ISuggestionsElement[],
  pagination?: IPagination
) => ({
  type: AdminModuleActions.SET_SUGGESTIONS_DATA,
  payload: {
    data,
    pagination
  }
});

export interface ISetCommonGroups {
  type: AdminModuleActions.SET_COMMON_GROUPS,
  payload: {
    groups: IWorkerGroup[],
  }
}

export const setCommonGroups = (groups: IWorkerGroup[]) => ({
  type: AdminModuleActions.SET_COMMON_GROUPS,
  payload: {
    groups
  }
});

export interface ISetCommonRounds {
  type: AdminModuleActions.SET_COMMON_ROUNDS;
  payload: {
    rounds: IRound[];
  };
}

export const setCommonRounds = (rounds: IRound[]) => ({
  type: AdminModuleActions.SET_COMMON_ROUNDS,
  payload: {
    rounds,
  }
});


export interface ISetCommonUsers {
  type: AdminModuleActions.SET_COMMON_USERS;
  payload: {
    users: IAPUser[],
  };
}

export const setCommonUsers = (users: IAPUser[]) => ({
  type: AdminModuleActions.SET_COMMON_USERS,
  payload: {
    users,
  }
});

export interface ISetFlowItemTypes {
  type: AdminModuleActions.SET_FLOW_ITEM_TYPES,
  payload: {
    flowItemTypes: IFlowItemTypesManage[]
  }
}

export const setFlowItemTypes = (flowItemTypes: IFlowItemTypesManage[]) => ({
  type: AdminModuleActions.SET_FLOW_ITEM_TYPES,
  payload: {
    flowItemTypes,
  }
})

export interface ISetRecentSelectedUsers {
  type: AdminModuleActions.SET_RECENT_SELECTED_USERS,
  payload: {
    users: IRecentUserForPicker[]
  }
}

export const setRecentSelectedUsers = (users: IRecentUserForPicker[]) => ({
  type: AdminModuleActions.SET_RECENT_SELECTED_USERS,
  payload: {
    users,
  }
})

export interface ISetRolesTableData {
  type: AdminModuleActions.SET_ROLES_TABLE_DATA,
  payload: Partial<IPagedData<IRole>>,
}

export const setRolesTableData = (data: IRole[], pagination?: IPagination) => ({
  type: AdminModuleActions.SET_ROLES_TABLE_DATA,
  payload: {
    data,
    pagination
  }
});

export interface ISetUsersTableData {
  type: AdminModuleActions.SET_USERS_TABLE_DATA;
  payload: Partial<IPagedData<IAPUser>>;
}

export const setUsersTableData: (data?: IAPUser[], pagination?: IPagination) => ISetUsersTableData = (
  data?: IAPUser[],
  pagination?: IPagination
) => ({
  type: AdminModuleActions.SET_USERS_TABLE_DATA,
  payload: {
    data,
    pagination
  }
});

export interface ISetWorkerGroupsTableData {
  type: AdminModuleActions.SET_WORKER_GROUPS_TABLE_DATA;
  payload: Partial<IPagedData<IWorkerGroup>>;
}

export const setWorkerGroupsTableData: (data?: IWorkerGroup[], pagination?: IPagination) => ISetWorkerGroupsTableData = (
  data?: IWorkerGroup[],
  pagination?: IPagination
) => ({
  type: AdminModuleActions.SET_WORKER_GROUPS_TABLE_DATA,
  payload: {
    data,
    pagination
  }
});

export interface ISetEmailTemplatesTableData {
  type: AdminModuleActions.SET_EMAILTEMPLATES_DATA;
  payload: Partial<IPagedData<IEmailTemplate>>;
}

export const setEmailTemplatesData: (data?: IEmailTemplate[], pagination?: IPagination) => ISetEmailTemplatesTableData = (
  data?: IEmailTemplate[],
  pagination?: IPagination
) => ({
  type: AdminModuleActions.SET_EMAILTEMPLATES_DATA,
  payload: {
    data,
    pagination
  }
});

export interface ISetActivitiesTableData {
  type: AdminModuleActions.SET_ACTIVITIES_TABLE_DATA;
  payload: Partial<IPagedData<IUserActivity>>;
}

export const setActivitiesData: (data?: IUserActivity[], pagination?: IPagination) => ISetActivitiesTableData = (
  data?: IUserActivity[],
  pagination?: IPagination
) => ({
  type: AdminModuleActions.SET_ACTIVITIES_TABLE_DATA,
  payload: {
    data,
    pagination
  }
});

export interface ISetKnowledgePagesTableData {
  type: AdminModuleActions.SET_KNOWLEDGE_PAGES_TABLE_DATA,
  payload: Partial<IPagedData<IKnowledgePage>>
}

export const setKnowledgePagesTableData = (data: IKnowledgePage[], pagination?: IPagination) => ({
  type: AdminModuleActions.SET_KNOWLEDGE_PAGES_TABLE_DATA,
  payload: {
    data,
    pagination,
  }
})
export interface ISetSurveysTableData {
  type: AdminModuleActions.SET_SURVEYS_TABLE_DATA,
  payload: Partial<IPagedData<ISurveyPlain>>
}

export const setSurveysTableData: (data: ISurveyPlain[], pagination?: IPagination) => ISetSurveysTableData = (data, pagination) => ({
  type: AdminModuleActions.SET_SURVEYS_TABLE_DATA,
  payload: {
    data,
    pagination
  }
})

export interface ISetTrainingRequestsTableData {
  type: AdminModuleActions.SET_TRAINING_REQUESTS_TABLE_DATA,
  payload: Partial<IPagedData<ITrainingRequests>>
}

export const setTrainingReguestsTableData: (data: ITrainingRequests[], pagination?: IPagination) => ISetTrainingRequestsTableData = (data, pagination) => ({
  type: AdminModuleActions.SET_TRAINING_REQUESTS_TABLE_DATA,
  payload: {
    data,
    pagination
  }
})
export interface ISetTrainingBudgetTableData {
  type: AdminModuleActions.SET_TRAINING_BUDGET_TABLE_DATA,
  payload: Partial<IPagedData<ITrainingBudgetListItem>>
}

export const setTrainingBudgetTableData: (data: ITrainingBudgetListItem[], pagination?: IPagination) => ISetTrainingBudgetTableData = (data, pagination) => ({
  type: AdminModuleActions.SET_TRAINING_BUDGET_TABLE_DATA,
  payload: {
    data,
    pagination
  }
})


export interface ISetQuizTableData {
  type: AdminModuleActions.SET_QUIZ_TABLE_DATA,
  payload: Partial<IPagedData<IQuiz>>
}

export const setQuizTableData: (data: IQuiz[], pagination?: IPagination) => ISetQuizTableData = (data, pagination) => ({
  type: AdminModuleActions.SET_QUIZ_TABLE_DATA,
  payload: {
    data,
    pagination
  }
});
export interface ISetNewsTableData {
  type: AdminModuleActions.SET_NEWS_TABLE_DATA,
  payload: Partial<IPagedData<INewsManage>>
}

export const setActivitiesImportHistoryData: (data: IUserActivityHistory[], pagination?: IPagination) => ISetUserActivityHistory = (data, pagination) => ({
  type: AdminModuleActions.SET_ACTIVITIES_IMPORT_HISTORY,
  payload: {
    data,
    pagination
  }
});
export interface ISetUserActivityHistory {
  type: AdminModuleActions.SET_ACTIVITIES_IMPORT_HISTORY,
  payload: Partial<IPagedData<IUserActivityHistory>>
}

export const setNewsTableData: (data: INewsManage[], pagination?: IPagination) => ISetNewsTableData = (data, pagination) => ({
  type: AdminModuleActions.SET_NEWS_TABLE_DATA,
  payload: {
    data,
    pagination
  }
});

export interface ISetFilesTableData {
  type: AdminModuleActions.SET_FILES_TABLE_DATA,
  payload: Partial<IPagedData<IFileManage>>
}

export const setFilesTableData: (data: IFileManage[], pagination?: IPagination) => ISetFilesTableData = (data, pagination) => ({
  type: AdminModuleActions.SET_FILES_TABLE_DATA,
  payload: {
    data,
    pagination
  }
});

export interface ISetQuizAnswersData {
  type: AdminModuleActions.SET_QUIZ_ANSWERS_TABLE_DATA,
  payload: Partial<IPagedData<IQuizAnswer>>,
}

export const setQuizAnswersData: (data: IQuizAnswer[], pagination?: IPagination) => ISetQuizAnswersData = (data: IQuizAnswer[], pagination?: IPagination) => ({
  type: AdminModuleActions.SET_QUIZ_ANSWERS_TABLE_DATA,
  payload: {
    data,
    pagination,
  },
});

export interface ISetQuizAnswersToReviewData {
  type: AdminModuleActions.SET_QUIZ_ANSWERS_TO_REVIEW_TABLE_DATA,
  payload: Partial<IPagedData<IQuizAnswer>>,
}

export const setQuizAnswersToReviewData: (data: IQuizAnswer[], pagination?: IPagination) => ISetQuizAnswersToReviewData = (data: IQuizAnswer[], pagination?: IPagination) => ({
  type: AdminModuleActions.SET_QUIZ_ANSWERS_TO_REVIEW_TABLE_DATA,
  payload: {
    data,
    pagination,
  },
});

export interface ISetQuestTableData {
  type: AdminModuleActions.SET_QUEST_TABLE_DATA,
  payload: Partial<IPagedData<IQuest>>
}

export const setQuestTableData: (payload: IQuest[], pagination?: IPagination) => ISetQuestTableData = (data, pagination) => ({
  type: AdminModuleActions.SET_QUEST_TABLE_DATA,
  payload: {
    data,
    pagination,
  }
});

export interface ISetKPITableData {
  type: AdminModuleActions.SET_KPI_TABLE_DATA,
  payload: Partial<IPagedData<IKPITask>>
}

export const setKpiTableData: (payload: IKPITask[], pagination?: IPagination) => ISetKPITableData = (data, pagination) => ({
  type: AdminModuleActions.SET_KPI_TABLE_DATA,
  payload: {
    data,
    pagination,
  }
})
export interface ISetKpiAssignmentTableData {
  type: AdminModuleActions.SET_KPI_ASSIGNMENT_DATA,
  payload: Partial<IPagedData<IKPITaskDto>>
}

export const setKpiAssignmentData: (payload: IKPITaskDto[], pagination?: IPagination) => ISetKpiAssignmentTableData = (data, pagination) => ({
  type: AdminModuleActions.SET_KPI_ASSIGNMENT_DATA,
  payload: {
    data,
    pagination,
  }
})
export interface ISetQuestAnswersTableData {
  type: AdminModuleActions.SET_QUEST_ANSWERS_TABLE_DATA,
  payload: IQuestAnswer[]
}

export const setQuestAnswersTableData: (payload: IQuestAnswer[]) => ISetQuestAnswersTableData = (payload) => ({
  type: AdminModuleActions.SET_QUEST_ANSWERS_TABLE_DATA,
  payload,
});

export interface ISetFlowTableData {
  type: AdminModuleActions.SET_FLOW_TABLE_DATA,
  payload: Partial<IPagedData<IFlowListItem>>
}

export const setFlowTableData: (payload: IFlowListItem[], pagination?: IPagination) =>
  ISetFlowTableData = (data, pagination) => ({
    type: AdminModuleActions.SET_FLOW_TABLE_DATA,
    payload: {
      data,
      pagination,
    }
  });

export interface ISetAuditEventsData {
  type: AdminModuleActions.SET_AUDIT_EVENTS_DATA,
  payload: IPagedListData<IAuditEvent, IAuditEventsFilters>
}

export const setAuditEventsData: (payload: IPagedListData<IAuditEvent, IAuditEventsFilters>) => ISetAuditEventsData = (payload) => ({
  type: AdminModuleActions.SET_AUDIT_EVENTS_DATA,
  payload,
});

export interface ISetMoreAuditEventsData {
  type: AdminModuleActions.SET_MORE_AUDIT_EVENTS_DATA,
  payload: IPagedListData<IAuditEvent, IAuditEventsFilters>
}

export const setMoreAuditEventsData: (payload: IPagedListData<IAuditEvent, IAuditEventsFilters>) => ISetMoreAuditEventsData = (payload) => ({
  type: AdminModuleActions.SET_MORE_AUDIT_EVENTS_DATA,
  payload,
});

export interface ISetStaticPagesData {
  type: AdminModuleActions.SET_STATIC_PAGES_DATA,
  payload: IStaticPage[],
}

export const setStaticPagesData: (payload: IStaticPage[]) => ISetStaticPagesData = (payload: IStaticPage[]) => ({
  type: AdminModuleActions.SET_STATIC_PAGES_DATA,
  payload,
});

export interface ISetResetAdminStore {
  type: AdminModuleActions.SET_RESET_ADMIN_STORE,
}

export const setResetAdminStore = (): ISetResetAdminStore => ({
  type: AdminModuleActions.SET_RESET_ADMIN_STORE,
});

export type Actions =
  ISetCommonGroups
  & ISetCommonClaims
  & ISetClaimsLoaded
  & ISetRolesTableData
  & ISetKnowledgePagesTableData
  & ISetCommonRegulations
  & ISetVisualizationTypes
  & ISetFAQData
  & ISetQuestTableData
  & ISetWorkerGroupsTableData
  & ISetQuestAnswersTableData
  & ISetEmailTemplatesTableData
  & ISetQuizTableData
  & ISetQuizAnswersData
  & ISetQuizAnswersToReviewData
  & ISetCommonRounds
  & ISetFilesTableData
  & ISetCommonUsers
  & ISetNewsTableData
  & ISetUsersTableData
  & ISetAuditEventsData
  & ISetMoreAuditEventsData
  & ISetStaticPagesData
  & ISetResetAdminStore
  & ISetFlowTableData
  & ISetFlowItemTypes
  & ISetRecentSelectedUsers
  & ISetKPITableData
  & ISetUserActivityHistory
  & ISetKpiAssignmentTableData
  & ISetSurveysTableData
  & ISetActivitiesTableData
  & ISetTrainingRequestsTableData
  & ISetTrainingBudgetTableData
  & ISetSuggestionsData;
