import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BACKGROUND, resolveBackground } from 'themes/utils/background-utils';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

export interface ICardHeaderGradientProps {
  color?: BACKGROUND;
  fontColor?: string;
  noBorderBottom?: boolean;
}

export type Props = React.PropsWithChildren<ICardHeaderGradientProps>;

const makeClasses = makeStyles<DefaultTheme, ICardHeaderGradientProps>(theme => ({
  root: {
    minHeight: 60,
    width: '100%',
    color: props => props.fontColor,
    display: 'inline-flex',
    padding: `0 ${theme.spacing(3)}px`,
    alignItems: 'center',
    background: props => resolveBackground(theme, props.color),
    borderRadius: 'initial',
    borderBottom: props => props.noBorderBottom ? 'none' : `2px solid ${theme.palette.grey[200]}`,
    '& svg': {
      height: 'auto',
      marginRight: 20
    },
  }
}));

export const CardHeaderGradient: React.FC<Props> = ({ color, children, fontColor, noBorderBottom }) => {
  const classes = makeClasses({
    color,
    fontColor,
    noBorderBottom
  });

  return <div className={classes.root}>{children}</div>;
};

CardHeaderGradient.defaultProps = {
  color: BACKGROUND.PRIMARY,
  noBorderBottom: false,
};
