import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useService, useIcons, IconsNames } from 'modules/core/hooks';
import { RankingService } from 'modules/user/services/ranking.service';
import {UsersService} from 'modules/core/services';
import { Config, VisualizationType } from 'config';
import { hiddenData } from 'const/hiddenData';
import { pointsElementsSelector, currentSelectedRoundSelector, userProfileSelector } from 'modules/core/store/selectors';
import { getObjectHeight, getObjectHeightUnit, getObjectHeightDescription } from 'utils/objectSize-utils';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { IMyResultsFeed, IRankingPerGroup } from 'interfaces/ranking.interface';
import { useSnackbar } from 'notistack';
import { showApiErrors } from 'utils';
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { userAllScoresSelector, userSelectedResultsGroupSelector } from 'modules/user/store/selectors';
import { UserPointsDialog } from '../../my-results';

interface IMyRankingFeedProps extends IRankingPerGroup {
  index: number;
}
export type MyRankingFeedProps = React.PropsWithChildren<IMyRankingFeedProps>;

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  wrapper: {
  },
  container: {
    height: 'inherit',
    padding: 10,
    position: 'relative',
  },
  avatarContainer: {
    margin: '10px 0'
  },
  iconsContainer: {
    padding: '6px 0 20px',
    marginLeft: '70px',
    marginTop: 40
  },
  stats: {
  },
  number: {
    ...theme.mixins.fonts.medium.l,
    marginRight: 5
  },
  divider: {
    width: '100%',
    color: theme.palette.grey[300],
    marginTop: 10
  },
  position: {
    position: 'absolute',
    top: 12,
    left: '10px',
    fontSize: '24px',
    color: theme.palette.primary.main
  },
  personAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(1),
    marginBottom: 5,
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%'
    }
  },
  starIcon: {
    float: 'left',
    width: theme.icons.sizes.large,
    height: theme.icons.sizes.large,
    marginRight: theme.spacing(1)
  },
  levelIcon: {
    float: 'left',
    width: theme.icons.sizes.large,
    height: theme.icons.sizes.large,
    marginRight: theme.spacing(1),
  },
  trophyIcon: {
    width: theme.icons.sizes.large,
    height: theme.icons.sizes.large,
    marginRight: theme.spacing(0.5),
  },
  dialogButton: {
    width: '100%',
    marginTop: '30px',
    marginBottom: 40
  }
}));

export const MyRankingFeed = () => {
  const classes = makeClasses();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const currentProfile = useSelector(userProfileSelector);
  const userRoundScoreList = useSelector(userAllScoresSelector);
  const rankingService = useService(RankingService);
  const usersService = useService(UsersService);

  const { level_points } = useSelector(pointsElementsSelector);
  const currentRound = useSelector(currentSelectedRoundSelector);
  const currentGroup = useSelector(userSelectedResultsGroupSelector);
  const [avatar, setAvatar] = useState<string>();
  const [userResult, setUserResult] = useState<IMyResultsFeed>();

  const visualisationType = currentRound.visualizationType?.name;
  const userRoundScore = userRoundScoreList.find(score => score.roundId === currentRound.id && !score.workerGroupId)

  const levelText = `${t('common.level').toLowerCase()}`;
  const [isPointDialogOpen, setIsPointDialogOpen] = React.useState<boolean>(false);

  const levelCount = getObjectHeight(visualisationType, parseInt(userResult?.treeLevel?.code ?? '0', 10));

  const growthText = level_points || visualisationType === VisualizationType.Buildings ? levelText :
    `${t(getObjectHeightUnit(visualisationType))} ${t(getObjectHeightDescription(visualisationType, levelCount), { count: levelCount })}`;

  const fetchAvatar = async () => {
    try {
      const avatarUrl = await usersService.fetchAvatar(currentProfile?.avatar);
      setAvatar(avatarUrl);
    } catch (e) {
      // showApiErrors(e, enqueueSnackbar);
    }
  }
  const fetchRankingPosition = async () => {
    try {
      if (currentRound && currentGroup) {
        const rankingPosition = await rankingService.getRankingSummary(currentRound.id, currentGroup === 'show-all' ? undefined : currentGroup, { page: 1, size: 1 })
        if (rankingPosition && rankingPosition.records?.length) {
          setUserResult({
            position: rankingPosition.records[0]?.position,
            treeLevel: rankingPosition.records[0]?.treeLevel,
            points: rankingPosition.records[0]?.points
          })
        } else {
          setUserResult({
            position: undefined,
            treeLevel: userRoundScore?.treeLevel ?? undefined,
            points: userRoundScore?.value ?? undefined,
          })
        }
      }
    } catch (e) {
      showApiErrors(e, enqueueSnackbar);
    }
  }

  useEffect(() => {
    fetchAvatar();
  }, [])

  useEffect(
    () => {
      fetchRankingPosition()
    }, [currentRound, currentGroup]
  )
  const trophyIcon = useIcons(
    IconsNames.CUP,
    classes.trophyIcon,
  );
  const starIcon = useIcons(
    IconsNames.STAR,
    classes.starIcon,
  );

  const levelIcon = useIcons(
    IconsNames.LEVEL,
    classes.levelIcon,
  );

  return (
    <>
      <Paper
        variant='outlined'
        className={classes.wrapper}
      >
        <Grid container alignItems='center' className={classes.container}>
          {userResult?.position && <div className={classes.position}>
            {/* {index === 0 && trophyIcon} */}
            <span>#{userResult?.position ?? 0}</span>
          </div>}
          <Grid container item alignItems='center' direction='column' className={classes.avatarContainer}>
            <div className={classes.personAvatar}>
              {avatar && <img src={avatar} alt="" />}
            </div>
            {userResult?.position ? <Typography>{t('userPanel.myRankingFeed.myResult')}</Typography> : <Typography>{t('userPanel.myRankingFeed.myRoundResult')}</Typography>}
          </Grid>
          <Divider className={classes.divider} />
          <Grid container item alignItems='flex-start' justify='center' className={classes.iconsContainer} spacing={1} direction='column'>
            <Grid item container direction='row' xs={12} justify='flex-start' alignItems='center'>
              {levelIcon}
              <Typography className={classes.number}>{userResult?.treeLevel?.code ?? 0}</Typography>
              <Typography className={classes.stats}>{growthText}</Typography>
            </Grid>
            <Grid item container alignItems='center' direction='row' xs={12} justify='flex-start'>
              {starIcon}
              <Typography className={classes.number}>{userResult?.points ?? '0'}</Typography>
              <Typography className={classes.stats}>{t('common.points', { count: userResult?.points ?? 0 })}</Typography>
            </Grid>

          </Grid>
          {userResult?.position && <Button
            className={classes.dialogButton}
            variant='outlined'
            size='medium'
            onClick={() => setIsPointDialogOpen(true)}
          >{t('common.resultList')}</Button>}
        </Grid>
      </Paper>
      {isPointDialogOpen &&
        <UserPointsDialog
          open={isPointDialogOpen}
          onClose={() => setIsPointDialogOpen(false)}
          userId='mine'
        />}
    </>
  );
};

export default MyRankingFeed;
