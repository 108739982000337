import React from 'react';
import { ResponsiveModal } from 'modules/core/components/layout';
import { DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { LoaderView } from 'modules/core/views';
import videojs from 'video.js';
import { useTranslation } from 'react-i18next';

export enum MediaDialogFileTypes {}

export interface IMediaDialog {
  open: boolean,
  mediaType?: 'image' | 'video'
  mediaMimeType?: string,
  mediaURL: string,
  onClose: () => any,
}

export type MediaDialogType = React.FC<IMediaDialog>;

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  paperRoot: {
    width: '100%'
  },
  cardContentRoot: {
    minHeight: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&, &:first-child': {
      padding: 0
    }
  },
  mediaContentRoot: {
    flex: 1
  },
  mediaContentImage: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%'
  }
}));


interface IVideoPlayerProps {
  src: string,
  mimeType?: string,
}

const VideoPlayer = React.forwardRef<videojs.Player | null, IVideoPlayerProps>(
  (props: IVideoPlayerProps, ref) => {
    const {
      src,
      mimeType
    } = props;

    const videoRef = React.useRef<HTMLVideoElement>(null);

    React.useEffect(
      () => {
        const player = videojs(videoRef.current, {
          autoplay: true,
          controls: true
        }, () => {
          player.src({
            src,
            type: mimeType || 'video/mp4'
          });
        });
        if (ref) {
          // @ts-ignore
          ref.current = player;
        }
      },
      [src]
    );
    return (
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-16-9 vjs-big-play-centered"/>
      </div>
    );
  }
);

export const MediaDialog: MediaDialogType = (props) => {

  const { t } = useTranslation();
  const classes = makeClasses();

  const videoPlayer = React.useRef<videojs.Player>(null);

  const renderMedia = React.useMemo(
    () => {
      if (props.mediaType && props.mediaURL) {
        switch (props.mediaType) {
          case 'image':
            return <img className={classes.mediaContentImage}
                        src={props.mediaURL}/>;
          case 'video':
            return <VideoPlayer ref={videoPlayer} mimeType={props.mediaMimeType} src={props.mediaURL}/>;
        }
      }
      return <LoaderView text={t('adminPanel.common.loadingAttachment')} visible={true}/>;
    },
    [t, props.mediaMimeType, props.mediaType, props.mediaURL]
  );

  return (
    <ResponsiveModal
      aria-labelledby='media-dialog'
      maxWidth='md'
      PaperProps={{
        classes: {
          root: classes.paperRoot
        }
      }}
      open={props.open}
      onClose={props.onClose}>
      <DialogContent id='media-dialog' classes={{ root: classes.cardContentRoot }}>
        <div className={classes.mediaContentRoot}>
          {renderMedia}
        </div>
      </DialogContent>
    </ResponsiveModal>
  );

};
