import { IUserGroup } from 'interfaces/user.interface';

export enum GroupsModuleActions {
  SET_GROUPS = 'SET_GROUPS',
  SET_SELECTED_DASHBOARD_GROUP = 'SET_SELECTED_DASHBOARD_GROUP',
  SET_SELECTED_RESULTS_GROUP = 'SET_SELECTED_RESULTS_GROUP',
}

export type Actions = ISetGroupsAction & ISetSelectedDashboardGroupIdAction & ISetSelectedResultsGroupIdAction;

export interface ISetGroupsAction {
  type: GroupsModuleActions.SET_GROUPS;
  payload: {
    list: IUserGroup[];
  };
}

export interface ISetSelectedDashboardGroupIdAction {
  type: GroupsModuleActions.SET_SELECTED_DASHBOARD_GROUP;
  payload: {
    selectedDashboardGroupId: string | null;
  };
}
export interface ISetSelectedResultsGroupIdAction {
  type: GroupsModuleActions.SET_SELECTED_RESULTS_GROUP;
  payload: {
    selectedResultsGroupId: string | null;
  };
}

export const groupsActions = {
  setGroups(rounds: IUserGroup[]) {
    return {
      type: GroupsModuleActions.SET_GROUPS,
      payload: {
        list: rounds
      }
    };
  },
  setSelectedDashboardGroupId(groupId: string | null) {
    return {
      type: GroupsModuleActions.SET_SELECTED_DASHBOARD_GROUP,
      payload: {
        selectedDashboardGroupId: groupId
      }
    };
  },
  setSelectedResultsGroupId(groupId: string | null) {
    return {
      type: GroupsModuleActions.SET_SELECTED_RESULTS_GROUP,
      payload: {
        selectedResultsGroupId: groupId
      }
    };
  }
};
