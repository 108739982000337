import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    display: 'block'
  },
  notLoaded: {
    display: 'none'
  }
}));

export interface ImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {}

export const Image: React.FC<ImageProps> = props => {
  const classes = makeClasses();
  const [isLoaded, setIsLoaded] = React.useState(false);

  return (
    <img
      {...props}
      onLoad={() => setIsLoaded(true)}
      className={clsx(classes.root, !isLoaded && classes.notLoaded, props.className)}
      alt=""
    />
  );
};
