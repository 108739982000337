import { getService, AppService } from 'modules/core/services';
import { HttpClientService } from 'modules/core/functional-services';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { tasksActions } from 'modules/user/store/actions/tasks.actions';
import store from 'modules/core/store';
import { ITasks, ITask } from 'modules/user/interfaces/task.interface';

export class TasksService {
  constructor(
    private appService = getService(AppService),
    private httpClientService = getService(HttpClientService)
  ) {
  }

  async getTasks(roundsId: string[]) {
    try {
      const { value } = await this.httpClientService.get<ITasks[]>(`/round-tasks?RoundIds=${roundsId.join('&RoundIds=')}`, {
        params: {
          asc: true
        }
      });
      this.applyTasks(value);
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getTasksFeed(payload: IPagination = { page: 1, size: 100 }): Promise<ITask[]> {
    try {
      const { value }: IAPIResponse = await this.httpClientService.get('/tasks', {
        params: {
          page: payload.page,
          size: payload.size
        }
      });
      return value.records;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  applyTasks(tasks: ITasks[]) {
    store.dispatch(tasksActions.setTasks(tasks));
  }
}
