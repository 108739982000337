import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import clsx from 'clsx';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  sun: {
    position: 'absolute',
    top: '-5vw',
    right: '21%',
    width: '16vw',
    height: '16vw',
    borderRadius: '50%',
    // backgroundColor: theme.palette.common.sun,
    [theme.breakpoints.down('sm')]: {
      width: '19vw',
      height: '19vw',
      right: '15%',
      top: '5vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '33vw',
      height: '33vw',
      right: '10%',
      top: '9vw',
    }
  },
}));

export interface SunProps {
  className?: string;
  sunClassName?: string;
}

export const Sun: React.FC<SunProps> = props => {
  const classes = makeClasses(props);

  return (
    <div className={clsx(classes.root, props.className)} >
      <div className={clsx(classes.sun, props.sunClassName)}/>
    </div>
  );
};
