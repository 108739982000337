import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { Config, SupportedCompanies } from 'config';
import en from './lang/defaults/en';
import pl from './lang/defaults/pl';
import lt from './lang/defaults/lt';
import lv from './lang/defaults/lv';
import sr from './lang/defaults/sr';
import sl from './lang/defaults/sl';
import sk from './lang/defaults/sk';
import ro from './lang/defaults/ro';
import cs from './lang/defaults/cs';
import hu from './lang/defaults/hu';
import de from './lang/defaults/de';
import es from './lang/defaults/es';
import hr from './lang/defaults/hr';
import it from './lang/defaults/it';
import bg from './lang/defaults/bg';
import et from './lang/defaults/et';
import en_EDU from './lang/EN/en_EDU';
import en_RABEN from './lang/EN/en_RABEN';
import en_TH from './lang/EN/en_TH';
import pl_EDU from './lang/PL/pl-EDU';
import pl_RABEN from './lang/PL/pl-RABEN';
import pl_TH from './lang/PL/pl-TH';
import { getLangByConfig } from './utils/lang-utils';

const lang = { fallbackLng: {
    [getLangByConfig('pl')]: ['pl'],
    [getLangByConfig('en')]: ['en'],
    [getLangByConfig('lt')]: ['lt'],
    [getLangByConfig('lv')]: ['lv'],
    [getLangByConfig('sr')]: ['sr'],
    [getLangByConfig('sr')]: ['sr'],
    [getLangByConfig('sk')]: ['sk'],
    [getLangByConfig('cs')]: ['cs'],
    [getLangByConfig('ro')]: ['ro'],
    [getLangByConfig('hu')]: ['hu'],
    [getLangByConfig('de')]: ['de'],
    [getLangByConfig('es')]: ['es'],
    [getLangByConfig('hr')]: ['hr'],
    [getLangByConfig('it')]: ['it'],
    [getLangByConfig('bg')]: ['bg'],
    [getLangByConfig('et')]: ['et'],
    'default': [getLangByConfig('en'), 'en']
  } };

i18n
  .use(intervalPlural)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(I18nextBrowserLanguageDetector)
  .init({
    detection: {
      order: ['navigator'],
    },
    useLocalStorage: false,
    resources: {
      en,
      en_EDU,
      en_RABEN,
      en_TH,
      pl,
      pl_EDU,
      pl_RABEN,
      pl_TH,
      lt,
      lv,
      sr,
      sl,
      sk,
      cs,
      ro,
      hu,
      de,
      es,
      hr,
      it,
      bg,
      et,
    },

    // TODO: maybe handle this better (from config?) when project grows
    fallbackLng: lang.fallbackLng,
    debug: false,

    interpolation: {
      escapeValue: true // react already safes from xss
    }
  });

export default i18n;
