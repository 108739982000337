import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
}));


export interface ScrollWrapperProps {
  /**
   * loadHeightRatio: number from 0 to 1. Indicates, on which relative bottom scroll position to the component height will fire "onLoad" handler.
   */
  loadHeightRatio?: number,
  onLoad?: () => any,
  debounce?: number,
  disableLoading?: boolean,
  className?: string,
}

export const ScrollWrapper: React.FC<React.PropsWithChildren<ScrollWrapperProps>> = (props: React.PropsWithChildren<ScrollWrapperProps>) => {
  const classes = makeClasses();
  const [state, setState] = React.useState({
    scrollBottom: 0,
    scrollHeight: 1,
    componentHeight: 1,
    loadTriggered: false,
  });

  const onScroll = debounce((e) => {
    if (e.target) {
      setState({
        scrollBottom: e.target.scrollTop + e.target.clientHeight,
        scrollHeight: e.target.scrollHeight,
        componentHeight: e.target.clientHeight,
        loadTriggered: false,
      });
    }
  }, props.debounce ?? 200);

  React.useEffect(() => {
    if (props.disableLoading || state.loadTriggered || !props.onLoad) {
      return;
    }
    if ((state.scrollHeight - state.scrollBottom) <= ((props.loadHeightRatio || 0.3) * state.componentHeight)) {
      props.onLoad();
      setState({
        ...state,
        loadTriggered: true,
      });
    }
  }, [state, props.disableLoading, props.onLoad, props.loadHeightRatio]);

  return (
    <div
      className={clsx(classes.root, props.className)}
      onScroll={(e) => {
        if (props.onLoad) {
          e.persist();
          onScroll(e);
        }
      }}
    >
      {props.children}
    </div>
  );
};
