import React from 'react';
import { IModule } from 'interfaces/module.interface';
import { Roles } from '../../const/roles';

const dashboardModule: IModule = {
  name: 'adminModule',
  title: 'adminPanel.common.adminPanel',
  module: React.lazy(() => import('./admin')),
  routeProps: {
    accessible: false,
    path: '/admin'
  },
  routeAuthorizationProps: {
    roleGuard: [Roles.Admin.name]
  }
};

export default dashboardModule;
