import { AppService, getService } from "modules/core/services";
import { HttpClientService } from "modules/core/functional-services";
import {
  INews,
  INewsManage,
  IThumbnail,
  NewsMainTextType,
} from "interfaces/news.interface";
import store from "modules/core/store";
import { newsActions } from "modules/user/store/actions/news.actions";
import { IPagination } from "interfaces/pagination.interface";
import { APIPaths } from "config/paths";
import { Config } from "config";
import { GalleryService } from 'modules/user/services/gallery.services';
import {useService} from "../../core/hooks";

export class NewsService {
  constructor(
    private appService = getService(AppService),
    private httpClientService = getService(HttpClientService),
    private galleryService = getService(GalleryService)
  ) {}

  async getNews(pagination: IPagination) {
    try {
      const { value } = await this.httpClientService.get("/news", {
        params: {
          ...pagination,
        },
      });
      const { records } = value;
      await this.addThumbnails(records);
      this.applyNews(records);
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
  async getSimpleNews(id: string) {
    try {
      const { value } = await this.httpClientService.get<INewsManage>(
        `/news/${id}`
      );
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async addThumbnails(news: INews[]) {
    try {
      const thumbnails: IThumbnail[] = [];

      for (const simpleNews of news) {
        const { type, externalId } = simpleNews;
        if (type === NewsMainTextType.PHOTO) {
          if (externalId !== undefined && typeof externalId !== "string") {
            // const value = this.fetchFileThumbnailFromGallery(
            //   externalId?.fileToken);
            const value = await this.galleryService.getGallerySmallItem(externalId?.fileToken);

            if (value) {
              thumbnails.push({
                id: externalId?.id,
                thumbnailUrlLink: value,
              });
            }
          }
        }
      }
      this.applyThumbnails(thumbnails);
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  // fetchFileThumbnailFromGallery(fileToken: string | undefined) {
  //   const baseUrl = window.location.origin.toString();
  //   if (!fileToken) {
  //     return;
  //   }
  //   return `${baseUrl}${APIPaths.GALLERY}/${fileToken}/thumbnail/small/`;
  // }

  applyNews(news: INews[]) {
    store.dispatch(newsActions.setNews(news));
  }

  applyThumbnails(thumbnails: IThumbnail[]) {
    store.dispatch(newsActions.setThumbnails(thumbnails));
  }
}
