import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { THEME_APPBAR_DESKTOP_HEIGHT, THEME_APPBAR_MOBILE_HEIGHT } from 'themes/default-theme';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useLocation } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { usePrevious } from 'modules/core/hooks/usePrevious';

export interface IMainProps {
  withHeader?: boolean;
  withFooter?: boolean;
  withGradient?: boolean;
}

const makeClasses = makeStyles<DefaultTheme, Props>(theme => ({
  main: {
    minHeight: 'calc(100vh - 81px)', // full height minus the height of navbar
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: props => (props.withHeader ? `${THEME_APPBAR_DESKTOP_HEIGHT}px` : 0),
    backgroundImage: props => (props.withGradient ? theme.backgrounds.main : ''),

    [theme.breakpoints.down('md')]: {
      marginTop: props => (props.withHeader ? `${THEME_APPBAR_MOBILE_HEIGHT}px` : 0),
    }
  }
}));

type Props = React.PropsWithChildren<IMainProps>;

export interface IMainComponentContext {
  mainRef: HTMLElement | null;
  scrollToTop: () => void;
}

export const MainComponentContext = React.createContext<IMainComponentContext>({
  mainRef: null,
  scrollToTop: () => null,
});

export const Main = (props: Props) => {
  const classes = makeClasses(props);
  const { ref: mainRef, height } = useResizeObserver();
  const prevHeight = usePrevious(height);
  const location = useLocation();

  const scrollToTop = () => {
    if (mainRef.current !== null) {
      mainRef.current.scrollTo(0, 0);
    }
  };

  React.useEffect(() => {
    if (prevHeight === 0 && height !== 0) {
      scrollToTop();
    }
  }, [prevHeight, height]);

  React.useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <main
      ref={mainRef}
      className={classes.main}
    >
      <MainComponentContext.Provider
        value={{
          mainRef: mainRef.current,
          scrollToTop,
        }}
      >
        {props.children}
      </MainComponentContext.Provider>
    </main>
  );
};
