import { IGalleryItem } from '../../interfaces/media.interface';

export enum GalleryModuleActions {
  SET_GALLERY = 'SET_GALLERY'
}

export interface ISetFaqAction {
  type: GalleryModuleActions.SET_GALLERY;
  payload: { list: IGalleryItem[] };
}

export type Actions = ISetFaqAction;

export const galleryActions = {
  setGallery(gallery: IGalleryItem[]) {
    return {
      type: GalleryModuleActions.SET_GALLERY,
      payload: {
        list: gallery
      }
    };
  }
};
