import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { userAllScoresSelector, userRoundsSelector } from 'modules/user/store/selectors';
import { IUserScore } from 'modules/user/interfaces/score.interface';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  },
  img: {
    margin: '0 5px',
    width: '50px',
    height: '55px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  noFill: {
    fill: 'none'
  },
  heart: {
    fill: theme.icons.colors.outerColor
  },
  heartText: {
    fontSize: '15px',
    fontFamily: 'Roboto-Medium,Roboto',
    fontWeight: 500,
    letterSpacing: '0.012em',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '50px',
    height: '51px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filled: {
    color: '#fff'
  },
  noFilled: {
    color: '#de3236'
  }
}));

type optionalFilledHearts = boolean[];

interface IHeartProps {
  full?: boolean | undefined;
  stageNumber?: number;
  className?: string;
}

export const Heart: React.FC<IHeartProps> = ({ full, stageNumber, className }) => {
  const classes = makeClasses();
  return (
    <div>
      {full ?
        <div className={`${classes.img} ${className}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="53.33" height="53.33" viewBox="0 0 53.33 53.33">
            <g transform="translate(-427 -520)">
              <g transform="translate(427 520)">
                <path className={classes.noFill} d="M0,0H53.33V53.33H0Z" />
                <path className={classes.heart}
                  d="M24.237,43.821l-3.224-2.936C9.561,30.5,2,23.644,2,15.235A12.114,12.114,0,0,1,14.23,3,13.314,13.314,0,0,1,24.237,7.649,13.314,13.314,0,0,1,34.244,3a12.114,12.114,0,0,1,12.23,12.235c0,8.409-7.561,15.261-19.013,25.672Z"
                  transform="translate(2.428 3.642)"
                />
              </g>
            </g>
          </svg>
          {stageNumber && <text className={`${classes.heartText} ${classes.filled}`} transform="translate(454 550)">
            <tspan>{`S${stageNumber}`}</tspan>
          </text>}
        </div> :
        <div className={`${classes.img} ${className}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="53.334" height="53.334" viewBox="0 0 53.334 53.334">
            <g transform="translate(-553 -520)">
              <g transform="translate(553 520)">
                <path className={classes.noFill} d="M0,0H53.334V53.334H0Z" />
                <path className={classes.heart}
                  d="M34.223,3a13.308,13.308,0,0,0-10,4.645A13.308,13.308,0,0,0,14.222,3,12.1,12.1,0,0,0,2,15.222c0,8.4,7.556,15.245,19,25.645l3.222,2.911,3.222-2.933c11.445-10.378,19-17.222,19-25.623A12.1,12.1,0,0,0,34.223,3ZM24.445,37.556l-.222.222L24,37.556C13.422,27.978,6.444,21.645,6.444,15.222a7.6,7.6,0,0,1,7.778-7.778,8.688,8.688,0,0,1,7.933,5.245h4.156a8.633,8.633,0,0,1,7.911-5.245A7.6,7.6,0,0,1,42,15.222C42,21.645,35.023,27.978,24.445,37.556Z"
                  transform="translate(2.444 3.667)" />
              </g>
            </g>
          </svg>
          {stageNumber && <text className={`${classes.heartText} ${classes.noFilled}`} transform="translate(580 550)">
            <tspan>{`S${stageNumber}`}</tspan>
          </text>}
        </div>
      }
    </div>
  );
};

interface IHeartsProps {

}

const sliceScores = (scores: IUserScore[]): optionalFilledHearts => {
  const result: optionalFilledHearts = [];
  scores.map((score, index) => score.value > 60 ? result.push(true) : result.push(false));
  return result;
};

export const Hearts: React.FC<IHeartsProps> = () => {
  const classes = makeClasses();
  const allScores = useSelector(userAllScoresSelector);
  const rounds = useSelector(userRoundsSelector);

  const roundsWithScores: IUserScore[] = [];
  rounds.forEach((round) => {
    const roundScore = allScores.find(score => score.roundId === round.id)
    if (roundScore) {
      roundsWithScores.push(roundScore);
    }
  })

  const filledHearts: optionalFilledHearts = sliceScores(roundsWithScores);
  return (
    <div className={classes.container}>
      {
        Object.entries(filledHearts).map((el, index) => {
          return (<Heart key={index} stageNumber={index + 1} full={el[1]} />);
        })
      }
    </div>
  );
};
