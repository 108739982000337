import React from 'react';
import _isEqual from 'lodash/isEqual';
import { IMyTableState, initialState, MyTableStateCachedProp, reducer } from './reducer';
import { Actions, actions } from './actions';
import { IMyTableEvents, IMyTableOptions } from '../interfaces';
import { getRowUUID } from 'modules/core/components/common/my-table/helpers';

export interface IMyTableStore {
  state: IMyTableState,
  dispatch: React.Dispatch<Actions>,
}

export interface IMyTableStoreProps {
  options?: IMyTableOptions,
  events?: IMyTableEvents
}

export const useMyTableStore = (props: IMyTableStoreProps): IMyTableStore => {

  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState
  });

  const store = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  )

  return store

};
