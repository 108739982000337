import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IMyTableColumn, IMyTableHeadProps, IMyTableOptions, IMyTableSort } from './interfaces';
import { useTranslation } from 'react-i18next';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { actions } from 'modules/core/components/common/my-table/store/actions';
import { getRowUUID } from 'modules/core/components/common/my-table/helpers';

const makeClasses = makeStyles<Theme & DefaultTheme, { options?: IMyTableOptions }>(theme => ({
  cellRoot: {
    ...theme.mixins.fonts.medium.xxs,
    position: props => props?.options?.stickyHeader ? 'sticky' : 'relative',
    color: props => props.options?.darkHeader ? theme.palette.secondary.main : theme.palette.primary.main,
    backgroundColor: props => props.options?.darkHeader ? theme.palette.primary.main : '#fafafa',
    borderColor: 'transparent',
    paddingTop: props => props.options?.darkHeader ? '11px' : '24px',
    paddingBottom: props => props.options?.darkHeader ? '11px' : '24px'
  },
  defaultCellRoot: {
    cursor: 'pointer',
    userSelect: 'none'
  },
  sortIconRoot: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    width: 14,
    height: 14,
    fontSize: 14
  },
  checkboxRoot: {
    color: props => props.options?.darkHeader ? theme.palette.secondary.main : theme.palette.primary.main
  }
}));

export type Props = IMyTableHeadProps;

export const MyTableHead: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    translations,
    events,
    store,
    columns,
    classes,
    options,
    withActions,
    extendsProps
  } = props;

  const {
    state,
    dispatch
  } = store;

  const myClasses = makeClasses({ options });

  const everyChecked = state.data.length > 0 && (state.checkedRows.length === state.data.length);

  const onCheckboxChange = React.useCallback(
    () => {
      if (state.checkedRows.length) {
        dispatch(actions.setCheckedList([]));

        if (events?.onCheckAll)
          events.onCheckAll([]);

      } else {
        const next = state.data.map(row => getRowUUID(row, options?.rowIdentityKey));
        dispatch(actions.setCheckedList(next));
        if (events?.onCheckAll)
          events.onCheckAll(state.data);
      }
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, state]
  );

  const sortCell = React.useCallback(
    (column: IMyTableColumn) => {
      if (events?.onSortChange && column?.isSortable) {
        const sortData: IMyTableSort = {
          field: column.field,
          asc: true // true is defualt
        };
        if (column.field === state.pagination?.sort) {
          if (state.pagination?.asc === false) {
            events.onSortChange();
            return;
          } else {
            sortData.asc = !state.pagination?.asc;
          }
        }
        events.onSortChange(sortData);
      }
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state,
      dispatch
    ]
  );

  const renderedCheck = React.useMemo(
    () => {
      if (options?.check) {
        if (options.checkAll) {
          return (
            <TableCell
              classes={{
                ...classes?.tableCellClasses,
                ...classes?.tableHeadCellClasses,
                root: clsx(
                  myClasses.cellRoot,
                  classes?.tableCellClasses?.root,
                  classes?.tableHeadCellClasses?.root
                )
              }}
              padding="checkbox">
              <Checkbox
                color={options?.darkHeader ? 'default' : 'primary'}
                checked={everyChecked}
                onChange={onCheckboxChange}
                classes={{
                  root: myClasses.checkboxRoot
                }}
                {...(extendsProps?.headCheckBoxProps ?? {})}
              />
            </TableCell>
          );
        }
        return (
          <TableCell
            classes={{
              ...classes?.tableCellClasses,
              ...classes?.tableHeadCellClasses,
              root: clsx(
                myClasses.cellRoot,
                classes?.tableCellClasses?.root,
                classes?.tableHeadCellClasses?.root
              )
            }}/>
        );
      }
      return null;
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [everyChecked, options, onCheckboxChange]
  );

  const renderedColumns = React.useMemo(
    () => columns.map((column, index) => (
      <TableCell
        onClick={() => sortCell(column)}
        classes={{
          ...classes?.tableCellClasses,
          ...classes?.tableHeadCellClasses,
          root: clsx(
            myClasses.cellRoot,
            column.isSortable ? myClasses.defaultCellRoot : '',
            classes?.tableCellClasses?.root,
            classes?.tableHeadCellClasses?.root
          )
        }}
        key={`key-${index}`}>
        {column.field === state.pagination?.sort ? (
          state.pagination?.asc === false ? (
            <ExpandMoreIcon className={myClasses.sortIconRoot}/>
          ) : (
            <ExpandLessIcon className={myClasses.sortIconRoot}/>
          )
        ) : null}
        {column.title ?? column.field}
      </TableCell>
    )),
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.pagination, columns, sortCell]
  );


  const renderedActions = React.useMemo(
    () => {
      if (withActions) {
        return (
          <TableCell classes={{
            ...classes?.tableCellClasses,
            ...classes?.tableHeadCellClasses,
            ...classes?.tableActionCellsClasses,
            ...classes?.tableHeadActionCellsClasses,
            root: clsx(
              myClasses.cellRoot,
              classes?.tableCellClasses?.root,
              classes?.tableHeadCellClasses?.root,
              classes?.tableActionCellsClasses?.root,
              classes?.tableHeadActionCellsClasses?.root
            )
          }}>
            {translations?.head?.actions ?? t('adminPanel.common.actions')}
          </TableCell>
        );
      }
      return null;
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [withActions, translations]
  );

  return (
    <TableHead classes={{ root: classes?.tableHeadRoot }}>
      <TableRow>
        {renderedCheck}
        {renderedColumns}
        {renderedActions}
      </TableRow>
    </TableHead>
  );
};
