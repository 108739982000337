import React, { useEffect, useState } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { RootLoader } from 'modules/core/components/base';
import { useModules, useService } from 'modules/core/hooks';
import { GlobalModals } from 'modules/core/components/base/global-modals/global-modals';
import { useGlobalStyles } from 'styles/global';
import { NotistackService } from 'modules/core/functional-services/notistack.service';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export const App = () => {
  const { Modules } = useModules();

  const { SnackbarUtilsConfigurator } = useService(NotistackService);

  useGlobalStyles();

  const updateServiceWorker = (registration: any) => {
    const waitingWorker  = registration && registration.waiting;
    // tslint:disable-next-line:no-unused-expression
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    window.location.reload();
  };

  serviceWorkerRegistration.register({ onUpdate: updateServiceWorker });

  return (
    <SnackbarProvider maxSnack={5}>
      <CssBaseline />
      <RootLoader />
      <Modules />
      <GlobalModals />
      <SnackbarUtilsConfigurator />
    </SnackbarProvider>
  );


};
