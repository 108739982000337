import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { UsersService } from 'modules/core/services/users.service';
import { useService, useWindowSize } from 'modules/core/hooks';
import { showApiErrors } from 'utils';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import i18next from 'i18next';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Size } from 'modules/core/hooks/useWindowSize';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const makeClasses = makeStyles(theme => ({
  pdfContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // minHeight: '821px'
  },
  pdfButton: {
    display: 'flex',
    width: '100%',
    margin: '0 15px',
    whiteSpace: 'nowrap'
  },
  buttonsContainer: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row'
  },
  pages: {
    display: 'flex',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  navigationSection: {
    display: 'flex',
    width: '100%',
    marginBottom: '20px',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  }
}));

export const PdfViewer = ({
  fileId,
  width,
  multiPageDisplay
}: {
  fileId: string;
  width: string;
  multiPageDisplay: boolean;
}) => {
  const { t } = useTranslation();
  const preDefinedPage = window.location.search ? window.location.search.split('=').pop() : null;
  const [numberOfPages, setNumberOfPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(preDefinedPage ? parseInt(preDefinedPage, 10) : 1);
  const [showPDF, setShowPDF] = useState(false);
  const [filePDF, setFilePDF] = useState<ArrayBuffer | string | null>(null);

  const language = i18next.language.slice(0, 2);
  const usersService = useService(UsersService);
  const { enqueueSnackbar } = useSnackbar();
  const size: Size = useWindowSize();

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumberOfPages(numPages);
  }

  const renderVideo = React.useCallback(
    async () => {
      try {
        const rabenPDF: Blob = await usersService.fetchKnowledgeFileBlob(fileId);
        if (rabenPDF) {
          const reader = new window.FileReader();
          reader.readAsDataURL(rabenPDF);
          reader.onload = () => {
            const imageDataUrl = reader.result;
            setFilePDF(imageDataUrl);
          };
        }
      } catch (e) {
        showApiErrors(e, enqueueSnackbar);
      } finally {
        setShowPDF(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  React.useEffect(() => {
    renderVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const goPrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goNext = () => {
    if (numberOfPages && pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const displayAsSinglePage = () => (
    <>
      <div className={classes.buttonsContainer}>
        <Button variant="outlined" className={classes.pdfButton} onClick={goPrevious}>
          {t('common.prevPage')}
        </Button>
        {numberOfPages && (
          <span className={classes.pages}>
            {pageNumber}/{numberOfPages}
          </span>
        )}
        <Button variant="outlined" className={classes.pdfButton} onClick={goNext}>
          {t('common.nextPage')}
        </Button>
      </div>
      <Document file={filePDF} onLoadSuccess={onDocumentLoadSuccess}>
      <Page width={width !== 'auto' ? +width : (size.width ?? 0) > 950 ? 900 : 380} pageNumber={pageNumber} />
    </Document>
    </>
  );

  const displayAsMultiplePage = () => (
    <Document file={filePDF} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(Array(numberOfPages).keys()).map(page => (
          <Page key={page} width={width !== 'auto' ? +width : (size.width ?? 0) > 950 ? 900 : 380} pageNumber={page + 1} />
        ))}
      </Document>
  );

  const classes = makeClasses();
  return (
    <div>
      {!showPDF && <div>{t('common.loadingPDF')}</div>}
      {showPDF && (
        <div className={classes.pdfContainer}>{multiPageDisplay ? displayAsMultiplePage() : displayAsSinglePage()}</div>
      )}
    </div>
  );
};
