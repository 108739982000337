import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const FlashSVG: React.FC<SVGComponentProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24">
      <defs>
        <mask id="b" width="2" height="2" x="-1" y="-1">
          <path fill="#fff" d="M2 0h12v20H2z" />
          <path d="M13.948 7.701a.4.4 0 00-.35-.212h-5.27L9.21.465a.422.422 0 00-.267-.44.397.397 0 00-.475.166L2.06 11.874a.433.433 0 00-.008.424.4.4 0 00.351.214h5.192l-.7 7.035c-.014.193.1.37.276.432.176.06.37-.011.47-.175l6.303-11.681a.433.433 0 00.005-.422z" />
        </mask>
      </defs>
      <g filter="url(#a)">
        <path
          fill="none"
          d="M13.948 7.701a.4.4 0 00-.35-.212h-5.27L9.21.465a.422.422 0 00-.267-.44.397.397 0 00-.475.166L2.06 11.874a.433.433 0 00-.008.424.4.4 0 00.351.214h5.192l-.7 7.035c-.014.193.1.37.276.432.176.06.37-.011.47-.175l6.303-11.681a.433.433 0 00.005-.422z"
          mask='url("#b")'
        />
        <path
          fillOpacity="0.18"
          d="M13.948 7.701a.4.4 0 00-.35-.212h-5.27L9.21.465a.422.422 0 00-.267-.44.397.397 0 00-.475.166L2.06 11.874a.433.433 0 00-.008.424.4.4 0 00.351.214h5.192l-.7 7.035c-.014.193.1.37.276.432.176.06.37-.011.47-.175l6.303-11.681a.433.433 0 00.005-.422z"
        />
      </g>
      <path
        fill="#fff"
        d="M13.948 7.701a.4.4 0 00-.35-.212h-5.27L9.21.465a.422.422 0 00-.267-.44.397.397 0 00-.475.166L2.06 11.874a.433.433 0 00-.008.424.4.4 0 00.351.214h5.192l-.7 7.035c-.014.193.1.37.276.432.176.06.37-.011.47-.175l6.303-11.681a.433.433 0 00.005-.422z"
      />
    </svg>
  );
};
