
export enum IFileType {
  OTHER = 'Other',
  PHOTO = 'Photo',
  VIDEO = 'Video',
  PDF = 'Pdf',
  DOCUMENT = 'Document',
  ARCHIVE = 'Archive'
}

export enum IDashboardElements {
  NEWS_FEED = 'newsFeed',
  RANKING_FEED = 'rankingFeed',
  TASK_FEED = 'taskFeed',
  HIDE_ROUND_DROPDOWN = 'hideRoundDropdown',
  HIDE_SELECTIVE_GROUPS = 'hideSelectiveGroups'
}

export enum IGameResultsElements {
  LIST_VIEW = 'list',
  MAP_VIEW = 'map'
}

export enum IBESettingsSectionName {
  GHOST_TEACHER_SETTINGS = 'GhostTeacherSettings',
  MICROSOFT_AUTHENTICATION_SETTINGS = 'MicrosoftAuthenticationSettings',
  GROUP_SETTINGS = 'GroupSettings',
  URL_SETTINGS = 'UrlSettings',
  FE_SETTINGS = 'FeSettings'
}
export enum IResultTreeTypes {
  ORGANIZATION = "Organization",
  USER_DEPARTMENT = 'UserDepartment',
  USER = "User",
}
export interface IFileTypeSettings {
  contentTypes: string[],
  maxFileSize: number,
  fileType: IFileType,
}

export interface IUserMenuType {
  name: string,
  url: string,
}
export interface IScoreFeedElements {
  foundationToken: boolean,
  bonusStar: boolean,
  points: boolean,
  growthIndex: boolean,
}

export interface ILayoutWrapperElements {
  scoreFeed: boolean,
  rankingFeed: boolean,
  taskFeed: boolean,
  duelFeed: boolean,
  duelRankingFeed: boolean
}
export interface IUserPanelElements {
  sex: boolean,
  changePassword: boolean,
  changeAvatar: boolean,
  changeLanguage: boolean,
  hidePersonalData: boolean,
  avatarAd: boolean;
}
export interface IUserScoreListType {
  hidePointsOnUserScoresList: boolean,
}
export interface IKnowledgeElements {
  display_date: boolean,
}
export interface IFESettingsType {
  tenant: {
    tenantId: string,
  },
  name: string,
  url: string,
  title: string,
  googleTagManagerId:	string,
  STORED_TOKEN_KEY:	string,
  STORED_LANGUAGE_KEY: string,
  DEFAULT_LANGUAGE:	string,
  STORED_CLAIMS: string,
  fileTypeSettings: IFileTypeSettings[],
  visualization_type: string,
  admin_redirect_route: string,
  user_menu: {
    navigation: IUserMenuType[],
    footerNavigation: IUserMenuType[],
    mobileFooterNavigation: IUserMenuType[],
  },
  displayed_elements: {
    dashboard_elements: string[],
    score_feed_elements: IScoreFeedElements,
    game_results_elements: string[],
    show_snackbar_info?: boolean,
    layout_wrapper_elements: ILayoutWrapperElements,
    user_panel_elements: IUserPanelElements,
    score_list_elements: IUserScoreListType,
    knowledge_elements: IKnowledgeElements,
    points_elements: IPointsSettings
  },
  group_settings: {
    resultsTreeType: IResultTreeTypes,
    dashboardTreeLevel: number,
  },
  theme: {
    default: SetterValue[]
  },
  enableKnowledgeBaseGroupFiltering: boolean,
  other_settings: IOtherSettings
}
export interface IGroupSettingsValue {
  ResultsTreeType: number,
  DashboardTreeLevel: number,
}

export interface SetterValue {
  key: string,
  value: string | number
}

export interface IPointsSettings {
  level_points: boolean
}
export interface IBEGroupSettings {
  id: string,
  sectionName: IBESettingsSectionName,
  value: IGroupSettingsValue,
}

export interface ITenantLogo {
  url: string,
}

export interface IOtherSettings {
  tasks: ITasks,
  quizzes: IQuizzes,
  quests: IQuests,
  gameResultsList: IGamerResultsList,
  popupModals: IPopupModals,
  staticPages: IStaticPages,
  selectPoints: IDynamicSelectPoints,
  alternativeDialog : IConfirmationDialog,
  company: ICompany,
  surveys: ISurveys
}

export interface IGamerResultsList {
  showPositionsNotIndex: boolean;
}

export interface ITasks {
  displayMaxBeforePoints: boolean;
}

export interface IQuizzes {
  goFurtherAfterQuiz: boolean;
  afterQuizMessage: boolean;
  hideZeroPoints?: boolean;
}
export interface IStaticPages {
  relatedTaskUrl: boolean;
}
export interface IPopupModals {
  welcomeModalId: string;
  quizModalId?: string;
}

export interface IQuests {
  hideStatus?: boolean;
}

export interface IDynamicSelectPoints{
  pointsValues: number[];
  defaultValue: number;
}

export interface IConfirmationDialog {
  showDialog? : boolean;
}

export interface ICompany{
  name?: string;
}

export interface ISurveys{
  linkToKnowledgePage?: boolean;
  afterSurveyMessage?: boolean;
}