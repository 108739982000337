import store from "modules/core/store";
import { galleryActions } from "modules/user/store/actions/gallery.actions";
import { IGalleryItem } from "modules/user/interfaces/media.interface";
import { IPagination } from "interfaces/pagination.interface";
import { IPagedResponse } from "interfaces/paged-data.interface";
import { APIPaths } from "config/paths";
import { CoreService } from "modules/core/services/core.service";
import { Config } from "config";

export class GalleryService extends CoreService {
  // TODO actually api endpoint doesn't exist yet
  async getGallery(pagination: IPagination) {
    try {
      const { value } = await this.httpClientService.get<
        IPagedResponse<IGalleryItem>
      >(APIPaths.GALLERY, {
        params: {
          page: pagination.page,
          size: pagination.size,
          asc: false,
        },
      });
      store.dispatch(galleryActions.setGallery(value.records));
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
  async getGalleryItem(fileToken: string | undefined, size?: string) {
    try {
      if (!fileToken) {
        return;
      }
      const { value } = await this.httpClientService.get(size ? `/gallery/${fileToken}/thumbnail/${size}` : `/gallery/${fileToken}`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async getGallerySmallItem(fileToken: string | undefined, size?: string) {
    try {
      if (!fileToken) {
        return;
      }
      const { value } = await this.httpClientService.get(`/gallery/${fileToken}/thumbnail/small`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async createMediaUrlById(fileToken: string | undefined, size?: string) {
    const baseUrl = window.location.origin.toString();
    if (!fileToken) {
      return;
    }
    return `${size ?
        `${baseUrl}${APIPaths.GALLERY}/${fileToken}/thumbnail/${size}`
      // `${Config.API_URL}${APIPaths.GALLERY}/${fileToken}/thumbnail/${size}`
      :
        `${baseUrl}${APIPaths.GALLERY}/${fileToken}`}`
      // `${Config.API_URL}${APIPaths.GALLERY}/${fileToken}`}`
  }
}
