import { Reducer } from 'redux';
import { Actions, ScoreModuleActions } from '../actions/score.actions';
import { IUserScore } from '../../interfaces/score.interface';

export interface IListedUserScore {
  list: IUserScore[];
  userScore: IUserScore;
}

export const scoreModuleStoreInitialState: IListedUserScore = {
  list: [],
  userScore: {
    value: 0,
    treeHeight: 0,
    bonusActivityCount: 0,
    roundId: '-1',
    time: 0,
    activityCount: 0,
    isWithered: false,
    treeLevel: {
      code: '0',
      id: ''
    }
  }
};

export const scoreModuleStoreReducer: Reducer<IListedUserScore, Actions> = (
  state: IListedUserScore = scoreModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case ScoreModuleActions.SET_SCORE:
      return {
        ...state,
        list: [...payload.list]
      };
    case ScoreModuleActions.SET_USER_SCORE:
      return {
        ...state,
        userScore: payload.userScore
      };
    default:
      return state;
  }
};

export default scoreModuleStoreInitialState;
