import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useSelector } from 'react-redux';
import { feVisualizationType } from 'modules/core/store/selectors';
import { SupportedCompanies } from 'config';

const makeClasses = makeStyles<DefaultTheme, { isEX: boolean }>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`,
    marginLeft: props => props.isEX ? '70px' : '0px'
  },
  value: {
    ...theme.mixins.fonts.bold.xs,
    color: theme.palette.grey['900'],
    paddingLeft: theme.spacing(1.5)
  },
  text: {
    ...theme.mixins.fonts.bold.xs,
    color: theme.palette.grey['900'],
    paddingLeft: theme.spacing(1.5),
  }
}));

export interface PointsRowProps {
  icon: React.ReactNode;
  value: string | null;
  className?: string,
  reverse?: boolean
  text: string;
}

export const PointsRow: React.FC<PointsRowProps> = ({ icon, value, text, className, reverse }) => {
  const visualizationType = useSelector(feVisualizationType);
  const isEXCompany = visualizationType === SupportedCompanies.EX
  const classes = makeClasses({
    isEX: isEXCompany
  });
  return (
    <div className={`${classes.root} ${className}`}>
      {icon}
      <span className={classes.value}>{reverse ? text + ' ' + value : value + ' ' + text}</span>
    </div>
  );
};
