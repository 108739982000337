import React from 'react';
import { RegulationsModal } from 'modules/core/components/base/global-modals/components/regulations-modal/regulations-modal';
import { Config, SupportedCompanies } from 'config';

export const GlobalModals = () => {
  // TODO: it may be good to change handling globalModals to render here one modal at the time instead of list of modals.
  return (
    <>
      {Config.COMPANY !== SupportedCompanies.EDU && (
        <RegulationsModal />
      )}
    </>
  );
};
