import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from '../themes/interfaces/default-theme.interfaces';

export const useGlobalStyles = makeStyles<DefaultTheme>(theme => ({
  '@global': {
    '@keyframes spinnerAnimation': {
      '0%': {
        transform: 'scaleY(1)',
        opacity: '.875'
      },
      '50%': {
        transform: 'scaleY(1.3)',
        background: theme.palette.primary.light,
        opacity: 1
      },
      '100%': {
        transform: 'scaleY(1)',
        opacity: '.875'
      }
    }
  }
}));
