import React from 'react';
import { UnauthNav } from 'modules/core/components/layout/nav/unauth-nav';
import { Main, ResponsiveModal } from 'modules/core/components/layout';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { _mockBackground } from 'styles/utils';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  section: {
    ..._mockBackground,
    flex: 1
  },
  dialogContent: {
    ...theme.mixins.fonts.regular.xs,
    padding: theme.spacing(1, 4, 3, 3),
  },
}));

export interface ExternalLoginViewProps {
  title: React.ReactNode,
}

export const ExternalLoginView: React.FC<React.PropsWithChildren<ExternalLoginViewProps>> = (props) => {
  const classes = makeClasses();

  return (
    <>
      <UnauthNav noAuthRedirect />
      <Main withGradient withFooter withHeader>
        <section
          className={classes.section}
        >
          <ResponsiveModal
            open
            disableBackdropClick
          >
            <DialogTitle>
              {props.title}
            </DialogTitle>
            <DialogContent
              className={classes.dialogContent}
            >
              {props.children}
            </DialogContent>
          </ResponsiveModal>
        </section>
      </Main>
    </>
  );
};
