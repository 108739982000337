import { ICurrentRoundModel, IRound } from 'interfaces/round.interface';
import { IAPIResponse } from 'interfaces/api-response.interface';
import store from 'modules/core/store';
import { roundsActions } from 'modules/user/store/actions/rounds.actions';
import { CoreService } from 'modules/core/services/core.service';
import { APIPaths } from 'config/paths'

export class RoundsService extends CoreService{

  applyRounds = (roundList: IRound[]) => store.dispatch(roundsActions.setRounds(roundList));

  getRound = (roundID: string) => this.httpClientService.get<IRound[]>(`${APIPaths.ROUNDS}/${roundID}`, {params: {showEnded: true}}).catch(this.appService.handleError);

  getRounds = async (parentId?: string, showEnded?: boolean) => {
    try {
      const { value }: IAPIResponse<IRound[]> = await this.httpClientService.get(APIPaths.ROUNDS, { params: { parentId, showEnded } });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  getCurrentRound = async () => {
    try {
      const { value: currentRoundScrap  }: IAPIResponse<ICurrentRoundModel> = await this.httpClientService.get(APIPaths.USERS + APIPaths.CURRENT_ROUND);
      const { value: currentRound } = await this.getRound(currentRoundScrap.id);
      return currentRound[0]
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  setCurrentRound = async (roundId: string) => {
    try {
      const { value }: IAPIResponse<boolean> = await this.httpClientService.post(APIPaths.USERS + APIPaths.CURRENT_ROUND, { roundId });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  getAllRoundEnded = async () => {
    try {
      const { value }: IAPIResponse<{allRoundEnded: boolean}> = await this.httpClientService.get(`${APIPaths.ROUNDS}/AllRoundEnded`);
      return value.allRoundEnded;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

}

