import { HttpClientService } from 'modules/core/functional-services';
import { AppService, getService } from 'modules/core/services';
import store from 'modules/core/store';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { questsActions } from '../store/actions/quests.actions';
import { IQuest } from '../interfaces/quest.interface';

export interface IQuestApprovementPayload {
  Id: string;
  EndDescription: string;
  file?: File;
  fileContent?: File;
}

export class QuestsService {
  constructor(private appService = getService(AppService), private httpClientService = getService(HttpClientService)) {
  }

  async getQuests() {
    try {
      const { value }: IAPIResponse<IQuest[]> = await this.httpClientService.get(`/users/quests/assignments`);
      store.dispatch(questsActions.setQuests(value));
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getQuestById(id: string): Promise<IQuest> {
    try {
      const { value }: IAPIResponse<IQuest> = await this.httpClientService.get(`/users/quests/assignments/${id}`);
      // console.log(value);
      // return {
      //   ...value,
      //   quest: {
      //     ...value.quest,
      //     hint: 'jakiś hint',
      //     questDisplayType: 2,
      //     isConsent: true,
      //   }
      // }
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async sendQuestApprovement(assignmentId: string, payload: IQuestApprovementPayload): Promise<IAPIResponse | undefined> {
    try {
      const fd = new FormData();
      if (payload.file) fd.append('File', payload.file);
      fd.append('Id', payload.Id);
      fd.append('EndDescription', payload.EndDescription);
      return await this.httpClientService.post(`/quests/assignments/${assignmentId}/complete`, fd);
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
}
