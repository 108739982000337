import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useIcons, IconsNames } from 'modules/core/hooks';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  starIcon: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
  },
  starText: {
    ...theme.mixins.fonts.bold.m,
    color: theme.palette.grey['900'],
    position: 'absolute',
    padding: 18
  },
  bottomText: {
    ...theme.mixins.fonts.bold.xs,
    color: theme.palette.grey['900'],
    paddingTop: theme.spacing(0.25),
    textAlign: 'center'
  },
  icon: {
    fill: theme.icons.colors.outerColor
  }
}));

export interface StarButtonProps {
  starText?: string;
  bottomText?: string;
  onClick?: (event: React.MouseEvent) => any;
  className?: string;
}

export const StarButton: React.FC<StarButtonProps> = (props: StarButtonProps) => {
  const classes = makeClasses();
  const bonusStarIcon = useIcons(
    IconsNames.BONUS_STAR,
    classes.icon
  );

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={classes.starIcon} onClick={props.onClick}>
        {/* <StarBigSVG /> */}
        {bonusStarIcon}
        <span className={classes.starText}>{props.starText}</span>
      </div>
      <span className={classes.bottomText}>{props.bottomText}</span>
    </div>
  );
};
