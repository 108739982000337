import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Footer, Main, Section } from 'modules/user/components/layout';
import { LoginCard } from './login-card';
import { UnauthNav } from 'modules/core/components/layout/nav/unauth-nav';
import { _mockBackground } from 'styles/utils';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const makeClasses = makeStyles(theme => ({
  authRoot: {
    ..._mockBackground,
    flex: 1
  },
  container: {
    maxWidth: '1325px',
    paddingTop: 45,
    margin: '0 auto',
    marginBottom: theme.spacing(10),
  },
  titleHeader: {
    flex: 1,
    width: '100%',
    color: '#fff',
    textShadow: '0 5px 2px rgba(0, 0, 0, 0.15);',
    lineHeight: '57px',
    fontSize: 56,
    fontWeight: 900,
    letterSpacing: '-1.68px'
  },
  titleContent: {
    marginTop: 50,
    minWidth: 320,
    maxWidth: 420
  },
  text: {
    maxWidth: 399,
    fontSize: 15,
    fontWeight: 500,
    color: '#2D2D2D'
  },
  list: {
    listStyleType: 'none',
    paddingLeft: 0,
    '& li': {
      '&::before': {
        content: '""',
        width: 33,
        height: 38,
        marginRight: 10,
        display: 'inline-block',
        verticalAlign: 'middle',
        backgroundImage: `url('/static/img/icon_leaf.svg')`,
        backgroundSize: 'contain'
      }
    }
  },
  grid: {
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  loginCard: {
    minWidth: 320,
    maxWidth: 420
  },
  title: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  }
}));

export const AuthAdminView = (props: RouteComponentProps) => {
  const classes = makeClasses();
  const { t } = useTranslation();
  return (
    <>
      <UnauthNav noAuthRedirect />
      <Main withGradient withFooter withHeader>
        <Section className={classes.authRoot}>
          <div className={classes.container}>
            <Box mx={4}>
              <Grid container spacing={0} className={classes.grid}>
                <Grid item xs={12} lg={4} className={classes.loginCard}>
                  <LoginCard authWithMicrosoft={false} />
                </Grid>
              </Grid>
            </Box>
          </div>
        </Section>
        <Footer />
      </Main>
    </>
  );
};
