import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const makeClasses = makeStyles(theme => ({
  termsLabel: {
    font: 'Regular 12px/24px Roboto', // why 12px from here desnt work??
    fontSize: '12px',
    letterSpacing: '0'
  },
  checkbox: {
    color: '#b1b1b1'
  }
}));

export const CheckboxFormField = ({ name, handleChange, agreedToTerms, color = 'primary' }: any) => {
  const classes = makeClasses();
  return (
    <FormControlLabel
      classes={{ label: classes.termsLabel }}
      control={
        <Checkbox className={classes.checkbox} checked={agreedToTerms} onChange={handleChange(name)} color={color} />
      }
      label="I agree Terms and conditions and Privacy policy."
    />
  );
};
