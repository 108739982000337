import React from 'react';
import { debug, flatChildren } from 'utils';
import { MultiSelectInput } from './multiselect-input';
import { MultiSelectPopover } from './multiselect-popover';
import { IMultiSelectValueProp, MultiSelectType } from './interfaces';

export const MultiSelect: MultiSelectType = props => {

  debug('MULTISELECT COMPONENT IS DEPRECATED PLEASE USE THE NEW ONE');

  const anchorRef = React.useRef<Element>();

  const [selected, setSelected] = React.useState<IMultiSelectValueProp[]>([]);
  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const [filterValue, setFilterValue] = React.useState('');

  const values = React.useMemo(() => props.values, [props.values]);

  const onFilterValueChange = (value: string) => {
    if (props.onFilterValueChange) {
      props.onFilterValueChange(value);
    }
    setFilterValue(value);
  };

  React.useEffect(() => {
    if (props.selectedValues) {
      setSelected(props.selectedValues);
    }
  }, [props.selectedValues]);

  React.useEffect(() => {
    if (props.selectedValuesById) {
      setSelected(flatChildren(values).filter(value => props.selectedValuesById?.some(id => id === value.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedValuesById]);

  const selectedDataString = React.useMemo(() => {
    if (props.getSelectedDataString) {
      return props.getSelectedDataString(selected);
    }
    return selected.reduce((payload, value) => {
      if (payload.length === 0) {
        return value.value;
      }
      return `${payload}, ${value.value}`;
    }, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onOpen = () => {
    if (props.options?.disabled !== true) {
      if (anchorRef.current) {
        setAnchor(anchorRef.current);
      }
    }
  };

  const onClose = () => {
    setAnchor(null);
  };

  const selectValue = (value: IMultiSelectValueProp) => {
    if (props.options?.disabled !== true) {
      let next: IMultiSelectValueProp[];
      const isSelected = selected.some(_selected => _selected.id === value.id);

      // tslint:disable-next-line: prefer-conditional-expression
      if (!isSelected) {
        next = [value, ...selected];
      } else {
        next = [...selected.filter(_value => _value.id !== value.id)];
      }

      if (props.options?.maxLength !== undefined) {
        if (next.length > props.options.maxLength) {
          next = next.slice(0, props.options.maxLength);
        }
      }
      setSelected(next);
      if (props.options?.closeOnChange) {
        onClose();
      }
      props.onChange(next);
    }
  };

  const selectAll = () => {
    if (props.options?.disabled !== true) {
      let next: IMultiSelectValueProp[];
      if (props.values.length === selected.length) {
        next = [];
        setSelected(next);
      } else {
        next = [...props.values];
        setSelected(next);
      }
      if (props.options?.closeOnChange) {
        onClose();
      }
      props.onChange(next);
    }
  };

  return (
    <>
      <MultiSelectInput
        isOpened={Boolean(anchor)}
        ref={anchorRef}
        classNames={props.classNames}
        required={props.required}
        label={props.label}
        selectedDataString={selectedDataString}
        onOpen={onOpen}
        onClose={onClose}
        disabled={props.options?.disabled ?? false}
        options={props.options}
        translations={props.translations}
      />

      <MultiSelectPopover
        classNames={props.classNames}
        filterValue={filterValue}
        setFilterValue={onFilterValueChange}
        selectAll={selectAll}
        selectValue={selectValue}
        onClose={onClose}
        anchor={anchor}
        translations={props.translations}
        values={props.values}
        selectedValues={selected}
        extendProps={props.extendProps}
        options={props.options}
        overrides={props.overrides}
        {...props.popoverProps}
        totalRecords={props.totalRecords}
      />
    </>
  );
};
MultiSelect.defaultProps = {
  options: {
    searchBar: true
  }
};

export default MultiSelect;
