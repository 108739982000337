import { IFaq } from '../../interfaces/faq.interface';

export enum FaqModuleActions {
  SET_FAQ = 'SET_FAQ'
}

export interface ISetFaqAction {
  type: FaqModuleActions.SET_FAQ;
  payload: IFaq[];
}

export type Actions = ISetFaqAction;

export const faqActions = {
  setFaq(faq: IFaq[]) {
    return {
      type: FaqModuleActions.SET_FAQ,
      payload: faq
    };
  }
};
