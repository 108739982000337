import { ITasks } from '../../interfaces/task.interface';

export enum TasksModuleActions {
  SET_TASKS = 'SET_TASKS'
}

export interface ITasksAction {
  type: TasksModuleActions.SET_TASKS;
  payload: {
    tasks: ITasks[];
  };
}

export type Actions = ITasksAction;

export const tasksActions = {
  setTasks(tasks: ITasks[]) {
    return {
      type: TasksModuleActions.SET_TASKS,
      payload: {
        tasks
      }
    };
  }
};
