import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const DollarSVG: React.FC<SVGComponentProps> = ({ className }) => {
  return (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g id="Group_14738" data-name="Group 14738" transform="translate(-762 -715)">
      <circle id="Ellipse_145" data-name="Ellipse 145" cx="15" cy="15" r="15" transform="translate(762 715)" fill="#e5e5ff"/>
      <path id="Icon_metro-money" data-name="Icon metro-money" d="M15.507,13.368c-1.995-.518-2.636-1.05-2.636-1.885,0-.958.883-1.63,2.372-1.63,1.564,0,2.144.747,2.2,1.845h1.942a3.5,3.5,0,0,0-2.821-3.348V6.427H13.926v1.9C12.221,8.7,10.85,9.8,10.85,11.5c0,2.03,1.683,3.04,4.13,3.629,2.2.527,2.636,1.3,2.636,2.122,0,.6-.426,1.568-2.372,1.568-1.81,0-2.526-.813-2.618-1.845H10.688a3.689,3.689,0,0,0,3.238,3.365v1.907h2.636V20.354c1.709-.33,3.075-1.318,3.075-3.124,0-2.487-2.135-3.339-4.13-3.862Z" transform="translate(761.838 715.665)" fill="#4c4cff"/>
    </g>
  </svg>
  );
};
