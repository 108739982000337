import React, { useState } from 'react';
import { useService } from 'modules/core/hooks';
import { RankingService } from 'modules/user/services/ranking.service';
import { useSelector } from 'react-redux';
import { currentSelectedRoundSelector, userProfileSelector } from 'modules/core/store/selectors';
import { IRankingPerGroup, IRankingsFilters } from 'interfaces/ranking.interface';
import { dashboardRankingsSelector, userScoreSelector, userSelectedDashboardGroupSelector } from 'modules/user/store/selectors';
import { IPagedData } from 'interfaces/paged-data.interface';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { Config, VisualizationType } from 'config';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { BackgroundRabenClouds } from 'modules/user/views/dashboard/components/background-components/background-raben-clouds';
import { Sun } from 'modules/user/views/dashboard/components/background-components/sun';
import { Grass } from 'modules/user/views/dashboard/components/background-components/grass';
import { Fade, useMediaQuery } from '@material-ui/core';
import { defaultTheme } from 'themes/default-theme';
import { SpacedComponents } from 'modules/user/views/dashboard/components/growth-components/spaced-components';
import { GrowthComponent } from 'modules/user/views/dashboard/components/growth-components/growth-component';
import { debugError } from 'utils/debug';
import clsx from 'clsx';
import Background from 'assets/img/r-background.png';

const makeClasses = makeStyles<DefaultTheme>((theme) => ({
  root: {
    position: 'relative'
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top 100%',
    height: '60vh',
    // height: 650,
    [theme.breakpoints.down('md')]: {
      height: 530
    },
    [theme.breakpoints.down('sm')]: {
      height: 450
    },
    [theme.breakpoints.down('xs')]: {
      height: 420
    }
  },
  slider: {
    height: '100%',
    zIndex: 1,
  },
  growComponents: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingBottom: 0,
    minHeight: 550,
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: '10vh',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      minHeight: 430
    }
  },
  clouds: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
    overflow: 'hidden'
  },
  grass: {
    position: 'absolute',
    bottom: 0,
  }
}));

export interface BackgroundComponentsProps {
  className?: string;
  noComponents?: boolean;
}

export const BackgroundComponents: React.FC<BackgroundComponentsProps> = (props) => {
  const classes = makeClasses();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(defaultTheme.breakpoints.down('md'));

  const [isRendered, setIsRendered] = React.useState(false);

  const rankingService = useService(RankingService);

  const [otherUsersScore, setOtherUsersScore] = useState<IRankingPerGroup[]>([]);

  const selectedGroupId = useSelector(userSelectedDashboardGroupSelector);
  const currentRound = useSelector(currentSelectedRoundSelector);
  const dashboardRankings: IPagedData<IRankingPerGroup, IRankingsFilters> = useSelector(dashboardRankingsSelector);
  const userScore = useSelector(userScoreSelector);
  const userProfile = useSelector(userProfileSelector);

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  React.useEffect(() => {
      if (dashboardRankings?.filters?.roundId !== currentRound.id
        || dashboardRankings?.filters?.workerGroupId !== selectedGroupId) {
        if (selectedGroupId && currentRound.id) {
          rankingService.getRankingsForDashboard(
            selectedGroupId,
            currentRound.id,
            {
              page: dashboardRankings.pagination.page,
              size: dashboardRankings.pagination.size
            }
            // not that important to inform the user about the error - data needed only for the background.
          ).catch(debugError);
        }
      }
    },
    [currentRound, selectedGroupId]
  );

  React.useEffect(() => {
    if (userProfile && dashboardRankings?.data) {
      setOtherUsersScore(dashboardRankings.data.filter(ranking => ranking.userId !== userProfile.id));
    }
  }, [dashboardRankings]);

  const visualizationType = currentRound?.visualizationType?.name ?? VisualizationType.Trees;

  const otherGrowthComponents = otherUsersScore.map(otherUserScore => (
    <GrowthComponent
      key={otherUserScore.userId}
      visualizationType={visualizationType}
      isWithered={otherUserScore.isWithered}
      growthIndex={parseInt(otherUserScore.treeLevel.code, 10) || 0}
    />
  ));

  const length = otherGrowthComponents.length;
  const leftSide = otherGrowthComponents.slice(0, Math.ceil(length / 2));
  const rightSide = otherGrowthComponents.slice(Math.ceil(length / 2), length);

  const { ref: containerRef, width: containerWidth } = useResizeObserver();

  return (
    <div
      className={clsx(classes.root, props.className)}
    >
      <div
        className={classes.container}
        ref={containerRef as React.RefObject<HTMLDivElement>}
      >
        {/* <BackgroundRabenClouds
          className={classes.clouds}
        /> */}
        <Sun/>
        {/* <Grass
          className={classes.grass}
        /> */}
        {!props.noComponents && !!length && (
          <Fade
            in={isRendered}
            timeout={250}
            style={{
              // delay the opacity of growth components for some time to minimize effect of jumping graphics caused by their too big size
              transitionDelay: '350ms'
            }}
          >
            <div>
              <SpacedComponents
                className={classes.growComponents}
                noPointerEvents
                fitWidth
                maxHeight={isMobile ? 280 : ((containerWidth ?? 0) * (isMedium ? 0.4 : 0.25))}
                verticalSpread={Config.GROWTH_SETTINGS.VISUALIZATIONS[visualizationType]?.VERTICAL_SPREAD}
                spacing={Config.GROWTH_SETTINGS.VISUALIZATIONS[visualizationType]?.SPACING}
                leftComponents={leftSide}
                rightComponents={rightSide}
              >
                <GrowthComponent
                  visualizationType={visualizationType}
                  growthIndex={parseInt(userScore.treeLevel.code, 10) || 0}
                  isWithered={userScore.isWithered}
                />
              </SpacedComponents>
            </div>
          </Fade>
        )}
      </div>
    </div>
  );
};
