import { IStoreState } from 'modules/core/store/store';
import { IRound } from 'interfaces/round.interface';
import { IStaticPages } from 'interfaces/static-pages.interface';
import { createSelector } from 'reselect';


export const showLoaderSelector = (state: IStoreState) => state.core.showLoader;
export const currentModuleNameSelector = (state: IStoreState) => state.core.currentModule;
export const showedMessageSelector = (state: IStoreState) => state.core.showedMessage;
export const showedNewsMessageSelector = (state: IStoreState) => state.core.showedNewsMessage;

export const currentSelectedRoundSelector = (state: IStoreState): IRound => state.core.currentSelectedRound;
export const staticPagesSelector = (state: IStoreState): IStaticPages[] => state.core.staticPages;

export const authTokenSelector = (state: IStoreState) => state.core.user.token;
export const languagesSelector = (state: IStoreState) => state.core.languages;
export const userProfileSelector = (state: IStoreState) => state.core.user.profile;
export const userRolesSelector = (state: IStoreState) => state.core.user.roles;
export const userAvatarURLSelector = (state: IStoreState) => state.core.user.avatarURL;

export const claimsListSelector = (state: IStoreState) => state.core.claims; // List of available claims in application
export const coreServicesReadySelector = (state: IStoreState) => state.core.coreServicesReady;

// FE settings

export const feSettings = (state: IStoreState) => state.core.settings;
export const feNavSettingsSelector = (state: IStoreState) => state.core.settings.user_menu;
export const showedTimeResultsSelector = (state: IStoreState) => state.core.showedMessages.results;
export const feVisualizationType = (state: IStoreState) => state.core.settings.visualization_type;
export const gtmIdSelector = (state: IStoreState) => state.core.settings.googleTagManagerId;
export const adminRouteSettings = (state: IStoreState) => state.core.settings.admin_redirect_route;
export const knowledgeElementSelector = (state: IStoreState) => state.core.settings.displayed_elements.knowledge_elements;
export const snackbarInfoSelector = (state: IStoreState) => state.core.settings.displayed_elements.show_snackbar_info;
export const dashboardElementSelector = (state: IStoreState) => state.core.settings.displayed_elements.dashboard_elements;
export const scoreFeedElementSelector = (state: IStoreState) => state.core.settings.displayed_elements.score_feed_elements;
export const gameResultsElements = (state: IStoreState) => state.core.settings.displayed_elements.game_results_elements;
export const layoutWrapperElementsSelector = (state: IStoreState) => state.core.settings.displayed_elements.layout_wrapper_elements;
export const userPanelElements = (state: IStoreState) => state.core.settings.displayed_elements.user_panel_elements;
export const pointsElementsSelector = (state: IStoreState) => state.core.settings.displayed_elements.points_elements;
export const scoreListElementsSelector = (state: IStoreState) => state.core.settings.displayed_elements.score_list_elements;
export const feGroupSettingsSelector = (state: IStoreState) => state.core.settings.group_settings;
export const feTheme = (state: IStoreState) => state.core.settings.theme;
export const feEnableKnowledgeBaseGroupFiltering = (state: IStoreState) => state.core.settings.enableKnowledgeBaseGroupFiltering;
export const otherSettings = (state: IStoreState) => state.core.settings.other_settings;

export const tenantLogoSelector = (state: IStoreState) => state.core.tenantLogo.url;

export const coreErrorSelector = (state: IStoreState) => state.core.error;

// Authorized user claims
export const userClaimsListSelector = createSelector(
  userProfileSelector,
  (profile) => (profile?.claims ?? [])
);
