import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {
  CupSVG,
  TrophySVG,
  LocationSVG,
  QuizSVG,
  UserSVG,
  EyeClosedSVG,
  MultipleUsersSVG,
  ClockSVG,
  FilledStarSVG,
  FlashSVG,
  PlainFlashSVG,
  CheckRoundedSVG,
  LevelSVG,
  PlainCheckRoundedSVG,
  StarBigSVG,
  ArrowUpSVG,
  PlusMinusSVG,
  BlockTypeSVG,
  ChartTypeSVG,
  DollarSVG,
  QuestionMarkSVG
} from 'modules/core/components/layout/svg';
import { SupportedCompanies } from 'config/config.const';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useSelector } from 'react-redux';
import { feVisualizationType } from 'modules/core/store/selectors';
const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  titleRoot: {
    ...theme.mixins.fonts.medium.xs,
    margin: `0 0 0 ${theme.spacing(1)}px`
  },
  plainIcon: {
    fill: 'none',
    stroke: theme.icons.colors.strokeColor,
  },
  plainFilledIcon: {
    fill: theme.icons.colors.strokeColor,
    stroke: 'none'
  },
  filledIcon: {
    fill: theme.icons.colors.fillColor,
    stroke: theme.icons.colors.fillColor,
  },
  innerColor: {
    fill: theme.icons.colors.innerColor,
  },
  outerColor: {
    fill: theme.icons.colors.outerColor,
  },
  feedCup: {
    fill: theme.palette.common.white,
  },
  rankingStar: {
    fill: '#ff8000',
  },
  bonusGoldStar: {
    fill: '#fb0'
  }
}));

export enum IconsNames {
  CLOCK = 'Clock',
  CUP = 'Cup',
  USER = 'User',
  LOCATION = 'Location',
  QUIZ = 'Quiz',
  EYE_CLOSED = 'EyeClosed',
  MULTIPLE_USERS = 'MultipleUsers',
  FLASH = 'Flash',
  STAR = 'Star',
  CHECK = 'Check',
  LEVEL = 'Level',
  CIRCLE_CHECK = 'CircleCheck',
  MY_RESULTS = 'MyResults',
  RANKING_STAR = 'RankingStar',
  BONUS_STAR = 'BonusStar',
  UP_ARROW = 'UpArrow',
  PLUS_MINUS = 'PlusMinus',
  CHART_TYPE = 'ChartType',
  BLOCK_TYPE = 'BlockType',
  SURVEY = 'Survey',
  DOLLAR = 'Dollar',
  QUESTION_MARK= 'QuestionMark'
}

export interface IIconObject {
  [key: string]: any,
}
export const useIcons = (iconName: string, styles?: string) => {
  const classes = makeClasses();
  const visualizationType = useSelector(feVisualizationType)

  const ExtrahutIcons: IIconObject = {
    [IconsNames.CLOCK]: <ClockSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.USER]: <UserSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.CUP]: <CupSVG className={`${classes.plainFilledIcon} ${styles}`} />,
    [IconsNames.LOCATION]: <LocationSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.QUIZ]: <QuizSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.EYE_CLOSED]: <EyeClosedSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.MULTIPLE_USERS]: <MultipleUsersSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.FLASH]: <PlainFlashSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.STAR]: <FilledStarSVG className={styles} innerElement={classes.innerColor} outerElement={classes.outerColor} />,
    [IconsNames.CHECK]: <CheckIcon className={`${classes.filledIcon} ${styles}`} />,
    [IconsNames.CIRCLE_CHECK]: <PlainCheckRoundedSVG innerElement={classes.plainFilledIcon} outerElement={classes.plainFilledIcon} />,
    [IconsNames.LEVEL]: <LevelSVG className={styles} innerElement={classes.innerColor} outerElement={classes.outerColor} />,
    [IconsNames.MY_RESULTS]: <CheckIcon className={`${classes.filledIcon} ${styles}`} />,
    [IconsNames.RANKING_STAR]: <FilledStarSVG className={styles} innerElement={classes.innerColor} outerElement={classes.outerColor} />,
    [IconsNames.BONUS_STAR]: <StarBigSVG className={styles} />,
    [IconsNames.UP_ARROW]: <ArrowUpSVG className={styles} />,
    [IconsNames.PLUS_MINUS]: <PlusMinusSVG className={styles} />,
    [IconsNames.BLOCK_TYPE]: <BlockTypeSVG className={styles} />,
    [IconsNames.CHART_TYPE]: <ChartTypeSVG className={styles} />,
    [IconsNames.SURVEY]: <EventNoteIcon className={classes.plainIcon}/>,
    [IconsNames.DOLLAR]: <DollarSVG className={styles} />,
    [IconsNames.QUESTION_MARK]: <QuestionMarkSVG className={styles} />
  }
  const GrowUperionIcons: IIconObject = {
    [IconsNames.CLOCK]: <ClockSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.USER]: <UserSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.CUP]: <TrophySVG className={`${classes.innerColor} ${styles}`} />,
    [IconsNames.LOCATION]: <LocationSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.QUIZ]: <QuizSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.EYE_CLOSED]: <EyeClosedSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.MULTIPLE_USERS]: <MultipleUsersSVG className={`${classes.plainIcon} ${styles}`} />,
    [IconsNames.FLASH]: <FlashSVG />,
    [IconsNames.STAR]: <FilledStarSVG className={styles} innerElement={classes.innerColor} outerElement={classes.outerColor} />,
    [IconsNames.CHECK]: <CheckIcon className={`${classes.filledIcon} ${styles}`} />,
    [IconsNames.CIRCLE_CHECK]: <CheckRoundedSVG className={`${classes.feedCup} ${styles}`} />,
    [IconsNames.LEVEL]: <LevelSVG className={styles} innerElement={classes.innerColor} outerElement={classes.outerColor} />,
    [IconsNames.MY_RESULTS]: <CheckRoundedSVG className={`${classes.filledIcon} ${styles}`} />,
    [IconsNames.RANKING_STAR]: <FilledStarSVG className={styles} innerElement={classes.innerColor} outerElement={classes.rankingStar} />,
    [IconsNames.BONUS_STAR]: <StarBigSVG className={styles} fill='#fb0' />,
    [IconsNames.UP_ARROW]: <ArrowUpSVG className={styles} />,
    [IconsNames.PLUS_MINUS]: <PlusMinusSVG className={styles} />,
    [IconsNames.SURVEY]: <EventNoteIcon className={classes.plainIcon}/>,
    [IconsNames.DOLLAR]: <DollarSVG />,
    [IconsNames.QUESTION_MARK]: <QuestionMarkSVG />
  }
  const renderIcon = (companyName: string, name: string) => {
    switch (companyName) {
      case SupportedCompanies.EX:
        return ExtrahutIcons[name];
      case SupportedCompanies.GU:
        return GrowUperionIcons[name];
      case SupportedCompanies.TH:
        return GrowUperionIcons[name];
      case SupportedCompanies.PP:
        return ExtrahutIcons[name];
      default:
        return <CupSVG />
    }
  }

  return (
    <>
      {renderIcon(visualizationType, iconName)}
    </>
  )

}