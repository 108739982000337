import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const FilledStarSVG: React.FC<SVGComponentProps> = ({ className, innerElement, outerElement }: SVGComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg"  width="30.331" height="30.331" viewBox="0 0 30.331 30.331" className={className}>
    <g transform="translate(0.331)">
      <circle className={innerElement} cx="9.5" cy="9.5" r="9.5" transform="translate(5.019 6.331)"/>
      <path className={outerElement} d="M15.166,30.331A15.165,15.165,0,0,1,4.442,4.442,15.165,15.165,0,1,1,25.889,25.889,15.065,15.065,0,0,1,15.166,30.331Zm0-22.242a.827.827,0,0,0-.758.492l-1.727,3.661-3.865.589a.841.841,0,0,0-.677.583.905.905,0,0,0,.208.926l2.8,2.847-.662,4.023a.89.89,0,0,0,.2.732.838.838,0,0,0,.637.3.8.8,0,0,0,.393-.1l3.459-1.9,3.458,1.9a.805.805,0,0,0,.392.1.841.841,0,0,0,.639-.3.888.888,0,0,0,.2-.731l-.662-4.023,2.8-2.847a.9.9,0,0,0,.209-.926.841.841,0,0,0-.676-.583l-3.865-.589L15.925,8.581A.841.841,0,0,0,15.163,8.088Z" transform="translate(-0.331)"/>
    </g>
  </svg>
);