import React from 'react';
import videojs from 'video.js';
import { useTranslation } from 'react-i18next';
import { LoaderView } from '../../../../views';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from '../../../../../../themes/interfaces/default-theme.interfaces';
import { useService } from '../../../../hooks';
import { UsersService } from '../../../../services';


const makeClasses = makeStyles<DefaultTheme>(theme => ({
  mediaContentRoot: {
    flex: 1
  }
}));

interface IVideoPlayerProps {
  src: string,
  mimeType?: string,
}

const VideoPlayer = React.forwardRef<videojs.Player | null, IVideoPlayerProps>(
  (props: IVideoPlayerProps, ref) => {
    const {
      src,
    } = props;

    const usersService = useService(UsersService);
    const videoRef = React.useRef<HTMLVideoElement>(null);

    const renderVideo = React.useCallback(
      async () => {
        const video: Blob = await usersService.fetchKnowledgeFileBlob(src)
        if (video) {
          const { type } = video
          const videoSrc = window.URL.createObjectURL(video);
          const player = videojs(videoRef.current, {
            autoplay: false,
            controls: true
          }, () => {
            player.src({
              src: videoSrc,
              type: type || 'video/mp4'
            });
          });
          if (ref) {
            // @ts-ignore
            ref.current = player;
          }
        }
      },
      []
    );

    React.useEffect(
      () => {
        renderVideo()
      },
      [src]
    );
    return (
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-16-9 vjs-big-play-centered"/>
      </div>
    );
  }
);

export const VideoUploaded = (props: any) => {

  const { t } = useTranslation();
  const classes = makeClasses();
  const videoPlayer = React.useRef<videojs.Player>(null);

  const renderMedia = React.useMemo(
    () => {
      if (props.mediaURL) {
        return <VideoPlayer ref={videoPlayer} src={props.mediaURL}/>
      }
      return <LoaderView text={t('adminPanel.common.loadingAttachment')} visible={true}/>;
    },
    [t, props.mediaURL]
  );

  return (
    <div className={classes.mediaContentRoot}>
      {renderMedia}
    </div>
  );
};
