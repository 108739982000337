import { HttpClientService } from 'modules/core/functional-services';
import { AppService, getService } from 'modules/core/services';
import store from 'modules/core/store';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { faqActions } from '../store/actions/faq.actions';
import { IPaginationResultOfFaqDto } from '../interfaces/faq.interface';
import { IPagination } from 'interfaces/pagination.interface';

export class FaqService {
  constructor(private appService = getService(AppService), private httpClientService = getService(HttpClientService)) {
  }

  async getFaq(pagination?: IPagination) {
    try {
      const { value }: IAPIResponse<IPaginationResultOfFaqDto> = await this.httpClientService.get(`/faq`, {
        params: pagination
      });
      store.dispatch(faqActions.setFaq(value.records));
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
}
