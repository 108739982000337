import { ITrainings } from '../../interfaces/trainings.interface';

export enum TrainingsModuleActions {
  SET_TRAININGS = 'SET_TRAININGS'
}

export interface ISetTrainingsAction {
  type: TrainingsModuleActions.SET_TRAININGS;
  payload: ITrainings;
}

export type Actions = ISetTrainingsAction;

export const trainingsActions = {
  setTrainings(trainings: ITrainings | unknown) {
    return {
      type: TrainingsModuleActions.SET_TRAININGS,
      payload: trainings
    };
  }
};
