export const displayElements = {
  dashboard_elements: [
    'taskFeed',
    'newsFeed',
    'rankingFeed',
    'hideSelectiveGroups',
    'hideRoundDropdown',
  ],
  score_feed_elements: {
    foundationToken: true,
    bonusStar: true,
    points: true,
    growthIndex: true,
  },
  game_results_elements: [
    'map',
    'list'
  ],
  show_snackbar_info: false,
  layout_wrapper_elements: {
    scoreFeed: true,
    rankingFeed: true,
    taskFeed: true,
    duelFeed: false,
    duelRankingFeed: false
  },
  points_elements: {
    level_points: false
  },
  user_panel_elements: {
    sex: true,
    changePassword: true,
    changeAvatar: true,
    hidePersonalData: true,
    changeLanguage: true,
    avatarAd: true
  },
  score_list_elements: {
    hidePointsOnUserScoresList: false
  },
  knowledge_elements: {
    display_date: true
  }
}
