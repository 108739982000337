import { Reducer } from 'redux';
import { Actions, RankingModuleActions } from '../actions/ranking.actions';
import { IMapRanking } from '../../interfaces/ranking.interface';
import { IUserProfile } from 'modules/user/interfaces/userProfile.interface';
import { IPagedData } from 'interfaces/paged-data.interface';

export interface IRanking {
  mapRanking: IMapRanking[];
  usersRanking: IUserProfile[];
  dashboardRanking: IPagedData;
  blockRanking: IPagedData;
}


export const rankingModuleStoreInitialState: IRanking = {
  mapRanking: [
    {
      averagePoints: 0,
      country: '',
      objectAvatars: []
    }
  ],
  blockRanking: {
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
    filters: {
      workerGroupId: '',
      roundId: '',
    },
    data: [],
  },
  usersRanking: [],
  dashboardRanking: {
    pagination: {
      page: 1,
      size: 6,
      total: 0,
    },
    filters: {
      workerGroupId: '',
      roundId: '',
    },
    data: [],
  },
};

export const rankingModuleStoreReducer: Reducer<IRanking, Actions> = (
  state: IRanking = rankingModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case RankingModuleActions.SET_USER_SET_RANKINGS:
      return {
        ...state,
        mapRanking: payload.mapRanking,
        usersRanking: payload.usersRanking
      };
    case RankingModuleActions.SET_DASHBOARD_RANKING:
      return {
        ...state,
        dashboardRanking: {
          ...payload,
        }
      };
    case RankingModuleActions.SET_BLOCK_RANKING:
      return {
        ...state,
        blockRanking: {
          ...payload,
        }
      };
    default:
      return state;
  }
};
