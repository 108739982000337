import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container } from "@material-ui/core";
import { DefaultTheme } from "themes/interfaces/default-theme.interfaces";
import { useTranslation } from "react-i18next";
import _isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import {
  feVisualizationType,
  otherSettings,
} from "modules/core/store/selectors";
import { SupportedCompanies } from "config";
import clsx from "clsx";

const makeClasses = makeStyles<DefaultTheme, { isEX: boolean }>((theme) => ({
  root: {
    color: (props) => theme.palette.common.white,
    ...theme.mixins.fonts.light.l,
    fontWeight: (props) => (props.isEX ? 300 : 500),
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: 113,
    maxHeight: 113,
    marginBottom: (props) => (props.isEX ? theme.spacing(12) : 0),
    minHeight: 113,

    [theme.breakpoints.down("md")]: {
      ...theme.mixins.fonts.bold.xl,
      fontSize: "40px",
      justifyContent: "center",
    },
  },
  backButton: {
    marginRight: theme.spacing(4),
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  backButtonRaben: {
    marginRight: theme.spacing(4),
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
  },
  titleShadow: {
    textShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.25), -4px 0px 4px rgba(0, 0, 0, 0.25), 4px 0px 4px rgba(0, 0, 0, 0.25)",
  },
}));
export interface HeaderProps {
  children?: React.PropsWithChildren<React.ReactNode>;
  backButton?: boolean;
  classRoot?: string;
  onBackButtonClick?: (event: React.MouseEvent) => any;
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { children, backButton = false, onBackButtonClick, classRoot } = props;
  const visualizationType = useSelector(feVisualizationType);
  const isEXCompany = visualizationType === SupportedCompanies.EX;
  const isGUCompany = visualizationType === SupportedCompanies.GU;
  const classes = makeClasses({
    isEX: isEXCompany,
  });
  const history = useHistory();
  const { t } = useTranslation();

  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);

  const { company } = useSelector(otherSettings);

  React.useEffect(() => {
    if (history.length < 2) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [history.length]);

  return (
    <Container
      className={
        isGUCompany
          ? clsx(classes.root, classRoot, classes.titleShadow)
          : clsx(classes.root, classRoot)
      }
    >
      {backButton && (
        <Button
          disabled={buttonDisabled}
          variant="outlined"
          size="medium"
          className={
            company?.name === "raben"
              ? classes.backButtonRaben
              : classes.backButton
          }
          onClick={onBackButtonClick || history.goBack}
        >
          {t("common.back")}
        </Button>
      )}
      {children}
    </Container>
  );
};
