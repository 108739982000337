import { Reducer } from 'redux';
import { Actions, GalleryModuleActions } from '../actions/gallery.actions';
import { IGalleryItem } from '../../interfaces/media.interface';

export interface IListedGallery {
  list: IGalleryItem[];
}

export const galleryModuleStoreInitialState: IListedGallery = {
  list: []
};

export const galleryModuleStoreReducer: Reducer<IListedGallery, Actions> = (
  state: IListedGallery = galleryModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case GalleryModuleActions.SET_GALLERY:
      return {
        ...state,
        list: [...payload.list]
      };
    default:
      return state;
  }
};
