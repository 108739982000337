import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useService, useIcons, IconsNames } from 'modules/core/hooks';
import { ScoreService } from 'modules/user/services/score.service';
import { IUserScore } from 'modules/user/interfaces/score.interface';
import { UserService } from 'modules/user/services/user.service';
import { FeedCard } from 'modules/user/components/layout';
import { StarButton } from 'modules/user/components/feed-cards/score-feed/components/star-button';
import { PointsRow } from 'modules/user/components/feed-cards/score-feed/components/points-row';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { BACKGROUND } from 'themes/utils/background-utils';
import { Config, SupportedCompanies, VisualizationType } from 'config';
import { debugError, showApiErrors, getObjectHeight, getObjectHeightDescription, getObjectHeightUnit, countOccurance } from 'utils';
import { SCORE_LIMIT_TO_PASS } from 'const/scoreTreshold';
import { currentSelectedRoundSelector, pointsElementsSelector, scoreFeedElementSelector, feVisualizationType } from 'modules/core/store/selectors';
import { userScoreSelector } from 'modules/user/store/selectors';
import { GrowthComponent } from 'config/interfaces/growth_settings.interface';
import { Button, Hidden } from '@material-ui/core';
import { Heart } from 'modules/core/components/common/rich-editor/components/hearts';
import scoreModuleStoreInitialState from 'modules/user/store/reducer/score.reducer';
import { UserPointsDialog } from 'modules/user/components/my-results';
import { BlockBox } from 'modules/user/views/game-results-group/components/block-user';
import { BadgesService } from 'modules/user/services';
import { BadgePreview } from 'modules/user/components';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    minWidth: 290,
    '& svg': {
      minWidth: '20px'
    }
  },
  treeRow: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingBottom: theme.spacing(5)
  },
  starButton: {
    paddingBottom: theme.spacing(2)
  },
  redIcon: {
    width: 32,
    height: 32,
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4)
  },
  checkIcon: {
    fill: theme.icons.colors.innerColor,
    stroke: 'none'
  },
  tree: {
    height: '200px',
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  heartWrapper: {
    paddingBottom: '0px'
  },
  heartIcon: {
    width: '40px',
    margin: '0px -3px -5px -5px'
  },
  treeScaled: {
    height: '100px',
    width: '75px',
    '& img': {
      paddingTop: theme.spacing(2),
      height: '100%',
      maxWidth: '100%',
      objectFit: 'cover'
    }
  },
  dialogButton: {
    width: '100%',
    marginTop: '30px'
  },
  blockBoxStyles: {
    marginBottom: theme.spacing(6),
  },
  badges: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgoundColor: 'red',
  },
}));

export const ScoreFeed = () => {
  const classes = makeClasses();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const visualizationType = useSelector(feVisualizationType);
  const { level_points } = useSelector(pointsElementsSelector);
  const userScore = useSelector(userScoreSelector);
  const currentRound = useSelector(currentSelectedRoundSelector);
  const scoreFeedItems = useSelector(scoreFeedElementSelector);
  const scoreService = useService(ScoreService);
  const userService = useService(UserService);


  const [score, setScore] = useState<IUserScore | null>(null);
  const [growthComponent, setGrowthComponent] = useState<GrowthComponent>({ assetPath: '', growthIndex: 0 });
  const [isHeartFull, setHeartFullness] = React.useState<boolean>(false);
  const [isPointDialogOpen, setIsPointDialogOpen] = React.useState<boolean>(false);

  const isEXCompany = React.useMemo(() => visualizationType === SupportedCompanies.EX, [visualizationType]);
  const visualisationType = React.useMemo(() => currentRound.visualizationType?.name, [currentRound]);
  const objectHeight = React.useMemo(() => getObjectHeight(visualisationType, growthComponent.growthIndex), [visualisationType, growthComponent])
  const currentGrowthType = React.useMemo(() => Config.GROWTH_SETTINGS.VISUALIZATIONS[visualisationType || VisualizationType.FruitTrees], [visualisationType]);

  const starIcon = useIcons(
    IconsNames.STAR,
    classes.redIcon,
  );

  const levelIcon = useIcons(
    IconsNames.LEVEL,
    classes.redIcon,
  );

  const roundCheckedIcon = useIcons(
    IconsNames.MY_RESULTS,
    classes.checkIcon,
  );

  const getGrowthComponent = (
    userScoreTreeLevelCode: number,
    currentRoundVisualizationTypeName?: VisualizationType
  ): GrowthComponent | undefined =>
    Config.GROWTH_SETTINGS.VISUALIZATIONS[
      currentRoundVisualizationTypeName || VisualizationType.FruitTrees
    ].COMPONENTS.find(component => component.growthIndex === userScoreTreeLevelCode);

  React.useEffect(
    () => {
      if (score && score.value > SCORE_LIMIT_TO_PASS) {
        setHeartFullness(true)
      } else {
        setHeartFullness(false)
      }
    },
    [score, setHeartFullness]
  );

  const selectGrowthCompontent = (itemScore: IUserScore) => {
    setScore(itemScore);
    const { treeLevel, isWithered } = itemScore;
    const growthCode = treeLevel.code !== 'Default' ? parseInt(treeLevel.code, 10) : 0;
    const growthComponentFound = getGrowthComponent(
      isWithered ? -growthCode : growthCode,
      currentRound?.visualizationType?.name
    );
    if (growthComponentFound) {
      setGrowthComponent(growthComponentFound);
    }
  };

  const renderDialogButton = React.useMemo(
    () => (
      history.location.pathname.indexOf('my-results') === -1
    ), [history]
  );

  const getInitialScoresData = React.useCallback(async () => {
    try {
      const isInitState = userScore && userScore.roundId !== '-1';
      selectGrowthCompontent(isInitState ? userScore : scoreModuleStoreInitialState.userScore);
    } catch (e) {
      showApiErrors(e, enqueueSnackbar);
      debugError(e);
    }
  }, [userScore, currentRound]);

  const onLoad = async () => {
    try {
      await userService.getScore(true);
      await userService.getUserScore()
    } catch (e) {
      showApiErrors(e, enqueueSnackbar);
      debugError(e);
    }
  }

  React.useEffect(() => {
    onLoad();
  }, []);

  React.useEffect(() => {
    getInitialScoresData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreService, userScore, currentRound]);


  const openDialog = React.useCallback(
    () => {
      setIsPointDialogOpen(true)
    }, [isPointDialogOpen]
  )

  const badgesWithOccurance = countOccurance(userScore.badges || []);

  return (
    <>
      <FeedCard
        openList={renderDialogButton ? openDialog : undefined}
        headingColor={visualizationType === SupportedCompanies.EX ? BACKGROUND.NONE : BACKGROUND.SECONDARY}
        title={`${t('userPanel.scoreFeed.myResult')} - ${currentRound.name}`}
        titleIcon={() => roundCheckedIcon}
        className={classes.root}
      >
        {score && score.roundId && growthComponent && scoreFeedItems && (
          <>
            <Hidden mdDown>
              <div className={classes.treeRow}>
                {isEXCompany ? <div className={classes.tree}>
                  <BlockBox value={parseInt(userScore.treeLevel.code, 10)} className={classes.blockBoxStyles} />
                </div> :
                  <div className={currentGrowthType.HEIGHT ? classes.treeScaled : classes.tree}>
                    <img src={`${growthComponent.assetPath}`} alt={'growth monument'} />
                  </div>}

                {scoreFeedItems.bonusStar &&
                  <StarButton
                    className={classes.starButton}
                    starText={`${score.bonusActivityCount}`}
                    bottomText={t('userPanel.scoreFeed.bonusTasks', { count: score.bonusActivityCount })}
                  />
                }
              </div>
            </Hidden>
            <div className={classes.badges}>
              {Object.entries(badgesWithOccurance).map(([badgeId]) => {
                return <BadgePreview key={badgeId} badgeId={badgeId} maxHeight='45px' occurance={badgesWithOccurance[badgeId]} />
              })}
            </div>
            {scoreFeedItems.foundationToken && <PointsRow
              icon={<Heart className={classes.heartIcon} full={isHeartFull} />}
              value={isHeartFull ? '' : '0'}
              text={isHeartFull ? t('userPanel.scoreFeed.foundationToken') : t('userPanel.scoreFeed.noFoundationToken')}
              className={classes.heartWrapper}
            />}
            {scoreFeedItems.points && <PointsRow
              icon={starIcon}
              value={`${score.value}`}
              text={t('common.points', { count: score.value })}
            />}
            {scoreFeedItems.growthIndex && <PointsRow
              icon={levelIcon}
              value={level_points ? userScore.treeLevel.code : `${objectHeight}`}
              text={level_points || visualisationType === VisualizationType.Buildings ?
                `${t('common.level').toLowerCase()}` :
                `${t(getObjectHeightUnit(visualisationType))}
                 ${t(getObjectHeightDescription(visualisationType, objectHeight), { count: objectHeight })}`}
            />}
            {renderDialogButton && <Button
              className={classes.dialogButton}
              variant='outlined'
              size='medium'
              onClick={() => setIsPointDialogOpen(true)}
            >{t('common.resultList')}</Button>}
          </>
        )}
      </FeedCard>
      {isPointDialogOpen &&
        <UserPointsDialog
          open={isPointDialogOpen}
          onClose={() => setIsPointDialogOpen(false)}
          userId='mine'
        />}
    </>
  );
};
