import { getService } from '../modules/core/services';
import { DeviceKind, ICommand } from '../interfaces/notifications.interface';
import { HttpClientService } from '../modules/core/functional-services';

export const sendToken = async (token: string) => {
  const service = getService(HttpClientService);

  try {
    await service.post<ICommand>('/notifications/devices', {token, kind: DeviceKind.WEB});
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.log(e);
  }


}
