
import React from 'react';
import { BlockSvg } from 'modules/core/components/layout/svg/block/block';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import clsx from 'clsx';
interface IBlockBoxProps {
  value: number,
  className?: string,
}
export type BlockBoxProps = React.PropsWithChildren<IBlockBoxProps>;

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  boxes: {
    '& svg' : {
      display: 'block',
      marginRight: theme.spacing(-1.25),
      marginBottom: theme.spacing(-6),
      position: 'relative',
      '&:nth-child(1)': { zIndex: 10 },
      '&:nth-child(2)': { zIndex: 9 },
      '&:nth-child(3)': { zIndex: 8 },
      '&:nth-child(4)': { zIndex: 7 },
      '&:nth-child(5)': { zIndex: 6 },
      '&:nth-child(6)': { zIndex: 5 },
      '&:nth-child(7)': { zIndex: 4 },
      '&:nth-child(8)': { zIndex: 3 },
      '&:nth-child(9)': { zIndex: 2 },
    }
  },
}));
export const BlockBox = (props: IBlockBoxProps) => {
  const boxes = Array.from(Array(props.value).keys());
  const classes = makeClasses();

  return (
    <div className={clsx(classes.boxes, props.className)}>
      {boxes.length ? boxes.map((_box, index) => (
       <BlockSvg key={index}/>
      )) : <BlockSvg key={1}/>}
    </div>
  )
}