import { HttpClientService } from '../../core/functional-services';
import { IPagination } from 'interfaces/pagination.interface';
import { AppService, getService } from '../../core/services';
import { IPagedResponse } from 'interfaces/paged-data.interface';
import { IAPIResponse } from 'interfaces/api-response.interface'
import { IActivity, IActivityKinds } from '../interfaces/activity.interface'
import { APIPaths } from 'config/paths';


export class ActivityService {

  constructor(
    private appService = getService(AppService),
    private httpClientService = getService(HttpClientService),
  ) { }

  fetchActivities = async (pagination: IPagination, roundId?: string, userId?: string) => {
    try {
      const params = roundId ? {
        ...pagination,
        roundId
      } : {
          ...pagination
        }
      const response = (userId === 'mine') ?
        await this.httpClientService.get<IPagedResponse<IActivity>>(APIPaths.ACTIVITIES, { params })
        :
        await this.httpClientService.get<IPagedResponse<IActivity>>(APIPaths.ACTIVITIES + `/${userId}`, { params });
      return response.value
    } catch (e) {
      return this.appService.handleError(e)
    }
  }

  fetchActivityKinds = async () => {
    try {
      const response = this.httpClientService.get<IAPIResponse<IActivityKinds>>(APIPaths.ACTIVITY_KINDS);
      return response
    } catch (error) {
      return this.appService.handleError(error)
    }
  }
}