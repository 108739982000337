import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const BonusTasksSVG: React.FC<SVGComponentProps> = ({ fill, className }: SVGComponentProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
    <defs>
      <style>{`.a{fill:${fill ?? '#fb0'};}`}</style>
    </defs>
    <path className="a"
          d="M19.544,3.114a1,1,0,0,1,1.912,0L25.1,14.984a1,1,0,0,0,.958.707l11.966-.024a1,1,0,0,1,.608,1.8L28.852,24.9A1,1,0,0,0,28.5,26l3.731,12a1,1,0,0,1-1.563,1.09l-9.562-7.334a1,1,0,0,0-1.217,0l-9.562,7.334a1,1,0,0,1-1.563-1.09L12.5,26a1,1,0,0,0-.349-1.093l-9.78-7.441a1,1,0,0,1,.608-1.8l11.966.024a1,1,0,0,0,.958-.707Z"/>
  </svg>
);
