import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { CardHeaderGradient } from 'modules/core/components/common/card';
import { BACKGROUND } from 'themes/utils/background-utils';
import { useTranslation } from 'react-i18next';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

export interface IFeedCardProps {
  className?: string;
  title: string;
  titleIcon?: any;
  headingColor?: BACKGROUND;
  openList?: () => void | null;
  buttonText?: string;
  buttonAction?: () => void | null;
  height?: string;
}

const makeClasses = makeStyles<DefaultTheme, { height: string }>(theme => {
  return {
    root: {
      background: theme.palette.common.white,
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
      height: (props) => props.height,
      borderRadius: 20,
      [theme.breakpoints.down('md')]: {
        borderRadius: '0px',
        marginBottom: theme.spacing(5),
      }
    },
    content: {
      ...theme.mixins.fonts.regular.xs,
      height: '100%',
      color: theme.palette.grey['500'],
      '& p:first-of-type': {
        marginTop: 0
      }
    },
    cardTitle: {
      ...theme.mixins.fonts.bold.s,
      borderBottom: 'none',
      color: '#D31D5A',
      marginTop: '20px'

    },
    cardContent: {
      padding: theme.spacing(3),
      height: '100%'
    },
    actionButton: {
      width: '100%',
    },
    seeModalButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      '& span:first-of-type': {
        fontSize: '14px'
      },
    },
    buttonWrapper: {
      padding: '8px 24px'
    },
  };
});

export const FeedCardPepco: React.FC<IFeedCardProps> = (props: React.PropsWithChildren<IFeedCardProps>) => {
  const { headingColor, titleIcon: TitleIcon, className, title, children, buttonAction, buttonText } = props;
  const { t } = useTranslation();
  const classes = makeClasses({ height: props.height ?? '500px' });

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeaderGradient
        color={headingColor}
        fontColor={'#302B27'}
        noBorderBottom={true}
      >
        {TitleIcon && <TitleIcon />}
        <span
          className={classes.cardTitle}>{title}</span>
      </CardHeaderGradient>

      <CardContent className={classes.cardContent}>
        <div className={classes.content}>{children}</div>

      </CardContent>
      {buttonAction && <CardActions className={classes.buttonWrapper}>
        <Button
          variant='outlined'
          size='medium'
          onClick={buttonAction}
          className={classes.actionButton}
        >
          {buttonText}
        </Button>
      </CardActions>}
    </Card>
  );
};

FeedCardPepco.defaultProps = {
  headingColor: BACKGROUND.PRIMARY
};
