import { IPagedData, IPagedResponse } from 'interfaces/paged-data.interface';
import { IEndGameUserRanking, IUserRankingGroups, IGroupRankingGroups, IRankingPerGroup, IRankingsFilters, IRankingGroups, IUserRankingChart } from 'interfaces/ranking.interface';
import { Config } from 'config';
import { setDashboardRanking, setGameResultGroupRanking } from 'modules/user/store/actions/ranking.actions';
import { IPagination } from 'interfaces/pagination.interface';
import store from 'modules/core/store';
import { CoreService } from 'modules/core/services/core.service';
import { IGroupRankingSummaryDTO, IIndividualRankingSummaryDTO, IMyIndividualRankingSummary } from '../interfaces/ranking.interface';
import { IAPIResponse } from 'interfaces/api-response.interface';

export class RankingService extends CoreService {


  applyRankingsForDashboard = (dashboardRanking: IPagedData<IRankingPerGroup, IRankingsFilters>) => {
    store.dispatch(setDashboardRanking(dashboardRanking));
  };

  applyRankingsForBlock = (dashboardRanking: IPagedData<IRankingPerGroup>) => {
    store.dispatch(setGameResultGroupRanking(dashboardRanking));
  };

  async getRankingsPerGroupAndRoundForDashboard(workerGroupId: string, roundId: string, page: number, size: number) {
    try {
      const { value } = await this.httpClientService.get<IPagedResponse<IRankingPerGroup>[]>(
        `/rankings/dashboard`,
        {
          params: {
            page,
            size,
            workerGroupIds: workerGroupId,
            roundId
          }
        }
      );
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getRankingsForDashboard(workerGroupId: string, roundId: string, pagination: IPagination): Promise<IPagedData<IRankingPerGroup, IRankingsFilters>> {
    try {
      let { value } = await this.httpClientService.get<IPagedResponse<IRankingPerGroup>>(
        `/rankings/dashboard`,
        {
          params: {
            ...pagination,
            workerGroupIds: workerGroupId,
            roundId
          }
        }
      );
      if (value) {
        // @ts-ignore
        value = value[0];
      }
      const { page, size, recordsCount } = value;
      const pagedData = {
        data: value.records,
        filters: {
          workerGroupId,
          roundId
        },
        pagination: {
          page,
          size,
          total: recordsCount
        }
      };
      this.applyRankingsForDashboard(pagedData);
      return pagedData;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getWinnersCount(workerGroupId: string) {
    try {
      const { value } = await this.httpClientService.get<any>(`/worker-groups-manage/${workerGroupId}/winners-counts`, {
      });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getRankingsPerGroupAndRound(workerGroupIds: string | string[] | undefined, roundId: string | null | undefined, page: number | undefined, size: number | undefined, showOnlyWinners?: boolean) {
    try {
      const { value } = await this.httpClientService.get<IPagedResponse<IRankingPerGroup>[]>(
        `/rankings`,
        {
          params: {
            asc: false,
            page,
            size,
            workerGroupIds,
            roundId,
            showOnlyWinners
          }
        }
      );
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getRankingForGroups(groupsIds: string[], roundId: string) {
    try {
      const { value } = await this.httpClientService.get<IRankingGroups[]>(
        `/rankings/average?groupIds=${groupsIds.join('&groupIds=')}`,
        {
          params: {
            roundId
          }
        }
      );
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getRankingSummary(roundId: string, groupId?: string, filters?: IPagination) {
    try {
      const parameters = groupId ? {
        roundId,
        workerGroupId: groupId,
        ...filters
      } : {
        roundId,
        ...filters
      }
      const { value } = await this.httpClientService.get<IPagedResponse<IRankingPerGroup>>(
        `/rankings/currentUser`,
        {
          params: parameters
        }
      );
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getTotalRankingByRoundId(filters: IPagination, roundId?: string) {
    try {
      const { value } = await this.httpClientService.get<IPagedResponse<IEndGameUserRanking>>(`/rankings/currentUser/company`, {
        params: {
          ...filters,
          ...(!!roundId && { roundId })
        }
      });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }

  }

  async getRankingAverage(workerGroupIds: string | string[], roundId: string) {
    try {
      const { value } = await this.httpClientService.get<IRankingGroups[]>(`/rankings/average`, {
        params: {
          groupIds: workerGroupIds,
          roundId
        },
      });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getUserRankingGroups(roundId?: string) {
    try {
      const { value } = await this.httpClientService.get<IUserRankingGroups[]>('/user-rankings', {
        params: {
          RoundId: roundId
        }
      })
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getGroupRankingGroups(roundId?: string) {
    try {
      const { value } = await this.httpClientService.get<IGroupRankingGroups[]>('/group-rankings', {
        params: {
          RoundId: roundId
        }
      })
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getTotalPoints(roundId: string, workerGroupIds: string) {
    try {
      const { value } = await this.httpClientService.get('/dashboard/total', {
        params: {
          workerGroupIds: [workerGroupIds],
          roundId
        }
      })
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getAllUserWithRanking(roundId: string, workerGroupId?: string) {
    try {
      const { value } = await this.httpClientService.get<IUserRankingChart[]>('/globa-round-ranking', {
        params: {
          roundId,
          workerGroupId
        }
      })
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getRankingSummariesGroups(level: number, howManyPlaces: number) {
    try {
      const { value } = await this.httpClientService.get<IGroupRankingSummaryDTO>(`/ranking-summaries/group/${level}/${howManyPlaces}`)
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getRankingSummariesIndividual(level: number, howManyPlaces: number) {
    try {
      const { value } = await this.httpClientService.get<IIndividualRankingSummaryDTO>(`/ranking-summaries/individual/${level}/${howManyPlaces}`)
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getIndividualRanking(level: number) {
    try {
      const { value }: IAPIResponse<IMyIndividualRankingSummary> = await this.httpClientService.get(`/ranking-summaries/my-individual/${level}`);
      return value

    } catch (e) {
      return this.appService.handleError(e);
    }
  }
  getBadge = async (badgeId: string) => {
    try {
      const response = await this.httpClientService.get<Blob, Blob>(`/badges-manage/${badgeId}/file`, { responseType: 'blob' })
      return URL.createObjectURL(response);
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  getBadgeMini = async (badgeId: string) => {
    try {
      const response = await this.httpClientService.get<Blob, Blob>(`/badges-manage/${badgeId}/thumbnail`, { responseType: 'blob' })
      return URL.createObjectURL(response);
    } catch (e) {
      return this.appService.handleError(e);
    }
  };
}
