import { IUserGroup, IUserProfile } from 'interfaces/user.interface';
import { Config } from 'config';
import axios from 'axios';
import { IAPIResponse } from 'interfaces/api-response.interface';
import store from 'modules/core/store';
import { setUserAvatarURL, setUserProfile, setUserRoles } from 'modules/core/store/actions';
import { CoreService } from 'modules/core/services/core.service';
import { APIPaths } from 'config/paths';
import { IPagedResponse } from 'interfaces/paged-data.interface';
import { IRole } from 'interfaces/role.interface';
import { userProfileSelector } from '../store/selectors';
import { DeviceKind, ICommand } from '../../../interfaces/notifications.interface';

export class UsersService extends CoreService {

  createLinkToRegulationsFile(regulationId: string) {
    if (!regulationId) return null;
    return `${Config.API_URL}regulations/${regulationId}`;
  }

  async getGroupsAssignedToUser() {
    try {
      const { value }: IAPIResponse<IUserGroup[]> = await this.httpClientService.get(`${APIPaths.USERS}${APIPaths.GROUPS}`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getUsersInGroup(id: string) {
    try {
      const { value }: IAPIResponse<IUserGroup[]> = await this.httpClientService.get(`${APIPaths.WORKERS_IN_GROUP}/${id}`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getAvatarAd() {
    try {
      return await this.httpClientService.post(APIPaths.AVATAR_AD, {});
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async setUserAvatar(file: File) {
    try {
      const fd = new FormData();
      fd.append('photo', file);
      return await this.httpClientService.post(`${APIPaths.USERS}${APIPaths.AVATAR}`, fd);
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  changePassword = (oldPassword: string, newPassword: string) =>
    this.httpClientService
      .post(`${APIPaths.USERS}${APIPaths.CHANGE_PASSWORD}`, {
        oldPassword,
        newPassword
      })
      .catch(this.appService.handleError);

  getNotAcceptedRegulations = () =>
    this.httpClientService.get(`${APIPaths.REGULATIONS}/not-accepted`).catch(this.appService.handleError);

  acceptRegulation = (regulationId: string) =>
    this.httpClientService.post(`${APIPaths.REGULATIONS}/${regulationId}/accept`, {}).catch(this.appService.handleError);

  setContentProfile = async (userProfile: IUserProfile) => {
    try {
      const { name, surname, hidePersonalData, gender } = userProfile
      const { value: profile } = await this.httpClientService.put<{}, IUserProfile>(`${APIPaths.USERS}`, {
        name,
        surname,
        hidePersonalData,
        gender
      });
      this.applyUserProfile(userProfile)
      return profile;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  fetchUserProfile = async () => {
    try {
      const { value: profile } = await this.httpClientService.get<IUserProfile>(`${APIPaths.USERS}${APIPaths.PROFILE}`);
      return profile;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  updateUserLanguage = async (userId: string, languageId: string, langName: string) => {
    try {
      await this.httpClientService.post(`${APIPaths.USERS}${APIPaths.LANGUAGE}`, {
        userId,
        languageId
      });
      this.httpClientService.setHeaderLanguage(langName);
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  applyUserProfile = (userProfile: IUserProfile) => store.dispatch(setUserProfile(userProfile));

  fetchUserRoles = async (userID: string) => {
    try {
      const { value: userRoles } = await this.httpClientService.get<IPagedResponse<IRole>>(`${APIPaths.USERS}/${userID}/roles`);
      return userRoles.records;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  applyUserRoles = (roles: IRole[]) => store.dispatch(setUserRoles(roles));

  applyUserAvatarURL = async () => {
    const profile = userProfileSelector(store.getState());
    if (profile) {
      const { avatar } = profile;
      if (Boolean(avatar) && avatar !== '00000000-0000-0000-0000-000000000000') {
        const avatarURL =
          !avatar || avatar.includes('.jpeg') || avatar.includes('.jpg') || avatar.includes('.png')
            ? ''
            : `${Config.API_URL}users/${avatar}/avatar`;
        const response = await this.httpClientService.get<Blob, Blob>(avatarURL, { responseType: 'blob' });

        store.dispatch(setUserAvatarURL(URL.createObjectURL(response)));
      }

    } else throw new Error();
  };

  fetchAvatar = async (id?: string) => {
    try {
      if (!id || id === '00000000-0000-0000-0000-000000000000') throw new Error("Missing avatar id");
      const response = await this.httpClientService.get<Blob, Blob>(`${Config.API_URL}users/${id}/avatar`, { responseType: 'blob' });
      return URL.createObjectURL(response);
    } catch (e) {
      // return this.appService.handleError(e);
      return '/static/img/empty_avatar.png'
    }
  }
  fetchBadge = async (id: string) => {
    try {
      const response = await this.httpClientService.get<Blob, Blob>(`${APIPaths.BADGES_MANAGE}/${id}/file`, { responseType: 'blob' });
      return URL.createObjectURL(response);
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
  updateAvatar = async (file: File) => {
    try {
      const hasUserAvatarBeenChanged = await this.setUserAvatar(file);
      const profile = await this.fetchUserProfile();
      this.applyUserProfile(profile);
      this.applyUserAvatarURL();
      return hasUserAvatarBeenChanged;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async getDefaultAvatars() {
    try {
      const { value } = await this.httpClientService.get('/default-avatars');
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async fetchAvatarImage(id: string) {
    try {
      return await this.httpClientService.get<Blob, Blob>(`/default-avatars/${id}/thumbnail`, { responseType: 'blob' });
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async uploadKnowledgeFile(file: File) {
    try {
      const fd = new FormData();
      if (file) {
        fd.append('file', file);
      }
      const { value } = await this.httpClientService.post<FormData, boolean>(`${APIPaths.KNOWLEDGE_PAGES}/files`, fd, { timeout: -1 });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async fetchKnowledgeFileBlob(id: string) {
    try {
      const video: Blob = await this.httpClientService.get<Blob, Blob>(`/knowledge-pages/file/${id}`, {
        responseType: 'blob'
      });
      return video;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async toggleUserState(id: string, state: boolean) {
    try {
      await this.httpClientService.post(`${APIPaths.USER_MANAGE}/activate`, {
        userIds: [id],
        isBlocked: !state,
        groupIds: []
      });
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async sendToken(token: string) {
    try {
      return await this.httpClientService.post<ICommand>('/notification/devices', {token, kind: DeviceKind.WEB});
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

}
