import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Avatar, Box } from '@material-ui/core';
import { defaultTheme } from 'themes/default-theme';
import { DefaultTheme } from '@material-ui/styles';
import { Config } from 'config';
import videojs from 'video.js';
import { useService } from 'modules/core/hooks';
import { GalleryService } from 'modules/user/services/gallery.services';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { LoaderView } from 'modules/core/views';

const makeClasses = makeStyles<Theme & DefaultTheme, { variant?: string }>(theme => ({
  avatar: {
    margin: theme.spacing(2),
    width: props => props.variant === 'bigger' ? 45 : 30,
    height: props => props.variant === 'bigger' ? 45 : 30,
  },
  avatarbox: {
    position: 'absolute',
    left: '0',
    padding: props => props.variant === 'bigger' ? theme.spacing(2) : '0',
    height: props => props.variant === 'bigger' ? 'auto' : '62px',
    overflow: 'hidden',
    width: '100%',
    bottom: '0'
  },
  author: {
    ...defaultTheme.mixins.fonts.bold.xxs,
    fontSize: props => props.variant === 'bigger' ? 15 : 12,
    verticalAlign: 'baseline'
  },
  videoLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0 none',
    maxWidth: props => props.variant === 'bigger' ? 'auto' : '342px',
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(2),
    color: "#FFF",
    background: '#000',
    '& svg': {
      width: props => props.variant === 'bigger' ? '96px' : '48px',
      height: props => props.variant === 'bigger' ? '96px' : '48px',
    }
  },
  overlay: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 2
  }
}));

interface IVideoPlayerProps {
  src: string,
  mimeType?: string,
  autoplay?: boolean,
}

const VideoPlayer = React.forwardRef<videojs.Player | null, IVideoPlayerProps>(
  (props: IVideoPlayerProps, ref) => {
    const {
      src,
      mimeType,
      autoplay: play
    } = props;

    const videoRef = React.useRef<HTMLVideoElement>(null);

    React.useEffect(
      () => {
        const player = videojs(videoRef.current, {
          autoplay: play,
          controls: true
        }, () => {
          player.src({
            src,
            type: mimeType || 'video/mp4'
          });
        });
        if (ref) {
          // @ts-ignore
          ref.current = player;
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [src]
    );
    return (
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-16-9 vjs-big-play-centered" />
      </div>
    );
  }
);

export interface IVideoWithAuthorProps {
  videoSrc: string;
  author: string;
  variant?: string;
  id?: string;
  type: string;
  size?: string;
  className?: string;
  authorAvatar?: string;
  isLink?: boolean;
  onClick?: (event: React.MouseEvent) => any;
}

export type VideoWithAuthorProps = React.PropsWithChildren<IVideoWithAuthorProps>;

export const VideoWithAuthor = (props: VideoWithAuthorProps) => {
  const { videoSrc, author, authorAvatar, variant, id, size, isLink, onClick } = props;
  const classes = makeClasses({ variant });

  const videoPlayer = React.useRef<videojs.Player>(null);
  const galleryService = useService(GalleryService);

  const [blobVideo, setBlobVideo] = React.useState<string | false>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  const avatar = React.useMemo(() => (
    <Box display="flex" className={classes.avatarbox} alignItems="center">
      <Avatar className={classes.avatar} src={`${Config.API_URL}users/${authorAvatar}/avatar`} alt={author} />
      <span className={classes.author}>{author}</span>
    </Box>
  ), [author, authorAvatar, classes.author, classes.avatar, classes.avatarbox]);

  const videoBox = React.useMemo(() => {

    const createVideo = async () => {
      setIsLoading(true);
      // const blob = await galleryService.createMediaUrlById(videoSrc);
      const blob = await galleryService.getGalleryItem(videoSrc);
      if (blob) {
        setBlobVideo(blob);
      }
      setIsLoading(false);
    };

    const onClickShow = () => {
      createVideo()
    };

    if (blobVideo) {
      return (
        <VideoPlayer
          autoplay={true}
          ref={videoPlayer}
          data-id={id}
          src={blobVideo} />)
    } else {
      return (
        <button
          data-id={id}
          className={classes.videoLink}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => isLink ? onClick && onClick(e) : onClickShow()}>
          <PlayCircleFilledIcon className={classes.playIcon} />
        </button>)
    }
  }, [blobVideo, classes.playIcon, classes.videoLink, galleryService, id, isLink, onClick, videoSrc])

  return (
    <>
      {size === 'small' ? <div className={classes.overlay} /> : null}
      <LoaderView visible={isLoading} position='absolute' />
      {videoSrc && videoBox}
      {avatar}
    </>
  )
};
