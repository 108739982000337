import { default as createMuiTheme } from '@material-ui/core/styles/createMuiTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { Fonts } from 'themes/interfaces/fonts.interface';
import { DefaultTheme, DefaultThemeOptions } from 'themes/interfaces/default-theme.interfaces';

export interface PepcoTheme extends DefaultTheme {}

const breakPoints = createBreakpoints({});

export const THEME_APPBAR_DESKTOP_HEIGHT = 81;
export const THEME_APPBAR_MOBILE_HEIGHT = 60;

const fontFamily = '"Source Sans Pro", sans-serif';

export const fontSizes: Fonts = {
  xxl: {
    fontSize: '56px',
    lineHeight: '84px',
    fontFamily
  },
  xl: {
    fontSize: '43px',
    lineHeight: '64px',
    fontFamily
  },
  l: {
    fontSize: '30px',
    lineHeight: '45px',
    fontFamily
  },
  m: {
    fontSize: '25px',
    lineHeight: '37px',
    fontFamily
  },
  s: {
    fontSize: '20px',
    lineHeight: '30px',
    fontFamily
  },
  xs: {
    fontSize: '15px',
    lineHeight: '24px',
    fontFamily
  },
  xxs: {
    fontSize: '12px',
    lineHeight: '19px',
    fontFamily
  }
};

export const theme: DefaultThemeOptions = {
  mixins: {
    colors: {
      red: '#DB4437',
      green: '#0F9D58',
      yellow: '#F4B400'
    },
    tables: {
      tableContainer: {
        overflow: 'auto',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: '.3s linear',
        paddingRight: 2,
        '&.fetch': {
          opacity: 0.5
        },
        [breakPoints.down('xs')]: {
          marginBottom: '15px'
        }
      },
    },
    fonts: {
      light: {
        xxl: {
          ...fontSizes.xxl,
          fontWeight: 300
        },
        xl: {
          ...fontSizes.xl,
          fontWeight: 300
        },
        l: {
          ...fontSizes.l,
          fontWeight: 300
        },
        m: {
          ...fontSizes.m,
          fontWeight: 300
        },
        s: {
          ...fontSizes.s,
          fontWeight: 300
        },
        xs: {
          ...fontSizes.xs,
          fontWeight: 300
        },
        xxs: {
          ...fontSizes.xxs,
          fontWeight: 300
        }
      },
      regular: {
        xxl: {
          ...fontSizes.xxl,
          fontWeight: 400
        },
        xl: {
          ...fontSizes.xl,
          fontWeight: 400
        },
        l: {
          ...fontSizes.l,
          fontWeight: 400
        },
        m: {
          ...fontSizes.m,
          fontWeight: 400
        },
        s: {
          ...fontSizes.s,
          fontWeight: 400
        },
        xs: {
          ...fontSizes.xs,
          fontWeight: 400
        },
        xxs: {
          ...fontSizes.xxs,
          fontWeight: 400
        }
      },
      medium: {
        xxl: {
          ...fontSizes.xxl,
          fontWeight: 500
        },
        xl: {
          ...fontSizes.xl,
          fontWeight: 500
        },
        l: {
          ...fontSizes.l,
          fontWeight: 500
        },
        m: {
          ...fontSizes.m,
          fontWeight: 500
        },
        s: {
          ...fontSizes.s,
          fontWeight: 500
        },
        xs: {
          ...fontSizes.xs,
          fontWeight: 500
        },
        xxs: {
          ...fontSizes.xxs,
          fontWeight: 500
        }
      },
      bold: {
        xxl: {
          ...fontSizes.xxl,
          fontWeight: 700
        },
        xl: {
          ...fontSizes.xl,
          fontWeight: 700
        },
        l: {
          ...fontSizes.l,
          fontWeight: 700
        },
        m: {
          ...fontSizes.m,
          fontWeight: 700
        },
        s: {
          ...fontSizes.s,
          fontWeight: 700
        },
        xs: {
          ...fontSizes.xs,
          fontWeight: 700
        },
        xxs: {
          ...fontSizes.xxs,
          fontWeight: 700
        }
      }
    }
  },
  backgrounds: {
    primary: 'linear-gradient(180deg, #3ecaff 0%, #2dabff 100%)',
    secondary: 'linear-gradient(180deg, #97cf25 0%, #6bbc1b 100%)',
    tertiary: 'linear-gradient(180deg, #ffaa00 0%, #ff8000 100%)',
    main: 'linear-gradient(#ECF0FA,#ECF0FA)',
    sky: 'linear-gradient(to bottom, #FFFFFF 0%, #6EDAE6 100%)',
    ground: 'linear-gradient(to bottom, #AFD435 0%, transparent 100%)',
    none: 'none',
    appBar: '#ECF0FA',
    footer: '#ADD2F2',
  },
  icons: {
    colors: {
      innerColor: '#4C4CFF',
      outerColor: '#E5E5FF',
      fillColor: '#4C53FB',
      strokeColor: '#4C53FB',
    },
    sizes: {
      small: '14px',
      medium: '20px',
      large: '30px'
    }
  },
  tenant: {
    logo: {
      height: '40px',
      maxWidth: '100%',
      mobileHeight: '20px',
      borderRadius: '0px',
      minWidth: '100px',
      mobileMinWidth: '50px'
    },
    body: {
      background: 'unset',
      backgroundColor: '#fafafa',
      backgroundSize: 'unset',
      backgroundAttachment: 'unset'
    }
  },
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#302B27',
      star: '#FFED00',
      trophy: '#FFD500',
      sun: '#FFD500',
      th: '#4C53FB',
      warmOrange: '#DD610E',
    },
    primary: {
      light: '#002C59',
      main: '#002C59',
      dark: '#00254b'
    },
    secondary: {
      light: '#002C59',
      main: '#002C59',
      dark: '#00254b'
    },
    error: {
      main: '#DE3236',
    },
    grey: {
      200: '#F3F3F3',
      300: '#E4E4E4',
      400: '#DDDDDD',
      500: '#979797',
      600: '#818A91',
      700: '#707070',
      900: '#445C6D'
    }
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    body2: {
      letterSpacing: 'initial',
      fontSize: '15px',
      lineHeight: '24px'
    }
  },
  overrides: {
    MuiCardHeader: {
      title: {
        color: '#D31D5A',
        marginTop: '15px',
        marginLeft: '30px',
      }
    },
    MuiCardContent: {
      root: {
        fontSize: 15
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 4
      },
      input: {
        // padding: '12px 12px'
      },
      notchedOutline: {
        borderColor: '#d5d5d5'
      }
    },
    MuiToolbar: {
      regular: {
        [breakPoints.up('lg')]: {
          minHeight: THEME_APPBAR_DESKTOP_HEIGHT
        }
      }
    },
    MuiButton: {
      root: {
        fontWeight: 500,
        textTransform: 'initial',
      },
      // contained: {
      //   boxShadow: 'none'
      // },
      // containedPrimary: {
      //   color: '#fff'
      // }
    },
    // to zmienia globalnie, a ja potrzebuje w jendym miejscu
    // MuiIconButton: {
    //   root: {
    //     backgroundColor: '#ECF0FA'
    //   },
    // },
    MuiSelect: {
      select: {
        '&:focus': {
          borderRadius: 4
        }
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '20px'
      }
    }
  },
};

export const pepcoTheme = createMuiTheme(theme) as PepcoTheme;
