import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, FormControlLabel, Checkbox, IconButton } from '@material-ui/core';
import {
  MultiSelectListRowType,
} from './interfaces';
import { MultiSelectList } from 'modules/core/components/common/multiselect/multiselect-list';
import { ExpandMoreRounded } from '@material-ui/icons';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import clsx from 'clsx';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  listItemRoot: {
    padding: 0
  },
  listItemTextRoot: {
    ...theme.mixins.fonts.regular.xs,
    margin: 0
  },
  listItemWithChildren: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listItemChildrenTextRoot: {
    ...theme.mixins.fonts.regular.xs,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  childrenCheckbox: {
    marginRight: 0,
  },
  showChildrenButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  textWithCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  childrenCount: {
    ...theme.mixins.fonts.medium.xs,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[200],
    textAlign: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
  },
  childrenList: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'auto',
    opacity: 1,
    transition: 'opacity 0.3s',
    transitionDelay: '0.1s',
    '& ul': {
      overflow: 'visible',
      padding: `0 0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  },
  childrenListHidden: {
    height: 0,
    opacity: 0,
  },
  arrowButton: {
    width: theme.spacing(5.25),
    height: theme.spacing(5.25),
    marginLeft: -11,
    padding: 0,
  },
  arrow: {
    width: theme.spacing(5.25),
    height: theme.spacing(5.25),
    padding: 3,
    transform: 'rotate(-90deg)',
    transition: 'transform 0.3s',
  },
  arrowOpened: {
    transform: 'rotate(0deg)',
  },
}));

export const MultiSelectListRow: MultiSelectListRowType = props => {
  const classes = makeClasses();

  const [childrenOpened, setChildrenOpened] = React.useState(false);

  const { row, options } = props;
  const { value, children } = row;

  const toggleChildren = () => {
    setChildrenOpened(!childrenOpened);
  };

  return (
    <ListItem classes={{ root: clsx(classes.listItemRoot, props.classNames?.listItemRoot) }}>
      {!children || children.length === 0 ? (
        <ListItemText classes={{ root: clsx(classes.listItemTextRoot, props.classNames?.listItemTextRoot) }}>
          <FormControlLabel
            onChange={() => props.onSelect(row)}
            label={value}
            checked={props.checked}
            control={<Checkbox color="primary" />}
          />
        </ListItemText>
      ) : (
        <div className={clsx(classes.listItemWithChildren, props.classNames?.listItemWithChildren)}>
          <div className={clsx(classes.listItemChildrenTextRoot, props.classNames?.listItemChildrenTextRoot)}>

            {options && options.selectableParent && (
              <FormControlLabel
                classes={{ root: classes.childrenCheckbox }}
                onChange={() => props.onSelect(row)}
                label={''}
                checked={props.checked}
                control={(
                  <Checkbox
                    color="primary"
                  />
                )}
              />
            )}

            <div className={classes.showChildrenButton} onClick={toggleChildren}>

              {!options?.selectableParent && (
                <IconButton color="primary" className={classes.arrowButton}>
                  <ExpandMoreRounded
                    className={clsx(classes.arrow, childrenOpened && classes.arrowOpened)}
                  />
                </IconButton>
              )}

              <div className={classes.textWithCount}>
                {value}
                <span className={clsx(classes.childrenCount, props.classNames?.childrenCount)}>
                  {children.length}
                </span>
              </div>

            </div>
          </div>

          <MultiSelectList
            className={clsx(classes.childrenList, !childrenOpened && classes.childrenListHidden, props.classNames?.childrenList)}
            onSelect={props.onSelect}
            values={children}
            selectedValues={props.selectedValues}
            classNames={props.classNames}
            options={props.options}
          />
        </div>
      )}
    </ListItem>
  );
};
