// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { sendToken } from './notifications';

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseApp = initializeApp({
  apiKey: 'AIzaSyCu2R2c5VyrMPzIuqBjPE8o2bLKBGQNoGg',
  authDomain: 'grow-uperion.firebaseapp.com',
  projectId: 'grow-uperion',
  storageBucket: 'grow-uperion.appspot.com',
  messagingSenderId: '760363972520',
  appId: '1:760363972520:web:17670f315b136432218f9d',
  measurementId: 'G-K5VK42G9PN'
});

// Initialize Firebase
export const messaging = getMessaging(firebaseApp);
if (process.env.NODE_ENV !== 'development') {
  getToken(messaging, {
    vapidKey: 'BJSIyLrAwzf6t8zfLR6agzgd9wxDMh7mjkcPKlJmq2t4fUNGDLjD-0uVra96Q0mu2Rua_WBLK1bglCUNEyDDTG4'
  }).then(currentToken => {
    sendToken(currentToken);
  });
}

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
