import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme, Props>(theme => ({
  appbarRoot: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.backgrounds.appBar ?? '#fff',
  },
  toolbarRoot: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      minHeight: 60
    }
  }
}));

export interface IAppbarProps {}

type Props = React.PropsWithChildren<IAppbarProps>;

export const Appbar: React.FC<Props> = (props: Props) => {
  const classes = makeClasses(props);

  return (
    <AppBar color="default" elevation={0} classes={{ root: classes.appbarRoot }}>
      <Toolbar classes={{ root: classes.toolbarRoot }}>{props.children}</Toolbar>
    </AppBar>
  );
};
