import jwtDecode from 'jwt-decode';
import i18n from 'i18n';
import { Config, SupportedCompanies } from 'config';
import { IToken } from 'interfaces/token.interface';
import store from 'modules/core/store';
import { getService, UsersService, SettingsService } from 'modules/core/services';
import { setAuthToken, setShowLoader, setResetCoreStore } from 'modules/core/store/actions';
import { setResetAdminStore } from 'modules/admin/store/actions';
import { setResetUserStore } from 'modules/user/store/actions';
import { debug, debugError } from 'utils/debug';
import {
  authTokenSelector,
  userClaimsListSelector,
  userProfileSelector,
  userRolesSelector
} from 'modules/core/store/selectors';
import { CoreService } from './core.service';
import { forceHttps, pageOpenedInIFrame } from 'utils/window-utils';
import { IUserProfile } from '../../../interfaces/user.interface';
import { getLangByConfig } from 'utils/lang-utils';
import { otherConsts } from '../../../const/otherConsts';

export class AuthService extends CoreService {

  constructor(
    private usersService = getService(UsersService),
    private settingsService = getService(SettingsService)
  ) {
    super();
  }

  public async bootstrap() {
    const storedToken = localStorage.getItem(Config.STORED_TOKEN_KEY);
    const validatedToken = this.isTokenValid(storedToken);
    if (storedToken && validatedToken) {
      try {
        this.setToken(storedToken);
        await this.prepareUser();
      } catch (e) {
        this.logout();
        debug(e);
      }
    } else if (storedToken) {
      localStorage.removeItem(Config.STORED_TOKEN_KEY);
    }
    store.dispatch(setShowLoader(false));
  }

  public get isAuthorized() {
    const storeState = store.getState();
    const hasToken = Boolean(authTokenSelector(storeState));
    const hasProfile = Boolean(userProfileSelector(storeState));
    const hasRoles = Boolean(userRolesSelector(storeState));
    return hasToken && hasProfile && hasRoles;
  }

  public async getSettings(isAdmin: boolean) {
    try {
      await this.settingsService.getSettings(isAdmin);
      await this.settingsService.getTenantLogo();
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  private isAdmin(profile: IUserProfile) {
    const { claims } = profile
    return claims.map(claim => claim.indexOf('.Manage') > -1).includes(true)
  }

  private async prepareUser() {
    const profile = await this.usersService.fetchUserProfile();
    await this.getSettings(this.isAdmin(profile));
    this.usersService.applyUserProfile(profile);
    this.httpClientService.setHeaderLanguage(profile.language);
    this.functionClientService.setHeaderLanguage(profile.language);
    i18n.changeLanguage(getLangByConfig(profile.language));
    this.usersService.applyUserRoles([{ id: profile.id, name: this.isAdmin(profile) ? 'Admin' : 'User' }]);
    this.usersService.applyUserAvatarURL();
  }

  public async auth(email: string, password: string) {
    try {
      const { value: { token } } = await this.httpClientService.post<{}, { token: string }>('/accounts/login', {
        email,
        password
      });
      await this.login(token);
      return true;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  public login = async (token: string) => {
    if (localStorage.getItem(Config.STORED_TOKEN_KEY) === token) {
      return false;
    }
    localStorage.removeItem(Config.STORED_TOKEN_KEY);
    this.setToken(token);
    // TODO: probably the reason for multi requests on transition from auth app
    if (!this.isAuthorized) {
      await this.prepareUser();
    }
  };

  public async logout(withExternal = true) {
    this.setToken(null);
    if (Config.COMPANY === SupportedCompanies.EDU && withExternal) {
      try {
        await this.externalSignout();
      } catch (e) {
        // no need to show the user that external signout failed
        debugError(e);
      } finally {
        store.dispatch(setResetCoreStore());
        store.dispatch(setResetAdminStore());
        store.dispatch(setResetUserStore());
        window.location.assign('/auth');
      }
    } else {
      store.dispatch(setResetCoreStore());
      store.dispatch(setResetAdminStore());
      store.dispatch(setResetUserStore());
      window.location.assign('/auth');
    }
  }

  public externalSignOutRedirect() {
    if (pageOpenedInIFrame()) {
      window.open(`${forceHttps(window.location.origin)}/accounts/externalSignOut`);
      window.location.assign('/auth-redirect');
    } else {
      window.location.assign(`${forceHttps(window.location.origin)}/accounts/externalSignOut`);
    }
  }

  public setToken(authorizationToken: string | null) {
    if (authorizationToken) {
      localStorage.setItem(Config.STORED_TOKEN_KEY, authorizationToken);
    } else {
      localStorage.removeItem(Config.STORED_TOKEN_KEY);
    }
    this.httpClientService.setHeaderToken(authorizationToken);
    this.functionClientService.setHeaderToken(authorizationToken);
    store.dispatch(setAuthToken(authorizationToken ?? ''));
  }

  public isTokenValid(token = localStorage.getItem(Config.STORED_TOKEN_KEY)): IToken | false {
    if (token) {
      try {
        const validated = jwtDecode(token) as IToken;
        const { exp } = validated;
        const now = Math.floor(Date.now() / 1000);
        if (exp > now) {
          return validated;
        }
        return false;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  public hasClaims(claims: string[], comparisionType: string = otherConsts.AND) {
    if (this.isAuthorized) {
      const userClaims = userClaimsListSelector(store.getState());
      if (comparisionType === otherConsts.OR) {
        return claims.some(requiredClaim => userClaims.some(userClaim => userClaim.toLowerCase() === requiredClaim.toLowerCase()));
      } else {
        return claims.every(requiredClaim => userClaims.some(userClaim => userClaim.toLowerCase() === requiredClaim.toLowerCase()));
      }
    }
    return false;
  }

  // Role names
  public hasRoles = (roles: string[]) => {
    if (this.isAuthorized) {
      const userRoles = userRolesSelector(store.getState());
      return roles.every(requiredRole => userRoles.some(userRole => userRole.name.toLowerCase() === requiredRole.toLowerCase()));
    }
    return false;
  };

  externalSignout = () => this.httpClientService.get('/accounts/externalSignOut');
}
