import React, { FormEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, InputLabel, OutlinedInput } from '@material-ui/core';
import { CardHeaderGradient } from 'modules/core/components/common/card';
import { AuthService } from 'modules/core/services';
import { useService } from 'modules/core/hooks';
import { _refButton } from 'styles/utils';
import { useSnackbar } from 'notistack';
import { BACKGROUND } from 'themes/utils/background-utils';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useSelector } from 'react-redux';
import { feVisualizationType } from 'modules/core/store/selectors'

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  errorText: {
    ...theme.mixins.fonts.regular.xs,
    color: theme.palette.error.main,
    textAlign: 'right'
  },
  cardRoot: {
    boxShadow: '0 31px 53px rgba(0, 0, 0, 0.3)',
    marginTop: 30,
    color: '#979797'
  },
  cardContentRoot: {
    padding: '30px',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  label: {
    marginTop: theme.spacing(1)
  },
  rootInput: {
    width: '100%'
  },
  rootInputField: {
    marginBottom: theme.spacing(3)
  },
  rootInputFieldText: {
    ...theme.mixins.fonts.regular.xxs,
    textAlign: 'right',
    margin: theme.spacing(1, 0),
    color: theme.palette.common.black
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column'
  },
  loginButton: {
    minWidth: 157,
    marginLeft: 'auto',
    marginTop: theme.spacing(3)
  },
  lostPasswordButton: {
    ..._refButton
  },
  refButton: {
    ..._refButton,
    color: theme.palette.primary.main
  },
  externalAuthorization: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    margin: '20px 0'
  }
}));

interface FromState {
  from: string;
}

export const LoginCard = ({ authWithMicrosoft }: any) => {
  const classes = makeClasses();
  const history = useHistory();
  const location = useLocation<FromState>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authService = useService<AuthService>(AuthService);
  const [token, setToken] = React.useState<string>('');
  const [isAuthorizing, setIsAuthorizing] = React.useState(false);
  const visualizationType = useSelector(feVisualizationType)

  const authorize = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsAuthorizing(true);
      await authService.login(token);
      enqueueSnackbar(t('auth.loginSuccess'), {
        variant: 'success'
      });
      history.push('/user');
    } catch (e) {
      enqueueSnackbar(t('auth.loginError'), {
        variant: 'error'
      });
      setIsAuthorizing(false);
    }
  };


  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeaderGradient
        color={BACKGROUND.SECONDARY}
      >
        <span>Login</span>
      </CardHeaderGradient>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <form className={classes.formRoot} onSubmit={authorize}
        >
          <div>
            <InputLabel
              className={classes.label}
            >
              JWT Token:
            </InputLabel>
            <OutlinedInput
              name="token"
              className={classes.rootInput}
              value={token}
              onChange={e => setToken(e.target.value)}

            />
          </div>


          <Button type="submit"
                  className={classes.loginButton}
                  variant="contained"
                  color="primary"
                  disabled={isAuthorizing}
          >
            {t('auth.loginButtonText')}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};
