import React from 'react';
import _get from 'lodash/get';
import clsx from 'clsx';
import { IMyTableCellProps } from './interfaces';
import TableCell from '@material-ui/core/TableCell';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { defaultTheme } from 'themes/default-theme';
import { Box } from '@material-ui/core';
import parse from 'html-react-parser';

export type Props = IMyTableCellProps;

const makeClasses = makeStyles<Theme, { width?: number | string, }>((theme: Theme) => ({
  root: {
    ...defaultTheme.mixins.fonts.regular.xxs,
    width: props => props.width ?? 'initial',
  },
  inputRoot: {
    ...defaultTheme.mixins.fonts.regular.xxs,
    height: '1rem'
  }
}));

export const MyTableCell: React.FC<Props> = (props: Props) => {

  const { classes, column, row, isEditing } = props;

  const myClasses = makeClasses({
    width: props.column.width
  });

  const unpreparedValue = React.useMemo(
    () => {
      if (isEditing) {
        return _get(props.row.cache, column.field) ?? '';
      }
      return _get(props.row.row, column.field) ?? '';
    },
    [column.field, props.row.cache, props.row.row, isEditing]
  );

  const value = React.useMemo(
    () => {
      if (column.prepareValue) {
        return column.prepareValue(unpreparedValue, props.row.row);
      }
      return unpreparedValue;
    },
    [props.row.row, unpreparedValue, column]
  );

  const editableDefault = React.useMemo(
    () => {
      switch (typeof value) {
        case 'number':
          return (
            <OutlinedInput type='number' fullWidth classes={{ input: myClasses.inputRoot }} name={column.field}
              value={value} onChange={(e) => {
                e.stopPropagation();
                props.row.edit(column.field, Number(e.target.value));
              }} />
          );
        case 'string':
          return (
            <OutlinedInput
              fullWidth
              classes={{
                input: myClasses.inputRoot
              }}
              name={column.field}
              value={parse(value)}
              onChange={(e) => {
                e.stopPropagation();
                props.row.edit(column.field, String(e.target.value));
              }} />
          );
        case 'boolean':
          return (
            <Switch
              color='primary'
              checked={value}
              onChange={(e) => {
                e.stopPropagation();
                props.row.edit(column.field, Boolean(!unpreparedValue));
              }} />
          );
        default:
          return null;
      }

    },
    [value, myClasses.inputRoot, column.field, props.row, unpreparedValue]
  );

  const renderEditable = React.useMemo(
    () => {
      return column.renderEdit ? column.renderEdit(props.row, column, myClasses.inputRoot) : editableDefault;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, column, props.row, editableDefault]
  );

  const renderDefault = React.useMemo(
    () => {
      if (!column.render) {
        switch (typeof value) {
          case 'boolean':
            return (
              <Checkbox checked={value} disabled={true} />
            );
          case 'string':
            return parse(value);
          case 'number':
            return <Box paddingLeft="8px">{value}</Box>;
          default:
            return null;
        }
      }
      return column.render(props.row, column, myClasses.inputRoot);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, props.row, column]
  );

  return (
    <TableCell classes={{
      ...classes?.tableCellClasses,
      ...classes?.tableBodyCellClasses,
      root: clsx(
        myClasses.root,
        classes?.tableCellClasses?.root,
        classes?.tableBodyCellClasses?.root,
        {
          expanded: row.isExpanded,
        }
      )
    }}>
      {
        props.isEditing && props.column.editable !== false
          ? renderEditable
          : renderDefault
      }
    </TableCell>
  );
};
