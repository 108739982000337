import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useService } from 'modules/core/hooks';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { BACKGROUND } from 'themes/utils/background-utils';
import {
  currentSelectedRoundSelector,
  userProfileSelector
} from 'modules/core/store/selectors';
import { Divider, Grid, Typography } from '@material-ui/core';
import { FeedCardPepco } from '../../layout/feed-card/feed-card-pepco';
import { UsersService } from 'modules/core/services';
import { defaultTheme } from 'themes/default-theme';
import { DuelsService, RankingService } from 'modules/user/services'
import { showApiErrors } from 'utils';
import { ApiError } from 'utils/api-error';
import { IMyDuelStatistics } from 'modules/user/interfaces/q&a.interface';
import { IMyIndividualRanking } from 'modules/user/interfaces/ranking.interface';
import { BadgePreview } from '../../shared/badge-preview';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  personAvatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%'
    }
  },

  labelName: {
    ...defaultTheme.mixins.fonts.regular.s,
    color: '#171717',
  },

  labelScore: {
    ...defaultTheme.mixins.fonts.bold.s,
    color: '#171717',
  },

  labelWon: {
    ...defaultTheme.mixins.fonts.regular.xs,
  },

  divider: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  }
}));

export const DuelFeed = () => {
  const classes = makeClasses();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const currentRound = useSelector(currentSelectedRoundSelector);
  const [avatar, setAvatar] = useState<string>();

  const currentProfile = useSelector(userProfileSelector);
  const rankingService = useService(RankingService);
  const usersService = useService(UsersService);
  const duelService = useService(DuelsService);
  const [myStats, setMyStats] = useState<IMyDuelStatistics>();
  const [myRanking, setMyRanking] = useState<IMyIndividualRanking>()

  const getMyStatistics = async () => {
    try {
      const response = await duelService.getMyDuelsStatistics();
      if (response) {
        setMyStats(response)
      }
    } catch (e) {
      showApiErrors(e as ApiError, enqueueSnackbar);
    }
  }
  const getMyRankinkPosition = async () => {
    try {
      const response = await rankingService.getIndividualRanking(2);
      if (response) {
        setMyRanking(response.all[0])
      }
    } catch (e) {
      showApiErrors(e as ApiError, enqueueSnackbar);
    }
  }

  useEffect(() => {
    fetchAvatar();
    getMyStatistics();
    getMyRankinkPosition();
  }, []);

  const fetchAvatar = async () => {
    try {
      const avatarUrl = await usersService.fetchAvatar(currentProfile?.avatar);
      setAvatar(avatarUrl);
    } catch (e) {
      showApiErrors(e as ApiError, enqueueSnackbar);
    }
  };

  return (
    <>
      <FeedCardPepco
        height={'500px'}
        headingColor={BACKGROUND.NONE}
        title={`${t('userPanel.dashboard.myScore')} - ${currentRound.name}`}
      >
        <div className={classes.personAvatar}>
          {avatar && <img src={avatar} alt='' />}
        </div>
        <Typography className={classes.labelName}>{currentProfile?.name + ' ' + currentProfile?.surname}</Typography>
        {myRanking && <Typography className={classes.labelScore}>{t('userPanel.myRankingFeed.myPlace', { place: myRanking.placement ?? 0 })}</Typography>}
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.labelWon}>{t('userPanel.duels.duelsWon')}</Typography>
            {myStats && <Typography className={classes.labelScore}>{myStats.duelsWon}/{myStats.allDuelsPlayed}</Typography>}
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.labelWon}>{t('userPanel.myRankingFeed.mask')}</Typography>
            {(myRanking && myRanking.badges.length) ? <BadgePreview badgeId={myRanking.badges[0]} /> : <Typography>{t('userPanel.myRankingFeed.noMask')}</Typography>}
          </Grid>
        </Grid>
      </FeedCardPepco>
    </>
  );
};
