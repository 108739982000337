import React from 'react';
import clsx from 'clsx';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const verticalSpreadStyle = (index: number, spreadParam: number) => {
  return {
    transform: `translateY(${(1 + index) % 2 * spreadParam - spreadParam / 2}px)`,
    zIndex: 1 - (index % 2),
  }
};

const scale = (props: StyledProps) => {
  const maxHeightScale =
    (props.componentsHeight || 0) > (props.maxHeight || Number.MAX_SAFE_INTEGER)
      ? (props.maxHeight || Number.MAX_SAFE_INTEGER) / (props.componentsHeight || 1)
      : 1;
  return Math.min(maxHeightScale, props.fitWidth ? (props.containerWidth || 1) / (props.componentsWidth || 1) : 1);
};

const fitHeight = (props: StyledProps) => {
  return (props.componentsHeight || 0) * scale(props) || 'auto';
};

const makeClasses = makeStyles<DefaultTheme, StyledProps>(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: props => fitHeight(props),
    bottom: props => -(props?.verticalSpread ?? 0) * scale(props),
  },
  components: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    justifyContent: 'center',
    transformOrigin: 'bottom',
    width: props => (!props.fitWidth && (!!props.leftComponents || !!props.rightComponents) ? '100%' : 'initial'),
    marginLeft: props => -((props.spacing ?? 20) / 2) * scale(props),
    transform: props => `scale(${scale(props)}) translateY(${(props.verticalSpread ?? 0) / 2}px)`
  },
  leftComponents: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    transformOrigin: 'bottom',
    justifyContent: 'flex-end',
    width: props => props.fitWidth ? '100%' : 0,
  },
  rightComponents: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    transformOrigin: 'bottom',
    justifyContent: 'flex-start',
    width: props => props.fitWidth ? '100%' : 0,
  },
  component: {
    position: 'relative',
    marginLeft: props => props.spacing ?? 20,
    pointerEvents: props => props.noPointerEvents ? 'none' : 'all',
    paddingBottom: props => props.verticalSpread ?? 0,
  },
  centeredComponent: {
    zIndex: 3,
  },
}));

export interface StyledProps extends SpacedComponentsProps {
  containerWidth?: number;
  containerHeight?: number;
  componentsWidth?: number;
  componentsHeight?: number;
}

export interface SpacedComponentsProps {
  className?: string;
  fitWidth?: boolean;
  maxHeight?: number;
  spacing?: number;
  verticalSpread?: number;
  leftComponents?: React.ReactNodeArray;
  rightComponents?: React.ReactNodeArray;
  onScaleChange?: (scale: number) => any;
  noPointerEvents?: boolean;
}

export const SpacedComponents: React.FC<React.PropsWithChildren<SpacedComponentsProps>> = props => {
  const { ref: containerRef, width: containerWidth, height: containerHeight } = useResizeObserver();
  const { ref: componentsRef, width: componentsWidth, height: componentsHeight } = useResizeObserver();
  const styledProps = { ...props, containerWidth, containerHeight, componentsWidth, componentsHeight };
  const classes = makeClasses(styledProps);

  React.useEffect(() => {
    if (props.onScaleChange) {
      props.onScaleChange(scale(styledProps));
    }
  }, [containerWidth, containerHeight, componentsWidth, componentsHeight]);

  return (
    <div className={clsx(classes.root, props.className)} ref={containerRef as React.RefObject<HTMLDivElement>}>
      <div className={classes.components} ref={componentsRef as React.RefObject<HTMLDivElement>}>
        {props.leftComponents && (
          <div className={classes.leftComponents}>
            {props.leftComponents?.map((child, i) => (
              <div
                key={`L${i}`}
                className={classes.component}
                style={verticalSpreadStyle(i, props.verticalSpread ?? 0)}
              >
                {child}
              </div>
            ))}
          </div>
        )}

        {props.children instanceof Array ? (
          props.children?.map((child, i) => (
            <div className={classes.component} key={i}>
              {child}
            </div>
          ))
        ) : (
          <div
            className={clsx(classes.component, classes.centeredComponent)}
          >
            {props.children}
          </div>
        )}

        {props.rightComponents && (
          <div className={classes.rightComponents}>
            {props.rightComponents?.map((child, i) => (
              <div
                className={classes.component}
                key={`R${i}`}
                style={verticalSpreadStyle(i, props.verticalSpread ?? 0)}
              >
                {child}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
