import React from 'react';
import { Box, Card } from '@material-ui/core';
import { MyTable } from './my-table';
import { MyTableExpandedRowComponent } from './interfaces';

export const MyTableExpandedRow: MyTableExpandedRowComponent = (props) => {

  const {
    preparedRow: parentPreparedRow,
    columns,
    options,
    classes,
    events,
    Actions,
    extendsProps,
    FocusedActions,
    _parentState
  } = props;

  const data = React.useMemo(
    () => {
      if (options?.getRowChildren)
        return options.getRowChildren(parentPreparedRow.row);
      if (parentPreparedRow.row.children)
        return parentPreparedRow.row.children;
      throw new Error('MyTableExpandedRow - Row Children have not been found');
    },
    [parentPreparedRow.row, options]
  );

  return (
    <Box overflow="auto" maxWidth="100%" p={1}>
      <Card variant="outlined">
        <MyTable
          size='small'
          Actions={Actions}
          FocusedActions={FocusedActions}
          classes={classes}
          checked={_parentState.checkedRows}
          events={{
            ...events // catching events is not necessary if nested data is the same
          }}
          data={data}
          options={{
            ...(extendsProps?.childrenTables?.options ?? {}),
          }}
          {...extendsProps?.childrenTables}
          columns={columns/* If you want to render other columns as children you should use override prop, and override extend column */}/>
      </Card>
    </Box>
  );

};
