import React from 'react';
import { getService, UsersService } from 'modules/core/services';
import { IUserProfile, IUserProfileSex } from 'interfaces/user.interface';
import { showApiErrors } from 'utils';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { userProfileSelector } from 'modules/core/store/selectors';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const makeClasses = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(1.25)
  },
  button: {
    border: '0 none',
    background: 'none',
    height: '32px',
    padding: '0',
    overflow: 'hidden',
    marginLeft: theme.spacing(1.25),
    '&.active': {
      textDecoration: 'underline'
    }
  }
}));

export const NavSex: React.FC = () => {
  const userService = getService(UsersService);
  const userProfile = useSelector(userProfileSelector);
  const { t } = useTranslation();
  const classes = makeClasses();
  const { enqueueSnackbar } = useSnackbar();
  const [currentSex, setCurrentSex] = React.useState<IUserProfileSex>();

  const onSelect = (sex: IUserProfileSex) => {
    setCurrentSex(sex);
  }

  const sendCurrentSex = async () => {
    if (currentSex && userProfile) {
      try {
        const changedProfile: IUserProfile = {...userProfile, gender: currentSex };
        await userService.setContentProfile(changedProfile);
      } catch (e) {
        showApiErrors(e, enqueueSnackbar);
      }
    }
  }

  React.useEffect(() => {
    sendCurrentSex();
  }, [currentSex])

  return (
    <div className={classes.wrapper}>
      <strong>{t('common.sex.title')}</strong>
      <button
        type="button"
        className={clsx(userProfile?.gender === IUserProfileSex.MALE ? 'active' : '', classes.button)}
        onClick={() => {onSelect(IUserProfileSex.MALE)}}>
        {t('common.sex.male')}
      </button>
      <button
        type="button"
        className={clsx(userProfile?.gender === IUserProfileSex.FEMALE ? 'active' : '', classes.button)}
        onClick={() => {onSelect(IUserProfileSex.FEMALE)}}>
        {t('common.sex.female')}
      </button>
      <button
        type="button"
        className={clsx(userProfile?.gender === IUserProfileSex.NONE ? 'active' : '', classes.button)}
        onClick={() => {onSelect(IUserProfileSex.NONE)}}>
        {t('common.sex.notSet')}
      </button>
    </div>
  );
};
