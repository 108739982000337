import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useService, useIcons, IconsNames } from 'modules/core/hooks';
import { currentSelectedRoundSelector } from 'modules/core/store/selectors';
import { userAllScoresSelector } from 'modules/user/store/selectors';

import { showApiErrors } from 'utils/show-errors';
import { useSnackbar } from 'notistack';
import { Box, Typography } from '@material-ui/core';
import { LoaderView } from 'modules/core/views';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { ActivityService } from 'modules/user/services/activity.services';
import { IPagination } from 'interfaces/pagination.interface';
import { MyTable } from 'modules/core/components/common/my-table';
import { IMyTableColumn, IMyTablePreparedRow, IMyTableSort } from 'modules/core/components/common/my-table/interfaces';
import { format } from 'date-fns';
import { TABLE_DATE_TIME_FORMAT } from 'utils/date-utils';
import { Section } from 'modules/core/components/common';
import { IActivity, IIndexedActivity } from 'modules/user/interfaces/activity.interface';
import { getResponsePagination } from 'utils';
import { ScrollWrapper } from 'modules/core/components/common/scroll-wrapper/scroll-wrapper';

const makeClasses = makeStyles<DefaultTheme, { tableHeight: string }>(theme => ({
  cardContentRoot: {
    position: 'relative',
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  noTasksRoot: {
    ...theme.mixins.fonts.medium.l,
    margin: 'auto',
  },
  sectionWrapper: {
    padding: '30px'
  },
  tableCellRoot: {
    borderBottom: 'none',
    height: '50px',
  },
  tableHeader: {
    fontWeight: 700,
    fontSize: '14px',
    background: 'white',
    color: 'black',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingBottom: '15px',
    paddingTop: 0
  },
  tableWrapper: {
    background: 'white',
    borderRadius: '10px',
    padding: '30px',
    height: 'fit-content'
  },
  points: {
    display: 'flex'
  },
  redIcon: {
    marginRight: theme.spacing(1),
    alignSelf: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  totalPointsWrapper: {
    fontWeight: 700,
    fontSize: '16px',
  },
  pointsIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    alignSelf: 'center',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  totalPoints: {
    fontSize: '27px',
    fontWeight: 300,
    marginRight: theme.spacing(1),
  },
  tableScrollWrapper: {
    overflow: 'auto',
    height: props => props.tableHeight,
    maxHeight: 520
  }
}));

export interface MyResultTableProps {
  isHeaderSticky?: boolean,
  tableHeight?: string,
  userId: string,
}
export const MyResultTable: React.FC<MyResultTableProps> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = makeClasses({
    tableHeight: props.tableHeight ?? '60vh'
  });
  const userRoundScoreList = useSelector(userAllScoresSelector);

  const activityService = useService(ActivityService);
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    size: 10,
    sort: 'created',
    asc: false
  })
  const currentRound = useSelector(currentSelectedRoundSelector);
  const userRoundScore = userRoundScoreList.find(score => score.roundId === currentRound.id && !score.workerGroupId)

  const [recordCount, setRecordCount] = useState<number | null>(null)
  const [pointsList, setPointsList] = useState<IActivity[]>([])
  const [canRequest, setCanRequest] = useState<boolean>(true)

  const [fetch, setFetch] = useState(false);
  const tableStarIcon = useIcons(
    IconsNames.STAR,
    classes.redIcon,
  );
  const titleStarIcon = useIcons(
    IconsNames.STAR,
    classes.pointsIcon,
  );

  useEffect(
    () => {
      if (currentRound) {
        fetchActivities({
          ...pagination,
          page: 1
        })
      }
    }, [currentRound]
  )
  useEffect(
    () => {
      setCanRequest(!(pointsList?.length === recordCount))
    }, [pointsList]
  )

  const fetchActivities = async (_pagination: IPagination) => {
    try {
      setFetch(true);
      const response = await activityService.fetchActivities(
        _pagination, currentRound.id, props.userId);
      if (response) {
        const responsePagination = getResponsePagination(response);
        setPagination({
          ..._pagination,
          ...responsePagination
        })
        setRecordCount(response.recordsCount);
        setPointsList(response.records);
      }
    } catch (error) {
      showApiErrors(error, enqueueSnackbar);
    } finally {
      setFetch(false)
    }
  }

  const fetchMoreData = useCallback(
    async () => {
      if (!fetch && canRequest) {
        try {
          setFetch(true);
          const nextPagination = {
            ...pagination,
            page: (pagination?.page ?? 0) + 1
          };
          const response = await activityService.fetchActivities(
            nextPagination, currentRound.id, props.userId);
          if (response) {
            const responsePagination = getResponsePagination(response);
            setPointsList([
              ...pointsList ?? [],
              ...response.records
            ])
            setPagination({
              ...pagination,
              ...responsePagination
            })
          }
        } catch (error) {
          showApiErrors(error, enqueueSnackbar);
        } finally {
          setFetch(false)
        }
      }

    }, [pointsList, fetch, pagination, setPointsList, canRequest])

  const preprareList = useMemo(
    () => {
      if (pointsList) {
        const next: IIndexedActivity[] = [];
        pointsList.forEach((item: IActivity, index: number) => {
          next.push({
            ...item,
            index
          })
        })
        return next
      }
    }, [pointsList]
  )
  const renderPoints = ({ row }: IMyTablePreparedRow<IActivity>) => {
    return <Typography component='div' className={classes.points}>
      {tableStarIcon}
      <Typography>
        {row.points}
      </Typography>
    </Typography>
  }

  const onSortChange = React.useCallback(
    (sort?: IMyTableSort) => {
      fetchActivities({
        ...pagination,
        page: 1,
        sort: sort?.field,
        asc: sort?.asc
      });
    }, [pagination]);

  const renderReviewer = ({ row }: IMyTablePreparedRow<IActivity>) => {
    if (row.addedBy) {
      return `${row.addedBy.name} ${row.addedBy.surname}`
    }
    return t('userPanel.myResults.automaticTask')
  }
  const columns: IMyTableColumn[] = useMemo(
    () => [
      {
        field: 'index',
        title: t('userPanel.myResults.index'),
        prepareValue: (index) => `${index + 1}.`
      },
      {
        field: 'name',
        title: t('userPanel.myResults.task'),
        isSortable: true
      },
      {
        field: 'points',
        title: t('userPanel.myResults.gatheredPoints'),
        render: renderPoints,
        isSortable: true
      },
      {
        field: '',
        title: t('userPanel.myResults.whoReviewed'),
        render: renderReviewer
      },
      {
        field: 'created',
        title: t("userPanel.myResults.reviewDate"),
        prepareValue: (date: string) => format(new Date(date), TABLE_DATE_TIME_FORMAT),
        isSortable: true
      }
    ], [pointsList, renderPoints]
  )
  const table = useMemo(
    () => (
      <MyTable
        pagination={pagination}
        options={{
          pagination: false,
          darkHeader: false,
          stickyHeader: props.isHeaderSticky,
        }}
        classes={{
          tableHeadCellClasses: {
            root: classes.tableHeader
          },
          tableRowClasses: {
            root: classes.tableCellRoot
          }
        }}
        events={{
          onSortChange
        }}
        size="small"
        columns={columns}
        data={preprareList ?? []}
      />
    ),
    [pointsList]
  );
  return (
    <Section className={classes.sectionWrapper}>
      <LoaderView visible={fetch} />
      {pointsList && pointsList.length ?
        <>
          {props.userId === 'mine' && <Box mb={1} display="flex" alignItems="flex-end" justifyContent="space-between">
            <Box pb={0.5} display="inline-flex" alignItems="center" justifyContent="flex-end">
              <Typography className={classes.totalPointsWrapper} >
                {t('userPanel.myResults.sumOfPoints')}
              </Typography>
              {titleStarIcon}
              <Typography className={classes.totalPoints}>
                {userRoundScore?.value ?? 0}
              </Typography>
              <Typography>
                {t('common.points', { count: userRoundScore?.value ?? 0 })}
              </Typography>
            </Box>
          </Box>}
          <ScrollWrapper
            onLoad={fetchMoreData}
            className={classes.tableScrollWrapper}
          >
            {table}
          </ScrollWrapper>
        </> :
        <Typography>
          {props.userId === 'mine' ? t('userPanel.myResults.noResults') : t('userPanel.myResults.noResultsForOtherUser')}
        </Typography>
      }
    </Section>
  )

}