export const debug = (...logs: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    // tslint:disable-next-line
    console.log(...logs);
  }
};

export const debugError = (...logs: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    // tslint:disable-next-line
    console.error(...logs);
  }
};
