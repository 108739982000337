import { Box, Avatar, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { UsersService } from 'modules/core/services';
import { useService } from 'modules/core/hooks';
import { showApiErrors } from 'utils/show-errors';
import { useSnackbar } from 'notistack';
import { ApiError } from "utils/api-error";

const makeClasses = makeStyles<DefaultTheme, { groupColor: string }>(theme => ({
  userInfo: {
    display: 'flex',
  },
  avatar: {
    marginRight: '8px'
  },
  points: {
    ...theme.mixins.fonts.bold.xs
  },
  groupName: {
    ...theme.mixins.fonts.light.xs,
    color: props => props.groupColor
  },
}));

interface Props {
  name: string,
  avatarId?: string,
  groupName?: string,
  groupColor?: string,
}
export const UserInfo = ({ name, avatarId, groupName, groupColor = '#002C59' }: Props) => {
  const classes = makeClasses({
    groupColor
  });
  const usersService = useService(UsersService);
  const { enqueueSnackbar } = useSnackbar();

  const [avatarSrc, setAvatarSrc] = useState<string>('');

  const getAvatar = async (id: string) => {
    try {
      const response = await usersService.fetchAvatar(id);
      if (response) {
        setAvatarSrc(response)
      }
    } catch (error) {
      showApiErrors(error as ApiError, enqueueSnackbar);

    }
  }
  useEffect(
    () => {
      if (avatarId) {
        getAvatar(avatarId)
      }
    }, []
  )
  return (
    <Box
      className={classes.userInfo}
    >
      <Avatar className={classes.avatar} src={avatarSrc} />
      <Box>
        <Typography className={classes.points} >{name}</Typography>
        <Typography className={classes.groupName} >{groupName}</Typography>
      </Box>
    </Box>
  )
}