import store from 'modules/core/store';
import { setCoreError } from 'modules/core/store/actions';
import { MyError } from 'utils/error';
import { Errors } from 'const';
import { getService } from 'modules/core/services/index';
import { NotistackService } from 'modules/core/functional-services/notistack.service';

import i18n from 'i18n';

import { AxiosError } from 'axios';

export class AppService {


  constructor(
    private notistackService = getService(NotistackService),
  ) {
  }


  private onNetworkError() {
    store.dispatch(setCoreError(Errors.NETWORK_ERROR));
  }

  private onFatalError() {
    store.dispatch(setCoreError(Errors.FATAL_ERROR));
  }

  private onClaimError(error: Partial<AxiosError>) {
    if (error?.config?.url !== '/worker-groups-manage' && error?.config?.url !== '/users-manage' && error?.config?.url !== '/rounds-manage') {
      this.notistackService.error(`${i18n.t('common.errors.claimError')} (${error?.config?.url ?? ''})`);
    } else {
      // tslint:disable-next-line
      console.error(`${i18n.t('common.errors.claimError')} (${error?.config?.url ?? ''})`)
    }
  }

  private onAuthError() {
    localStorage.clear();
    if (!window.location.pathname.includes('/auth')) {
      window.location.replace('/auth');
    }
  }

  handleError(error: MyError | any) { // Errors like Response or Request error are handled by components
    if (error instanceof MyError) {
      switch (error.type) {
        case Errors.NETWORK_ERROR:
          this.onNetworkError();
          break;
        case Errors.CLAIM_ERROR:
          this.onClaimError(error.payload);
          break;
        case Errors.AUTH_ERROR:
          this.onAuthError();
          break;
        case Errors.FATAL_ERROR:
          this.onFatalError();
          break;
      }
    }
    return Promise.reject(error);
  }

}
