export enum VisualizationType {
  // key -> value. The value is the name of the visualization type stored in the database.
  FruitTrees = 'Drzewa z owocami',
  Buildings = 'Budynki',
  Trees = 'Drzewa',
  SeedBags = 'Worki na nasiona',
  FruitWormTrees = 'Drzewa z owocami i robakami',
  Seedlings = 'Sadzonki',
  m1 = 'm1',
  m2 = 'm2',
  m3 = 'm3',
  m4 = 'm4',
  m5 = 'm5',
  m6 = 'm6'
}

export enum SupportedCompanies {
  GU = 'GU',
  TH = 'TH',
  EDU = 'EDU',
  EX = 'EX',
  PP = 'PP',
}
