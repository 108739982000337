import React from 'react';
import { Background, Section } from 'modules/user/components/layout';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { defaultTheme } from 'themes/default-theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { feVisualizationType } from 'modules/core/store/selectors';
import { useService } from 'modules/core/hooks';
import { AuthService } from 'modules/core/services';
import { SupportedCompanies } from 'config';

const makeClasses = makeStyles<Theme>((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  box: {
    maxWidth: '500px',
    padding: '40px',
    margin: '0 20px',
    textAlign: 'center',
    background: defaultTheme.palette.common.white,
    boxShadow: '0 31px 53px rgba(0, 0, 0, 0.3), inset 0px 0px 0px 1px #E4E4E4',
    borderRadius: defaultTheme.shape.borderRadius
  },
  headline: {
    ...defaultTheme.mixins.fonts.regular.l,
    margin: '0 0 20px'
  },

  link: {
    fontWeight: 'bold',
    color: defaultTheme.palette.secondary.main,
    cursor: 'pointer'
  }
}));



export const BlockedView = () => {
  const classes = makeClasses();
  const { t } = useTranslation();
  const history = useHistory();
  const authService = useService(AuthService);
  const visualizationType = useSelector(feVisualizationType)

  const logout = async () => {
    await authService.logout();
    if (visualizationType === SupportedCompanies.EDU) {
      authService.externalSignOutRedirect();
    } else {
      history.push(`/auth?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
    }
  };

  return (<>
    {/* <Background blurred /> */}
    <Section >
      <div className={classes.wrapper}>
        <div className={classes.box}>
          <h2 className={classes.headline}>{t('common.blockedUserPage.headline')}</h2>
          <p>{t('common.blockedUserPage.text')}</p>
          <p onClick={logout} className={classes.link}>{t('common.blockedUserPage.link')}</p>
        </div>
      </div>
    </Section>
  </>);
};