import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const StarBigSVG: React.FC<SVGComponentProps> = ({ fill, className }: SVGComponentProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65">
    <path
      fill={fill}
      d="M31.544,3.114a1,1,0,0,1,1.912,0L39.92,24.17a1,1,0,0,0,.958.707l21.146-.043a1,1,0,0,1,.608,1.8l-17.217,13.1a1,1,0,0,0-.349,1.093l6.585,21.171a1,1,0,0,1-1.563,1.09L33.109,50.06a1,1,0,0,0-1.217,0L14.913,63.083a1,1,0,0,1-1.563-1.09l6.585-21.171a1,1,0,0,0-.349-1.093L2.368,26.63a1,1,0,0,1,.608-1.8l21.146.043a1,1,0,0,0,.958-.707Z"
    />
  </svg>
);
