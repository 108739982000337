import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { feNavSettingsSelector, feVisualizationType } from 'modules/core/store/selectors';

import { THEME_APPBAR_DESKTOP_HEIGHT } from 'themes/default-theme';
import { NavListElement } from './nav-list-element';
import { IModuleView } from 'interfaces/module-view.interface';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useLocation } from 'react-router-dom';
import { SupportedCompanies } from 'config';

export type NavVariantType = 'mobile' | 'desktop';

export interface INavListProps {
  list: IModuleView[];
  variant?: NavVariantType;
}

const makeClasses = makeStyles<DefaultTheme, INavListProps>(theme => ({
  navList: {
    display: 'flex',
    flexDirection: props => props.variant === 'desktop' ? 'row' : 'column',
    alignItems: props => props.variant === 'desktop' ? 'flex-end' : 'flex-start',
    height: props => props.variant === 'desktop' ? THEME_APPBAR_DESKTOP_HEIGHT : 'auto',
    marginRight: "auto",
  }
}));

export const NavList: React.FC<INavListProps> = (props: INavListProps) => {
  const { t } = useTranslation();
  const visualizationType = useSelector(feVisualizationType)
  const isEXCompany = visualizationType === SupportedCompanies.EX;
  const classes = makeClasses(props);
  const { navigation } = useSelector(feNavSettingsSelector);
  const { pathname } = useLocation();
  const extandsPaths = {
    gameMap: '/user/game-results/map',
    gameList: '/user/game-results/list',
    quests: '/user/quest',
    quizzes: '/user/quizzes',
    tasks: '/user/tasks',
    groupBlock: '/user/game-results-group/block',
    group: '/user/game-results-group',
    groupChart: '/user/game-results-group/chart'
  }

  return (
    <nav className={classes.navList}>
      {navigation.map(({ name, url }) => (
        <NavListElement
          key={name}
          to={url}
          isActive={url === pathname ||
            (extandsPaths.gameMap === pathname && url === extandsPaths.gameList) ||
            ((pathname.indexOf(extandsPaths.quests) > -1 || pathname.indexOf(extandsPaths.quizzes) > -1) && url === extandsPaths.tasks) ||
            (pathname.indexOf(url) > -1 && (pathname === extandsPaths.groupBlock || pathname === extandsPaths.groupChart))
          }
          variant={props.variant}
          isExCompany={isEXCompany}
        >
          {t(`userPanel.nav.${name}`)}
        </NavListElement>
      ))}
    </nav>
  );
};
