import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, useMediaQuery } from '@material-ui/core';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { defaultTheme } from 'themes/default-theme';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@material-ui/icons/Help';
import clsx from 'clsx';
import {useLocation} from 'react-router-dom';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    position: 'relative'
  },
  content: {
    minHeight: 92,
    minWidth: 360
  },
  message: {
    ...theme.mixins.fonts.regular.xs,
    padding: theme.spacing(1),
    flex: 1,
  },
  icon: {
    margin: 'auto'
  },
  actions: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

export interface ResponsiveModalProps extends DialogProps {
  className?: string;
  fullScreenBreakpoint?: Breakpoint;
  message?: JSX.Element | string;
  onNo?: () => void;
  onYes?: () => void;
  actions?: PropsWithChildren<React.ReactNode>;
  actionsDisabled?: boolean,
}

export const ConfirmationDialog: React.FC<ResponsiveModalProps> = props => {
  const { onYes, onNo, className, ...rest } = props;
  const classes = makeClasses();
  const { t } = useTranslation();
  const location = useLocation();

  const fullScreen = useMediaQuery(defaultTheme.breakpoints.down(props.fullScreenBreakpoint || 'xs'));
  return (
    <Dialog
      closeAfterTransition
      maxWidth="xs"
      fullScreen={fullScreen}
      className={clsx(classes.root, className)}
      {...rest}>

      <DialogContent
        className={classes.content}>
        {props.message && (
          <Box display='flex'>
            <HelpIcon fontSize="large" className={classes.icon}/>
            <div className={classes.message}>
              {props.message}
            </div>
          </Box>
        )}
        {props.children}
      </DialogContent>

      <DialogActions
        className={classes.actions}>
        {props.actions ?? (
          <>
            <Button
              onClick={props.onNo}
              disabled={props.actionsDisabled}>
              {location.pathname.includes('admin/') ? t('adminPanel.common.no') : t('common.no')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={props.onYes}
              disabled={props.actionsDisabled}>
              {location.pathname.includes('admin/') ? t('adminPanel.common.yes') : t('common.yes')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
