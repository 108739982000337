export enum TasksTypes {
  QUEST = 'Quest',
  QUIZ = 'Quiz',
  SURVEY = 'Survey'
}

export enum TaskStatuses {
  NONE = 'None',
  NEW = 'New',
  APPROVE = 'Approve',
  REJECTED = 'Rejected',
  FINISHED = 'Finished',
  IN_REVIEW = 'InReview',
  CANCELLED = 'Cancelled',
  SOON = 'Soon',
  EXPIRED = 'Expired'
}

export enum TaskUserTypes {
  INDIVIDUAL = 'Individual',
  GROUP = 'Group'
}

export interface ITask {
  id: string;
  name: string;
  status: TaskStatuses;
  start: string;
  type: TasksTypes;
  text?: string;
  isBonus: boolean;
  isExternal: boolean;
  objectId: string;
  isPassed: boolean;
  round?: string;
  actual?: boolean; // true if task is on actual opened stage
  userType: TaskUserTypes;
  attempts: number,
  currentAttempt: number,
  tokens: number;
  maxTokens: number;
  canEditPoints: boolean;
}

export interface ITasks {
  roundId: string;
  tasks: ITask[] | [];
}
