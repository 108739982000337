import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'modules/core/hoc/withSuspense';
import { useService } from 'modules/core/hooks';
import _set from 'lodash/set'
import { AuthView, ErrorView, BlockedView } from '../views';
import { AuthService } from '../services';
import modules from '../../';
import { coreServicesReadySelector, feTheme, gtmIdSelector } from '../store/selectors';
import { feVisualizationType } from 'modules/core/store/selectors'
import { SignOutView } from '../views/sign-out/sign-out';
import { IFrameExternalLogin } from 'modules/core/views/auth/iframe-external-login';
import { AuthAdminView } from 'modules/core/views/auth/auth-admin';
import { IModule } from 'interfaces/module.interface';
import { Config, languages } from 'config';
import { SupportedCompanies } from 'config/config.const';

import { setCurrentModule } from '../store/actions';
import GlobalPage from 'modules/core/views/global-page/global-page';
import { changeFavicon } from 'utils/changeFavicon';
import { Errors } from 'const';
import i18n from 'i18n';
import { HttpClientService } from '../functional-services';
import BrowserDetect from 'utils/browser-detect';
import { DefaultTheme, ThemeProvider } from '@material-ui/styles';
import { defaultTheme } from 'themes/default-theme';
import { rabenTheme } from 'themes/raben-theme';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { pepcoTheme } from 'themes/pepco-theme';
import { Roles } from 'const/roles';

changeFavicon(Config.COMPANY);

export const useModules = () => {
  const coreServicesReady = useSelector(coreServicesReadySelector);
  const visualizationType = useSelector(feVisualizationType)
  const gtmId = useSelector(gtmIdSelector);
  const feThemeStore = useSelector(feTheme);

  const authService = useService(AuthService);
  const httpClientService = useService(HttpClientService);
  const [gtmConfig, setGtmConfig] = React.useState<TagManagerArgs>()
  const [themeBySettings, setThemeBySettings] = React.useState<DefaultTheme>();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const forceLanguage = () => {
    const { search } = location;
    const lang = new URLSearchParams(search).get('lang');
    if (!authService.isAuthorized && lang && languages.includes(lang)) {
      httpClientService.setHeaderLanguage(lang);
      i18n?.changeLanguage(lang);
    }
  }

  const forceTheme = () => {
    const themeFetched = { ...pickThemeByCompany() }
    for (const item of feThemeStore.default) {
      _set(themeFetched, item.key, item.value)
    }
    setThemeBySettings(themeFetched)
  }

  useEffect(() => {
    if (feThemeStore.default && feThemeStore.default.length > 0) {
      forceTheme();
    }
  }, [feThemeStore]);

  useEffect(() => {
    getSettingsTenant();
    forceLanguage();
    if (BrowserDetect.isIE()) {
      history.push({
        pathname: '/ie'
      })
    }
  }, [])

  const getSettingsTenant = async () => {
    if (authService.isAuthorized) {
      try {
        await authService.getSettings(authService.hasRoles([Roles.Admin.name]));
      } catch (e) {
        authService.appService.handleError(e);
      }
    }
  }

  React.useEffect(() => {
    if (gtmId) {
      setGtmConfig({
        gtmId
      })
    }
  }, [gtmId])

  React.useEffect(() => {
    if (gtmConfig && gtmConfig.gtmId && gtmConfig.gtmId !== 'null') {
      TagManager.initialize(gtmConfig)
    }
  }, [gtmConfig])

  const AuthorizedRoute = React.useCallback(
    (routeProps: RouteProps) => {
      // console.log('czy jest authorized chcą zawołać?', authService.isAuthorized, routeProps);
      // const redirectUrl = `/accounts/external?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`;
      // console.log('przekieruj na:', `${window.location.origin}/accounts/external?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
      // window.location.replace(`${forceHttps(window.location.origin)}/accounts/external?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`)
      return (authService.isAuthorized ? <Route {...routeProps} /> : <Redirect to={{ pathname: '/auth', state: { from: location } }} />);
      // return (authService.isAuthorized ? <Route {...routeProps} /> : <Redirect to={{ pathname: redirectUrl, state: { from: location } }} />);
    },
    [authService.isAuthorized]
  );
  const DevelopementRoute = React.useCallback(
    (routeProps: RouteProps) => {
      return (process.env.NODE_ENV === 'development' ? <Route {...routeProps} /> : <Redirect to={{ pathname: '/auth-redirect', state: { from: location } }} />);
    },
    [authService.isAuthorized]
  );
  const pickThemeByCompany = React.useCallback(
    () => {
      switch (visualizationType) {
        case SupportedCompanies.TH:
          return defaultTheme;
        case SupportedCompanies.EX:
          return defaultTheme;
        case SupportedCompanies.GU:
          return rabenTheme;
        case SupportedCompanies.EDU:
          return rabenTheme;
        case SupportedCompanies.PP:
          return pepcoTheme;
        default:
          return defaultTheme;
      }
    }, [visualizationType]
  );
  const GuestRoute = React.useCallback(
    (routeProps: RouteProps) => {
      return (
        authService.isAuthorized ?
          <Redirect to={'/user'} />
          :
          <Route {...routeProps} />);
    },
    [authService.isAuthorized]
  );

  const prepareModule = React.useCallback(
    (module: IModule) => () => {
      const { module: ModuleComponent, title, name, routeAuthorizationProps } = module;
      if (history.location.pathname === module.routeProps.path) {
        document.title = `${Config.APP_TITLE} - ${t(title)}`;
      }
      dispatch(setCurrentModule(name));
      if (routeAuthorizationProps?.roleGuard || routeAuthorizationProps?.claimGuard) {
        const [hasRoles, hasClaims] = [
          authService.hasRoles(routeAuthorizationProps.roleGuard || []),
          authService.hasClaims(routeAuthorizationProps.claimGuard || [])
        ];
        if (!(hasRoles && hasClaims)) {
          return <ErrorView error={Errors.AUTH_ERROR} />
        }
      }
      return (
        <ModuleComponent />
      );
    },
    [authService, dispatch, t]
  );

  const Modules = React.memo(() => {
    // const mm = [modules[0], modules[1]];
    if (!coreServicesReady)
      return null;
    return (
      <ThemeProvider theme={themeBySettings ? themeBySettings : pickThemeByCompany()}>
        <Switch>
          {modules.map(module => {
          // {mm.map(module => {
            return <AuthorizedRoute key={module.name} {...module.routeProps} component={prepareModule(module)} />;
          })}
          <GuestRoute key="externalLoginRedirect" path={'/auth-redirect'} component={IFrameExternalLogin} />
          <DevelopementRoute key="authAdminView" exact path={'/auth-admin'} component={AuthAdminView} />
          <Route key="authView" path={'/auth'} component={AuthView} />
          <Route key="signout" path={'/signout'} component={SignOutView} />
          <Route key="globalPage" path={'/global-page/:id'} component={() => <GlobalPage />} />
          <Route key="redirectToHome" exact path={'/'} render={() => <Redirect to={'/user'} />} />
          <Route key="IE" path={'/ie'} component={() => <ErrorView error={Errors.IE_NOT_SUPPORTED} />} />
          <Route key="blocked" path={'/blocked'} component={BlockedView} />
          <Route key="notSupported" path={'/not-supported'} component={() => <ErrorView error={Errors.NOT_SUPPORTED} />} />
          <Route key="errorView" component={ErrorView} />
        </Switch>
      </ThemeProvider>
    );
  });

  return {
    Modules: withSuspense(Modules)
  };

};
