export enum AdminModuleTableViews {
  USERS = 'USERS',
  GROUPS = 'GROUPS',
  DASHBOARD_USERS = 'DASHBOARD_USERS',
  QUIZZES = 'QUIZZES',
  QUIZZES_ANSWERS = 'QUIZZES_ANSWERS',
  QUIZZES_ANSWERS_TO_REVIEW = 'QUIZZES_ANSWERS_TO_REVIEW',
  QUESTS = 'QUESTS',
  NEWS = 'NEWS',
  EMAIL_TEMPLATES = 'EMAIL_TEMPLATES',
  FILES = 'FILES',
  KNOWLEDGE_PAGES = 'KNOWLEDGE_PAGES',
  QUESTS_ANSWERS = 'QUESTS_ANSWERS',
  ROLES = 'ROLES',
  FLOWS = 'FLOWS',
  ACTIVITES = 'ACTIVITES',
  ACTIVITIES_IMPORT_HISTORY = 'ACTIVITIES_IMPORT_HISTORY',
  KPIS = 'KPIS',
  KPI_ASSIGNMENTS = 'KPI_ASSIGNMENTS',
  WORKER_GROUPS = 'WORKER_GROUPS',
  SURVEYS = 'SURVEYS',
  TRAINING_REQUESTS = 'TRAINING_REQUESTS',
  TRAINING_BUDGET = 'TRAINING_BUDGET',
  FAQ = 'FAQ',
  SUGGESTIONS = 'SUGGESTIONS'
}
