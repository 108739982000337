export enum Errors {
  NETWORK_ERROR = 'NETWORK_ERROR',
  RESPONSE_ERROR = 'RESPONSE_ERROR',
  REQUEST_ERROR = 'REQUEST_ERROR',
  FATAL_ERROR = 'FATAL_ERROR',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  AUTH_ERROR = 'AUTH_ERROR',
  CLAIM_ERROR = 'CLAIM_ERROR',
  IE_NOT_SUPPORTED = 'IE_NOT_SUPPORTED',
}
