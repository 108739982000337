import React from 'react';
import i18n from 'i18n';
import clsx from 'clsx'
import { useSelector } from 'react-redux';

import store from 'modules/core/store';
import { ILanguage } from 'interfaces/language-user.interface';
import { makeStyles } from '@material-ui/core/styles';

import { getService, LangService, UsersService } from 'modules/core/services';
import { setShowLoader, setUserProfile } from 'modules/core/store/actions';
import {  userProfileSelector } from 'modules/core/store/selectors';
import PL from 'assets/img/ico_flag_pl.png';
import EN from 'assets/img/ico_flag_en.png';
import LT from 'assets/img/ico_flag_lt.png';
import LV from 'assets/img/ico_flag_lv.png';
import SR from 'assets/img/ico_flag_sr.png';
import SL from 'assets/img/ico_flag_sl.png';
import SK from 'assets/img/ico_flag_sk.png';
import CS from 'assets/img/ico_flag_cs.png';
import RO from 'assets/img/ico_flag_ro.png';
import HU from 'assets/img/ico_flag_hu.png';
import DE from 'assets/img/ico_flag_de.png';
import ES from 'assets/img/ico_flag_es.png';
import HR from 'assets/img/ico_flag_hr.png';
import ET from 'assets/img/ico_flag_et.png';
import IT from 'assets/img/ico_flag_it.png';
import BG from 'assets/img/ico_flag_bg.png';
import { getLangByConfig } from 'utils/lang-utils';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px'
  },
  button: {
    background: 'none',
    borderRadius: '100%',
    width: '32px',
    height: '32px',
    padding: '0',
    overflow: 'hidden',
    marginRight: '10px',
    border: '1px solid #CCC',
    filter: 'grayscale(100%)',
    '&:last-child': {
      marginRight: 0
    },
    '&.active': {
      filter: 'none'
    }
  },
  icon: {
    maxWidth: '100%'
  }
}));

export interface INavListProps {
}

export const LanguageDropdown: React.FC<INavListProps> = () => {
  const classes = makeClasses();
  const userService = getService(UsersService);
  const langService = getService(LangService);
  const userProfile = useSelector(userProfileSelector);
  const [currentLang, setCurrentLang] = React.useState<ILanguage | undefined>(undefined);
  const [currentLangs, setCurrentLangs] = React.useState<ILanguage[] | undefined>(undefined);
  const onSelect = (lang: string) => {
    if (currentLangs) {
      const languages = currentLangs.filter(language => language.name === lang)
      // store.dispatch(setShowLoader(true));
      setCurrentLang(languages[0])
    }
  }

  React.useEffect(() => {
    if (userProfile) {
      langService.fetchAllLanguages().then((languages: ILanguage[]) => {
        setCurrentLangs(languages)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.language])

  React.useEffect(() => {
    if (userProfile && currentLang) {
      const changedProfile = {...userProfile, language: currentLang.name }
      userService.updateUserLanguage(userProfile.id, currentLang.id, currentLang.name).then(() => {
        store.dispatch(
          setUserProfile(changedProfile)
        );
        i18n.changeLanguage(getLangByConfig(currentLang.name));
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang])


  return (
    <div className={classes.root}>
      {currentLangs?.find((value) => value.name === 'pl') && (
        <button className={clsx(classes.button, userProfile?.language === 'pl' ? 'active' : '')} type="button"  onClick={() => {onSelect('pl')}}>
          <img className={classes.icon} src={PL} alt="Polish" />
        </button>
      )}
      {currentLangs?.find((value) => value.name === 'en') && (
      <button className={clsx(classes.button, userProfile?.language === 'en' ? 'active' : '')} type="button" onClick={() => {onSelect('en')}}>
        <img className={classes.icon} src={EN} alt="English" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'lt') && (
      <button className={clsx(classes.button, userProfile?.language === 'lt' ? 'active' : '')} type="button" onClick={() => {onSelect('lt')}}>
        <img className={classes.icon} src={LT} alt="Lithuanian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'lv') && (
      <button className={clsx(classes.button, userProfile?.language === 'lv' ? 'active' : '')} type="button" onClick={() => {onSelect('lv')}}>
        <img className={classes.icon} src={LV} alt="Latvian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'sr') && (
      <button className={clsx(classes.button, userProfile?.language === 'sr' ? 'active' : '')} type="button" onClick={() => {onSelect('sr')}}>
        <img className={classes.icon} src={SR} alt="Serbian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'sl') && (
      <button className={clsx(classes.button, userProfile?.language === 'sl' ? 'active' : '')} type="button" onClick={() => {onSelect('sl')}}>
        <img className={classes.icon} src={SL} alt="Slovenian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'sk') && (
      <button className={clsx(classes.button, userProfile?.language === 'sk' ? 'active' : '')} type="button" onClick={() => {onSelect('sk')}}>
        <img className={classes.icon} src={SK} alt="Slovak" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'cs') && (
      <button className={clsx(classes.button, userProfile?.language === 'cs' ? 'active' : '')} type="button" onClick={() => {onSelect('cs')}}>
        <img className={classes.icon} src={CS} alt="Czech" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'ro') && (
      <button className={clsx(classes.button, userProfile?.language === 'ro' ? 'active' : '')} type="button" onClick={() => {onSelect('ro')}}>
        <img className={classes.icon} src={RO} alt="Romanian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'hu') && (
      <button className={clsx(classes.button, userProfile?.language === 'hu' ? 'active' : '')} type="button" onClick={() => {onSelect('hu')}}>
        <img className={classes.icon} src={HU} alt="Hungarian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'de') && (
      <button className={clsx(classes.button, userProfile?.language === 'de' ? 'active' : '')} type="button" onClick={() => {onSelect('de')}}>
        <img className={classes.icon} src={DE} alt="German" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'es') && (
      <button className={clsx(classes.button, userProfile?.language === 'es' ? 'active' : '')} type="button" onClick={() => {onSelect('es')}}>
        <img className={classes.icon} src={ES} alt="Spanish" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'hr') && (
      <button className={clsx(classes.button, userProfile?.language === 'hr' ? 'active' : '')} type="button" onClick={() => {onSelect('hr')}}>
        <img className={classes.icon} src={HR} alt="Croatian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'it') && (
      <button className={clsx(classes.button, userProfile?.language === 'it' ? 'active' : '')} type="button" onClick={() => {onSelect('it')}}>
        <img className={classes.icon} src={IT} alt="Italian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'et') && (
      <button className={clsx(classes.button, userProfile?.language === 'et' ? 'active' : '')} type="button" onClick={() => {onSelect('et')}}>
        <img className={classes.icon} src={ET} alt="Estonian" />
      </button>
      )}
      {currentLangs?.find((value) => value.name === 'bg') && (
      <button className={clsx(classes.button, userProfile?.language === 'bg' ? 'active' : '')} type="button" onClick={() => {onSelect('bg')}}>
        <img className={classes.icon} src={BG} alt="Bulgarian" />
      </button>
      )}
      </div>
  );
};