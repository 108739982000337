import { HttpClientService } from 'modules/core/functional-services';
import { AppService, getService } from 'modules/core/services';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { IAnswerResult, IPracticeStatistics, IQuestionDTO } from '../interfaces/q&a.interface';


export class PracticeService {
  constructor(private appService = getService(AppService), private httpClientService = getService(HttpClientService)) {
  }

  async getQuestion(roundId: string) {
    try {
      const { value }: IAPIResponse<IQuestionDTO> = await this.httpClientService.get(`/training/questions/draw-question/${roundId}`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async getAnswer(questionId: string, answerId: string) {
    try {
      const { value }: IAPIResponse<IAnswerResult> = await this.httpClientService.get(`/training/answers/answer-question/${questionId}/${answerId}`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getStatistics() {
    try {
      const { value }: IAPIResponse<IPracticeStatistics> = await this.httpClientService.get(`/training/answers/statistics`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

}
