import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { defaultTheme } from 'themes/default-theme';
import clsx from 'clsx';

const makeClasses = makeStyles<DefaultTheme, { bullets: boolean, right: boolean }>(theme => ({
  root: {
    ...theme.mixins.fonts.regular.xs,
  },

  ul: {
    paddingLeft: props => props.bullets ? '0 !important' : '1.5em',
    listStyle: 'none',
    '& > li > a:visited': {
      color: 'rgb(0, 0, 238)'
    },
    '& > li': {
      paddingLeft: props => props.bullets ? '0 !important' : '1.5em',
    },
    '& > li:before': {
      position: 'absolute',
      display: props => props.right ? 'none' : props.bullets ? 'none' : 'inline-block',
      fontWeight: 'bold',
      fontSize: 29,
      content: `"\\2022"`,  /* \2022 is the CSS Code/unicode for a bullet */
      color: defaultTheme.palette.secondary.main,
      width: theme.spacing(1.5),
      marginLeft: -theme.spacing(2) - 1,
      marginTop: 1,
    },
    '& > li:after': {
      position: 'absolute',
      display: props => props.right ? props.bullets ? 'none' : 'inline-block' : 'none',
      fontWeight: 'bold',
      fontSize: 29,
      content: `"\\2022"`,  /* \2022 is the CSS Code/unicode for a bullet */
      color: defaultTheme.palette.secondary.main,
      width: theme.spacing(1.5),
      marginLeft: -theme.spacing(2) - 1,
      marginTop: 1,
    },
  }
}));

export interface ListProps {
  type?: 'ordered' | 'unordered';
  hideBullets?: boolean;
  alignRight?: boolean;
  className?: string;
}

export const List: React.FC<React.PropsWithChildren<ListProps>> = (props) => {
  const { type, hideBullets, alignRight, className, children } = props
  const hidingBullets = hideBullets !== undefined ? hideBullets : false
  const aligningRight = alignRight !== undefined ? alignRight : false
  const classes = makeClasses({ bullets: hidingBullets, right: aligningRight });
  if (type === 'ordered') {
    return (
      <ol
        className={clsx(classes.root, className)}
      >
        {children}
      </ol>
    );
  }
  return (
    <ul
      className={clsx(classes.root, classes.ul, className)}
    >
      {children}
    </ul>
  );
};
