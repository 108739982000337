import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { BACKGROUND } from 'themes/utils/background-utils';
import { showApiErrors } from 'utils/show-errors';
import { useService, useIcons, IconsNames } from 'modules/core/hooks';
import { RankingService } from 'modules/user/services/ranking.service';
import { useSnackbar } from 'notistack';
import { FeedCardPepco } from '../../layout/feed-card/feed-card-pepco';
import { ApiError } from 'utils/api-error';
import { PepcoRankingelement } from './pepco-ranking-element';
import { IRankingSummary } from 'modules/user/interfaces/ranking.interface';
import { format } from 'date-fns'
import { TABLE_DATE_TIME_FORMAT } from 'utils/date-utils';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    paddingTop: '8px',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  icon: {
    width: theme.icons.sizes.medium
  },
  noResults: {
    color: theme.palette.common.black
  }
}));

export interface IRankingList {
  id: string;
  rank?: number;
  avatar?: string;
  name: string;
  points: number;
  index?: number;
  isGroup?: boolean;
}

export const RankingFeedPepco = () => {
  const { t } = useTranslation();
  const rankingService = useService(RankingService);
  const [rankingList, setRankingList] = React.useState<IRankingSummary[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [groupName, setGroupName] = React.useState<string>();
  const [calculatedOn, setCalculatedOn] = React.useState<string | undefined>(undefined);

  const classes = makeClasses();
  const trophyIcon = useIcons(
    IconsNames.CUP,
    classes.icon
  );

  const getRegionRanking = async () => {
    try {
      const response = await rankingService.getRankingSummariesIndividual(2, 5)
      if (response) {
        setGroupName(response.totalSummaries[0].groupName);
        setCalculatedOn(response.totalSummaries[0].calculatedOn);
        setRankingList(response.totalSummaries[0].summaries);
      }
    } catch (e) {
      showApiErrors(e as ApiError, enqueueSnackbar);
    }
  }

  useEffect(
    () => {
      getRegionRanking()
    }, []
  )

  return (
    <FeedCardPepco
      headingColor={BACKGROUND.NONE}
      title={t('userPanel.dashboard.rankingFeed')}
      titleIcon={() => trophyIcon}
    >
      {calculatedOn && <Typography>{t('userPanel.dashboard.lastUpdate', { date: format(new Date(calculatedOn as string), TABLE_DATE_TIME_FORMAT) ?? '' })}</Typography>}
      <div className={classes.root}>
        {rankingList.length ? rankingList.map((item, index) => item ? (
          <PepcoRankingelement key={index} name={item.name} avatarId={item.avatarId ?? ''} points={item.score} badgeId={item.badges[0] ?? ''} groupName={groupName ?? ''} />
        ) : null) : <Typography className={classes.noResults}>{t('userPanel.gameResults.noListGameResults')}</Typography>}
      </div>
    </FeedCardPepco>
  );
};
