export enum CompletionStatusTypes {
  ACTIVE = 'Active',
  APPROVE = 'Approve',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

export enum QuestTypes {
  INDIVIDUAL = 'Individual',
  GROUP = 'Group'
}

export enum QuestDisplayType {
  SHOW_BOTH = 0,
  ONLY_TEXTFIELD ,
  ONLY_UPLOADER,
}

export interface IUserQuest {
  id: string;
  name: string;
  isExternal: boolean;
  description: string;
  type: QuestTypes;
  attempts: number;
  tokens: number;
  hint?: string;
  questDisplayType?: QuestDisplayType;
  isConsent?: boolean;
}

export interface IQuest {
  id: string;
  roundId: string;
  userId: string;
  groupId: string;
  endDescription: string;
  completionStatus: CompletionStatusTypes;
  completionFile: string;
  quest: IUserQuest;
  rejectReason?: string;
  history?: IQuest[];
  attempts: number;
  currentAttempt: number;
}
