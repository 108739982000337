import React from 'react';
import { useService } from '../../../hooks';
import { AuthService } from '../../../services';

export const useRoleGuard = (roles: string[]) => {
  const authService = useService(AuthService);
  return [
    authService.hasRoles(roles)
  ]
};

export interface IRoleGuardProps {
  roles: string[],
  invert?: boolean
}

export type RoleGuardType = React.FC<IRoleGuardProps>

export const RoleGuard: RoleGuardType = (props) => {
  const { roles, invert, children } = props;
  const [render] = useRoleGuard(roles);
  if ((render && !invert) || (!render && invert)) {
    return children as JSX.Element;
  }
  return null;
};