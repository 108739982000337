import { HttpClientService } from 'modules/core/functional-services';
import { AppService, getService } from 'modules/core/services';
import { IAPIResponse } from 'interfaces/api-response.interface';
import {
  IAnswerDuelQuestionCommand, IAnswerDuelResult, IAnswerResult,
  IGetDuelsResult,
  IGetMyDuelResult,
  IMyDuelStatistics,
  IQuestionDTO
} from '../interfaces/q&a.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { ITotalDuels } from '../interfaces/duels.interface';


export class DuelsService {
  constructor(private appService = getService(AppService), private httpClientService = getService(HttpClientService)) {
  }

  async getMyDuels(pagination?: IPagination) {
    try {
      const { value }: IAPIResponse<IGetDuelsResult> = await this.httpClientService.get('/duels/my-duels', {
        params: pagination
      });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async getMyDuelsStatistics() {
    try {
      const { value }: IAPIResponse<IMyDuelStatistics> = await this.httpClientService.get('/duels/my-duels-statistics');
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getMyDuel(duelID?: string) {
    try {
      const { value }: IAPIResponse<IGetMyDuelResult> = await this.httpClientService.get('/duels/my-duel', {
        params: {
          DuelId: duelID,
        }
      });
      return value;
    } catch (e) {
      // commented out to hide error on login
      // return this.appService.handleError(e);
    }
  };

  async drawQuestion(duelID: string) {
    try {
      const { value }: IAPIResponse<IQuestionDTO> = await this.httpClientService.get(`/duels/questions/draw-question/${duelID}`);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  async answerQuestion(answer: IAnswerDuelQuestionCommand) {
    try {
      const value = await this.httpClientService.post<IAnswerDuelQuestionCommand, IAnswerDuelResult>('duels/answers', answer);
      return value;
    }
    catch (e) {
      return this.appService.handleError(e);
    }
  };

  async timeoutQuestion(duelId: string) {
    try {
      const value = await this.httpClientService.post('duels/answers/timeout-last-drawn-question', { duelId });
      return value;
    }
    catch (e) {
      return this.appService.handleError(e);
    }
  }
  async getTotalDuelCount() {
    try {
      const { value }: IAPIResponse<ITotalDuels> = await this.httpClientService.get('duels/all-duels-statistics');
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
}
