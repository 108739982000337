import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useSearchParams, useService } from 'modules/core/hooks';
import { LoaderView } from 'modules/core/views';
import { AuthService } from 'modules/core/services';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Config, SupportedCompanies } from 'config';
// import { ExternalLoginError } from 'modules/core/views/auth/external-login-error';

export interface IAuthData {
  name: string;
  password: string;
}

export interface ILoginViewProps { }

export const AuthView = (props: ILoginViewProps & RouteComponentProps) => {
  const params = useSearchParams();
  const authToken = params.get('token');
  const authService = useService(AuthService);
  const history = useHistory();
  // const [externalLoginError, setExternalLoginError] = React.useState(false);
  // const [silentLogin, setSilentLogin] = React.useState(false);
  const { t } = useTranslation();
  // console.log('2.4 AuthView render!', props);

  const silentExternalLogin = async () => {
    try {
      // clear query params in url
      // window.history.pushState(null, '', '/');
      if (authToken) {
        // console.log('2.0 silentExternalLogin try', authToken);
        // await authService.logout();
        await authService.login(authToken);
        if (authService.isAuthorized) {
          history.replace('/user');
        }
      }
    } catch (e) {
      // console.log('2.1 silentExternalLogin catch');
      // there was an error with singing in with the provided token. Something has gone wrong.
      await authService.logout(); // clear the token from the cache
      // setExternalLoginError(true);
      // setSilentLogin(false);
    }
  };

  React.useEffect(() => {
    // if (!silentLogin) {
      // setSilentLogin(true);
      silentExternalLogin();
    // }
  }, []);

  // if (externalLoginError) {
  //   return <ExternalLoginError />;
  // }
// console.log('jest token?!', authToken);
  if (authToken) {
    return <LoaderView background="white" text={t('common.loading')} visible />;
  } else {
  // } else if (process.env.NODE_ENV !== 'development') {
  //   // if no auth token and the current company is GU, we don't want to show them the in-app login screen,
  //   // as they can login only with microsoft account by external login screen.
    return <Redirect to={`/auth-redirect?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`} />;
  }

  return <Redirect to="/user/" />
};
