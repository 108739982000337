export interface FlatChildrenObjectType<T> {
  children?: FlatChildrenObjectType<T>[],
}

export type FlatChildrenElem<T> = FlatChildrenObjectType<T> & T;

export const flatChildren = <T extends any, O = T>(
  elements: FlatChildrenElem<T>[],
  propertyName = 'children'
  ): O[] => {
  const output = [];
  for (const element of elements) {
    if (element[propertyName] && element[propertyName].length) {
      output.push({ ...element }, ...flatChildren(element[propertyName], propertyName));
    } else {
      output.push(element);
    }
  }
  return Array.from(new Set(output));
};
