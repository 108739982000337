import { getService, AppService } from 'modules/core/services';
import { HttpClientService } from 'modules/core/functional-services';
import { IKPITaskDto, IKpiTaskAssignmentTask } from 'interfaces/kpi.interface';
import { IPagedResponse } from 'interfaces/paged-data.interface';
import { IPagination } from 'interfaces/pagination.interface';
import { APIPaths } from 'config/paths';

export class KpiService {
  constructor(
    private appService = getService(AppService),
    private httpClientService = getService(HttpClientService)) {
  }

  async getAssignmentsByUser(pagination: IPagination, roundId?: string) {
    try {
      const { value } = await this.httpClientService.get<IPagedResponse<IKPITaskDto>>(`${APIPaths.KPI}/by-user/assignments`, {
        params: {
          ...pagination,
          roundId
        }
      });
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }
  async getAssignedTasks(assignmentId: string, pagination?: IPagination) {
    try {
      const { value } = await this.httpClientService.get<IPagedResponse<IKpiTaskAssignmentTask>>(`${APIPaths.KPI}/assignments/${assignmentId}/tasks`, {
        params: {
          ...pagination
        }
      })
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

}