import { IAPIResponse } from 'interfaces/api-response.interface';
import { IStaticPage, IStaticPages } from 'interfaces/static-pages.interface';
import store from 'modules/core/store';
import { setStaticPages } from 'modules/core/store/actions';
import { APIPaths } from 'config/paths';
import { CoreService } from 'modules/core/services/core.service';

export class StaticPagesService extends CoreService{


  async getStaticPages() {
    try {
      const { value }: IAPIResponse<IStaticPages[]> = await this.httpClientService.get(APIPaths.STATIC_PAGES);
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  applyStaticPages(staticPagesList: IStaticPages[]) {
    store.dispatch(setStaticPages(staticPagesList));
  }

  async getStaticPageById(staticPageId: string) {
    try {
      const { value }: IAPIResponse<IStaticPage> = await this.httpClientService.get(`${APIPaths.STATIC_PAGES}/${staticPageId}`);
      return value;
    } catch (e) {
      throw (e)
    }
  }
}
