import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import clsx from 'clsx';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  root: {
    ...theme.mixins.fonts.regular.xs,
  },
}));

export const Paragraph: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>> = (props) => {
  const classes = makeClasses();
  return (
    <p
      {...props}
      className={clsx(classes.root, props.className)}
    />
  );
};
