import React from 'react';
import { useSelector } from 'react-redux';
import { scoreListElementsSelector } from 'modules/core/store/selectors';
import { ResponsiveModal } from 'modules/core/components/layout';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

import {
  DialogActions,
  Button,
} from '@material-ui/core';
import { MyResultTable } from 'modules/user/components/my-results/my-result-table/my-result-table';

const makeClasses = makeStyles<DefaultTheme, { short: boolean }>(theme => ({
  container: {
    width: '100%'
  },
  wrapper: {
    paddingTop: '30px'
  }
}))

export interface UserPointsDialogProps {
  open: boolean,
  onClose: () => void,
  userId: string,
}

export const UserPointsDialog = (props: UserPointsDialogProps) => {
  const { t } = useTranslation();
  const scoreListElements = useSelector(scoreListElementsSelector);

  const classes = makeClasses({
    short: scoreListElements && scoreListElements.hidePointsOnUserScoresList
  });

  return (
    <ResponsiveModal
      open={props.open}
      onClose={props.onClose}
      maxWidth='lg'
    >
      <div className={classes.wrapper}>
        <MyResultTable
          isHeaderSticky
          tableHeight='50vh'
          userId={props.userId}
        />
      </div>

      <DialogActions>
        <Button onClick={props.onClose}>{t('common.close')}</Button>
      </DialogActions>
    </ResponsiveModal>

  )
}