import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

export enum BACKGROUND {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  NONE,
}

export const resolveBackground = (theme: DefaultTheme, backgroundType?: BACKGROUND) => {
  switch (backgroundType) {
    case BACKGROUND.PRIMARY:
      return theme.backgrounds.primary;
    case BACKGROUND.SECONDARY:
      return theme.backgrounds.secondary;
    case BACKGROUND.TERTIARY:
      return theme.backgrounds.tertiary;
    case BACKGROUND.NONE:
      return theme.backgrounds.none;
    default:
      return theme.backgrounds.primary;
  }
};
