import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { Roles } from 'const/roles';
import { RoleGuard, ClaimedRender } from '../../base';
import { AdminUsersService } from '../../../../admin/service/admin.users.service';
import { Button, ButtonBase, makeStyles, Popover, Theme, Typography } from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useService } from 'modules/core/hooks';
import { AuthService, UsersService } from 'modules/core/services';
import { AvatarInput } from 'modules/core/components/common';
import { LanguageDropdown, NavSex } from './index';
import { _refButton } from 'styles/utils';
import { showApiErrors } from 'utils/show-errors';
import { userAvatarURLSelector, userProfileSelector, feVisualizationType, adminRouteSettings, userPanelElements } from 'modules/core/store/selectors';
import { SupportedCompanies } from 'config/config.const';

const makeClasses = makeStyles<Theme, { borderTopWidth: number; popoverIsOpen: boolean }>(theme => ({
  navUser: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  navButton: {
    display: 'flex',
    alignItems: 'center',
    height: '81px',
    padding: theme.spacing(0, 3.5),
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    '& > svg': {
      marginLeft: theme.spacing(1.25)
    },
    '&:focus': {
      outline: '0px'
    }
  },
  navButtonIcon: {
    overflow: 'hidden',
    marginRight: theme.spacing(1.25),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  navButtonIconPepco: {
    overflow: 'hidden',
    marginLeft: theme.spacing(1.25),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  popover: {
    overflow: 'auto',
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
    padding: theme.spacing(3.5, 2.5),
    minWidth: '300px',
    maxWidth: '350px',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '230px',
      minWidth: '230px'
    }
  },
  borderTop: {
    position: 'absolute',
    top: '-1px',
    right: 0,
    width: props => props.borderTopWidth - 2,
    height: '1px',
    background: theme.palette.common.white
  },
  heading: {
    color: '#2D2D2D',
    margin: '0 0 5px',
    font: 'Bold 15px/24px Roboto'
  },
  changeAvatarIcon: {
    overflow: 'hidden',
    margin: '0 auto',
    width: '84px',
    height: '84px',
    borderRadius: '50%',
    '& img': {
      maxWidth: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  changeAvatarButton: {
    ..._refButton,
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(2.5)}px auto`,
    color: 'rgba(0, 0, 0, 0.54)',
    textDecoration: 'none'
  },
  changeAvatarEditIcon: {
    marginLeft: theme.spacing(1.25),
    width: '18px',
    height: '18px',
    border: '2px solid #666666',
    borderRadius: '3px'
  },
  rootInputFieldText: {
    fontSize: 12,
    textAlign: 'right',
    margin: theme.spacing(-2, 0, 2.5)
  },
  lostPasswordButton: {
    ..._refButton
  },
  controlButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  button: {
    width: '45%',
    maxHeight: '40px',
    font: 'Bold 15px/24px Roboto'
  },
  buttonBack: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid lightgrey'
  },
  buttonLogout: {
    width: '100%'
  },
  appContextLink: {
    textDecoration: 'none'
  },
  buttonAppContext: {
    width: '100%',
    marginBottom: `${theme.spacing(1.5)}px`
  },
  switchWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2.5)
  }
}));

export type NavType = 'user' | 'admin';

interface INavUserProps {
  type: NavType;
}


export const NavUser: React.FC<INavUserProps> = (props: INavUserProps) => {
  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const visualizationType = useSelector(feVisualizationType)
  const adminRoute = useSelector(adminRouteSettings)
  const userPanelSettings = useSelector(userPanelElements);
  const open = Boolean(anchor);
  const [borderTopWidth, setBorderTopWidth] = React.useState(0);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [avatarFile, setAvatarFile] = React.useState<File>();
  const [uploadingAvatar, setUploadingAvatar] = React.useState(false)
  const history = useHistory();
  const [switchPersonalData, setSwitchPersonalData] = React.useState(false);
  const authService = useService(AuthService);
  const userService = useService(UsersService);
  const adminUsersService = useService(AdminUsersService);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const currentAvatar = useSelector(userAvatarURLSelector);
  const currentUser = useSelector(userProfileSelector);

  const calcBorderTopWidth = () => {
    if (buttonRef && buttonRef.current) {
      const width = buttonRef.current.offsetWidth;
      setBorderTopWidth(width);
    }
  };

  const classes = makeClasses({
    borderTopWidth,
    popoverIsOpen: open
  });

  const openMenu = (e: React.MouseEvent) => {
    setAnchor(e.currentTarget);
  };

  const changePersonaDataVisibility = async () => {
    if (currentUser?.id) {
      setSwitchPersonalData(true);
      try {
        await adminUsersService.editUserPersonalData({
          name: currentUser.name,
          surname: currentUser.surname,
          hidePersonalData: !currentUser.hidePersonalData
        });
        userService.applyUserProfile({
          ...currentUser,
          hidePersonalData: !currentUser.hidePersonalData
        });
        setSwitchPersonalData(false);
      } catch (e) {
        showApiErrors(e, enqueueSnackbar);
      }
    }
  };

  const logout = async () => {
    await authService.logout();
    if (visualizationType === SupportedCompanies.EDU) {
      authService.externalSignOutRedirect();
    } else {
      history.push(`/auth-redirect?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
    }
  };

  const onSetAvatarFile = useCallback((fileToSet: File) => {
    setAvatarFile(fileToSet);
  }, []);


  const handleCancelAvatar = () => {
    setAvatarFile(undefined);
  };

  const handleSaveButton = async () => {
    // Changing avatar
    if (avatarFile) {
      try {
        const hasUserAvatarBeenChanged = await userService.updateAvatar(avatarFile);
        if (hasUserAvatarBeenChanged) {
          enqueueSnackbar(t('userPanel.header.successfullyAvatarChanged'), {
            variant: 'success'
          });
        } else {
          enqueueSnackbar(t('common.problemWithSendingFile'), {
            variant: 'error'
          });
        }
      } catch (e) {
        showApiErrors(e, enqueueSnackbar);
      } finally {
        setAvatarFile(undefined);
      }
    }
  };

  const getAvatarAd = async () => {
    setUploadingAvatar(true)
    try {
      const { success } = await userService.getAvatarAd();
      if (success) {
        const profile = await userService.fetchUserProfile()
        userService.applyUserProfile(profile)
        try {
          await userService.applyUserAvatarURL();
          enqueueSnackbar(t('userPanel.myAccount.avatarChanged'), {
            variant: 'success'
          })
        } catch (e) {
          showApiErrors(e, enqueueSnackbar)
        } finally {
          setUploadingAvatar(false)
        }
      } else {
        enqueueSnackbar(t('userPanel.header.failedDownloadAvatar'), {
          variant: 'error'
        })
      }
    } catch (e) {
      enqueueSnackbar(t('userPanel.header.failedDownloadAvatar'), {
        variant: 'error'
      })
    } finally {
      setUploadingAvatar(false)
    }
  }


  return (
    <div className={classes.navUser}>
      {visualizationType === SupportedCompanies.PP &&
      <ButtonBase ref={buttonRef} onClick={openMenu} className={classes.navButton}>
        <Typography noWrap variant="body2">
          {currentUser?.name} {currentUser?.surname}
        </Typography>
        <div className={classes.navButtonIconPepco}>
          <img src={currentAvatar ? currentAvatar : '/static/img/empty_avatar.png'} alt="" />
        </div>
        <ArrowDropDownIcon />
      </ButtonBase>
      }
      {visualizationType !== SupportedCompanies.PP &&
      <ButtonBase ref={buttonRef} onClick={openMenu} className={classes.navButton}>
        <div className={classes.navButtonIcon}>
          <img src={currentAvatar ? currentAvatar : '/static/img/empty_avatar.png'} alt="" />
        </div>
        <Typography noWrap variant="body2">
          {t('userPanel.myAccount.myAccount')}
        </Typography>
        <ArrowDropDownIcon />
      </ButtonBase>
      }
      <Popover
        open={open}
        onEntered={() => calcBorderTopWidth()}
        onClose={() => setAnchor(null)}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        marginThreshold={0}
        classes={{
          paper: classes.popover
        }}
      >
        {userPanelSettings.changeLanguage && (
          <ClaimedRender claims={['Permission.Settings.Languages.DisplayLanguages']}>
            <LanguageDropdown />
          </ClaimedRender>
        )}
        {userPanelSettings.hidePersonalData && (
          <div className={classes.switchWrapper}>
            <h2 className={classes.heading}>
              <b>{t('userPanel.myAccount.hidePersonalData')}</b>
            </h2>
            <Switch
              color={'primary'}
              checked={currentUser?.hidePersonalData}
              onChange={changePersonaDataVisibility}
              disabled={switchPersonalData}
            />
          </div>
        )}
        <span className={classes.borderTop} />
        {(userPanelSettings.changeAvatar || userPanelSettings.sex) && (<h2 className={classes.heading}>
          <b>{t('userPanel.myAccount.myAccount')}</b>
        </h2>)}
        {userPanelSettings.changeAvatar && (
          <AvatarInput
            avatarImage={
              avatarFile
                ? URL.createObjectURL(avatarFile)
                : currentAvatar
                ? currentAvatar
                : '/static/img/empty_avatar.png'
            }
            onUpload={onSetAvatarFile}
            avatarFile={avatarFile}
            handleSave={handleSaveButton}
            handleCancel={handleCancelAvatar}
          />
        )}
        {userPanelSettings.sex && (
          <NavSex />
        )}
        {userPanelSettings.avatarAd && (
          <Button
            style={{ width: '100%', marginBottom: '12px'}}
            color='primary'
            variant={'contained'}
            className={classes.button}
            disabled={uploadingAvatar}
            onClick={() => getAvatarAd()}>
            {uploadingAvatar ?
              t('common.loading') :
              t('userPanel.myAccount.uploadAvatar')}
            {uploadingAvatar && <CircularProgress style={{ marginLeft: '8px' }} size={'16px'}/>}
          </Button>
        )}
        <RoleGuard roles={[Roles.Admin.name]}>
          {props.type === 'admin'
            ? (
              <Link to="/user" className={classes.appContextLink}>
                <Button
                  className={clsx(classes.button, classes.buttonAppContext)}
                  variant="contained"
                  color='primary'
                >
                  {t('adminPanel.common.userPanel')}
                </Button>
              </Link>
            ) : (
              <Link to={`/admin${(adminRoute && adminRoute !== 'null') ? adminRoute : '/users-list'}`} className={classes.appContextLink}>
                <Button
                  className={clsx(classes.button, classes.buttonAppContext)}
                  variant="contained"
                  color='primary'
                >
                  {t('adminPanel.common.adminPanel')}
                </Button>
              </Link>
            )}
        </RoleGuard>
        <Button color='primary' className={clsx(classes.button, classes.buttonLogout)} variant="contained" onClick={logout}>
          {t('common.logout')}
        </Button>
      </Popover>
    </div>
  );
};
