import { Reducer } from 'react';
import { Actions, MyTableActions } from '../actions';
import { IMyTableRow } from '../../interfaces';
import { IPagination } from 'interfaces/pagination.interface';

export type MyTableStateCachedProp = {
  [uuid: string]: IMyTableRow | null;
}

export interface IMyTableState {
  pagination?: IPagination, // Pagination, filtering and sort type, field
  data: IMyTableRow[];
  cached: MyTableStateCachedProp,
  expandedRows: string[],
  checkedRows: string[],
}

export const initialState: IMyTableState = {
  data: [],
  pagination: undefined,
  cached: {},
  expandedRows: [],
  checkedRows: []
};

export const reducer: Reducer<IMyTableState, Actions> = (state = initialState, action: Actions): IMyTableState => {
  switch (action.type) {

    case MyTableActions.APPLY_CACHED_ROW: {
      return {
        ...state,
        data: state.data.map(row => {
          if (row.__table.uuid === action.payload.row.__table.uuid) {
            return action.payload.row;
          }
          return row;
        }),
        cached: {
          ...state.cached,
          [action.payload.row.__table.uuid]: null,
        }
      }
    }

    case MyTableActions.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.payload.pagination
      };
    }

    case MyTableActions.SET_CHECKED:
      return {
        ...state,
        checkedRows: action.payload.checkedRows
      };

    case MyTableActions.SET_EXPANDED:
      return {
        ...state,
        expandedRows: action.payload.expandedRows
      };

    case MyTableActions.SET_CACHED_ROW:
      return {
        ...state,
        cached: {
          ...state.cached,
          [action.payload.uuid]: action.payload.data && { ...action.payload.data }
        }
      };

    case MyTableActions.SET_DATA:
      return {
        ...state,
        data: [...action.payload.data]
      };

    default:
      throw new Error('Undefiend action');
  }
};
