import { Reducer } from 'redux';
import { Actions, QuestsModuleActions } from '../actions/quests.actions';
import { CompletionStatusTypes, IQuest, QuestTypes } from '../../interfaces/quest.interface';

export const questsModuleStoreInitialState: IQuest[] = [
  {
    id: '',
    completionFile: '',
    completionStatus: CompletionStatusTypes.ACTIVE,
    endDescription: '',
    groupId: '',
    roundId: '',
    userId: '',
    attempts: 0,
    currentAttempt: 0,
    quest: {
      attempts: -1,
      description: '',
      id: '',
      isExternal: false,
      name: '',
      tokens: -1,
      type: QuestTypes.INDIVIDUAL
    }
  }
];

export const questsModuleStoreReducer: Reducer<IQuest[], Actions> = (
  state: IQuest[] = questsModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case QuestsModuleActions.SET_QUESTS:
      return {
        ...state,
        quests: payload
      };
    default:
      return state;
  }
};

export default questsModuleStoreInitialState;
