import { Reducer } from 'redux';
import { Actions, TasksModuleActions } from '../actions/tasks.actions';

import { ITasks } from '../../interfaces/task.interface';

export const tasksModuleStoreInitialState: IListedTasks = { list: [] };

export interface IListedTasks {
  list: ITasks[],
}

export const tasksModuleStoreReducer: Reducer<IListedTasks, Actions> = (
  state: IListedTasks = tasksModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case TasksModuleActions.SET_TASKS:
      return {
        ...state,
        list: [...payload.tasks],
      };
    default:
      return state;
  }
};

export default tasksModuleStoreInitialState;
