import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import 'i18n';
import store from 'modules/core/store';
import { Config } from 'config';
import { App } from 'App';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { bootstrapCoreServices } from './bootstrap-services';
import MyBrowserRouter from 'browser-router';
import 'video.js/dist/video-js.min.css';

import {HTML5Backend} from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
document.title = Config.APP_TITLE;
bootstrapCoreServices();
smoothscroll.polyfill();

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DndProvider backend={HTML5Backend}>
      <MyBrowserRouter>
        <Provider store={store}>
          {/* <ThemeProvider theme={pickThemeByCompany()}> */}
            <App/>
          {/* </ThemeProvider> */}
        </Provider>
      </MyBrowserRouter>
    </DndProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
);