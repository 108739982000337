import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export interface IBadgeProps {
  color?: string;
  textColor?: string;
}

const makeClasses = makeStyles<Theme, IBadgeProps>((theme: Theme) => ({
  root: {
    backgroundColor: props => props.color ?? theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    color: props => props.textColor ?? '#fff',
    padding: '0 10px',
    minWidth: 60,
    lineHeight: '15px',
    fontSize: 14,
    textAlign: 'center'
  }
}));

export type Props = React.PropsWithChildren<IBadgeProps>;

export const Badge: React.FC<Props> = ({ children, color, textColor }) => {
  const classes = makeClasses({
    color,
    textColor
  });
  return (
    <Box className={classes.root} component="span">
      {children}
    </Box>
  );
};
