export const navigation = [
  {
    name: "tasks",
    url: "/user/tasks"
  },
  {
    name: "results",
    url: "/user/game-results"
  },
  {
    name: "knowledge",
    url: "/user/knowledge-base"
  },
  {
    name: "FAQ",
    url: "/user/faq"
  },
  {
    name: "endGame",
    url: "/user/end-game/my-statistics"
  },
  {
    name: "rules",
    url: "/user/rules"
  },
  {
    name: "videos",
    url: "/user/videos"
  },
  {
    name: "myResults",
    url: "/user/my-results"
  }
]