/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import _isEqual from 'lodash/isEqual';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useMyTableStore } from './store';
import { IMyTableHeadProps, IMyTableProps } from './interfaces';
import { actions } from './store/actions';
import { MyTableHead } from './my-table-head';
import { MyTableBody } from './my-table-body';
import { MyTableFooter } from './my-table-footer';
import { prepareRowTableData } from './helpers';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  table: {
    position: 'relative',
  },
  tableFetchCover: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000
  }
}));

export type Props = IMyTableProps;

export const MyTable: React.FC<Props> = (props: Props) => {
  const {
    checked, //
    data,
    pagination,
    columns,
    size,
    Actions,
    FocusedActions,
    events,
    options,
    classes: tableClasses,
    translations,
    extendsProps,
    overrides
  } = props;

  const store = useMyTableStore({
    options,
    events
  });

  const withActions = Boolean(Actions);
  const classes = makeClasses();

  const rowIdentityKey = React.useMemo(() => options?.rowIdentityKey, [options]);

  // Set initial data
  React.useEffect(() => {
    store.dispatch(actions.setData(data.map((row, index) => prepareRowTableData(row, rowIdentityKey, index))));
  }, [data]); // on received data change

  // set checked rows
  React.useEffect(() => {
    if (checked) {
      if (!_isEqual(checked, store.state.checkedRows)) {
        store.dispatch(actions.setCheckedList(checked));
      }
    }
  }, [checked]);

  // set pagination
  React.useEffect(() => {
    if (pagination) {
      if (!_isEqual(store.state.pagination, pagination)) {
        store.dispatch(actions.setPagination(pagination));
      }
    }
  }, [pagination]); // on received pagination change

  const Head = React.useMemo(() => {
    const headProps: IMyTableHeadProps = {
      options,
      translations,
      events,
      store,
      columns,
      withActions,
      extendsProps,
      classes: tableClasses
    };
    return <MyTableHead {...headProps} />;
  }, [store, columns, events, options, translations, withActions, tableClasses, extendsProps]);

  return (
    <>
      <Table stickyHeader={options?.stickyHeader} classes={{ root: tableClasses?.tableRoot }} className={classes.table} size={size ?? 'medium'}>
      {props.fetch && <div className={classes.tableFetchCover} />}
        {Head}
        <MyTableBody
          overrides={overrides}
          classes={tableClasses}
          options={options}
          events={events}
          Actions={Actions}
          FocusedActions={FocusedActions}
          columns={columns}
          extendsProps={extendsProps}
          translations={translations}
          store={store} />
      </Table>
      <MyTableFooter events={events} options={options} store={store} />
    </>
  );
};

export default MyTable;
