import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const BlockTypeSVG: React.FC<SVGComponentProps> = ({ fill, className }: SVGComponentProps) => (
  <svg  xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g id="Group_14619" data-name="Group 14619" transform="translate(-1368.534 -222)">
      <path id="Path_4088" data-name="Path 4088" d="M441.976,487.014a.462.462,0,0,1,0,.836l-3.706,2.425a1.274,1.274,0,0,1-1.282,0l-3.731-2.425a.462.462,0,0,1,0-.836l3.706-2.425a1.272,1.272,0,0,1,1.282,0Z" transform="translate(949.828 -246.361)" fill={`${fill ?? '#fff'};`}/>
      <g id="Group_14618" data-name="Group 14618">
        <path id="Path_4088-2" data-name="Path 4088" d="M441.976,487.014a.462.462,0,0,1,0,.836l-3.706,2.425a1.274,1.274,0,0,1-1.282,0l-3.731-2.425a.462.462,0,0,1,0-.836l3.706-2.425a1.272,1.272,0,0,1,1.282,0Z" transform="translate(938.828 -249.361)" fill={`${fill ?? '#fff'};`}/>
        <path id="Path_4088-3" data-name="Path 4088" d="M441.976,487.014a.462.462,0,0,1,0,.836l-3.706,2.425a1.274,1.274,0,0,1-1.282,0l-3.731-2.425a.462.462,0,0,1,0-.836l3.706-2.425a1.272,1.272,0,0,1,1.282,0Z" transform="translate(938.828 -257.361)" fill={`${fill ?? '#fff'};`}/>
        <path id="Path_4088-4" data-name="Path 4088" d="M441.976,487.014a.462.462,0,0,1,0,.836l-3.706,2.425a1.274,1.274,0,0,1-1.282,0l-3.731-2.425a.462.462,0,0,1,0-.836l3.706-2.425a1.272,1.272,0,0,1,1.282,0Z" transform="translate(949.828 -254.361)" fill={`${fill ?? '#fff'};`}/>
        <g id="Component_44_2" data-name="Component 44 – 2" transform="translate(1368.534 222)">
          <g id="Rectangle_928" data-name="Rectangle 928" transform="translate(27) rotate(90)" fill="none" stroke="#f2f2f2" strokeWidth="1" opacity="0">
            <rect width="27" height="27" rx="3" stroke="none"/>
            <rect x="0.5" y="0.5" width="26" height="26" rx="2.5" fill="none"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
