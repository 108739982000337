import React, { useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Avatar, Box } from '@material-ui/core';
import { defaultTheme } from 'themes/default-theme';
import { DefaultTheme } from '@material-ui/styles';
import { Config } from 'config';

const makeClasses = makeStyles<Theme & DefaultTheme, { variant?: string }>(theme => ({
  image: {
    width: '100%',
    objectFit: 'contain',
    display: 'flex'
  },
  avatar: {
    margin: theme.spacing(2),
    width: props => props.variant === 'bigger' ? 45 : 30,
    height: props => props.variant === 'bigger' ? 45 : 30,
  },
  avatarbox: {
    position: 'absolute',
    left: '0',
    padding: props => props.variant === 'bigger' ? theme.spacing(2) : '0',
    height:  props => props.variant === 'bigger' ? 'auto' : '62px',
    overflow: 'hidden',
    width: '100%',
    bottom: '0'
  },
  author: {
    ...defaultTheme.mixins.fonts.bold.xxs,
    fontSize: props => props.variant === 'bigger' ? 15 : 12,
    verticalAlign: 'baseline'
  }
}));

export interface IImageWithAuthorProps {
  imageSrc: string;
  author: string;
  variant?: string;
  id?: string;
  className?: string;
  authorAvatar?: string;
  onClick?: (event: React.MouseEvent) => any;
}

export type ImageWithAuthorProps = React.PropsWithChildren<IImageWithAuthorProps>;

export const ImageWithAuthor = (props: ImageWithAuthorProps) => {
  const { imageSrc, author, authorAvatar, onClick, variant, id } = props;
  const classes = makeClasses({ variant });
  const onClickEv = useCallback(
    (e: React.MouseEvent) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );
  const baseUrl = window.location.origin.toString();
  return React.useMemo(
    () => (
      <>
        <img className={classes.image} src={imageSrc} alt="" data-id={id} onClick={onClickEv}/>
        <Box display="flex" className={classes.avatarbox} alignItems="center">
          <Avatar className={classes.avatar} src={`${baseUrl}/users/${authorAvatar}/avatar`} alt={author}/>
          <span className={classes.author}>{author}</span>
        </Box>
      </>
    ),
    [author, authorAvatar, classes.author, classes.image, classes.avatar, imageSrc, onClickEv]
  );
};
