import { DomElement } from 'domhandler';
import React from 'react';

export const parseToText: (domNode: DomElement) => React.ReactElement | object | void | undefined | null | false = (domNode) => {
  if (domNode.type === 'text') {
    return <>{`${domNode.data} `}</>;
  }
  if (domNode.name === 'span' || domNode.name === 'strong' || domNode.name === 'p') {
    if (domNode.children?.length) {
      return <>{domNode.children.map(child => parseToText(child))}</>;
    }
  }
  return <></>;
};
