import { Reducer } from 'redux';
import { Actions, FaqModuleActions } from '../actions/faq.actions';
import { IFaq } from '../../interfaces/faq.interface';

export const faqModuleStoreInitialState: IFaq[] = [
  {
    id: '',
    question: '',
    answer: ''
  }
];

export const faqModuleStoreReducer: Reducer<IFaq[], Actions> = (
  state: IFaq[] = faqModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case FaqModuleActions.SET_FAQ:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export default faqModuleStoreReducer;
