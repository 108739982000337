import { Box, Typography } from '@material-ui/core';
import { UserInfo } from 'modules/user/views/ranking/components';
import React from 'react';
import { BadgePreview } from '../../shared/badge-preview';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import {useTranslation} from 'react-i18next';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    height: '68px'
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  points: {
    ...theme.mixins.fonts.regular.xs,
    color: '#006BD6',
    fontSize: '14px'
  }
}));
interface Props {
  name: string,
  avatarId: string,
  badgeId?: string,
  points: number,
  groupName: string
}
export const PepcoRankingelement = ({
  name,
  avatarId,
  badgeId,
  points,
  groupName
}: Props) => {
  const classes = makeClasses();
  const { t } = useTranslation()

  return (
    <Box className={classes.container}>
      <UserInfo name={name} avatarId={avatarId} groupName={groupName} groupColor='#D31D5A'/>
      <Box className={classes.scoreContainer}>
      <BadgePreview badgeId={badgeId ?? ''}/>
      <Typography className={classes.points}>{t('userPanel.myRankingFeed.points', {count: points ?? 0})}</Typography>
      </Box>
    </Box>
  )
}