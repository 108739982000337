export enum QuizzesModuleActions {
  SET_QUIZZES = 'SET_QUIZZEZ'
}

export interface ISetQuizzesAction {
  type: QuizzesModuleActions.SET_QUIZZES;
  payload: {
    quizzes: any | any[];
  };
}

export type Actions = ISetQuizzesAction;

export const quizzesActions = {
  setQuizzes(quizzes: any | any[]) {
    return {
      type: QuizzesModuleActions.SET_QUIZZES,
      payload: {
        quizzes
      }
    };
  }
};
