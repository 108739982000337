import { FlatChildrenElem } from './flat-children';

export const filterChildren = <T extends any>(elements: FlatChildrenElem<T>[], filter: (elem: T) => boolean): T[] => {

  const output = [];

  for (const element of elements) {
    if (element.children && element.children.length) {
      if (filter(element)) {
        element.children = filterChildren(element.children, filter);
        output.push(element);
      }
    } else if (filter(element)) {
      output.push(element);
    }
  }

  return output;
};