import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const ArrowUpSVG: React.FC<SVGComponentProps> = ({ fill, className }: SVGComponentProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
    <g id="Group_14605" data-name="Group 14605" transform="translate(10179.5 11983.5)">
    <circle id="Ellipse_139" data-name="Ellipse 139" cx="6" cy="6" r="6" transform="translate(-10179 -11983)" fill="none" stroke="#4c4cff" strokeWidth="1"/>
    <path id="Icon_material-arrow-forward" data-name="Icon material-arrow-forward" d="M3,0,2.471.529l2.093,2.1H0v.75H4.564l-2.093,2.1L3,6,6,3Z" transform="translate(-10176 -11974.75) rotate(-90)" fill="#4c4cff"/>
  </g>
  </svg>
);
