import { Reducer } from 'redux';
import { Actions, TrainingsModuleActions } from '../actions/trainings.actions';
import { ITrainings } from '../../interfaces/trainings.interface';

export const trainingsModuleStoreInitialState: ITrainings = {
  yearsInTenderhut: 0,
  userSeniority: 0,
  budgetLevel: {
    actual: 0,
    max: 0,
    calculated: 0,
    manuallyModified: {
      modification: 0,
      reason: '',
    }
  },
  budgetValue: {
    actual: 0,
    max: 0,
    calculated: 0,
    used: 0,
    extra: 0,
    manuallyModified: {
      modification: 0,
      reason: '',
    },
    requiredQuests: {
      min: 0,
      actual: 0,
      questIds: []
    }
  }
};

export const trainingsModuleStoreReducer: Reducer<ITrainings, Actions> = (
  state: ITrainings = trainingsModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case TrainingsModuleActions.SET_TRAININGS:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export default trainingsModuleStoreReducer;
