import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useTranslation } from 'react-i18next';
import { NewsMainTextType, INews, IThumbnail } from 'interfaces/news.interface';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { useSelector } from 'react-redux';
import { SupportedCompanies } from 'config';
import { feVisualizationType, userProfileSelector } from 'modules/core/store/selectors';
import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns'
import { pl, enUS } from 'date-fns/locale'
const makeClasses = makeStyles<DefaultTheme, { isEX: boolean }>(theme => {
  return {
    root: {
      borderBottom: props => props.isEX ? 'none' : `1px solid ${theme.palette.secondary.light}`,
    },
    heading: {
      color: props => props.isEX ? 'white' : 'red',
      ...theme.mixins.fonts.bold.xs,
      marginBottom: theme.spacing(1)
    },
    text: {
      color: props => props.isEX ? theme.palette.common.black : theme.palette.grey['500'],
      ...theme.mixins.fonts.regular.xs,
      lineHeight: '20px',
      textAlign: props => props.isEX ? 'left' : 'center',
      maxHeight: '80px',
      overflow: 'hidden',
      paddingBottom: theme.spacing(2)
    },
    imageLink: {
      marginBottom: theme.spacing(2),
      display: 'block',
      textAlign: 'center'
    },
    videoLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '0 none',
      maxWidth: '342px',
      height: '192px',
      width: '100%',
      marginBottom: theme.spacing(2),
      color: theme.palette.common.white,
      background: theme.palette.common.black
    },
    playIcon: {
      width: 60,
      height: 60
    },
    link: {
      ...theme.mixins.fonts.bold.xs,
      fontSize: props => props.isEX ? 20 : 15,
      textDecoration: props => props.isEX ? 'none' : 'underline',
      color: props => props.isEX ? theme.palette.common.black : theme.palette.grey['500'],
    },
    image: {
      maxWidth: '100%'
    },
    dateFormat: {
      ...theme.mixins.fonts.medium.xxs,
      textAlign: 'end'
    }
  };
});

interface INewsCard extends INews {
  thumbnail: IThumbnail | undefined,
}

export const NewsFeedCard: React.FC<INewsCard> = ({ externalId, id, type, textHeader, mainText, thumbnail, date, addedAt }: INewsCard) => {
  const { t } = useTranslation();
  const quizType = type === NewsMainTextType.QUIZ;
  const currentUser = useSelector(userProfileSelector);
  const visualizationType = useSelector(feVisualizationType);
  const isEXCompany = visualizationType === SupportedCompanies.EX;
  const userLanguage = currentUser?.language === 'pl' ? pl : enUS;
  const classes = makeClasses({
    isEX: isEXCompany
  });

  const getMedia = useMemo(() => (
    thumbnail ? (
      <Link
        to={`/user/gallery?image=${thumbnail.id}`}
        className={classes.imageLink}
      >
        <img
          key={thumbnail.id}
          className={classes.image}
          src={thumbnail.thumbnailUrlLink}
          alt={thumbnail.id} />
      </Link>
    ) : type === NewsMainTextType.VIDEO && externalId && typeof externalId !== 'string' && (
      <Link
        to={`/user/gallery?image=${externalId?.id}`}
        className={classes.videoLink}>
        <PlayCircleFilledIcon className={classes.playIcon} />
      </Link>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [id, thumbnail, type]);

  return (
    <article className={classes.root}>
      {isEXCompany && getMedia}
      {!quizType && isEXCompany ? (
        <Grid container spacing={1} alignItems='center' justify='space-between'>
          <Grid item xs={8}>
            <h3 className={classes.heading}>
              {type === NewsMainTextType.TEXT ? textHeader : <Link className={classes.link} to={`/user/news/${id}`}>{textHeader}</Link>}
            </h3>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.dateFormat}>{format(new Date(date), 'iiii, HH:mm', { locale: userLanguage })}</Typography>
          </Grid>
        </Grid>

      ) : <h3 className={classes.heading}>
        {type === NewsMainTextType.TEXT ? textHeader : <Link className={classes.link} to={`/user/news/${id}`}>{textHeader}</Link>}
      </h3>}
      <div className={classes.text}>
        {`${mainText.replace(/(<([^>]+)>)/gi, "")} `}
        {quizType && <Link className={classes.link} to={`/user/quizzes/${externalId}`}>{t('userPanel.dashboard.newsLink')}</Link>}
      </div>
      {!isEXCompany && getMedia}
    </article>
  );
};
