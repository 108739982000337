import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const ChartTypeSVG: React.FC<SVGComponentProps> = ({ fill, className }: SVGComponentProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g id="Group_14616" data-name="Group 14616" transform="translate(-1332 -222)">
      <g id="Group_14617" data-name="Group 14617">
        <g id="Component_39_2" data-name="Component 39 – 2" transform="translate(1395 222) rotate(90)">
          <g id="Component_38_1" data-name="Component 38 – 1" transform="translate(4 42)">
            <rect id="Rectangle_922" data-name="Rectangle 922" width="11" height="3" rx="1.5" transform="translate(8)" fill={`${fill ?? '#fff'};`}/>
            <rect id="Rectangle_927" data-name="Rectangle 927" width="5" height="3" rx="1.5" fill={`${fill ?? '#fff'};`}/>
            <rect id="Rectangle_923" data-name="Rectangle 923" width="11" height="3" rx="1.5" transform="translate(8 6)" fill={`${fill ?? '#fff'};`}/>
            <rect id="Rectangle_926" data-name="Rectangle 926" width="5" height="3" rx="1.5" transform="translate(0 6)" fill={`${fill ?? '#fff'};`}/>
            <rect id="Rectangle_924" data-name="Rectangle 924" width="11" height="3" rx="1.5" transform="translate(8 12)" fill={`${fill ?? '#fff'};`}/>
            <rect id="Rectangle_925" data-name="Rectangle 925" width="5" height="3" rx="1.5" transform="translate(0 12)" fill={`${fill ?? '#fff'};`}/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
