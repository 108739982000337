import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz } from '@material-ui/icons';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme, SVGComponentProps>(theme => ({
  root: {
    ...theme.mixins.fonts.bold.xxs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%'
  },
  icon: {
    fill: '#818181',
    width: '35px',
    height: '35px'
  }
}));

export const DotsRoundIcon = (props: SVGComponentProps) => {
  const classes = makeClasses(props);
  return (
    <span className={clsx(classes.root, props.className)}>
      <MoreHoriz className={classes.icon} />
    </span>
  );
};
