import React from 'react';
import { IModule } from 'interfaces/module.interface';

const userModule: IModule = {
  name: 'userModule',
  title: 'common.user',
  module: React.lazy(() => import('./user')),
  routeProps: {
    accessible: false,
    path: '/user',
  },
  routeAuthorizationProps: {}
};

export default userModule;
