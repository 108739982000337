export enum APIPaths {
  TREE_LEVELS_MANAGE = '/tree-levels-manage',
  ROUND_MANAGE = '/rounds-manage',
  USER_MANAGE = '/users-manage',
  QUESTS_MANAGE = '/quests-manage',
  QUIZ = '/quizes',
  USERS = '/users',
  QUIZ_MANAGE = '/quizes-manage',
  ROLE_MANAGE = '/roles-manage',
  KNOWLEDGE_PAGES = '/knowledge-pages-manage',
  WORKER_GROUPS = '/worker-groups-manage',
  TRANSLATIONS = '/translations',
  VISUALIZATION_TYPES = '/visualization-types',
  STATIC_PAGES_MANAGE = '/static-pages-manage',
  REGULATIONS_MANAGE = '/regulations-manage',
  AUDIT_EVENTS = '/audit-events',
  KPI_MANAGE = '/kpi-manage',
  KPI = '/kpi',
  CLAIMS = '/claims',
  STATIC_PAGES = '/static-pages',
  ROUNDS = '/rounds',
  AVATAR = '/avatar',
  CURRENT_ROUND = '/current-round',
  PASSWORD_RESET = '/reset-password',
  PASSWORD_RESET_TOKEN = '/password-reset-token',
  CHANGE_PASSWORD = '/change-password',
  WORKERS_IN_GROUP = '/worker-groups',
  GROUPS = '/groups',
  NEWS_MANAGE = '/news-manage',
  REGULATIONS = '/regulations',
  PROFILE = '/profile',
  LANGUAGE = '/language',
  GALLERY = '/gallery',
  FLOWS_MANAGE = '/flows-manage',
  FLOW_ITEM_TYPES_MANAGE = '/flow-item-types-manage',
  FILES = '/files-manage',
  ACTIVITIES = '/activities',
  ACTIVITIES_MANAGE = '/activities-manage',
  ACTIVITIES_IMPORT_HISTORY = '/activities-import-histories-manage',
  ACTIVITY_KINDS = '/activity-kinds',
  FE_SETTINGS = '/fe-settings',
  FE_SETTINGS_MANAGE = '/fe-settings-manage',
  SETTINGS_MANAGE = '/settings-manage',
  AVATAR_AD = '/users/getuserphotofromad',
  SURVEYS_MANAGE = '/surveys-manage',
  SURVEYS = '/surveys',
  TENANTS_LOGO = '/tenants/logo',
  BADGES_MANAGE = '/badges-manage'
}
