import { IMyTableState } from '../reducer';
import { IMyTablePreparedRow, IMyTableRow } from '../../interfaces';
import { IPagination } from 'interfaces/pagination.interface';

export enum MyTableActions {
  SET_DATA = 'SET_DATA',
  SET_CACHED_ROW = 'SET_CACHED_ROW',
  SET_EXPANDED = 'SET_EXPANDED',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_CHECKED = 'SET_CHECKED',
  APPLY_CACHED_ROW = 'APPLY_CACHED_ROW',
}

export interface IApplyCachedRow {
  type: MyTableActions.APPLY_CACHED_ROW,
  payload: {
    row: IMyTableRow
  }
}

export interface ISetChecked {
  type: MyTableActions.SET_CHECKED,
  payload: {
    checkedRows: string[],
  }
}

export interface ISetExpanded {
  type: MyTableActions.SET_EXPANDED;
  payload: {
    expandedRows: string[],
  };
}

export interface ISetCachedRowAction {
  type: MyTableActions.SET_CACHED_ROW;
  payload: {
    uuid: string,
    data: IMyTableRow | null;
  };
}

export interface ISetDataAction {
  type: MyTableActions.SET_DATA;
  payload: {
    data: IMyTableRow[];
  };
}

export interface ISetPagination {
  type: MyTableActions.SET_PAGINATION;
  payload: {
    pagination: IPagination;
  };
}

export interface IActions {
  setCheckedRow: (row: IMyTablePreparedRow<any>, state: IMyTableState, callBack?: (rows: string[]) => any) => ISetChecked,
  setCheckedList: (checkedRowsIDList: string[]) => ISetChecked,
  setRowUnchecked: (row: IMyTablePreparedRow<any>, state: IMyTableState) => ISetChecked,
  // REFACTORED
  applyCachedRow: (row: IMyTableRow<any>) => IApplyCachedRow,
  setCachedRow: (uuid: string, data: IMyTableRow | null) => ISetCachedRowAction;
  setPagination: (pagination: IPagination) => ISetPagination;
  setData: (data: IMyTableRow[]) => ISetDataAction;
  setExpanded: (row: IMyTablePreparedRow<any>, state: IMyTableState) => ISetExpanded,
}

export const actions: IActions = {

  setExpanded: (tableRow: IMyTablePreparedRow<any>, state: IMyTableState) => {
    const expandedRows = [...state.expandedRows];
    if (tableRow.isExpanded) {
      const index = expandedRows.findIndex(expandedRowUUID => expandedRowUUID === tableRow.row.__table.uuid);
      expandedRows.splice(index, 1);
    } else {
      expandedRows.push(tableRow.row.__table.uuid);
    }
    return {
      type: MyTableActions.SET_EXPANDED,
      payload: {
        expandedRows
      }
    };
  },

  setRowUnchecked: (row: IMyTablePreparedRow<any>, state: IMyTableState) => {
    const checkedRows = [...state.checkedRows];
    const index = checkedRows.findIndex(checkedRowUUID => checkedRowUUID === row.row.__table.uuid);
    if (index > -1) {
      checkedRows.splice(index, 1);
    }
    return {
      type: MyTableActions.SET_CHECKED,
      payload: {
        checkedRows,
      },
    }
  },

  setCheckedList: (checkedRows: string[]) => ({
    type: MyTableActions.SET_CHECKED,
    payload: {
      checkedRows,
    }
  }),

  setCheckedRow: (tableRow, state, callBack) => {
    const checkedRows = [...state.checkedRows];
    if (tableRow?.isChecked) {
      const index = checkedRows.findIndex(checkedRowUUID => checkedRowUUID === tableRow.row.__table.uuid);
      checkedRows.splice(index, 1);
    } else {
      checkedRows.push(tableRow.row.__table.uuid);
    }
    if (callBack)
      callBack(checkedRows);
    return {
      type: MyTableActions.SET_CHECKED,
      payload: {
        checkedRows
      }
    };
  },

  setCachedRow: (uuid: string, data: IMyTableRow | null) => ({
    type: MyTableActions.SET_CACHED_ROW,
    payload: {
      uuid,
      data
    }
  }),

  applyCachedRow: (cachedRow: IMyTableRow<any>) => ({
    type: MyTableActions.APPLY_CACHED_ROW,
    payload: {
      row: cachedRow,
    }
  }),

  setData: (data: IMyTableRow[]) => ({
    type: MyTableActions.SET_DATA,
    payload: {
      data
    }
  }),

  setPagination: (pagination: IPagination) => ({
    type: MyTableActions.SET_PAGINATION,
    payload: {
      pagination
    }
  }),

};

export type Actions =
  | IApplyCachedRow
  | ISetExpanded
  | ISetDataAction
  | ISetCachedRowAction
  | ISetPagination
  | ISetChecked
