import React from 'react';
import { useService } from 'modules/core/hooks';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogActions, DialogContent, InputLabel } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ResponsiveModal } from 'modules/core/components/layout';
import { defaultTheme } from 'themes/default-theme';
import { UsersService } from 'modules/core/services/users.service';
import { showApiErrors } from 'utils/show-errors';
import { useSnackbar } from 'notistack';
import { ThumbnailAvatar } from './thumbnail-avatar';

export interface IChangeAvatarModalProps {
  open: boolean,
  onSubmit: () => void,
  onCancel: () => void,
  onUpload: (file: File) => void
}
export interface IAvatarResponse {
  id: string,
  gender: string | null;
}
export type ChangeAvatarDialogType = React.FC<IChangeAvatarModalProps>;

const makeClasses = makeStyles((theme: Theme) => ({
  paperRoot: {
    width: '100%'
  },
  headRoot: {
    ...defaultTheme.mixins.fonts.bold.s
  },
  avatarSelection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '20px',
    flexWrap: 'wrap',
    listStyleType: 'none',
    padding: 0
  },
  avatarLabel: {
    marginTop: '20px',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export const ChangeAvatarModal: ChangeAvatarDialogType = (props) => {
  const classes = makeClasses();
  const usersService = useService(UsersService);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { open, onUpload } = props;
  const [fetch, setFetch] = React.useState(false);
  const [avatars, setAvatars] = React.useState<IAvatarResponse[]>([]);

  const fetchAvatars = React.useCallback(
    async () => {
      try {
        setFetch(true);
        await usersService.getDefaultAvatars()
          .then(resAvatars => setAvatars([...resAvatars]))
      } catch (error) {
        showApiErrors(error, enqueueSnackbar);
      } finally {
        setFetch(false);
      }
    },
    [fetch]
  );

  React.useEffect(
    () => {
      if (open) {
        fetchAvatars();
      }
    },
    [open]
  );

  const onSubmit = () => {
    props.onSubmit();
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <ResponsiveModal
      open={open}
      onClose={onCancel}
      maxWidth={'sm'}
      PaperProps={{
        classes: {
          root: classes.paperRoot
        }
      }}>
      <DialogContent>
        <Box className={classes.headRoot} display='inline-flex' alignItems='center'>
          {t('userPanel.header.choiceAvatar')}
        </Box>
        {props.children}
        <ul className={classes.avatarSelection}>
          {avatars.map((avatar, index) => <ThumbnailAvatar key={index} avatarId={avatar.id} onUpload={onUpload}/>)}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button onClick={onSubmit} variant='contained'
                color='primary'>{t('common.submit')}</Button>
      </DialogActions>
    </ResponsiveModal>
  );
};
