import React, { useCallback } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { IModuleView } from 'interfaces/module-view.interface';
import { Helmet } from 'react-helmet';
import { Config } from 'config';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export const useModuleViews = (views: IModuleView[]) => {
  const location = useLocation();
  const { t } = useTranslation();
  const currentTitle = useCallback(
    () => views.find(view => {
      if (location.pathname === view.routeProps.path) {
        return view.title
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  )

  const Views = React.useMemo(() => {
    const component = currentTitle()
    return (
      <>
        {component && (
          <Helmet>
            <title>{`${Config.APP_TITLE} - ${parse(t(component.title))}`}</title>
          </Helmet>
        )}
        <Switch>
          {views.map(({ view, routeProps, key }) => (
            <Route key={key} {...routeProps} component={view} />
          ))}
        </Switch>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);
  return {
    Views
  };
};
