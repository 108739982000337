import { IRound } from 'interfaces/round.interface';

export enum RoundsModuleActions {
  SET_ROUNDS = 'SET_ROUNDS'
}

export type Actions = ISetRoundsAction;

export interface ISetRoundsAction {
  type: RoundsModuleActions.SET_ROUNDS;
  payload: {
    list: IRound[];
  };
}

export const roundsActions = {
  setRounds(rounds: IRound[]) {
    return {
      type: RoundsModuleActions.SET_ROUNDS,
      payload: {
        list: rounds,
      }
    };
  }
};
