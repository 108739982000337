import React from 'react';
import { useSelector } from 'react-redux';
import { LoaderView } from 'modules/core/views';
import { showLoaderSelector } from 'modules/core/store/selectors';

export const RootLoader = () => {
  const showLoader = useSelector(showLoaderSelector);
  return <LoaderView visible={showLoader} />;
};

export default RootLoader;
