import { CoreService } from './core.service';
import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { MyError } from 'utils';
import { Config, languages } from 'config';
import { Errors } from 'const';
import { ILanguage } from 'interfaces/language-user.interface';
import store from 'modules/core/store';
import { setLanguage } from 'modules/core/store/actions';


export class LangService extends CoreService {
  i18next?: i18n;

  public async bootstrap() {
    if (Config.TRANSLATION_ENABLED) {
      this.i18next = await i18next.use(LanguageDetector).use(initReactI18next);
      await this.i18next.init({
        fallbackLng: Config.DEFAULT_LANGUAGE,
        resources: {}
      });
      this.language = LangService.normalizeLanguageCode(this.i18next.language);
    }
  }

  static normalizeLanguageCode(code?: string | string[]) {
    if (code) {
      const language = Array.isArray(code) ? [...code].pop() : code; // Chrome fix
      if (language) {
        return language.split('-')[0];
      }
    }
    return Config.DEFAULT_LANGUAGE;
  }

  get language() {
    return LangService.normalizeLanguageCode(this.i18next?.language);
  }

  set language(language: string) {
    if (languages.includes(language)) {
      this.getLanguage(language).then(({ code, translation }) => {
        this.i18next?.addResourceBundle(code, Config.TRANSLATION_NAMESPACE, translation, true, true);
        this.httpClientService.setHeaderLanguage(language);
        this.i18next?.changeLanguage(code);
      });
    } else {
      this.appService.handleError(new MyError(Errors.FATAL_ERROR, 'unsupported language'));
    }
  }

  async fetchAllLanguages () {
    try {
      const { value: langs } = await this.httpClientService.get<ILanguage[]>('/languages');
      return langs;
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  applyLanguages = (langs: ILanguage[]) => store.dispatch(setLanguage(langs));

  async getLanguage(code: string) {
    try {
      const { value: { translation } } = await this.httpClientService.get(`/translation/${code}`); // TODO: Add API request url
      return {
        code,
        translation
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
