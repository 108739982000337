import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { NavVariantType } from 'modules/core/components/layout/nav/nav-list';

export interface INavListElementProps {
  isActive: boolean;
  to: string;
  variant?: NavVariantType;
  isExCompany?: boolean;
}

type Props = React.PropsWithChildren<INavListElementProps>;

const makeClasses = makeStyles<DefaultTheme, Props>((theme: DefaultTheme) => ({
  link: {
    ...theme.mixins.fonts.regular.xs,
    position: 'relative',
    background: 'transparent',
    color: theme.palette.common.black,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    width: props => props.variant === 'desktop' ? 'auto' : '100%',
    margin: props => props.variant === 'desktop' ? theme.spacing(0, 1.5) : 0,
    padding: props => props.variant === 'desktop' ? theme.spacing(0, 1, 3.5) : theme.spacing(1, 2),
    '&.active': {
      fontWeight: 700,
      borderBottom: props => props.isExCompany ? `4px solid ${theme.palette.primary.main}` : 'none',
      padding: props => props.isExCompany ? (props.variant === 'desktop' ? theme.spacing(0, 1, 3) : theme.spacing(1, 2)) : 'none',
    },
  }
}));

export const NavListElement: React.FC<Props> = (props: Props) => {
  const { to, children, isActive } = props
  const classes = makeClasses(props);
  const isHomepage = to === '/user/';
  return (
    <NavLink
      exact={isHomepage}
      activeClassName="active"
      to={to}
      isActive={() => isActive}
      className={classes.link}>
      {children}
    </NavLink>
  );
};
