import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useService } from 'modules/core/hooks';
import { UsersService } from 'modules/core/services';

export interface IThumbnailAvatarProps {
  avatarId: string
  onUpload: (file: File) => void
}

export type ChangeAvatarDialogType = React.FC<IThumbnailAvatarProps>;

const makeClasses = makeStyles((theme: Theme) => ({
  thumbnailAvatar: {
    margin: '0 10px',
    width: '50px',
    height: '50px',
    marginBottom: '20px',
    '&:hover': {
      cursor: 'pointer'
    },
    '& img': {
      width: 50,
      height: 50,
      objectFit: 'cover'
    }
  }
}));

export const ThumbnailAvatar: ChangeAvatarDialogType = (props) => {
  const usersService = useService(UsersService);
  const classes = makeClasses();
  const { avatarId, onUpload } = props;
  const [avatar, setAvatar] = React.useState<Blob | null>(null);

  const fetchAvatar = React.useCallback(
    async () => {
      const avatarFile = await usersService.fetchAvatarImage(avatarId);
      setAvatar(avatarFile);
    },
    []
  )

  React.useEffect(
    () => {
      if (avatarId) {
        fetchAvatar();
      }
    },
    []
  );

  const onImageClick = () => {
    const blob =  new Blob([avatar as BlobPart]);
    const file = new File([blob], 'avatarImage.png', { type: 'image/jpeg' });
    onUpload(file);
  };

  return (
    <li className={classes.thumbnailAvatar}>
      {avatar && <img src={URL.createObjectURL(avatar)} onClick={onImageClick} />}
    </li>
  );
};
