import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, IconButton, Avatar } from '@material-ui/core';
import { ChangeAvatarModal } from '../change-avatar-modal/change-avatar-modal';

const makeClasses = makeStyles(theme => ({
  root: {
    display: 'grid',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  avatarInput: {
    display: 'none'
  },
  avatar: {
    position: 'relative',
    margin: '10px',
    width: '91px',
    height: '91px',
    background: '#f1f1f1',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  avatarLabel: {
    marginBottom: '20px',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  dragging: {
    backgroundColor: theme.palette.grey[300],
    border: '2px',
    borderStyle: 'dashed'
  },
  changeAvatarLabel: {
    marginTop: '20px',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
}));

export interface AvatarInputProps {
  onUpload: (file: File) => any;
  avatarFile?: File;
  onClear?: (event: React.MouseEvent) => any;
  className?: string;
  avatarImage?: string;
  handleSave: () => any;
  handleCancel: () => any;
}

export const AvatarInput: React.FC<AvatarInputProps> = props => {
  const { onUpload, className, avatarImage, handleSave, avatarFile, handleCancel } = props;
  const classes = makeClasses();
  const inputRef = React.useRef<any>(null);
  const inputRefModal = React.useRef<any>(null);
  const { t } = useTranslation();

  const [isDragging, setIsDragging] = React.useState(false);
  const [changeAvatar, setChangeAvatar] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const onDragOver = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = () => {
    setIsDragging(false);
  };

  const onDropFile = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const fileList = e.dataTransfer.files;
    if (fileList && fileList.length > 0) {
      onUpload(fileList[0]);
    }
  };
  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      onUpload(fileList[0]);
    }
  };

  const handleOpen = () => {
    setChangeAvatar(true);
  };

  const handleClose = () => {
    handleCancel()
    setChangeAvatar(false);
  };

  const handleSubmit = async () => {
    setChangeAvatar(false);
    setSaving(true);
    await handleSave();
    setSaving(false);
  }

  return (
    <div>
      <div
        className={clsx(classes.root, className)}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDropFile}
      >
        <IconButton className={clsx(isDragging && classes.dragging)} onClick={handleOpen}>
          <Avatar className={classes.avatar} src={avatarImage} />
        </IconButton>
        <input accept="image/*" className={classes.avatarInput} type="file" ref={inputRef} onChange={onFileSelected} />
        {avatarFile ?
          <InputLabel className={classes.avatarLabel}>
            {saving ? t('userPanel.header.saving') : t('userPanel.header.clickSave')}
          </InputLabel> :
          <InputLabel className={classes.avatarLabel} onClick={handleOpen}>
            {avatarImage ? `${t('userPanel.header.changeAvatar')}` : `${t('userPanel.header.choiceAvatar')}`}
          </InputLabel>}
      </div>

      <ChangeAvatarModal open={changeAvatar} onSubmit={handleSubmit} onCancel={handleClose} onUpload={onUpload}>
        <div
          className={clsx(classes.root, className)}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDropFile}
        >
          <InputLabel className={classes.changeAvatarLabel} onClick={() => inputRefModal?.current?.click()}>
            {t('userPanel.header.changeAvatarSubtitle')}
          </InputLabel>
          <IconButton className={clsx(isDragging && classes.dragging)} onClick={() => inputRefModal?.current?.click()}>
            <Avatar className={classes.avatar} src={avatarImage} />
          </IconButton>
          <input accept="image/*" className={classes.avatarInput} type="file" ref={inputRefModal} onChange={onFileSelected} />
        </div>
      </ChangeAvatarModal>
    </div>
  );
};
