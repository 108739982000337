import { IUserScore } from '../../interfaces/score.interface';

export enum ScoreModuleActions {
  SET_SCORE = 'SET_SCORE',
  SET_USER_SCORE = 'SET_USER_SCORE'
}

export interface ISetScoreAction {
  type: ScoreModuleActions.SET_SCORE;
  payload: { list: IUserScore[] };
}

export interface ISetUserScoreAction {
  type: ScoreModuleActions.SET_USER_SCORE;
  payload: { userScore: IUserScore };
}


export type Actions = ISetScoreAction & ISetUserScoreAction;

export const scoreActions = {
  setScore(score: IUserScore[]) {
    return {
      type: ScoreModuleActions.SET_SCORE,
      payload: {
        list: score
      }
    };
  },
  setUserScore(score: IUserScore) {
    return {
      type: ScoreModuleActions.SET_USER_SCORE,
      payload: {
        userScore: score
      }
    };
  }
};
