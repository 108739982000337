import { combineReducers } from 'redux';

import * as CoreStoreReducer from './reducer';
import * as CoreStoreActions from './actions';

// Admin Module
import * as AdminModuleReducer from 'modules/admin/store/reducer';
import * as AdminModuleActions from 'modules/admin/store/actions';

// User module
import * as UserModuleReducer from 'modules/user/store/reducer';
import * as UserModuleActions from 'modules/user/store/actions';

export type StoreActions = AdminModuleActions.Actions & CoreStoreActions.Actions & UserModuleActions.Actions;

export interface IStoreState {
  core: CoreStoreReducer.ICoreStoreState;
  adminModule: AdminModuleReducer.IAdminModuleStoreState;
  userModule: UserModuleReducer.IUserModuleStoreState;
}

export const initialStoreState: IStoreState = {
  core: CoreStoreReducer.coreStoreInitialState,
  adminModule: AdminModuleReducer.adminModuleStoreInitialState,
  userModule: UserModuleReducer.userModuleStoreInitialState
};

export const reducers = combineReducers<IStoreState>({
  core: CoreStoreReducer.coreStoreReducer,
  adminModule: AdminModuleReducer.adminModuleStoreReducer,
  // @ts-ignore
  userModule: UserModuleReducer.rootReducer
});
