import { Reducer } from 'redux';

import { AdminModuleTableViews } from 'modules/admin/const';
import { Actions, AdminModuleActions } from 'modules/admin/store/actions';

import { IRole } from 'interfaces/role.interface';
import { IQuiz, IQuizAnswer } from 'interfaces/quiz.interface';
import { IAPUser, IRecentUserForPicker } from 'interfaces/user.interface';
import { IRound } from 'interfaces/round.interface';
import { IFlowListItem, IFlowItemTypesManage } from 'interfaces/flows.interface';
import { IQuest, IQuestAnswer } from 'interfaces/quest.interface';
import { IKnowledgePage } from 'interfaces/knowledge-page.interface';
import { IPagedData } from 'interfaces/paged-data.interface';
import { IWorkerGroup } from 'interfaces/worker-group.interface';
import { IFAQElement } from 'interfaces/faq.interface';
import { IKPITask, IKPITaskDto } from 'interfaces/kpi.interface';

import { IMappedProperty } from 'interfaces/core/mapped-property';
import { IAuditEvent, IAuditEventsFilters } from 'interfaces/admin/audit-event';
import { IPagedListData } from 'interfaces/paged-list-data';

import { IRegulation } from 'modules/admin/interfaces/regulation.interface';
import { IClaim } from 'interfaces/models/claim.interface';
import { IStaticPage } from 'interfaces/admin/static-page';
import { IEmailTemplate } from 'interfaces/models/email-templates';
import { DEFAULT_PAGINATION } from '../../const/pagination';
import { INewsManage } from 'interfaces/news.interface';
import { IFileManage } from 'interfaces/files.interface';
import { IUserActivity, IUserActivityHistory } from 'interfaces/activity.interface';
import { ISurveyPlain } from 'interfaces/survey.interface';
import { ITrainingBudgetListItem, ITrainingRequests } from 'interfaces/trainings.interface';
import { ISuggestionsElement } from 'interfaces/suggestions.interface';

export interface IAdminModuleStoreState {
  common: {
    claims: IClaim[];
    claimsLoaded: boolean;
    groups: IWorkerGroup[];
    rounds: IRound[];
    users: IAPUser[]; // all users, without pagination
    visualizationTypes: IMappedProperty[],
    regulations: IRegulation[],
    flowItemTypes: IFlowItemTypesManage[],
    recentSelectedUsers: IRecentUserForPicker[],
  };
  tables: {
    [AdminModuleTableViews.WORKER_GROUPS]: IPagedData<IWorkerGroup>,
    [AdminModuleTableViews.USERS]: IPagedData<IAPUser>,
    [AdminModuleTableViews.DASHBOARD_USERS]: IPagedData<IAPUser>,
    [AdminModuleTableViews.QUIZZES]: IPagedData<IQuiz>,
    [AdminModuleTableViews.EMAIL_TEMPLATES]: IPagedData<IEmailTemplate>,
    [AdminModuleTableViews.QUIZZES_ANSWERS]: IPagedData<IQuizAnswer>,
    [AdminModuleTableViews.ACTIVITIES_IMPORT_HISTORY]: IPagedData<IUserActivityHistory>,
    [AdminModuleTableViews.FILES]: IPagedData<IFileManage>,
    [AdminModuleTableViews.QUIZZES_ANSWERS_TO_REVIEW]: IPagedData<IQuizAnswer>,
    [AdminModuleTableViews.QUESTS]: IPagedData<IQuest>,
    [AdminModuleTableViews.ACTIVITES]: IPagedData<IUserActivity>,
    [AdminModuleTableViews.NEWS]: IPagedData<INewsManage>,
    [AdminModuleTableViews.KNOWLEDGE_PAGES]: IPagedData<IKnowledgePage>,
    [AdminModuleTableViews.ROLES]: IPagedData<IRole>,
    [AdminModuleTableViews.FLOWS]: IPagedData<IFlowListItem>,
    [AdminModuleTableViews.QUESTS_ANSWERS]: IQuestAnswer[],
    [AdminModuleTableViews.KPIS]: IPagedData<IKPITask>,
    [AdminModuleTableViews.KPI_ASSIGNMENTS]: IPagedData<IKPITaskDto>,
    [AdminModuleTableViews.SURVEYS]: IPagedData<ISurveyPlain>,
    [AdminModuleTableViews.TRAINING_REQUESTS]: IPagedData<ITrainingRequests>,
    [AdminModuleTableViews.TRAINING_BUDGET]: IPagedData<ITrainingBudgetListItem>,
    [AdminModuleTableViews.FAQ]: IPagedData<IFAQElement>,
    [AdminModuleTableViews.SUGGESTIONS]: IPagedData<ISuggestionsElement>,
  },
  data: {
    auditEvents: IPagedListData<IAuditEvent, IAuditEventsFilters>,
    staticPages: IStaticPage[],
  }
}

export const adminModuleStoreInitialState: IAdminModuleStoreState = {
  common: {
    claims: [],
    groups: [],
    rounds: [],
    users: [],
    claimsLoaded: false,
    visualizationTypes: [],
    regulations: [],
    flowItemTypes: [],
    recentSelectedUsers: [],
  },
  tables: {
    [AdminModuleTableViews.USERS]: {
      pagination: {},
      data: []
    },
    [AdminModuleTableViews.ACTIVITIES_IMPORT_HISTORY]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.WORKER_GROUPS]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.EMAIL_TEMPLATES]: {
      pagination: {},
      data: []
    },
    [AdminModuleTableViews.NEWS]: {
      pagination: {},
      data: []
    },
    [AdminModuleTableViews.DASHBOARD_USERS]: {
      pagination: {},
      data: []
    },
    [AdminModuleTableViews.KNOWLEDGE_PAGES]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.QUIZZES]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.QUIZZES_ANSWERS]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.FILES]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.QUIZZES_ANSWERS_TO_REVIEW]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.QUESTS]: {
      pagination: {},
      data: []
    },
    [AdminModuleTableViews.ROLES]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.FLOWS]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.ACTIVITES]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.QUESTS_ANSWERS]: [],
    [AdminModuleTableViews.KPIS]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.KPI_ASSIGNMENTS]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.SURVEYS]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.TRAINING_REQUESTS]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.TRAINING_BUDGET]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.FAQ]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    },
    [AdminModuleTableViews.SUGGESTIONS]: {
      pagination: DEFAULT_PAGINATION,
      data: []
    }
  },
  data: {
    auditEvents: {
      results: []
    },
    staticPages: [],
  }
};

export const adminModuleStoreReducer: Reducer<IAdminModuleStoreState, Actions> = (
  state: IAdminModuleStoreState = adminModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;

  switch (type) {

    case AdminModuleActions.SET_COMMON_REGULATIONS:
      return {
        ...state,
        common: {
          ...state.common,
          regulations: [...action.payload.regulations]
        }
      };

    case AdminModuleActions.SET_VISUALIZATION_TYPES:
      return {
        ...state,
        common: {
          ...state.common,
          visualizationTypes: action.payload.types
        }
      };

    case AdminModuleActions.SET_FAQ_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.FAQ]: {
            ...state.tables[AdminModuleTableViews.FAQ],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.FAQ].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.FAQ].pagination }
          }
        }
      };

    case AdminModuleActions.SET_SUGGESTIONS_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.SUGGESTIONS]: {
            ...state.tables[AdminModuleTableViews.SUGGESTIONS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.SUGGESTIONS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.SUGGESTIONS].pagination }
          }
        }
      };

    case AdminModuleActions.SET_COMMON_GROUPS:
      return {
        ...state,
        common: {
          ...state.common,
          groups: [...action.payload.groups]
        }
      };

    case AdminModuleActions.SET_COMMON_ROUNDS:
      return {
        ...state,
        common: {
          ...state.common,
          rounds: [...action.payload.rounds]
        }
      };

    case AdminModuleActions.SET_COMMON_USERS:
      return {
        ...state,
        common: {
          ...state.common,
          users: [...action.payload.users]
        }
      };

    case AdminModuleActions.SET_COMMON_CLAIMS:
      return {
        ...state,
        common: {
          ...state.common,
          claims: [...action.payload.claims]
        },
      };

    case AdminModuleActions.SET_COMMON_CLAIMS_LOADED:
      return {
        ...state,
        common: {
          ...state.common,
          claimsLoaded: action.payload.claimsLoaded
        },
      };

    case AdminModuleActions.SET_FLOW_ITEM_TYPES:
      return {
        ...state,
        common: {
          ...state.common,
          flowItemTypes: [...action.payload.flowItemTypes]
        },
      };
    case AdminModuleActions.SET_RECENT_SELECTED_USERS:
      return {
        ...state,
        common: {
          ...state.common,
          recentSelectedUsers: [...action.payload.users]
        },
      };

    case AdminModuleActions.SET_USERS_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.USERS]: {
            ...state.tables[AdminModuleTableViews.USERS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.USERS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.USERS].pagination }
          }
        }
      };
    case AdminModuleActions.SET_WORKER_GROUPS_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.WORKER_GROUPS]: {
            ...state.tables[AdminModuleTableViews.WORKER_GROUPS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.WORKER_GROUPS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.WORKER_GROUPS].pagination }
          }
        }
      };
    case AdminModuleActions.SET_ACTIVITIES_IMPORT_HISTORY:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.ACTIVITIES_IMPORT_HISTORY]: {
            ...state.tables[AdminModuleTableViews.ACTIVITIES_IMPORT_HISTORY],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.ACTIVITIES_IMPORT_HISTORY].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.ACTIVITIES_IMPORT_HISTORY].pagination }
          }
        }
      };

    case AdminModuleActions.SET_ROLES_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.ROLES]: {
            ...state.tables[AdminModuleTableViews.ROLES],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.ROLES].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.ROLES].pagination }
          }
        }
      };

    case AdminModuleActions.SET_ACTIVITIES_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.ACTIVITES]: {
            ...state.tables[AdminModuleTableViews.ACTIVITES],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.ACTIVITES].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.ACTIVITES].pagination }
          }
        }
      };

    case AdminModuleActions.SET_KNOWLEDGE_PAGES_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.KNOWLEDGE_PAGES]: {
            ...state.tables[AdminModuleTableViews.KNOWLEDGE_PAGES],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.KNOWLEDGE_PAGES].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.KNOWLEDGE_PAGES].pagination }
          }
        }
      };

    case AdminModuleActions.SET_SURVEYS_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.SURVEYS]: {
            ...state.tables[AdminModuleTableViews.SURVEYS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.SURVEYS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.SURVEYS].pagination }
          }
        }
      }

    case AdminModuleActions.SET_TRAINING_REQUESTS_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.TRAINING_REQUESTS]: {
            ...state.tables[AdminModuleTableViews.TRAINING_REQUESTS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.TRAINING_REQUESTS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.TRAINING_REQUESTS].pagination }
          }
        }
      }
    case AdminModuleActions.SET_TRAINING_BUDGET_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.TRAINING_BUDGET]: {
            ...state.tables[AdminModuleTableViews.TRAINING_BUDGET],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.TRAINING_BUDGET].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.TRAINING_BUDGET].pagination }
          }
        }
      }

    case AdminModuleActions.SET_QUIZ_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.QUIZZES]: {
            ...state.tables[AdminModuleTableViews.QUIZZES],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.QUIZZES].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.QUIZZES].pagination }
          }
        }
      };
    case AdminModuleActions.SET_NEWS_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.NEWS]: {
            ...state.tables[AdminModuleTableViews.NEWS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.NEWS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.NEWS].pagination }
          }
        }
      };

    case AdminModuleActions.SET_FILES_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.FILES]: {
            ...state.tables[AdminModuleTableViews.FILES],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.FILES].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.FILES].pagination }
          }
        }
      };
    case AdminModuleActions.SET_EMAILTEMPLATES_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.EMAIL_TEMPLATES]: {
            ...state.tables[AdminModuleTableViews.EMAIL_TEMPLATES],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.EMAIL_TEMPLATES].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.EMAIL_TEMPLATES].pagination }
          }
        }
      };

    case AdminModuleActions.SET_QUIZ_ANSWERS_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.QUIZZES_ANSWERS]: {
            ...state.tables[AdminModuleTableViews.QUIZZES_ANSWERS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.QUIZZES_ANSWERS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.QUIZZES_ANSWERS].pagination }
          },
        },
      };

    case AdminModuleActions.SET_QUIZ_ANSWERS_TO_REVIEW_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.QUIZZES_ANSWERS_TO_REVIEW]: {
            ...state.tables[AdminModuleTableViews.QUIZZES_ANSWERS_TO_REVIEW],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.QUIZZES_ANSWERS_TO_REVIEW].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.QUIZZES_ANSWERS_TO_REVIEW].pagination }
          },
        },
      };

    case AdminModuleActions.SET_QUEST_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.QUESTS]: {
            ...state.tables[AdminModuleTableViews.QUESTS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.QUESTS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.QUESTS].pagination }
          }
        }
      };
    case AdminModuleActions.SET_KPI_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.KPIS]: {
            ...state.tables[AdminModuleTableViews.KPIS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.KPIS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.KPIS].pagination }
          }
        }
      };
    case AdminModuleActions.SET_KPI_ASSIGNMENT_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.KPI_ASSIGNMENTS]: {
            ...state.tables[AdminModuleTableViews.KPI_ASSIGNMENTS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.KPI_ASSIGNMENTS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.KPI_ASSIGNMENTS].pagination }
          }
        }
      }
    case AdminModuleActions.SET_FLOW_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.FLOWS]: {
            ...state.tables[AdminModuleTableViews.FLOWS],
            data: payload.data ? [...payload.data] : [...state.tables[AdminModuleTableViews.FLOWS].data],
            pagination: payload.pagination ? { ...payload.pagination } : { ...state.tables[AdminModuleTableViews.FLOWS].pagination }
          }
        }
      }
    case AdminModuleActions.SET_QUEST_ANSWERS_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [AdminModuleTableViews.QUESTS_ANSWERS]: payload ?? state.tables[AdminModuleTableViews.QUESTS_ANSWERS]
        }
      };

    case AdminModuleActions.SET_AUDIT_EVENTS_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          auditEvents: {
            ...(payload ?? state.data.auditEvents)
          }
        }
      };

    case AdminModuleActions.SET_MORE_AUDIT_EVENTS_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          auditEvents: {
            ...payload,
            results: [...state.data.auditEvents.results, ...(payload.results ?? [])]
          }
        }
      };

    case AdminModuleActions.SET_STATIC_PAGES_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          staticPages: [...payload],
        }
      };

    case AdminModuleActions.SET_RESET_ADMIN_STORE:
      return {
        ...adminModuleStoreInitialState
      };

    default:
      return state;
  }
};

export default adminModuleStoreInitialState;
