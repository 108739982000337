import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FeedCard } from 'modules/user/components/layout';
import { TaskRow } from 'modules/user/components/feed-cards/task-feed/components/task-row';
import { ITask, TaskStatuses, TasksTypes } from 'modules/user/interfaces/task.interface';
import { TasksService } from 'modules/user/services/tasks.service';
import { BACKGROUND } from 'themes/utils/background-utils';
import { useSnackbar } from 'notistack';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import clsx from 'clsx';
import { userTasksListSelector } from 'modules/user/store/selectors';
import { useService, useIcons, IconsNames } from 'modules/core/hooks';
import { flatChildren, showApiErrors } from 'utils';
import { currentSelectedRoundSelector, feVisualizationType } from 'modules/core/store/selectors';
import { SupportedCompanies } from 'config';
import TaskFeedItem from './components/task-item';
import { Button, Grid } from '@material-ui/core';

const makeClasses = makeStyles<DefaultTheme, { isEX: boolean }>(theme => ({
  root: {
    minWidth: 290,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  heading: {
    ...theme.mixins.fonts.bold.xs
  },
  text: {
    ...theme.mixins.fonts.regular.xs,
    color: props => props.isEX ? theme.palette.common.black : theme.palette.grey['500'],
    lineHeight: '20px',
    paddingBottom: theme.spacing(2)
  },

  icon: {
    width: theme.icons.sizes.medium
  },
  goToTasksButton: {
    width: '100%',
    marginBottom: 50,
  },
  buttonContainer: {
    marginBottom: 20
  },
  selectGroupButton: {
    borderRadius: 11,
    padding: '0px 7px',
    height: 'auto',
  },
  selectedFinished: {
    color: theme.palette.common.white,
    background: theme.palette.secondary.main,
    border: 'none',
    '&:hover': {
      background: theme.palette.secondary.main,
      cursor: 'default'
    }
  },
  selectedRejected: {
    color: theme.palette.common.white,
    background: theme.palette.error.main,
    border: 'none',
    '&:hover': {
      background: theme.palette.error.main,
      cursor: 'default'
    }
  },
  selectedOther: {
    color: theme.palette.common.black,
    background: theme.palette.grey[300],
    border: 'none',
    '&:hover': {
      background: theme.palette.grey[300],
      cursor: 'default'
    }
  }
}));

export interface TaskFeedProps {
  className?: string;
}
export enum taskgroups {
  REJECTED = 'Rejected',
  FINISHED = 'Finished',
  OTHER = 'Other'
}

export const TaskFeed: React.FC<TaskFeedProps> = (props) => {

  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const visualizationType = useSelector(feVisualizationType)
  const [selectedGroup, setSelectedGroup] = React.useState(taskgroups.FINISHED)
  const tasksService = useService(TasksService);
  const userTasksList = useSelector(userTasksListSelector); // move
  const currentRound = useSelector(currentSelectedRoundSelector);
  const currentRoundFlat = [...flatChildren(currentRound.children), currentRound];

  const isEXCompany = visualizationType === SupportedCompanies.EX;
  const classes = makeClasses({
    isEX: isEXCompany
  });
  const checkIcon = useIcons(
    IconsNames.CIRCLE_CHECK,
    classes.icon
  );
  const fetchData = React.useCallback(
    async () => {
      try {
        await tasksService.getTasks(currentRoundFlat.map(({ id }) => id));
      } catch (error) {
        showApiErrors(error, enqueueSnackbar);
      }
    },
    [currentRoundFlat, enqueueSnackbar, tasksService]
  );
  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: taskgroups) => {
    event.preventDefault()
    setSelectedGroup(nextView);
  };
  React.useEffect(
    () => {
      if (currentRound.id.length) {
        fetchData();
      }
    },
    [currentRound]
  );

  const tasksList = React.useMemo(
    () => {
      const flatTasksList = userTasksList.flatMap(({ tasks }) => tasks);
      return flatTasksList.filter(task => ![TaskStatuses.FINISHED, TaskStatuses.IN_REVIEW].includes(task.status));
    },
    [userTasksList]
  );

  const filteredTaskList = React.useMemo(
    () => {
      const flatTasksList = userTasksList.flatMap(({ tasks }) => tasks);
      switch (selectedGroup) {
        case taskgroups.FINISHED:
          return flatTasksList.filter(task => task.status === TaskStatuses.FINISHED).slice(0, 5);
        case taskgroups.REJECTED:
          return flatTasksList.filter(task => task.status === TaskStatuses.REJECTED).slice(0, 5);
        case taskgroups.OTHER:
          return flatTasksList.filter(task => task.status !== TaskStatuses.REJECTED && task.status !== TaskStatuses.FINISHED).slice(0, 5);
        default:
          return flatTasksList.filter(task => task.status !== TaskStatuses.REJECTED && task.status !== TaskStatuses.FINISHED).slice(0, 5);
      }
    }, [userTasksList, selectedGroup]
  )
  const onTaskClick = (event: React.MouseEvent, task: ITask) => {
    if (task.status === TaskStatuses.SOON) {
      enqueueSnackbar(t('userPanel.taskFeed.noAccess'), {
        variant: 'warning',
      });
    } else {
      if (task.type === TasksTypes.QUEST) {
        history.push(`/user/quest/${task.id}`);
      } else if (task.type === TasksTypes.QUIZ) {
        history.push(`/user/quizzes/${task.objectId}`);
      }
    }
  };
  const onTasksButtonClick = () => {
    history.push('/user/tasks')
  }

  return (
    <FeedCard
      className={clsx(classes.root, props.className)}
      headingColor={isEXCompany ? BACKGROUND.NONE : BACKGROUND.SECONDARY}
      title={t('userPanel.taskFeed.myTasks')}
      titleIcon={() => checkIcon}
      buttonAction={onTasksButtonClick}
      buttonText={t('userPanel.tasks.goToTasks')}
    >
      {isEXCompany && <Grid container alignItems='center' spacing={2} className={classes.buttonContainer}>
        <Grid item >
          <Button
            className={clsx(classes.selectGroupButton, selectedGroup === taskgroups.FINISHED ? classes.selectedFinished : '')}
            variant='outlined'
            size='medium'
            onClick={(e) => handleChange(e, taskgroups.FINISHED)}
          >{t('userPanel.tasks.evaluated')}</Button>
        </Grid>
        <Grid item >
          <Button
            className={clsx(classes.selectGroupButton, selectedGroup === taskgroups.REJECTED ? classes.selectedRejected : '')}
            variant='outlined'
            size='medium'
            onClick={(e) => handleChange(e, taskgroups.REJECTED)}
          >{t('userPanel.tasks.rejected')}</Button>
        </Grid>
        <Grid item >
          <Button
            className={clsx(classes.selectGroupButton, selectedGroup === taskgroups.OTHER ? classes.selectedOther : '')}
            variant='outlined'
            size='medium'
            onClick={(e) => handleChange(e, taskgroups.OTHER)}
          >{t('userPanel.tasks.other')}</Button>
        </Grid>
      </Grid>}
      {
        !isEXCompany && !!tasksList.length &&
        <div className={classes.heading}>{t('userPanel.taskFeed.hurryUp')}</div>
      }
      {!isEXCompany && <div className={classes.text}>
        {
          tasksList.length
            ? (
              <>
                {t('userPanel.taskFeed.tasksDescriptionTop')}
                {/*<br/>*/}
                {/*{t('userPanel.taskFeed.tasksDescriptionBottom')}*/}
              </>
            )
            : (
              <div className={classes.text}> {t('userPanel.taskFeed.noTasks')}</div>
            )
        }

      </div>}
      {
        isEXCompany && !filteredTaskList.length && <div className={classes.text}> {t('userPanel.taskFeed.noTasks')}</div>
      }
      {isEXCompany ? filteredTaskList.map((task, index) => (
        <TaskFeedItem task={task} onClick={onTaskClick} key={index} />
      )) : tasksList.map((task, index) => (
        <TaskRow key={`${task.id}${index}`} heading={task.name} onClick={onTaskClick} task={task} />
      ))}

    </FeedCard>
  );
};
