export enum DeviceKind {
  ANDROID = 'Android',
  WEB = 'Web',
  IOS = 'IOS',
  WEBSAFARI = 'WebSafari',
}

export interface ICommand {
  token: string,
  kind: DeviceKind
}