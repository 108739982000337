import { INews, IThumbnail } from '../../../../interfaces/news.interface';

export enum NewsModuleActions {
  SET_NEWS = 'SET_NEWS',
  SET_THUMBNAILS = 'SET_THUMBNAILS'
}

export type Actions = ISetNewsAction & ISetThumbnailsAction;

export interface ISetNewsAction {
  type: NewsModuleActions.SET_NEWS;
  payload: INews[];
}

export interface ISetThumbnailsAction {
  type: NewsModuleActions.SET_THUMBNAILS;
  payload: IThumbnail[]
}

export const newsActions = {
  setNews(news: INews[]) {
    return {
      type: NewsModuleActions.SET_NEWS,
      payload: news
    };
  },

  setThumbnails(thumbnails: IThumbnail[]) {
    return {
      type: NewsModuleActions.SET_THUMBNAILS,
      payload: thumbnails
    };
  }
};
