import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userNewsFeedSelector, userThumbnailsSelector } from 'modules/user/store/selectors';
import { makeStyles } from '@material-ui/core/styles';

import { useService } from 'modules/core/hooks';
import { NewsService } from 'modules/user/services/news.service';
import { showApiErrors } from 'utils';
import { useSnackbar } from 'notistack';
import { currentSelectedRoundSelector, feVisualizationType } from 'modules/core/store/selectors';
import { NewsFeedCard } from './news-card'
import { SupportedCompanies } from 'config';
import { Typography } from '@material-ui/core';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { useTranslation } from 'react-i18next';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  noResults: {
    color: theme.palette.common.black
  }
}));

export const NewsFeed = () => {
  const { t } = useTranslation();
  const classes = makeClasses();

  const newsService = useService(NewsService);
  const newsSelector = useSelector(userNewsFeedSelector);
  const thumbnailsSelector = useSelector(userThumbnailsSelector);
  const currentRound = useSelector(currentSelectedRoundSelector);
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    try {
      await newsService.getNews({ page: 1, size: 5, asc: false });
    } catch (error) {
      showApiErrors(error, enqueueSnackbar);
    }
  }

  const feedNews = useMemo(() => newsSelector.map((news, i) =>
    <NewsFeedCard key={news.id} {...news} thumbnail={thumbnailsSelector.find(thumbnail => typeof news.externalId !== 'string' && thumbnail.id === news?.externalId?.id)} />
  ), [newsSelector, thumbnailsSelector])

  React.useEffect(() => {
    if (currentRound) {
      fetchData();
    }
  }, [currentRound]);

  return (
    <>
      {newsSelector.length ? feedNews : <Typography className={classes.noResults}>{t('userPanel.gameResults.noListGameResults')}</Typography>}
    </>
  );
};
