import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useService } from 'modules/core/hooks/useService';
import { StaticPagesService } from 'modules/core/services/static-pages.service';
import { Main, Section } from 'modules/user/components/layout';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import { _mockBackground } from '../../../../styles/utils';

const makeClasses = makeStyles(theme => ({
  welcomeRoot: {
    ..._mockBackground,
    flex: 1
  },
  clouds: {
    position: 'absolute',
    bottom: 0,
  },
  grass: {
    position: 'absolute',
    bottom: 0,
  },
  pageContent: {
    width: '80%',
    margin: '10% auto',
    backgroundColor: '#fff',
    padding: '20px',
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    top: '-50px',
    left: '0px',
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white
  }
}));

const GlobalPage: React.FC = () => {
  const classes = makeClasses();
  const staticPagesService = useService(StaticPagesService);
  const [pageContent, setPageContent] = React.useState<string | null>(null);
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (id) {
      staticPagesService.getStaticPageById(id).then(staticPage => {
      if (staticPage && staticPage.content) {
        setPageContent(staticPage.content);
      }
    }).catch(e => {
      history.push('/user');
    });
    }
  }, [id, history, staticPagesService]);

  return (<>
    <Main withGradient>
      <Section className={classes.welcomeRoot}>
        <div className={classes.pageContent}>
          <Button
          variant="outlined"
          size="medium"
          className={classes.backButton}
          onClick={() => history.push('/user')}
        >
          {t('common.back')}
        </Button>
          {!!pageContent && ReactHtmlParser(String(pageContent))}
        </div>
      </Section>
    </Main>
  </>);
};

export default GlobalPage;
