import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TableBody, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import { MyTableRow } from './my-table-row';
import { IMyTableBodyProps, IMyTableRow, IMyTableRowComponentProps } from './interfaces';
import {useLocation} from 'react-router-dom';
export type Props = IMyTableBodyProps;

const makeClasses = makeStyles(() => ({
  myTableBodyRoot: {

  }
}));

export const MyTableBody: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();
  const location = useLocation();

  const myClasses = makeClasses();

  const {
    classes,
    columns,
    Actions,
    FocusedActions,
    store,
    events,
    options,
    translations,
    extendsProps,
    overrides
  } = props;

  const {
    state,
  } = store;

  const { data } = state;

  const getRowProps = React.useCallback(
    (row: IMyTableRow<any>): IMyTableRowComponentProps => ({
      overrides,
      classes,
      columns,
      store,
      events,
      options,
      Actions,
      FocusedActions,
      translations,
      row,
      extendsProps,
      ...extendsProps?.rowProps,
    }),
    [store, overrides, extendsProps, Actions, FocusedActions, classes, columns, events, options, translations]
  );

  const rows = React.useMemo(
    () => {
      const _rows = data.map(row => <MyTableRow key={row.__table.uuid} {...getRowProps(row)} />);
      if (!_rows.length) {
        let colSpan = columns.length;
        if (Actions)
          colSpan++;
        if (options?.check || options?.checkAll)
          colSpan++;
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <Box textAlign='center' padding={.5}>
                <Typography component='span'>
                  {translations?.common?.noRows ?? (location.pathname.includes('admin/') ? t('adminPanel.common.table.noRows') : t('common.table.noRows'))}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        );
      }
      return _rows;
    },
    [options, columns, data, getRowProps, Actions, translations]
  );

  return <TableBody className={myClasses.myTableBodyRoot}>{rows}</TableBody>;
};
