import React from 'react';
import clsx from 'clsx';
import { TableRow, TableCell, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MyTableExpandRowType } from './interfaces';

const makeClasses = makeStyles({
  expandedRowRoot: {
    position: 'relative',
    zIndex: 0,
    borderColor: 'transparent',
    '&.expanded': {
      borderColor: '#ececec',
    }
  }
});


export const MyTableExpandRow: MyTableExpandRowType = (props) => {
  const classes = makeClasses();
  return (
    <TableRow>
      <TableCell
        className={clsx({ expanded: props.expanded })}
        classes={{ root: classes.expandedRowRoot }}
        colSpan={props.size}
        padding='none'
      >
        <Collapse
          unmountOnExit={true}
          in={props.expanded}>
          {props.children}
        </Collapse>
      </TableCell>
    </TableRow>
  )
};
