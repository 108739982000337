import { combineReducers } from 'redux';
import { Actions, UserStoreActions } from '../actions';
import * as QuizzesReducer from './quizzes.reducer';
import * as QuestsReducer from './quests.reducer';
import * as ScoreReducer from './score.reducer';
import * as RoundsReducer from './rounds.reducer';
import * as RankingReducer from './ranking.reducer';
import * as GalleryReducer from './gallery.reducer';
import * as TasksReducer from './tasks.reducer';
import * as FaqReducer from './faq.reducer';
import * as TrainingsReducer from './trainings.reducer';
import * as GroupsReducer from './groups.reducer';
import * as NewsReducer from './news.reducer';

import { IQuiz } from 'modules/user/interfaces/quiz.interface';
import { IQuest } from '../../interfaces/quest.interface';
import { IFaq } from '../../interfaces/faq.interface';
import { ITrainings } from '../../interfaces/trainings.interface';

export interface IUserModuleStoreState {
  quizzes: IQuiz[];
  quests: IQuest[];
  score: ScoreReducer.IListedUserScore;
  rounds: RoundsReducer.IListedRounds;
  ranking: RankingReducer.IRanking;
  gallery: GalleryReducer.IListedGallery;
  tasks: TasksReducer.IListedTasks;
  faq: IFaq[];
  trainings: ITrainings;
  groups: GroupsReducer.IListedGroups,
  news: NewsReducer.INewsListed
}

export const userModuleStoreInitialState: IUserModuleStoreState = {
  quizzes: { ...QuizzesReducer.quizzesModuleStoreInitialState },
  quests: { ...QuestsReducer.questsModuleStoreInitialState },
  score: { ...ScoreReducer.scoreModuleStoreInitialState },
  rounds: { ...RoundsReducer.roundsModuleStoreInitialState },
  ranking: { ...RankingReducer.rankingModuleStoreInitialState },
  gallery: { ...GalleryReducer.galleryModuleStoreInitialState },
  tasks: { ...TasksReducer.tasksModuleStoreInitialState },
  faq: { ...FaqReducer.faqModuleStoreInitialState },
  trainings: { ...TrainingsReducer.trainingsModuleStoreInitialState },
  groups: { ...GroupsReducer.groupsModuleStoreInitialState },
  news: { ...NewsReducer.newsModuleStoreInitialState }
};

export const userModuleStoreReducer = combineReducers<IUserModuleStoreState, Actions>({
  quizzes: QuizzesReducer.quizzesModuleStoreReducer,
  quests: QuestsReducer.questsModuleStoreReducer,
  score: ScoreReducer.scoreModuleStoreReducer,
  rounds: RoundsReducer.roundsModuleStoreReducer,
  ranking: RankingReducer.rankingModuleStoreReducer,
  tasks: TasksReducer.tasksModuleStoreReducer,
  faq: FaqReducer.faqModuleStoreReducer,
  trainings: TrainingsReducer.trainingsModuleStoreReducer,
  gallery: GalleryReducer.galleryModuleStoreReducer,
  groups: GroupsReducer.groupsModuleStoreReducer,
  news: NewsReducer.newsModuleStoreReducer,
});

export const rootReducer = (state: IUserModuleStoreState, action: Actions) => {
  if (action.type === UserStoreActions.SET_RESET_USER_STORE)
    state = {
      ...userModuleStoreInitialState
    }

  return userModuleStoreReducer(state, action);
};
