export const countOccurance = (list: string[]) => {
    const occurance: Record<string, number> = {};
    list.forEach((item) => {
      if (occurance[item]) {
        occurance[item] += 1;
      } else {
        occurance[item] = 1;
      }
    });
    return occurance;
  }