import { IMyTableRow } from './interfaces';
import _get from 'lodash/get';
import { v4 } from 'uuid';
import { debug } from 'utils';

export interface IPrepareRowPayload {
  index: number,
}

export const getRowUUID = (row: any, key?: string) => {
  const uuid =  (key && _get(row, key)) || row['id'] || row['uuid'];
  if (!uuid) {
    debug('MyTable:getRowUUID - Row UUID has not been found generating the new one (it may cause bugs)');
    return v4();
  }
  return uuid;
};

export const prepareRowTableData = (row: IMyTableRow, key?: string, index?: number): IMyTableRow => {
  const uuid = getRowUUID(row, key);
  if (!uuid)
    throw new Error('invalid uuid');
  return {
    ...row,
    __table: {
      uuid,
      index,
    }
  }
};
