import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const CheckRoundedSVG: React.FC<SVGComponentProps> = ({ fill, className }: SVGComponentProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path
      fill={fill}
      d="M10.998 4.994l-5.044 5.043a.581.581 0 01-.825 0l-2.71-2.71a.292.292 0 010-.413l.412-.412a.292.292 0 01.413 0L5.542 8.8l4.631-4.631a.292.292 0 01.412 0l.413.412a.292.292 0 010 .413zM7 0C3.14 0 0 3.14 0 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7z"
    />
  </svg>
);
