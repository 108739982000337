import { HttpClientService } from 'modules/core/functional-services';
import { AppService, getService, RoundsService, UsersService } from 'modules/core/services';
import { ScoreService } from 'modules/user/services/score.service';
import { RankingService } from 'modules/user/services/ranking.service';
import { TasksService } from 'modules/user/services/tasks.service';
import store from 'modules/core/store';
import { setCurrentSelectedRound } from 'modules/core/store/actions';
import { groupsActions } from 'modules/user/store/actions/groups.actions';
import { StaticPagesService } from 'modules/core/services/static-pages.service';

export class UserService {
  constructor(
    private appService = getService(AppService),
    private httpClientService = getService(HttpClientService),
    private scoreService = getService(ScoreService),
    private rankingService = getService(RankingService),
    private coreUsersService = getService(UsersService),
    private roundsService = getService(RoundsService),
    private tasksService = getService(TasksService),
    private staticPagesService = getService(StaticPagesService),
  ) {
  }

  getInitialData = async () => {
    await this.getRounds();
    await this.getScore();
    await Promise.all([
      this.getUserScore(),
      this.getGroups(),
      this.getStaticPages()
    ]);
  };

  // nav round selection dropdown
  getRounds = async () => {
    const { userModule } = store.getState();
    if (userModule.rounds.list.length === 0) {
      try {
        const roundsList = await this.roundsService.getRounds(undefined, true);
        const currentRound = await this.roundsService.getCurrentRound();
        if (roundsList.length) {
          this.roundsService.applyRounds(roundsList);
        }
        if (currentRound) {
          store.dispatch(setCurrentSelectedRound(currentRound));
        } else if (!currentRound && roundsList.length) {
          store.dispatch(setCurrentSelectedRound(roundsList[0]))
        }
      } catch (e) {
        return this.appService.handleError(e);
      }
    }
  };

  // groups assigned to user
  getGroups = async () => {
    const { userModule } = store.getState()
    if (userModule.groups.list.length === 0) {
      try {
        const state = store.getState();
        const groups = await this.coreUsersService.getGroupsAssignedToUser();
        store.dispatch(groupsActions.setGroups(groups));
        if (state.userModule.groups.selectedDashboardGroupId === '') {
          if (groups.length) {
            store.dispatch(groupsActions.setSelectedDashboardGroupId(groups[0].id));
            store.dispatch(groupsActions.setSelectedResultsGroupId(groups[0].id));
          } else {
            store.dispatch(groupsActions.setSelectedDashboardGroupId(null));
            store.dispatch(groupsActions.setSelectedResultsGroupId(null));
          }
        }
      } catch (e) {
        return this.appService.handleError(e);
      }
    }
  };

  // score feed wrapper
  getScore = async (force: boolean = false) => {
    const { userModule, core } = store.getState();
    if (userModule.score.list.length === 0 || force) {
      await this.scoreService.getScore(core.user.profile.id).then(scores => this.scoreService.applyScores(scores));
    }
  }

  // actual logged in user score
  getUserScore = async () => {
    // if (userModule.score.userScore.roundId === '-1') {
    await this.scoreService.getUserScorePerActualRound().then(score => score && this.scoreService.applyUserScore(score));
    // }
  }

  // static pages
  getStaticPages = async () => {
    const { core } = store.getState()
    if (core.staticPages.length === 0) {
      await this.staticPagesService.getStaticPages().then(staticPages => this.staticPagesService.applyStaticPages(staticPages));
    }
  }
}
