import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
import { IMultiSelectClassNamesProp, IMultiSelectTranslationProp } from './interfaces';

export interface IMultiSelectSearchbarProps {
  translations?: IMultiSelectTranslationProp;
  classNames?: IMultiSelectClassNamesProp;

  searchbarValue: string;
  onSearchBarValueChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
}

export type MultiSelectSearchBarType = React.FC<IMultiSelectSearchbarProps>;

const makeClasses = makeStyles(theme => ({
  searchbarRoot: {
    padding: `0 ${theme.spacing(4)}px`,
    position: 'sticky',
    top: 0,
    left: 0,
    background: theme.palette.common.white
  },
  searchbarInputRoot: {
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`
  }
}));

export const MultiSelectSearchBar: MultiSelectSearchBarType = props => {
  const classes = makeClasses();
  const { t } = useTranslation();
  return (
    <div className={clsx(classes.searchbarRoot, props.classNames?.searchbarRoot)}>
      <InputBase
        classes={{ root: clsx(classes.searchbarInputRoot, props.classNames?.searchbarInputRoot) }}
        placeholder={props.translations?.searchbarPlaceholder ?? t('common.search')}
        value={props.searchbarValue}
        onChange={props.onSearchBarValueChange}
        autoFocus
      />
    </div>
  );
};
