import { HttpClientService } from 'modules/core/functional-services';
import { AppService, getService } from 'modules/core/services';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { ISurvey, ISurveyUserAnswers } from 'interfaces/survey.interface';
import { APIPaths } from 'config/paths';
import { IPagination } from 'interfaces/pagination.interface';
import { IPagedResponse } from 'interfaces/paged-data.interface';

export class SurveyService {

  constructor(
    private appService = getService(AppService),
    private httpClientService = getService(HttpClientService)
  ) {
  }

  async getSurveysList() {
    try {
      const pagination: IPagination = {
        page: 1,
        size: 100
      }
      const { value } = await this.httpClientService.get<IPagedResponse<ISurvey>>(APIPaths.SURVEYS, {
        params: {
          ...pagination
        }
      });
      return value.records
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getSurveyById(id: string) {
    try {
      const { value }: IAPIResponse<ISurvey> = await this.httpClientService.get<ISurvey>(`${APIPaths.SURVEYS}/${id}`)
      return value
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async sendSurveyAnswers(surveyId: string, answers: ISurveyUserAnswers[]) {
    try {
      await this.httpClientService.post(`${APIPaths.SURVEYS}/${surveyId}/user-answers`, {
        surveyId,
        answers
      })

    } catch (e) {
      return this.appService.handleError(e);
    }
  }

}
