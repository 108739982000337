import { IPagedResponse } from '../interfaces/paged-data.interface';
import { IPagination } from '../interfaces/pagination.interface';

export const getPagedParams = (paged: IPagedResponse<any>): IPagination => ({
  ...(paged.term !== undefined ? { term: paged.term } : {}),
  ...(paged.page !== undefined ? { page: paged.page } : {}),
  ...(paged.size !== undefined ? { size: paged.size } : {}),
  ...(paged.recordsCount !== undefined ? { total: paged.recordsCount } : {})
});

export const getResponsePagination = <T extends any>(response: IPagedResponse<T>): IPagination => getPagedParams(response);