import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MyTablePagination} from './my-table-pagination';
import { MyTableFooterType } from './interfaces';

const makeClasses = makeStyles(theme => ({
  root: {
    position: 'sticky',
    bottom: 0,
    marginTop: 'auto',
    background: '#fafafa',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      width: '100%',
      left: 0
    }
  }
}));

export const MyTableFooter: MyTableFooterType = (props) => {

  const classes = makeClasses();

  const {
    store,
    options,
    events
  } = props;

  const Pagination = React.useMemo(
    () => {
      if (options?.pagination === true) {
        if (store.state.pagination) {
          return <MyTablePagination events={events} pagination={store.state.pagination} />
        }
      }
      return null;
    },
    [events, options, store.state.pagination]
  );

  return (
    <div className={classes.root}>
      {Pagination}
    </div>
  )
};