import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tenantLogoSelector } from 'modules/core/store/selectors'

const makeClasses = makeStyles<DefaultTheme, { onClickEnabled: boolean }>(theme => (
  {
  navBrand: {
    display: 'flex',
    alignItems: 'center',
    // marginRight: 'auto',
    cursor: props => (props.onClickEnabled ? 'pointer' : 'default'),

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: 0
    }
  },
  navBrandImg: {
    maxWidth: theme.tenant.logo.maxWidth,
    height: theme.tenant.logo.height,
    borderRadius: theme.tenant.logo.borderRadius,
    minWidth: theme.tenant.logo.minWidth,

    [theme.breakpoints.down('sm')]: {
      height: theme.tenant.logo.mobileHeight,
      minWidth: theme.tenant.logo.mobileMinWidth,
    }
  },
  navBrandImgRabenManager: {
    marginLeft: '20px',
    maxWidth: "100%",
    height: 60,

    [theme.breakpoints.down('lg')]: {
      height: 46
    },
    [theme.breakpoints.down('md')]: {
      height: 36
    }
  },
  navBrandImgGrowUperion: {
    maxWidth: "100%",
    height: 60,

    [theme.breakpoints.down('md')]: {
      height: 30
    }
  },
}));

export interface INavBrandProps {
  before?: React.ComponentType;
  after?: React.ComponentType;
  linkTo?: string | undefined;
}

export type Props = INavBrandProps;


export const NavBrand: React.FC<Props> = (props: Props) => {
  const { before: Before, after: After, linkTo } = props;
  const tenantLogo = useSelector(tenantLogoSelector);
  const classes = makeClasses({ onClickEnabled: !!linkTo });

  return (
      <div className={classes.navBrand}>
        {Before && <Before/>}
        <Link to={linkTo ? linkTo : '/user/'}>
        <img src={tenantLogo} alt={'Tenant logo'} className={classes.navBrandImg}/>
        </Link>
        {After && <After/>}
      </div>
  );
};
