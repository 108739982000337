import { IRanking } from 'modules/user/store/reducer/ranking.reducer';
import { IPagedData } from 'interfaces/paged-data.interface';
import { IRankingsFilters, IRankingPerGroup, IRankingList } from 'interfaces/ranking.interface';

export enum RankingModuleActions {
  SET_USER_SET_RANKINGS = 'SET_USER_SET_RANKINGS',
  SET_DASHBOARD_RANKING = 'SET_DASHBOARD_RANKING',
  SET_BLOCK_RANKING = 'SET_BLOCK_RANKING'
}

export interface ISetRankings {
  type: RankingModuleActions.SET_USER_SET_RANKINGS;
  payload: IRanking;
}

export const setRankings = (ranking: IRanking) => {
  return {
    type: RankingModuleActions.SET_USER_SET_RANKINGS,
    payload: ranking
  };
};

export interface ISetDashboardRanking {
  type: RankingModuleActions.SET_DASHBOARD_RANKING;
  payload: IPagedData<IPagedData>;
}

export const setDashboardRanking = (dashboardRanking: IPagedData<IRankingPerGroup, IRankingsFilters>) => {
  return {
    type: RankingModuleActions.SET_DASHBOARD_RANKING,
    payload: dashboardRanking,
  };
};
export interface ISetGameResultGroupRanking {
  type: RankingModuleActions.SET_BLOCK_RANKING;
  payload: IPagedData<IPagedData>;
}

export const setGameResultGroupRanking = (gameresultRanking: IPagedData<IRankingPerGroup>) => {
  return {
    type: RankingModuleActions.SET_BLOCK_RANKING,
    payload: gameresultRanking,
  };
};


export const rankingActions = {
  setRankings,
  setDashboardRanking,
};

export type Actions = ISetRankings & ISetDashboardRanking & ISetGameResultGroupRanking;
