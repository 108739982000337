import React from 'react';
import { Appbar } from 'modules/core/components/common';
import { NavBrand } from './nav-brand';

export interface UnauthNavProps {
  noAuthRedirect?: boolean;
}

export const UnauthNav: React.FC<UnauthNavProps> = (props) => {
  return (
    <Appbar>
      <NavBrand linkTo={props.noAuthRedirect ? undefined : '/auth'} />
    </Appbar>
  );
};
