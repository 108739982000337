import { AdminModuleTableViews } from '../../const';
import { IStoreState } from 'modules/core/store/store';
import { IWorkerGroup } from 'interfaces/worker-group.interface';
import { IRound } from 'interfaces/round.interface';
import { IAPUser } from 'interfaces/user.interface';
import { IQuestAnswer } from 'interfaces/quest.interface';
import { IPagedListData } from 'interfaces/paged-list-data';
import { IAuditEvent, IAuditEventsFilters } from 'interfaces/admin/audit-event';
import { IQuizAnswer } from 'interfaces/quiz.interface';
import { IStaticPage } from 'interfaces/admin/static-page';
import { ITrainingRequests } from 'interfaces/trainings.interface';

// Common selectors
export const commonGroupsSelector = (state: IStoreState): IWorkerGroup[] => state.adminModule.common.groups;
export const commonRoundsSelector = (state: IStoreState): IRound[] => state.adminModule.common.rounds;
export const commonUsersSelector = (state: IStoreState): IAPUser[] => state.adminModule.common.users;
export const visualizationTypesSelector = (state: IStoreState) => state.adminModule.common.visualizationTypes;
export const regulationsSelector = (state: IStoreState) => state.adminModule.common.regulations;
export const commonClaimsSelector = (state: IStoreState) => state.adminModule.common.claims;
export const claimsLoadedSelector = (state: IStoreState) => state.adminModule.common.claimsLoaded;
export const flowItemTypeSelector = (state: IStoreState) => state.adminModule.common.flowItemTypes;
export const recentSelectedUsersSelector = (state: IStoreState) => state.adminModule.common.recentSelectedUsers;

//  Table selectors

//    Worker Groups
export const groupsListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.WORKER_GROUPS].data;
export const groupsPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.WORKER_GROUPS].pagination;

//    User
export const usersListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.USERS].data;
export const usersListPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.USERS].pagination;

//    Files
export const filesListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.FILES].data;
export const filesPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.FILES].pagination;

//    Quest
export const questListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.QUESTS].data;
export const questPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.QUESTS].pagination;

//    Quest Answers
export const questAnswersSelector = (state: IStoreState): IQuestAnswer[] => state.adminModule.tables[AdminModuleTableViews.QUESTS_ANSWERS];

//    Email Template
export const emailTemplatesListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.EMAIL_TEMPLATES].data;
export const emailTemplatesPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.EMAIL_TEMPLATES].pagination;

//    News
export const newsListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.NEWS].data;
export const newsPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.NEWS].pagination;

// Syrveys
export const surveyListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.SURVEYS].data;
export const surveyPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.SURVEYS].pagination;

// Training Requests
export const trainingRequestesListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.TRAINING_REQUESTS].data;
export const trainingPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.TRAINING_REQUESTS].pagination;
// Training Budget
export const trainingBudgetListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.TRAINING_BUDGET].data;
export const trainingBudgetPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.TRAINING_BUDGET].pagination;

//    Quiz
export const quizListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.QUIZZES].data;
export const quizPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.QUIZZES].pagination;

export const quizAnswersToReviewSelector = (state: IStoreState): IQuizAnswer[] => state.adminModule.tables[AdminModuleTableViews.QUIZZES_ANSWERS_TO_REVIEW].data;
export const quizAnswersToReviewPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.QUIZZES_ANSWERS_TO_REVIEW].pagination;

export const quizAnswersSelector = (state: IStoreState): IQuizAnswer[] => state.adminModule.tables[AdminModuleTableViews.QUIZZES_ANSWERS].data;
export const quizAnswersPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.QUIZZES_ANSWERS].pagination;

//    Knowledge Pages
export const knowledgePagesListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.KNOWLEDGE_PAGES].data;
export const knowledgePagesPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.KNOWLEDGE_PAGES].pagination;

//    Roles
export const rolesListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.ROLES].data;
export const rolesPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.ROLES].pagination;

//    Flow
export const flowListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.FLOWS].data;
export const flowPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.FLOWS].pagination;


//    Activities
export const activitiesListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.ACTIVITES].data;
export const activitiesPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.ACTIVITES].pagination;

export const activitiesImportHistoryListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.ACTIVITIES_IMPORT_HISTORY].data;
export const activitiesImportHistoryPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.ACTIVITIES_IMPORT_HISTORY].pagination;
// KPIs
export const kpiListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.KPIS].data;
export const kpiPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.KPIS].pagination;

export const kpiAssignmentListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.KPI_ASSIGNMENTS].data;
export const kpiAssignmentPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.KPI_ASSIGNMENTS].pagination;

// FAQ
export const faqListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.FAQ].data;
export const faqListPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.FAQ].pagination;

//    Other
export const auditEventsSelector = (state: IStoreState): IPagedListData<IAuditEvent, IAuditEventsFilters> => state.adminModule.data.auditEvents;
export const staticPagesSelector = (state: IStoreState): IStaticPage[] => state.adminModule.data.staticPages;

// Suggestions
export const suggestionsListSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.SUGGESTIONS].data;
export const suggestionsListPaginationSelector = (state: IStoreState) => state.adminModule.tables[AdminModuleTableViews.SUGGESTIONS].pagination;