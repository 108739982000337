import { HttpClientService } from 'modules/core/functional-services/httpClient.service';
import { setCommonUsers, setUsersTableData, setRecentSelectedUsers } from 'modules/admin/store/actions';
import { usersListPaginationSelector } from 'modules/admin/store/selectors';
import { AppService, getService } from 'modules/core/services';
import { APIPaths } from 'config/paths';
import {
  IAPUser,
  IAPUserRaw,
  IUserPersonalData,
  IUserProfile,
  IRecentUserForPicker
} from 'interfaces/user.interface';
import { IPagedResponse } from 'interfaces/paged-data.interface';
import { ImportUsersResult } from 'interfaces/admin/import-users-result';
import { IPagination } from 'interfaces/pagination.interface';
import store from 'modules/core/store';
import { IQuest, IUserAssignedQuest } from 'interfaces/quest.interface';
import { IRound } from 'interfaces/round.interface';

export class AdminUsersService {
  constructor(
    private appService = getService(AppService),
    private httpClientService = getService(HttpClientService)
  ) { }

  applyAllUsers = (users: IAPUser[]) => {
    store.dispatch(setCommonUsers(users));
  };

  applyUsersList = (usersList: IAPUser[], pagination?: IPagination) => {
    store.dispatch(setUsersTableData(usersList, pagination));
  };

  addRecentSelectedUsers = (users: IRecentUserForPicker[]) => {
    store.dispatch(setRecentSelectedUsers(users))
  }

  fetchUsersList = async (pagination: IPagination = usersListPaginationSelector(store.getState())) => {
    try {
      const response = await this.httpClientService.get<IPagedResponse<IAPUser>>(APIPaths.USER_MANAGE, {
        params: pagination
      });
      return response.value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };
  fetchUsersByIds = async (userIds: string[]) => {
    try {
      const response = await this.httpClientService.get<IPagedResponse<IAPUser>>(APIPaths.USER_MANAGE, {
        params: {
          userIds
        }
      })
      return response.value;

    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  fetchUserQuests = async (userId: string) => {
    try {
      const { value: quests } = await this.httpClientService.get<IUserAssignedQuest[]>(`${APIPaths.USER_MANAGE}/${userId}/quests`);
      return quests;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

  addQuestAssigment = (quest: IQuest, round: IRound, user: IAPUser) => this.httpClientService.post(`${APIPaths.QUESTS_MANAGE}/${quest.id}/users`, {
    id: quest.id,
    assignments: [
      {
        userId: user.id,
        roundId: round.id
      }
    ]
  }).catch(this.appService.handleError);

  removeUserQuestAssignment = (assignedQuest: IUserAssignedQuest) => this.httpClientService.delete(`${APIPaths.QUESTS_MANAGE}/${assignedQuest.quest.id}/users`, {
    data: {
      assignments: [
        {
          id: assignedQuest.id
        }
      ]
    }
  }).catch(this.appService.handleError);

  editUser = (user: IAPUserRaw) => this.httpClientService.put(APIPaths.USER_MANAGE, user).catch(this.appService.handleError);
  editUserPersonalData = (user: IUserPersonalData) => this.httpClientService.put(APIPaths.USERS, user).catch(this.appService.handleError);
  addUser = (user: IAPUserRaw) => this.httpClientService.post(`${APIPaths.USER_MANAGE}/add`, user).catch(this.appService.handleError);
  resetUserPassword = (email: string) => this.httpClientService.post(`${APIPaths.USERS}/password-reset-token`, {
    email
  }).catch(this.appService.handleError);

  changeUserPassword = (userId: string, password: string) => this.httpClientService.post(`${APIPaths.USER_MANAGE}/change-user-password`, {
    userId,
    password
  }).catch(this.appService.handleError);

  importUsers = (formData: FormData) => this.httpClientService.post<FormData, ImportUsersResult>(`${APIPaths.USER_MANAGE}/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).catch(this.appService.handleError);
}
