import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { ClockSVG } from 'modules/core/components/layout/svg/clock/clock';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { ITask } from 'modules/user/interfaces/task.interface';

interface MakeClassesProps {
  clickable: boolean;
}

const makeClasses = makeStyles<DefaultTheme, MakeClassesProps>(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`,
    cursor: props => props.clickable ? 'pointer' : 'default',
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  texts: {
    paddingLeft: theme.spacing(2)
  },
  heading: {
    ...theme.mixins.fonts.bold.xs
  },
  text: {
    ...theme.mixins.fonts.regular.xxs,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey['500']
  },
  greenText: {
    ...theme.mixins.fonts.bold.xxs,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main
  },
  clock: {
    fill: 'none',
    stroke: theme.palette.grey['500']
  },
  greenClock: {
    fill: theme.palette.secondary.main
  },
  padded: {
    paddingTop: 2,
    paddingLeft: theme.spacing(1)
  },
  button: {
    minWidth: 40,
    minHeight: 40,
    maxWidth: 40,
    maxHeight: 40
  },
  regularButton: {
    color: theme.palette.common.white
  },
  arrow: {
    fill: theme.palette.grey['500'],
    cursor: 'pointer'
  }
}));

export interface TaskRowProps {
  stage?: number;
  heading: string;
  days?: number;
  onClick?: (event: React.MouseEvent, task: ITask) => any;
  task: ITask;
  className?: string;
  isNew?: boolean;
}

export const TaskRow: React.FC<TaskRowProps> = props => {
  const { className, stage, heading, days, isNew, onClick, task } = props;
  const classes = makeClasses({ clickable: !!onClick && typeof onClick === 'function' });

  const bottomText = `day${days === 1 ? '' : 's'} to finish`;

  const handleTaskClick = (event: React.MouseEvent) => {
    if (!!onClick && typeof onClick === 'function') {
      onClick(event, task);
    }
  };

  return (
    <div className={clsx(classes.root, className)} onClick={handleTaskClick}>
      <div className={classes.content}>
        {!!stage && <Button
          className={clsx(classes.button, isNew ? classes.newButton : classes.regularButton)}
          color="secondary"
          variant={isNew ? 'outlined' : 'contained'}
        >
          {`S${stage}`}
        </Button>}
        <div className={classes.texts}>
          <div className={`${classes.heading}`}>{heading}</div>
          {!!days && <div className={isNew ? classes.greenText : classes.text}>
            <ClockSVG className={isNew ? classes.greenClock : classes.clock}/>
            <span className={classes.padded}>{`${days} ${isNew ? bottomText.toUpperCase() : bottomText}`}</span>
          </div>}
        </div>
      </div>
      <NavigateNext className={classes.arrow}/>
    </div>
  );
};
