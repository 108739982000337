import { Reducer } from 'redux';
import { Actions, GroupsModuleActions } from '../actions/groups.actions';

import { IUserGroup } from 'interfaces/user.interface';

export const groupsModuleStoreInitialState: IListedGroups = {
  list: [],
  selectedDashboardGroupId: '',
  selectedResultsGroupId: ''
};

export interface IListedGroups {
  list: IUserGroup[],
  selectedDashboardGroupId: string | null,
  selectedResultsGroupId: string | null,
}

export const groupsModuleStoreReducer: Reducer<IListedGroups, Actions> = (
  state: IListedGroups = groupsModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case GroupsModuleActions.SET_GROUPS:
      return {
        ...state,
        list: [...payload.list]
      };
    case GroupsModuleActions.SET_SELECTED_DASHBOARD_GROUP:
      return {
        ...state,
        selectedDashboardGroupId: payload.selectedDashboardGroupId
      };
      case GroupsModuleActions.SET_SELECTED_RESULTS_GROUP:
      return {
        ...state,
        selectedResultsGroupId: payload.selectedResultsGroupId
      };
    default:
      return state;
  }
};