import { HttpClientService } from 'modules/core/functional-services';
import { AppService, getService } from 'modules/core/services';
import { IAPIResponse } from 'interfaces/api-response.interface';
import { ITotalBagdes } from '../interfaces/badges.interface';


export class BadgesService {
  constructor(private appService = getService(AppService), private httpClientService = getService(HttpClientService)) {
  }

  async getTotalBadges() {
    try {
      const { value }: IAPIResponse<ITotalBagdes> = await this.httpClientService.get('/badges/total');
      return value;
    } catch (e) {
      return this.appService.handleError(e);
    }
  };

}
