import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const makeClasses = makeStyles(theme => ({
  grid: {
    flexGrow: 1,
    marginTop: 20
  }
}));

export interface IGalleryComponent {
  children: React.ReactNode;
}

export type GalleryProps = React.PropsWithChildren<IGalleryComponent>;

export const Gallery = ({ children }: GalleryProps) => {
  const classes = makeClasses();

  return (
    <div className={classes.grid}>
      <Grid container spacing={3} justify="center" alignItems="center" alignContent="center">
        <Grid container item xs={12} spacing={1} alignItems="flex-end" alignContent="center">
          {React.Children.map(children, (child, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              {child}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};
