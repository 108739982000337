import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const BlockSvg: React.FC<SVGComponentProps> = ({ className }: SVGComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="77.227" height="60.755" viewBox="0 0 77.227 60.755">
    <defs>
      <filter id="Path_4085" x="29.87" y="13.771" width="47.358" height="46.984" filterUnits="userSpaceOnUse">
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#efefef" />
        <stop offset="1" stopColor="#a4a4a4" />
      </linearGradient>
    </defs>
    <g id="Group_2797" data-name="Group 2797" transform="translate(0 0)">
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_4085)">
        <path id="Path_4085-2" data-name="Path 4085" d="M482.3,498.281l-27.414,15.9v10.361l27.385-15.912a3.313,3.313,0,0,0,1.941-2.719q.019-5.178.032-10.352A3.32,3.32,0,0,1,482.3,498.281Z" transform="translate(-416.02 -475.79)" fill="#a4a4a4" />
      </g>
      <path id="Path_4086" data-name="Path 4086" d="M434.98,498.3a3.343,3.343,0,0,1-1.973-2.757l-.03,10.354a3.345,3.345,0,0,0,1.971,2.757l27.581,15.935V514.206Z" transform="translate(-432.977 -475.808)" fill="#f0f0f0" />
      <g id="Group_2785" data-name="Group 2785" transform="translate(29.479 38.369)">
        <g id="Group_2784" data-name="Group 2784">
          <path id="Path_4087" data-name="Path 4087" d="M459.078,506.046l-.03,10.354a10.421,10.421,0,0,1-9.453,0l.028-10.354A10.42,10.42,0,0,0,459.078,506.046Z" transform="translate(-449.595 -506.046)" fill="url(#linear-gradient)" />
        </g>
      </g>
      <path id="Path_4088" data-name="Path 4088" d="M499.218,501.429c2.617,1.513,2.634,3.963.03,5.478l-27.321,15.878a10.419,10.419,0,0,1-9.455,0l-27.5-15.882c-2.618-1.511-2.636-3.959-.032-5.474l27.325-15.878a10.41,10.41,0,0,1,9.453,0Z" transform="translate(-432.965 -484.416)" fill="#fafafa" />
      <line id="Line_90" data-name="Line 90" x2="25.709" y2="14.856" transform="translate(4.149 1.334)" fill="#cfe9ff" />
    </g>
  </svg>
);