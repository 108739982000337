import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { ITask } from 'modules/user/interfaces/task.interface';
import { IconsNames, useIcons } from 'modules/core/hooks';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const makeClasses = makeStyles<DefaultTheme, { clickable: boolean }>(theme => ({
  root: {
    cursor: props => props.clickable ? 'pointer' : 'default',
    margin: '10px 0'
  },
  name: {
    ...theme.mixins.fonts.bold.xs,
    color: theme.palette.common.black
  },
  status: {
    ...theme.mixins.fonts.regular.xxs,
    color: theme.palette.common.black
  },
  icon: {
    width: theme.icons.sizes.small,
    height: theme.icons.sizes.small,
    marginRight: 5
  }
}));

export interface ITaskItemProps {
  task: ITask,
  onClick?: (event: React.MouseEvent, task: ITask) => any;
}

export const TaskFeedItem: React.FC<ITaskItemProps> = (props) => {
  const { name, maxTokens, status } = props.task;
  const classes = makeClasses({ clickable: !!props.onClick && typeof props.onClick === 'function' });
  const { t } = useTranslation();
  const starIcon = useIcons(
    IconsNames.RANKING_STAR,
    classes.icon
  );
  const handleTaskClick = (event: React.MouseEvent) => {
    if (!!props.onClick && typeof props.onClick === 'function') {
      props.onClick(event, props.task);
    }
  };
  return (
    <Grid wrap='nowrap' container alignItems='center' justify='space-between' direction='row' onClick={handleTaskClick} className={classes.root}>
      <Grid item xs={9}>
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.status}>{t(`userPanel.tasks.status.${status}`)}</Typography>
      </Grid>
      <Grid container xs={3} item alignItems='center' direction='row' wrap='nowrap' justify='flex-end'>
        {starIcon}
        <Typography>{maxTokens}</Typography>
      </Grid>
    </Grid>
  )
}

export default TaskFeedItem;