import { Reducer } from 'redux';
import { INews, IThumbnail } from '../../../../interfaces/news.interface';
import { Actions, NewsModuleActions } from '../actions/news.actions';

export interface INewsListed {
  list: INews[],
  thumbnails: IThumbnail[],
}

export const newsModuleStoreInitialState: INewsListed = { list: [], thumbnails: [] };

export const newsModuleStoreReducer: Reducer<INewsListed, Actions> = (
  state: INewsListed = newsModuleStoreInitialState,
  action: Actions
) => {
  const { type, payload } = action;
  switch (type) {
    case NewsModuleActions.SET_NEWS:
      return {
        ...state,
        list: payload
      };

    case NewsModuleActions.SET_THUMBNAILS:
      return {
        ...state,
        thumbnails: payload
      };
    default:
      return state;
  }
};
