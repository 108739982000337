import React from 'react';
import { useService } from 'modules/core/hooks';
import { AuthService } from 'modules/core/services';
import { useHistory, Redirect } from 'react-router-dom';
import { LoaderView } from 'modules/core/views';
import { Config, SupportedCompanies } from 'config';
import { useTranslation } from 'react-i18next';
import { ExternalLoginView } from './components/external-login-view';
import { ExternalLoginLink } from 'modules/core/views/auth/components/external-login-link';
import { forceHttps, pageOpenedInIFrame } from 'utils/window-utils';

export const IFrameExternalLogin = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const authService = useService(AuthService);

  // console.log('wchodzi w iframe?!');


  if (authService.isAuthorized) {
    history.replace('/user');
    return (
      <LoaderView
        visible
      />
    );
  }

  if (Config.COMPANY === SupportedCompanies.GU) {
    if (pageOpenedInIFrame()) {
      // The page is in an iframe - show message + link to open login page
      return (
        <ExternalLoginView
          title={t('auth.externalLogin.title')}
        >
          {`${t('auth.externalLogin.description')} `}
          <ExternalLoginLink>
            {t('auth.externalLogin.loginLink')}
          </ExternalLoginLink>
        </ExternalLoginView>
      );
    } else {
      // The page is not in an iframe - redirect automatically to the external login page + force https (MS login works only by https)
    //  console.log("przekierowuje tutaj!");
    window.location.replace(`${forceHttps(window.location.origin)}/accounts/external?urlQuery=${encodeURIComponent('/user/tenants?logout=true')}`);
    return (
        <LoaderView
          visible
        />
      );
    }
  }

  return (
    <Redirect to={'/auth'} />
  );
};
