import { IQuest } from '../../interfaces/quest.interface';

export enum QuestsModuleActions {
  SET_QUESTS = 'SET_QUESTS'
}

export type Actions = ISetQuestsAction;

export interface ISetQuestsAction {
  type: QuestsModuleActions.SET_QUESTS;
  payload: IQuest[];
}

export const questsActions = {
  setQuests(quests: IQuest[]) {
    return {
      type: QuestsModuleActions.SET_QUESTS,
      payload: quests
    };
  }
};
