import React from 'react';
import { SVGComponentProps } from 'modules/core/components/layout/svg/index';

export const PlusMinusSVG: React.FC<SVGComponentProps> = ({ className }: SVGComponentProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="11" height="10.394" viewBox="0 0 11 10.394">
    <g transform="translate(15798.375 -14460.813)">
      <line className={className} x1="7.75" y2="7" transform="translate(-15796.5 14463.5)" />
      <line className={className} x2="5" transform="translate(-15792.875 14470.5)" />
      <g transform="translate(36)">
        <line className={className} x2="5" transform="translate(-15833.875 14463.813)" />
        <line className={className} x2="5" transform="translate(-15831.374 14461.313) rotate(90)" />
      </g>
    </g>
  </svg>
);
