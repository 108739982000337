import React from 'react';
import parse  from 'html-react-parser';
import { parseToComponents } from 'modules/core/components/common/rich-editor/parser-options/parse-to-components';
import { parseToText } from 'modules/core/components/common/rich-editor/parser-options/parse-to-text';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

export type Parser = 'component' | 'text';

export const pickParser = (parserType?: Parser) => {
  switch (parserType) {
    case 'component': return parseToComponents;
    case 'text': return parseToText;
    default: return parseToComponents;
  }
};

export interface RichTextParserProps {
  html?: string,
  parser?: 'component' | 'text',
}

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  richText: {
    padding: 0,
    wordBreak: 'break-word'
  },
  containerFix: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    position: 'relative',
    '& .ql-container': {
      width: '100%'
    }
  },
}))

export const RichTextParser: React.FC<RichTextParserProps> = (props: RichTextParserProps) => {
  const classes = makeClasses();
  try {
    const parsedContent = parse(props.html || '', {
      replace: pickParser(props.parser),
    });
    if (props.html) {
      return (
        <div className={`${classes.containerFix}`}>
          <div className={`ql-container`}>
            <div className={`${classes.richText} ql-editor`}>
              {parsedContent}
            </div>
          </div>
        </div>
      );
    }
    return null;
  } catch (e) {
    return null;
  }
};
