import { Avatar, Badge } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { UsersService } from 'modules/core/services';
import { useService } from 'modules/core/hooks';
import { showApiErrors } from 'utils/show-errors';
import { useSnackbar } from 'notistack';
import { ApiError } from 'utils/api-error';

const makeClasses = makeStyles<DefaultTheme, { maxHeight: string }>(theme => ({
  image: {
    width: props => (props.maxHeight ? 'auto' : '100%'),
    height: props => (props.maxHeight ? '100%' : 'auto'),
    objectFit: 'contain',
    maxWidth: props => (props.maxHeight !== 'unset' ? 'unset' : '40px'),
    maxHeight: props => props.maxHeight
  },
}));

const badgesR: Record<string, string> = {
  "1d83aa82-4180-4f70-a162-08db86ac7ba0": "/static/img/badges/lucky_lottery.png",
  "dd8f3153-0e76-4006-a163-08db86ac7ba0": "/static/img/badges/genius_at_work.png",
  "03ed9bfe-44a7-4d23-a164-08db86ac7ba0": "/static/img/badges/express_delivery.png",
  "cce1facd-d96a-45c0-a165-08db86ac7ba0": "/static/img/badges/early_bird.png",
  "5c7dc651-72f7-4bc5-a166-08db86ac7ba0": "/static/img/badges/terminator.png",
  "bdc9c301-b99f-40d7-3e32-08dbd8b471c1": "/static/img/badges/starszy_specjalista_1.png",
  "ff017197-f812-43ca-3e33-08dbd8b471c1": "/static/img/badges/starszy_specjalista_2.png",
  "da5c0b55-7e81-48ed-3e34-08dbd8b471c1": "/static/img/badges/starszy_specjalista_3.png",
}

interface Props {
  badgeId: string;
  maxHeight?: string;
  occurance?: number;
}
export const BadgePreview = ({ badgeId, maxHeight, occurance = 0 }: Props) => {
  const classes = makeClasses({ maxHeight: maxHeight ?? 'unset' });

  const [badgeSrc, setBadgeSrc] = useState<string>('');
  const usersService = useService(UsersService);
  const { enqueueSnackbar } = useSnackbar();

  const getAvatar = async (id: string) => {
    try {
      if (badgesR.hasOwnProperty(id)) { // if badge is in the list of static badges then use it
        setBadgeSrc(badgesR[id]);
        return;
      }
      const response = await usersService.fetchBadge(id);
      if (response) {
        setBadgeSrc(response);
      }
    } catch (error) {
      showApiErrors(error as ApiError, enqueueSnackbar);
    }
  };
  useEffect(() => {
    if (badgeId) {
      getAvatar(badgeId);
    }
  }, []);
  if (occurance === 0) {
    return <img src={badgeSrc} alt="" className={classes.image} />;
  } else {
    return (
      <Badge badgeContent={occurance === 1 ? 0 : occurance} color="primary" overlap='circle' showZero={false}>
        <img src={badgeSrc} alt="" className={classes.image} />
      </Badge>
    )
  }
};
