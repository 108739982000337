import { IRound } from 'interfaces/round.interface';
import { IUserProfile } from 'interfaces/user.interface';
import { IFESettingsType, IBEGroupSettings, ITenantLogo } from 'interfaces/settings.interface';
import { IStaticPages } from 'interfaces/static-pages.interface';
import { IRole } from 'interfaces/role.interface';
import { Errors } from 'const';
import { ILanguage } from 'interfaces/language-user.interface';

export enum CoreStoreActions {
  SET_SHOW_LOADER = 'SET_SHOW_LOADER',
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
  SET_CURRENT_MODULE = 'SET_CURRENT_MODULE',
  SET_SHOWED_MESSAGE = 'SET_SHOWED_MESSAGE',
  SET_SHOWED_NEWS_MESSAGE = 'SET_SHOWED_NEWS_MESSAGE',
  SET_APP_CLAIMS = 'SET_APP_CLAIMS',
  SET_CURRENT_SELECTED_ROUND = 'SET_CURRENT_SELECTED_ROUND',
  SET_ERROR_TYPE = 'SET_ERROR_TYPE',
  SET_USER_PROFILE = 'SET_USER_PROFILE',
  SET_USER_ROLES = 'SET_USER_ROLES',
  SET_SHOWED_MESSAGE_RESULTS = 'SET_SHOWED_MESSAGE_RESULTS',
  SET_USER_AVATAR_URL = 'SET_USER_AVATAR_URL',
  SET_STATIC_PAGES = 'SET_STATIC_PAGES',
  SET_CORE_SERVICES_READY = 'SET_CORE_SERVICES_READY',
  SET_RESET_CORE_STORE = 'SET_RESET_CORE_STORE',
  SET_LANGUAGES = 'SET_LANGUAGES',
  SET_FE_SETTINGS = 'SET_FE_SETTINGS',
  SET_TENANT_LOGO = 'SET_TENANT_LOGO',
}

export interface ISetUserAvatarURL {
  type: CoreStoreActions.SET_USER_AVATAR_URL,
  payload: {
    avatarURL: string,
  }
}

export const setUserAvatarURL = (avatarURL: string) => ({
  type: CoreStoreActions.SET_USER_AVATAR_URL,
  payload: {
    avatarURL
  }
});

export interface ISetFeSettings {
  type: CoreStoreActions.SET_FE_SETTINGS,
  payload: {
    settings: IFESettingsType,
  }
}

export const setFeSettings = (settings: IFESettingsType) => ({
  type: CoreStoreActions.SET_FE_SETTINGS,
  payload: {
    settings
  }
})

export interface ISetTenantLogo {
  type: CoreStoreActions.SET_TENANT_LOGO,
  payload: {
    tenantLogo: ITenantLogo,
  }
}

export const setTenantLogo = (tenantLogo: ITenantLogo) => ({
  type: CoreStoreActions.SET_TENANT_LOGO,
  payload: {
    tenantLogo
  }
})
export interface ISetUserRoles {
  type: CoreStoreActions.SET_USER_ROLES,
  payload: {
    roles: IRole[],
  }
}

export const setUserRoles = (roles: IRole[]) => ({
  type: CoreStoreActions.SET_USER_ROLES,
  payload: {
    roles
  }
});

export interface ISetCoreServicesReady {
  type: CoreStoreActions.SET_CORE_SERVICES_READY,
  payload: {
    statement: boolean,
  }
}

export const setCoreServicesReady = (statement: boolean) => ({
  type: CoreStoreActions.SET_CORE_SERVICES_READY,
  payload: {
    statement
  }
});

export interface ISetCoreError {
  type: CoreStoreActions.SET_ERROR_TYPE,
  payload: {
    error: {
      type: Errors;
      message?: string;
    } | null
  }
}

export const setCoreError = (error: Errors | null, message?: string) => {
  if (error) {
    return {
      type: CoreStoreActions.SET_ERROR_TYPE,
      payload: {
        type: error,
        message,
      }
    }
  }
  return {
    type: CoreStoreActions.SET_ERROR_TYPE,
    payload: {
      error: null,
    }
  }
};

export interface ISetShowLoaderAction {
  type: CoreStoreActions.SET_SHOW_LOADER;
  payload: {
    statement: boolean;
  };
}

export const setShowLoader = (statement: boolean) => ({
  type: CoreStoreActions.SET_SHOW_LOADER,
  payload: {
    statement
  }
});

export interface ISetShowedMessage {
  type: CoreStoreActions.SET_SHOWED_MESSAGE;
  payload: {
    statement: boolean;
  };
}

export const setShowedMessage = (statement: boolean) => ({
  type: CoreStoreActions.SET_SHOWED_MESSAGE,
  payload: {
    statement
  }
});
export interface ISetShowedNewsMessage {
  type: CoreStoreActions.SET_SHOWED_NEWS_MESSAGE;
  payload: {
    statement: boolean;
  };
}

export const setShowedNewsMessage = (statement: boolean) => ({
  type: CoreStoreActions.SET_SHOWED_NEWS_MESSAGE,
  payload: {
    statement
  }
});
export interface ISetShowedMessageResults {
  type: CoreStoreActions.SET_SHOWED_MESSAGE_RESULTS,
  payload: {
    time: number
  }
}

export const setShowedMessageResults = (time: number) => ({
  type: CoreStoreActions.SET_SHOWED_MESSAGE_RESULTS,
  payload: {
    time
  }
});


export interface ISetAuthTokenAction {
  type: CoreStoreActions.SET_AUTH_TOKEN;
  payload: {
    token: string;
  };
}

export const setAuthToken = (token: string) => ({
  type: CoreStoreActions.SET_AUTH_TOKEN,
  payload: {
    token
  }
});

export interface ISetCurrentModuleAction {
  type: CoreStoreActions.SET_CURRENT_MODULE;
  payload: {
    currentModule: string;
  };
}

export const setCurrentModule = (currentModule: string) => ({
  type: CoreStoreActions.SET_CURRENT_MODULE,
  payload: {
    currentModule
  }
});

export interface ISetAPPClaims {
  type: CoreStoreActions.SET_APP_CLAIMS;
  payload: {
    claims: string[];
  };
}

export const setAPPClaims = (claims: string[]) => ({
  type: CoreStoreActions.SET_APP_CLAIMS,
  payload: {
    claims
  }
});

export interface ISetCurrentSelectedRound {
  type: CoreStoreActions.SET_CURRENT_SELECTED_ROUND,
  payload: {
    currentSelectedRound: IRound,
  }
}

export const setCurrentSelectedRound = (round: IRound) => ({
  type: CoreStoreActions.SET_CURRENT_SELECTED_ROUND,
  payload: {
    currentSelectedRound: round
  }
});

export interface ISetUserProfile {
  type: CoreStoreActions.SET_USER_PROFILE,
  payload: {
    profile: IUserProfile,
  }
}

export const setUserProfile = (profile: IUserProfile): ISetUserProfile => ({
  type: CoreStoreActions.SET_USER_PROFILE,
  payload: {
    profile
  }
});

export interface ISetStaticPages {
  type: CoreStoreActions.SET_STATIC_PAGES,
  payload: {
    staticPages: IStaticPages[]
  }
}

export const setStaticPages = (staticPages: IStaticPages[]) => ({
  type: CoreStoreActions.SET_STATIC_PAGES,
  payload: {
    staticPages
  }
});

export interface ISetLanguage {
  type: CoreStoreActions.SET_LANGUAGES,
  payload: {
    languages: ILanguage[]
  }
}

export const setLanguage = (languages: ILanguage[]) => ({
  type: CoreStoreActions.SET_LANGUAGES,
  payload: {
    languages
  }
});

export interface ISetResetCoreStore {
  type: CoreStoreActions.SET_RESET_CORE_STORE,
}

export const setResetCoreStore = (): ISetResetCoreStore => ({
  type: CoreStoreActions.SET_RESET_CORE_STORE,
});

export type Actions = ISetShowLoaderAction &
  ISetUserAvatarURL &
  ISetUserRoles &
  ISetShowedMessage &
  ISetShowedNewsMessage &
  ISetCoreServicesReady &
  ISetAuthTokenAction &
  ISetCurrentModuleAction &
  ISetAPPClaims &
  ISetCoreError &
  ISetCurrentSelectedRound &
  ISetUserProfile &
  ISetStaticPages &
  ISetLanguage &
  ISetFeSettings &
  ISetTenantLogo &
  ISetShowedMessageResults &
  ISetResetCoreStore;
