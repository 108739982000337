const isIE = () => {
  const { userAgent } = navigator;
  return userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;
}

const isChrome = () => {
  const { userAgent, vendor } = navigator;
  return userAgent.indexOf("Chrome ") > -1 || vendor.indexOf("Google Inc/") > -1;
}

const isFirefox = () => {
  const { userAgent } = navigator;
  return userAgent.indexOf("Firefox") > -1;
}

const isSafari = () => {
  const { userAgent } = navigator;
  return userAgent.indexOf("Safari ") > -1
}

export default {
  isIE,
  isFirefox,
  isSafari,
  isChrome
}