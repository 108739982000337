import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { IRankingList } from './ranking-feed';
import { useService, useIcons, IconsNames } from 'modules/core/hooks';
import { UsersService } from 'modules/core/services/users.service';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { userProfileSelector } from 'modules/core/store/selectors';
import { useSelector } from 'react-redux';

export interface IRankingFeedElementProps {
  data: IRankingList;
  hasIcon?: boolean;
}

const makeClasses = makeStyles<DefaultTheme>((theme) => ({
  root: {
    display: 'flex',
    margin: '10px 0',
  },
  rootGroup: {
    display: 'flex',
    margin: theme.spacing(2.5, 0),
    '&:nth-child(1) > div:first-child': {
      backgroundColor: theme.palette.common.trophy
    },
    '&:nth-child(2) > div:first-child': {
      backgroundColor: '#C0C0C0'
    },
    '&:nth-child(3) > div:first-child': {
      backgroundColor: '#CD7F32'
    }
  },
  avatar: {
    width: 44,
    height: 44,
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: theme.spacing(2.5),
    lineHeight: '44px',
    textAlign: 'center',
    fontWeight: 700,
    alignSelf: 'center',
    color: theme.palette.common.black,
    '& img': {
      objectFit: 'cover',
      width: '100%',
      verticalAlign: 'middle'
    }
  },
  content: {
    flex: 1
  },
  name: {
    ...theme.mixins.fonts.medium.xs,
    color: theme.palette.common.black,
  },
  points: {
    fontSize: 12,
    '& span, & svg': {
      display: 'inline',
      verticalAlign: 'middle'
    },
    '& svg': {
      marginRight: 5,
      width: theme.icons.sizes.small,
      height: theme.icons.sizes.small
    }
  },
  rank: {
    fontSize: 15,
    alignSelf: 'center',
    position: 'relative'
  },
  icon: {
    position: 'absolute',
    right: '-15px',
    top: '50%',
    transform: 'translateY(-50%)'
  }
}));

export const RankingFeedElement: React.FC<IRankingFeedElementProps> = (props: IRankingFeedElementProps) => {
  const { t } = useTranslation();
  const classes = makeClasses();
  const [avatarUrl, setAvatarUrl] = React.useState<string>()
  const { data: { points, isGroup, name, rank, avatar, id }, hasIcon } = props;
  const usersService = useService(UsersService);
  const currentUser = useSelector(userProfileSelector);

  const starIcon = useIcons(
    IconsNames.RANKING_STAR,
  );
  const upArrow = useIcons(
    IconsNames.UP_ARROW,
    classes.icon,
  );
  React.useEffect(
    () => {
      if (avatar) {
        fetchAvatar(avatar)
      }
    }, [avatar]
  )
  const fetchAvatar = async (avatarId: string) => {
    const response = await usersService.fetchAvatar(avatarId);
    setAvatarUrl(response);
  }
  return (
    <div className={!isGroup ? classes.root : classes.rootGroup}>
      <div className={classes.avatar}>
        {!isGroup ? (
          <img src={avatarUrl} alt='' />
        ) : (
          <span>{`#${rank}`}</span>
        )}
      </div>
      <div className={classes.content}>
        <Typography className={classes.name}>
          {name}
          {currentUser && currentUser.id === id ? t('common.you') : ''}
        </Typography>
        <div className={classes.points}>
          {starIcon}
          <span>{`${Math.round(points)} ${t('common.points', { count: points })}`}</span>
        </div>
      </div>
      {!isGroup && (
        <div className={classes.rank}>
          <Typography>{`#${rank} `}{currentUser && hasIcon && currentUser.id === id ? upArrow : ''}</Typography>
        </div>
      )}
    </div>
  );
};
