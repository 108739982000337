import { VisualizationType } from 'config';

export const getObjectHeightDescription = (visualisationType: any, value?: number): string => {
  switch (visualisationType) {
    case VisualizationType.SeedBags: {
      return 'common.visualitations.SeedBags';
    }
    case VisualizationType.Seedlings: {
      return 'common.visualitations.Seedlings';
    }
    case VisualizationType.Buildings: {
      return 'common.visualitations.Building';
    }
    case VisualizationType.Trees: {
      return 'common.visualitations.Trees';
    }
    case VisualizationType.FruitTrees: {
      return 'common.visualitations.FruitTrees';
    }
    case VisualizationType.FruitWormTrees: {
      return 'common.visualitations.FruitWormTrees';
    }
    case VisualizationType.m1:
    case VisualizationType.m2:
    case VisualizationType.m3:
    case VisualizationType.m4:
    case VisualizationType.m5:
    case VisualizationType.m6: {
      return 'common.visualitations.level';
    }
    default:
      return 'common.visualitations.other';
  }
};

export const getObjectHeightTitle = (visualisationType: any): string => {
  switch (visualisationType) {
    case VisualizationType.Buildings: return 'common.levelHeightText'
    default: return 'common.growHeightText';
  }
}

export const getObjectHeightUnit = (visualisationType: any): string => {
  switch (visualisationType) {
    case VisualizationType.SeedBags: {
      return '';
    }
    case VisualizationType.Seedlings: {
      return 'common.centimetersShort';
    }
    case VisualizationType.Trees: {
      return 'common.metersShort';
    }
    case VisualizationType.FruitTrees: {
      return '';
    }
    case VisualizationType.FruitWormTrees: {
      return '';
    }
    case VisualizationType.m1:
    case VisualizationType.m2:
    case VisualizationType.m3:
    case VisualizationType.m4:
    case VisualizationType.m5:
    case VisualizationType.m6: {
      return '';
    }
    default:
      return 'common.metersShort';
  }
};

export const getObjectHeight = (visualisationType: any, value: number): number => {
  const height = Math.abs(value) > 0 ? (Math.abs(value) < 10 ? Math.abs(value) : 10) : 1;
  switch (visualisationType) {
    case VisualizationType.SeedBags: {
      return height;
    }
    case VisualizationType.Seedlings: {
      return height;
    }
    case VisualizationType.Trees: {
      return height;
    }
    case VisualizationType.FruitTrees: {
      return height;
    }
    case VisualizationType.FruitWormTrees: {
      return 10 - height;
    }
    case VisualizationType.m1:
      case VisualizationType.m2:
      case VisualizationType.m3:
      case VisualizationType.m4:
      case VisualizationType.m5:
      case VisualizationType.m6: {
      return height - 1;
    }
    default:
      return height;
  }
};
