import React from 'react';
import { IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { MyTableFocusedRowActionsComponent } from './interfaces';

export const MyTableFocusedRowActions: MyTableFocusedRowActionsComponent = props => {
  const { disabled } = props;

  const onAccept = () => {
    props.row.accept();
  };

  const onCancel = () => {
    props.row.cancel();
  };

  return (
    <>
      <IconButton disabled={disabled} onClick={onAccept}>
        <CheckCircleIcon />
      </IconButton>
      <IconButton disabled={disabled} onClick={onCancel}>
        <CancelIcon />
      </IconButton>
    </>
  );
};
