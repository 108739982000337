import React from 'react';
import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';

export interface IInnerSnackbarUtilsConfiguratorProps {
  setSnackbarRef: (handle: WithSnackbarProps) => any,
}

export class NotistackService {

  snackbarHandle?: WithSnackbarProps;

  setSnackbarHandle = (handle: WithSnackbarProps) => {
    this.snackbarHandle = handle;
  };

  InnerSnackbarUtilsConfigurator: React.FC<IInnerSnackbarUtilsConfiguratorProps> = (props) => {
    props.setSnackbarRef(useSnackbar());
    return null;
  };

  SnackbarUtilsConfigurator = () => {
    return <this.InnerSnackbarUtilsConfigurator setSnackbarRef={this.setSnackbarHandle}/>;
  };

  toast(message: string, variant: VariantType) {
    if (this.snackbarHandle) {
      this.snackbarHandle.enqueueSnackbar(message, {
        variant
      });
    }
  }

  forceClose = () => this.snackbarHandle?.closeSnackbar();

  success = (message: string) => this.toast(message, 'success');
  warning = (message: string) => this.toast(message, 'warning');
  info = (message: string) => this.toast(message, 'info');
  error = (message: any) => this.toast(message, 'error');

}
