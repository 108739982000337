import React from 'react';
import _set from 'lodash/set';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { IMyTableExpandedRowProps, IMyTablePreparedRow, MyTableRowComponent } from './interfaces';
import { actions } from './store/actions';
import { MyTableCell } from './my-table-cell';
import { MyTableFocusedRowActions } from './my-table-focused-row-actions';
import { MyTableExpandRow } from './my-table-expand-row';
import { MyTableExpandedRow } from './my-table-expanded-row';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { getRowUUID } from 'modules/core/components/common/my-table/helpers';
import clsx from 'clsx';
import { differenceInHours } from 'date-fns';

const makeClasses = makeStyles<DefaultTheme, { otherRowFocused: boolean }>(theme => ({
  rowRoot: {
    opacity: props => (props.otherRowFocused ? 0.5 : 1),
    userSelect: props => (props.otherRowFocused ? 'none' : 'initial'),
    // '& > td:first-child': {
    //   borderTopLeftRadius: theme.shape.borderRadius,
    //   borderBottomLeftRadius: theme.shape.borderRadius,
    // },
    // '& > td:last-child': {
    //   borderTopRightRadius: theme.shape.borderRadius,
    //   borderBottomRightRadius: theme.shape.borderRadius,
    // }
    '& td': {
      padding: '1px 6px',
    }
  }
}));

export const MyTableRow: MyTableRowComponent = props => {

  const {
    Actions,
    classes,
    events,
    row,
    columns,
    store,
    FocusedActions,
    options,
    overrides,
    extendsProps
  } = props;

  const {
    state,
    dispatch
  } = store;

  const index = React.useMemo(
    () => state.data.findIndex(_row => _row.__table.uuid === row.__table.uuid),
    [state.data, row.__table.uuid]
  );

  const cache = React.useMemo(
    () => state.cached[row.__table.uuid],
    [state.cached, row.__table.uuid]
  );

  const isChecked = React.useMemo(
    () => state.checkedRows.includes(row.__table.uuid),
    [state.checkedRows, row.__table.uuid]
  );

  const isFocused = Boolean(cache);

  const isEditing = Boolean(cache);

  const isExpanded = React.useMemo(
    () => state.expandedRows.includes(row.__table.uuid),
    [state.expandedRows, row.__table.uuid]
  );

  const checkHour = (created: string) => {
    const currentTime = new Date();
    const timeStore = new Date(created);
    return differenceInHours(currentTime, timeStore)
  }

  const isOutdated = React.useMemo(
    () => checkHour(row.created) > 48 || checkHour(row.completionDate) > 48,
    [row.created, row.completionDate]
  );

  const isLately = React.useMemo(
    () => checkHour(row.created) > 24 || checkHour(row.completionDate) > 24,
    [row.created, row.completionDate]
  );

  const canExpand = React.useMemo(
    () => (options?.expandAll) || (!!row.children && !!row.children.length),
    [options, row.children]
  );

  const anyRowFocused = Object.values(state.cached).some(cachedRow => Boolean(cachedRow));

  const otherRowFocused = anyRowFocused && !isFocused;

  const myClasses = makeClasses({
    otherRowFocused
  });

  const preparedRow: IMyTablePreparedRow = {

    id: getRowUUID(row, options?.rowIdentityKey),
    index,
    cache,
    row,
    isExpanded,
    canExpand,
    isFocused,
    isLately,
    isOutdated,
    isChecked,
    isEditing,

    checkAll() {
      return null;
    },

    check() {
      if (this.isChecked) {
        if (events?.onUncheck)
          events.onUncheck(row);
      } else {
        if (events?.onCheck)
          events.onCheck(row);
      }
      dispatch(actions.setCheckedRow(this, state));
    },

    uncheck() {
      dispatch(actions.setRowUnchecked(this, state));
    },

    expand() {
      if (this.canExpand) {
        dispatch(actions.setExpanded(this, state));
      }
    },

    async accept() {
      if (this.isEditing) {
        if (this.cache) {
          if (events?.beforeRowEdit) {
            try {
              await events.beforeRowEdit(this.row, this.cache);
            } catch {
              //
            }
          }
          dispatch(actions.applyCachedRow(this.cache));
        } else throw new Error('Cache has not been found');
      } else throw new Error('Row is not being edited');
    },

    cancel() {
      if (this.isEditing) {
        if (this.cache) {
          dispatch(actions.setCachedRow(this.row.__table.uuid, null));
        } else throw new Error('Cache has not been found');
      } else throw new Error('Row is not being edited');
    },

    async edit(field: string, value: any) {
      if (this.isEditing) {
        if (this.cache) {
          const next = _set({ ...this.cache }, field, value);
          dispatch(actions.setCachedRow(this.row.__table.uuid, next));
        } else throw new Error('Cache has not been found');
      } else throw new Error('Row is not being edited');
    },

    setEditing() {
      dispatch(actions.setCachedRow(row.__table.uuid, row));
    }

  };

  const renderedCheckbox = React.useMemo(() => {
    if (options?.check) {
      return (
        <TableCell
          classes={{
            ...classes?.tableCellClasses,
            ...classes?.tableBodyCellClasses,
            root: clsx(
              classes?.tableCellClasses?.root,
              classes?.tableBodyCellClasses?.root,
              {
                expanded: isExpanded,
              }
            )
          }}
          padding="checkbox">
          <Checkbox onChange={() => preparedRow.check()} color="primary" checked={isChecked}/>
        </TableCell>
      );
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, isChecked, preparedRow, options]);

  const renderedCells = React.useMemo(() => {
    return columns.map((column, columnIndex) => (
      <MyTableCell
        classes={classes}
        isFocused={isFocused}
        isEditing={isEditing}
        column={column}
        row={preparedRow}
        key={columnIndex}
      />
    ));
  }, [classes, columns, isEditing, isFocused, preparedRow]);

  const renderedActions = React.useMemo(() => {
    if (Actions) {
      return (
        <TableCell classes={{
          ...classes?.tableCellClasses,
          ...classes?.tableActionCellsClasses,
          ...classes?.tableBodyCellClasses,
          ...classes?.tableBodyActionCellsClasses,
          root: clsx(
            classes?.tableCellClasses?.root,
            classes?.tableActionCellsClasses?.root,
            classes?.tableBodyCellClasses?.root,
            classes?.tableBodyActionCellsClasses?.root,
            {
              expanded: isExpanded,
              lately: isLately,
              outdated: isOutdated
            }
          )
        }}>
          {isFocused ? (
            FocusedActions ? (
              <FocusedActions row={preparedRow} disabled={otherRowFocused}/>
            ) : (
              <MyTableFocusedRowActions row={preparedRow} disabled={otherRowFocused}/>
            )
          ) : Actions ? (
            <Actions disabled={otherRowFocused} row={preparedRow}/>
          ) : null}
        </TableCell>
      );
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Actions, FocusedActions, classes, isFocused, otherRowFocused, preparedRow]);

  const renderExpandedRow = React.useMemo(() => {
    if (canExpand) {

      const expandedRowProps: IMyTableExpandedRowProps = {
        extendsProps,
        preparedRow,
        columns,
        options,
        events,
        Actions,
        FocusedActions,
        classes,
        _parentState: state,
        _parentDispatch: dispatch
      };
      return (
        <MyTableExpandRow  options={options} size={columns.length + 2}
                          expanded={options?.allRowsExpanded ? !isExpanded : isExpanded}>
          {
            overrides?.ExpandedRow
              ? (<overrides.ExpandedRow {...expandedRowProps} />)
              : <MyTableExpandedRow {...expandedRowProps} />
          }
        </MyTableExpandRow>
      );
    }
    return null;
  }, [
    overrides,
    dispatch,
    state,
    extendsProps,
    canExpand,
    isExpanded,
    preparedRow,
    Actions,
    FocusedActions,
    classes,
    columns,
    events,
    options
  ]);

  return (
    <>
      <TableRow
        classes={{
          ...classes?.tableRowClasses,
          root: clsx(
            myClasses.rowRoot,
            classes?.tableRowClasses?.root,
            {
              expanded: isExpanded,
              lately: isLately,
              outdated: isOutdated,
            }
          )
        }}
      >
        {renderedCheckbox}
        {renderedCells}
        {renderedActions}
      </TableRow>
      {renderExpandedRow}
    </>
  );
};
