import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import { Grid, Typography } from '@material-ui/core';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  container: {
    background: theme.palette.common.white,
    borderRadius: 5,
    padding: '10px 15px',
    maxWidth: 340,
    marginTop: 5,
    boxShadow: '0px 0px 10px #0000005c'
  },
  icon: {
    fill: 'none',
    stroke: theme.palette.primary.main,
  },
  message: {
    color: theme.palette.common.black
  }
}));

export interface ICustomSnackbarProps {
  icon: JSX.Element,
  message: string,
}

export const CustomSnackbar = React.forwardRef<HTMLDivElement, ICustomSnackbarProps>((props, ref) => {
  const classes = makeClasses();
  const { icon, message } = props;

  return (
    <Grid
      ref={ref}
      container
      spacing={2}
      className={classes.container}
      direction='row'
      wrap='nowrap'
    >
      <Grid item className={classes.icon}>
        {icon}
      </Grid>
      <Grid item>
        <Typography className={classes.message}>{message}</Typography>
      </Grid>
    </Grid>
  )
})
