import { SupportedCompanies } from "config/config.const";

export const changeFavicon = (company: SupportedCompanies) => {
  const favicon: HTMLLinkElement = document.createElement('link');
  favicon.rel = 'icon';
  switch (company) {
    case SupportedCompanies.TH:
      favicon.href = "/favicon.png";
      break;
    case SupportedCompanies.EX:
      favicon.href = "/favicon.png";
      break;
    case SupportedCompanies.GU:
      favicon.href = "/favicon.png";
      break;
    case SupportedCompanies.EDU:
      favicon.href = "/favicon.png";
      break;
  }
  document.getElementsByTagName('head')[0].appendChild(favicon);
};
