import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';

const makeClasses = makeStyles<DefaultTheme, { width: number }>(theme => ({
  progressWrapper: {
    marginTop: '20px',
    width: '100%',
    height: '5px',
    position: 'relative'
  },
  progressBase: {
    width: '100%',
    height: '4px',
    background: theme.palette.grey[300],
    opacity: 0.2,
    position: 'absolute',
    top: '50%',
    left: 0,
    zIndex: 0,
    transform: 'translateY(50%)'
  },
  progressContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
    top: '-6px',
    left: 0
  },
  progressBar: {
    height: '4px',
    width: props => `${props.width}%`,
    background: theme.palette.common.th
  },
  progressValue: {
    color: theme.palette.common.white,
    background: theme.palette.common.th,
    padding: '2px 7px',
    borderRadius: '8px',
    height: '25px',
    width: '45px',
    fontSize: '14px',
    textAlign: 'center'
  }
}));
export interface ProgressBarProps {
  width: number;
}
export const ProgressBar: React.FC<ProgressBarProps> = props => {
  const classes = makeClasses({
    width: props.width
  });

  return (
    <div className={classes.progressWrapper}>
      <div className={classes.progressBase} />
      <div className={classes.progressContainer}>
        <div className={classes.progressBar} />
        <div className={classes.progressValue}>{props.width}%</div>
      </div>
    </div>
  );
};
