import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const makeClasses = makeStyles<DefaultTheme>(theme => ({
  playerWrapper: {
    width: 'auto',
    height: 'auto'
  },
  reactPlayer: {
    paddingTop: '56.25%', // percentage ratio for 16:9. Without this trick video player will have wrong (to small) height
    position: 'relative',
    '& div, & video': {
      position: 'absolute',
      top: 0,
      left: 0
    }
  },
  videoLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0 none',
    maxWidth: '342px',
    height: '192px',
    width: '100%',
    marginBottom: theme.spacing(2),
    color: "#FFF",
    background: '#000'
  },
  playIcon: {
    width: 60,
    height: 60
  },
}));

export interface VideoProps extends ReactPlayerProps {
  className?: string,
  src?: string,
  url?: string
}

export const Video: React.FC<VideoProps> = props => {
  const classes = makeClasses();
  const { src, url, className } = props
  return (
    <div className={clsx(classes.playerWrapper, className)}>
      {url ? (
        <Link className={classes.videoLink} to={url}>
          <PlayCircleFilledIcon className={classes.playIcon} />
        </Link>
      ) : (<ReactPlayer
        width="100%"
        height="auto"
        controls
        url={src}
        {...props}
        className={classes.reactPlayer} // must be done after ...props to override className properly
      />)}
    </div>
  );
};
