import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from 'themes/interfaces/default-theme.interfaces';
import clsx from 'clsx';

export interface SectionProps {
  className?: string
  style?: React.CSSProperties
}

type Props = React.PropsWithChildren<SectionProps>;

const makeClasses = makeStyles<DefaultTheme>(() => ({
  wrapper: {
    position: 'relative',
    flex: '1 0 100%',
    maxWidth: '100%'
  },
}));

export const Section: React.FC<Props> = (props: Props) => {
  const { className,  children, style } = props;
  const classes = makeClasses();
  return (
    <section style={style} className={clsx(classes.wrapper, className)}>
      {children}
    </section>
  )
}