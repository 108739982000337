import { IAPIResponse } from 'interfaces/api-response.interface';
import { IFESettingsType, ITenantLogo } from 'interfaces/settings.interface';
import { setFeSettings, setTenantLogo } from 'modules/core/store/actions';
import store from 'modules/core/store';
import { APIPaths } from 'config/paths';
import { CoreService } from 'modules/core/services/core.service';
import { navigation } from 'const/navigationItems';
import { displayElements } from 'const/displayElements'
export class SettingsService extends CoreService {
  async getSettings(isAdmin: boolean) {
    try {
      const { value }: IAPIResponse<IFESettingsType> = await this.httpClientService.get(isAdmin ? APIPaths.FE_SETTINGS_MANAGE : APIPaths.FE_SETTINGS);
      if (Object.keys(value.user_menu).length === 0) {
        value.user_menu.navigation = navigation
      }
      if (Object.keys(value.displayed_elements).length === 0) {
        value.displayed_elements = displayElements
      }
      if (value.displayed_elements.knowledge_elements) {
        if (Object.keys(value.displayed_elements.knowledge_elements).length === 0) {
          value.displayed_elements.knowledge_elements = displayElements.knowledge_elements
        }
      } else {
        value.displayed_elements.knowledge_elements = displayElements.knowledge_elements
      }
      if (value.displayed_elements.user_panel_elements) {
        if (Object.keys(value.displayed_elements.user_panel_elements).length === 0) {
          value.displayed_elements.user_panel_elements = displayElements.user_panel_elements
        }
      } else {
        value.displayed_elements.user_panel_elements = displayElements.user_panel_elements
      }
      if (value.displayed_elements.layout_wrapper_elements) {
        if (Object.keys(value.displayed_elements.layout_wrapper_elements).length === 0) {
          value.displayed_elements.layout_wrapper_elements = displayElements.layout_wrapper_elements
        }
      } else {
        value.displayed_elements.layout_wrapper_elements = displayElements.layout_wrapper_elements
      }
      if (!value.displayed_elements.show_snackbar_info) {
        value.displayed_elements.show_snackbar_info = displayElements.show_snackbar_info
      }
      if (value.displayed_elements.score_feed_elements) {
        if (Object.keys(value.displayed_elements.score_feed_elements).length === 0) {
          value.displayed_elements.score_feed_elements = displayElements.score_feed_elements
        }
      } else {
        value.displayed_elements.score_feed_elements = displayElements.score_feed_elements
      }
      if (value.displayed_elements.game_results_elements) {
        if (Object.keys(value.displayed_elements.game_results_elements).length === 0) {
          value.displayed_elements.game_results_elements = displayElements.game_results_elements
        }
      } else {
        value.displayed_elements.game_results_elements = displayElements.game_results_elements
      }
      if (!value.displayed_elements.score_list_elements) {
        value.displayed_elements.score_list_elements = displayElements.score_list_elements
      }
      if (value.displayed_elements.points_elements) {
        if (Object.keys(value.displayed_elements.points_elements).length === 0) {
          value.displayed_elements.points_elements = displayElements.points_elements
        }
      } else {
        value.displayed_elements.points_elements = displayElements.points_elements
      }
      this.applySetings(value);
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  async getTenantLogo() {
    try {
      const { value }: IAPIResponse = await this.httpClientService.get(APIPaths.TENANTS_LOGO);
      if (value) { // if value == null use default logo
        this.applyTenantLogo({url: value});
      }
    } catch (e) {
      return this.appService.handleError(e);
    }
  }

  applySetings(feSettings: IFESettingsType) {
    store.dispatch(setFeSettings(feSettings));
  }

  applyTenantLogo(tenantLogo: ITenantLogo) {
    store.dispatch(setTenantLogo(tenantLogo));
  }
}